import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { RadioGroup, RadioItem } from '@finn/design-system/atoms/radio';
import { CalendarFilled } from '@finn/design-system/icons/calendar-filled';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { trackEvent } from '@finn/ui-utils';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { DATE_IN_14_DAYS, DATE_IN_30_DAYS } from '../../../../helpers';
import {
  FilterKey,
  FiltersResponse,
  FiltersState,
} from '../../../filters-management';
import {
  FilterTestID,
  FilterToggleData,
  SHORT_14_DAYS_FILTER,
  SHORT_30_DAYS_FILTER,
} from '../../helpers';
import {
  trackFilterOptionClicked,
  useTrackFilterCategoryClicked,
} from '../../tracking';
import { FilterAccordion } from '../atoms/FilterAccordion';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';
import AvailabilitySelector from './AvailabilitySelector';

type Props = {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onClick: FilterOptionClickFunction;
};

export const AvailabilityFilter = ({
  selectedFilters,
  availableFilters,
  onClick,
}: Props) => {
  const i18n = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDateSelectorExp = useIsABVariant(Features.ExpPLPDateSelector);

  const filterLabel = useMemo(
    () => i18n.formatMessage({ id: 'plp.availability' }),
    [i18n]
  );

  const onCloseModal = () => {
    trackEvent('Availability Date Picker Closed', {});
    setIsModalOpen(false);
  };

  const onOpenModal = () => {
    trackEvent('Availability Date Picker Opened', {});
    setIsModalOpen(true);
  };

  const onDateSelection = useCallback(
    (filtersObject: FiltersState) => {
      const isChanged =
        filtersObject?.available_from !== selectedFilters?.available_from ||
        filtersObject?.available_to !== selectedFilters?.available_to;

      if (isChanged) {
        onClick(filtersObject);
        onCloseModal();
      }
    },
    [onCloseModal, onClick, selectedFilters]
  );

  const trackFilterOpen = useTrackFilterCategoryClicked('availability');

  const selectedDates: [string | undefined, string | undefined] = useMemo(
    () => [selectedFilters.available_from, selectedFilters.available_to],
    [selectedFilters]
  );

  const isAvailabilitySelected = useMemo(
    () => selectedDates.some((date) => date !== undefined && date !== null),
    [selectedDates]
  );

  const selectedDateLabel = useMemo(() => {
    if (!selectedFilters.available_from || !selectedFilters.available_to) {
      return i18n.formatMessage({ id: 'plp.availabilityPicker' });
    }

    return (selectedDates as string[])
      .map((dateStr) => {
        const dayjsDate = dayjs(dateStr);

        const startMonthStr = dayjsDate.format('MMM');
        const startDateStr = dayjsDate.format('D');

        return `${startMonthStr} ${startDateStr}`;
      })
      .join(' - ');
  }, [i18n, isAvailabilitySelected, selectedDates]);

  const selectedValue = selectedFilters[FilterKey.AVAILABLE_TO];

  const availableFilterValues: FilterToggleData[] = useMemo(() => {
    const in14 = dayjs().add(14, 'day');
    const in30 = dayjs().add(30, 'day');

    return [
      {
        value: null,
        label: i18n.formatMessage({ id: 'plp.all' }),
        selected: !selectedValue || selectedValue === null,
        disabled: false,
      },
      {
        value: DATE_IN_14_DAYS,
        label: i18n.formatMessage({ id: 'plp.availabilityFilter.14days' }),
        selected: selectedValue === DATE_IN_14_DAYS,
        disabled: !availableFilters.availability.some((a) =>
          dayjs(a).isBefore(in14)
        ),
      },
      {
        value: DATE_IN_30_DAYS,
        label: i18n.formatMessage({ id: 'plp.availabilityFilter.30days' }),
        selected: selectedValue === DATE_IN_30_DAYS,
        disabled: !availableFilters.availability.some((a) =>
          dayjs(a).isBefore(in30)
        ),
      },
    ];
  }, [availableFilters.availability, i18n, selectedValue]);

  const handleToggle = useCallback(
    (value?: string) => {
      if (value === 'undefined') {
        value = undefined;
      }
      if (value === DATE_IN_14_DAYS) {
        trackFilterOptionClicked(FilterKey.AVAILABLE_TO, SHORT_14_DAYS_FILTER);
      }
      if (value === DATE_IN_30_DAYS) {
        trackFilterOptionClicked(FilterKey.AVAILABLE_TO, SHORT_30_DAYS_FILTER);
      }

      onClick({
        [FilterKey.AVAILABLE_FROM]: undefined,
        [FilterKey.AVAILABLE_TO]: value,
      });
    },
    [onClick, selectedValue]
  );

  return (
    <FilterAccordion
      title={filterLabel}
      count={isAvailabilitySelected && 1}
      onOpen={trackFilterOpen}
      testId={FilterTestID.AvailabilityFilter}
    >
      <div className="w-full">
        <Modal
          open={isModalOpen}
          onOpenChange={(isOpen) => !isOpen && onCloseModal()}
          variant="small"
        >
          <ModalContent>
            <ModalTitle>{filterLabel}</ModalTitle>

            <AvailabilitySelector
              selectedDates={selectedDates}
              availableDates={availableFilters.availability}
              isAvailabilitySelected={isAvailabilitySelected}
              onDateSelection={onDateSelection}
            />
          </ModalContent>
        </Modal>

        {isDateSelectorExp && (
          <div className={'pb-4 pl-1.5'}>
            <RadioGroup
              orientation="vertical"
              value={String(selectedValue)}
              onValueChange={handleToggle}
              className="mt-1"
            >
              {(availableFilterValues as FilterToggleData[]).map(
                ({ value, label, disabled }: FilterToggleData) => (
                  <RadioItem
                    key={label + value}
                    value={String(value)}
                    label={label}
                    className="-ml-1.5"
                    disabled={disabled}
                  />
                )
              )}
            </RadioGroup>
          </div>
        )}

        <Button
          variant="outline"
          size="sm"
          data-testid="availability-openpicker"
          className="h-10 w-full"
          onClick={onOpenModal}
        >
          <CalendarFilled className="mr-2" />
          {selectedDateLabel}
        </Button>
      </div>
    </FilterAccordion>
  );
};
