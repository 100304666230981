import { useMemo } from 'react';

import { formatMessage } from './formatMessage';

export const useIntl = () => {
  return useMemo(
    () => ({
      formatMessage,
    }),
    []
  );
};
