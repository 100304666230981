import { SelectField } from '@finn/auto-ui/components/FormElements/SelectField';
import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { useSignOut } from '@finn/ua-auth';
import { config, useCurrentLocale, useSessionInCookie } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import allFeatureFlags from '~/localization/features2.json';
import DevModalSwitch from '~/modules/dev/DevModalSwitch';
import useActivateTestStripe from '~/modules/dev/hooks/useActivateTestStripe';
import useCosmicHelperActivation from '~/modules/dev/hooks/useCosmicHelperActivation';
import useCosmicS3 from '~/modules/dev/hooks/useCosmicS3';
import useDisableDirectCheckout from '~/modules/dev/hooks/useDisableDirectCheckout';

import useCheckoutHelperActivation from './hooks/useCheckoutHelperActivation';

const DevModal: React.FC<{ open: boolean; onClose(): void }> = ({
  open,
  onClose,
}) => {
  const { isCosmicHelperActive, toggleCosmicHelper } =
    useCosmicHelperActivation();
  const session = useSessionInCookie();
  const { handleSignOut } = useSignOut({ session });
  const { isCheckoutHelperActive, toggleCheckoutHelper } =
    useCheckoutHelperActivation();
  const { isTestStripeEnabled, toggleTestStripe } = useActivateTestStripe();
  const { isCosmicS3, toggleCosmicS3 } = useCosmicS3();
  const { isDirectCheckoutDisabled, toggleDirectCheckoutDisabling } =
    useDisableDirectCheckout();

  const [currentVariants, setCurrentVariants] = useState<
    Record<string, string> | undefined
  >();

  const { locale } = useCurrentLocale();
  const [flagList, disableFlagList, featureFlags] = useMemo(() => {
    const featureFlagsForLocale = allFeatureFlags[locale];
    const flagsWithMultipleBucket = Object.entries(
      featureFlagsForLocale || {}
    ).filter((item: [string, any]) => item[1]?.buckets?.length > 1);
    const otherFlags = Object.entries(featureFlagsForLocale || {}).filter(
      (item: [string, any]) => (item[1]?.buckets?.length || 0) < 2
    );

    return [
      flagsWithMultipleBucket.map((item) => item[0]),
      otherFlags.map((item) => item[0]),
      featureFlagsForLocale,
    ];
  }, [locale]);

  const form = useForm({
    mode: 'onChange',
    defaultValues: flagList.reduce(
      (acc, name) => {
        acc[name] = Cookies.get(`${locale}_${name}`);

        return acc;
      },
      {} as { [key: string]: string }
    ),
  });

  useEffect(() => {
    const val = {};
    flagList.forEach((name) => {
      val[name] = Cookies.get(`${locale}_${name}`);
    });
    setCurrentVariants(val);
  }, []);

  const switchExperiment = (name: string) => (variant: string) => {
    Cookies.set(`${locale}_${name}`, variant);
    form.setValue(name, variant);
  };

  return (
    <Modal
      open={open}
      onOpenChange={(isOpen) => {
        if (isOpen) return;

        onClose();
        // if we close with esc, that esc also prevents the reloading. Adding a delay works
        window.setTimeout(() => {
          window.location.reload();
        }, 250);
      }}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Dev Controls</ModalTitle>
        </ModalHeader>
        <div className="flex flex-col gap-2">
          <DevModalSwitch
            text="Content Helper"
            hotkey="Control+1"
            isActive={isCosmicHelperActive}
            onClick={toggleCosmicHelper}
          />
          <DevModalSwitch
            text="Checkout Helper"
            hotkey="Control+2"
            isActive={isCheckoutHelperActive}
            onClick={toggleCheckoutHelper}
          />
          <DevModalSwitch
            text="Test Stripe on Prod"
            hotkey="Control+3"
            helperText="Control+3: non-prod ENVs always use test stripe"
            isActive={isTestStripeEnabled}
            onClick={toggleTestStripe}
          />
          <DevModalSwitch
            text="Disable Direct Checkout"
            hotkey="Control+4"
            isActive={isDirectCheckoutDisabled}
            onClick={toggleDirectCheckoutDisabling}
          />
          {config.APP_STAGE === 'development' ? (
            <DevModalSwitch
              text="Use Cosmic Directly From S3 Backup"
              helperText="To toggle, Set COSMIC_USE_S3=yes (or no) in your .env variable"
              hotkey="Control+Shift+Option+9"
              isActive={isCosmicS3}
              onClick={toggleCosmicS3}
            />
          ) : null}

          {currentVariants && (
            <FormProvider {...form}>
              <form>
                <div className="my-6">
                  <h5 className="global-t5-semibold">Experiments</h5>
                </div>
                {flagList.map((name) => (
                  <div className="mb-2" key={name}>
                    <div className="grid grid-cols-2 items-center">
                      <div>{name}</div>
                      <div>
                        <SelectField
                          name={name}
                          placeholder=""
                          options={featureFlags[name].buckets
                            .map(
                              (variant) => ({
                                label: variant,
                                value: variant,
                              })
                              // removing duplicates
                            )
                            .filter(
                              (item, index, arr) =>
                                index ===
                                arr.findIndex(
                                  (value) => item.value === value.value
                                )
                            )}
                          onChange={switchExperiment(name)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="mb-4 mt-10">
                  <h5 className="global-t5-semibold">Disabled Experiments</h5>
                </div>
                <span className="body-14-light">
                  {disableFlagList.join(', ')}
                </span>
              </form>
            </FormProvider>
          )}
        </div>
        <ModalFooter>
          <div className="flex gap-8">
            <Button
              className="w-full"
              onClick={() => {
                onClose();
                window.location.reload();
              }}
            >
              Close & Reload
            </Button>
            <Button
              className="w-full"
              variant="ghost"
              onClick={() => {
                handleSignOut();
              }}
            >
              Logout
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default React.memo(DevModal);
