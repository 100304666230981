import { IntlShape } from 'react-intl';

import { FilterValuesObject } from '../../filters-management/helpers/filter-parser';
import { FilterKey } from '../../filters-management/hooks/useGetFilters';
import { isElectricVehicle } from '../../product-cards';
import { getSingleSelectItem } from '../helpers/seo';
import { getCustomTitleAndSeoHeader } from '../helpers/seo-header';

const booleanFiltersToTitle = {
  [FilterKey.HAS_DEALS]: 'plp.discounts',
  [FilterKey.IS_YOUNG_DRIVER]: 'plp.isYoungDriver',
  [FilterKey.HAS_HITCH]: 'plp.hasHitch',
};

export const getSelectedFilterText = (
  i18n: IntlShape,
  filters: FilterValuesObject,
  filterKey: FilterKey
) => {
  if (Array.isArray(filters[filterKey])) {
    const arrVal = getSingleSelectItem(filters, filterKey);

    if (filterKey === FilterKey.TERMS) {
      return arrVal > 1
        ? `${filters[filterKey]} ${i18n.formatMessage({ id: 'plp.months' })}`
        : i18n.formatMessage({ id: 'plp.flex' });
    }

    return arrVal;
  }

  const text = filters[filterKey];
  if (text && booleanFiltersToTitle[filterKey]) {
    return i18n.formatMessage({ id: booleanFiltersToTitle[filterKey] });
  }

  return text;
};

type GetTitleProps<T> = {
  filters: FilterValuesObject;
  i18n: IntlShape;
  routerPath: string;
  pageData: T;
};

export const getTitle = <T>({
  filters,
  i18n,
  routerPath,
  pageData,
}: GetTitleProps<T>) => {
  const customTitle = getCustomTitleAndSeoHeader(routerPath, pageData).title;
  if (customTitle) {
    return customTitle;
  }

  const defaultHeadline = i18n.formatMessage({ id: 'plp.defaultTitle' });
  const autoAboHeadline = i18n.formatMessage({ id: 'plp.headline' });
  const electricHeadline = i18n.formatMessage({ id: 'plp.electricTitle' });
  const dealsHeadline = i18n.formatMessage({ id: 'plp.discountsTitle' });
  const hitchHeadline = i18n.formatMessage({ id: 'plp.hitchTitle' });
  const youngDriverHeadline = i18n.formatMessage({
    id: 'plp.youngDriverTitle',
  });

  const isElectric =
    filters[FilterKey.FUELS]?.length === 1 &&
    isElectricVehicle(filters[FilterKey.FUELS][0]);

  const numFiltersApplied = Object.keys(filters).length;

  const selectedBrand = getSingleSelectItem(filters, FilterKey.BRANDS);
  const selectedModel = getSingleSelectItem(filters, FilterKey.MODELS);
  const selectedFuelType = getSingleSelectItem(filters, FilterKey.FUELS);
  const selectedCarType = getSingleSelectItem(filters, FilterKey.CAR_TYPES);
  const selectedTerm = getSingleSelectItem(filters, FilterKey.TERMS);
  const selectedDeals = !!filters.has_deals;

  if (selectedBrand && selectedModel) {
    return `${selectedBrand} ${selectedModel} ${autoAboHeadline}`;
  } else if (selectedBrand && selectedFuelType && numFiltersApplied === 2) {
    return `${selectedBrand} ${selectedFuelType} ${autoAboHeadline}`;
  } else if (selectedBrand && selectedCarType && numFiltersApplied === 2) {
    return `${selectedBrand} ${selectedCarType} ${autoAboHeadline}`;
  } else if (selectedBrand) {
    return `${selectedBrand} ${autoAboHeadline}`;
  } else if (isElectric) {
    return electricHeadline;
  } else if (selectedCarType) {
    return `${selectedCarType} ${autoAboHeadline}`;
  } else if (selectedFuelType) {
    return `${selectedFuelType} ${autoAboHeadline}`;
  } else if (filters.has_hitch) {
    return hitchHeadline;
  } else if (filters.is_young_driver) {
    return youngDriverHeadline;
  } else if (selectedTerm) {
    const monthsText = `${selectedTerm} ${i18n.formatMessage({
      id: 'plp.months',
    })}`;
    const flexText = i18n.formatMessage({ id: 'plp.flex' });

    return `${selectedTerm > 1 ? monthsText : flexText} ${autoAboHeadline}`;
  } else if (selectedDeals) {
    return dealsHeadline;
  }

  return defaultHeadline;
};
