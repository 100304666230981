import { ReactElement, ReactNode } from 'react';

import { FilterData, TestProps } from '../../helpers';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';
import { FilterAccordion } from './FilterAccordion';
import { FilterOptions } from './FilterOptions';
import { getSelectedCount } from './MiscFilter';

type Props = TestProps & {
  title: string | ReactElement;
  warning?: string;
  data: FilterData;
  disabled?: boolean;
  expanded?: boolean;
  onOptionClick: FilterOptionClickFunction;
  onOpen?: (isOpen: boolean) => void;
  reverse?: boolean;
  children?: ReactNode;
};

export const MultiSelectFilter = ({
  title,
  data,
  warning,
  expanded,
  disabled = false,
  onOpen,
  onOptionClick,
  reverse,
  testId,
  children,
}: Props) => {
  const { selectedValues } = data;

  const selectedCount = getSelectedCount(selectedValues);

  return (
    <FilterAccordion
      title={title}
      warning={warning}
      count={!reverse && selectedCount}
      disabled={disabled}
      expanded={expanded}
      onOpen={onOpen}
      reverse={reverse}
      testId={testId}
    >
      <FilterOptions data={data} onClick={onOptionClick}>
        {children}
      </FilterOptions>
    </FilterAccordion>
  );
};
