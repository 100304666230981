export {
  // CombinedFiltersTags and CombinedFilters are used in discovery plan to be used in b2b
  // they part of public API of UA Vehicle, provide the way to use filters UI across FINN
  CombinedFiltersTags,
  CombinedFilters,

  // filterSubscription and filterMock are mocked data, used in some tests inside finn-auto-ui
  filterSubscription,
  filterMock,
} from './modules/filters';

// Those are part of public legit API of UA Vehicle package
export {
  ProductListing,
  useProducts,
  TopCampaignBanner,
} from './modules/product-listing';

// most of those tracking used in discovery, checkout and ui-modules
// to provide some team specific tracking, general idea
// is to reduce amount of tracking events that package exposes
// TODO
export {
  getEcommerceProduct,
  triggerEcommerceOrderingTrackingEvent,
  ecommerceTrackingEvent,
  type GACustomDimensions,
  type SelectedProductInfo,
  kmOptions,
} from './modules/tracking';

// those mostly used on PDP, after PDP extracted we can remove
// this export
export {
  getDefaultTerm,
  useCalculatePrice,
  usePreDiscountRawValue,
  getKmPackagePriceWithoutVat,
  calculateTotalPrice,
  PRICING_TYPE,
  usePreDiscount,
} from './modules/product-pricing';

// those exports are messed, again, mostly used on PDP,
// and those can be remove after PDP extraction,
// but some also used in sliders, cosmic types, not sure what to do with those
// TODO
export {
  VehicleOfferType,
  type FirstVehicleImageAsBase64,
  type MonthlyPrice,
  Image,
  type CompanyDiscount,
  ProductLink,
  isElectricVehicle,
  getVehicleMonthlyPrice,
  ProductCard,
  ProductImage,
  CampaignCard,
  type Color,
  type VehicleContextType,
  type VehiclesResponse,
  type VehicleColor,
  FUEL,
  type CampaignCardProps,
  getConfigLineLabel,
  getEarliestDelivery,
  isVehicleComingSoon,
  getEquipmentLine,
  getPowerLabel,
  type Review,
  type Brand,
  type VehicleDealInfo,
  type GenericVehicleDetails,
  type OneTimePrice,
  VehicleContext,
  useProductLabel,
} from './modules/product-cards';

// used mostly on checkout, likely it is OK
export {
  usePlpRetentionStore,
  RESTORE_DEDUPING_INTERVAL,
  useReadyForRetentionDeals,
} from './modules/retention';

// mostly used on PDP, can be removed after extraction TODO
// but fetchProductItems and fetchAdjustedProductListItemsResponse are
// likely part of stable API as required for SSR
export {
  getClosedUserGroupId,
  useGetVehicle,
  getVehicle,
  type GetProductListItemsResponse,

  // fetchProductItems -> used to pre-fetch data on server side mostly
  fetchProductItems,
  // fetchAdjustedProductListItemsResponse -> used to pre-fetch data on server side mostly
  fetchAdjustedProductListItemsResponse,
  // FiltersType -> used to be able to send data into fetchProductItems in typesafe way
  type FiltersType,

  // IN_STOCK_LIMIT -> used in discovery and b2b to fetch feed back to
  // useProduct hook as default limit, if nothing else provided
  IN_STOCK_LIMIT,
  deserializeFiltersFromPath,
  serializeFiltersToPath,
  useProductListItems,
  PathFilterProvider,
  QueryFilterProvider,
} from './modules/fleet';

// Used everywhere around from PDP to some modules
// likely long term we want to keep only useFilters or smth like this
// TODO
export {
  // AvailabilityMap -> used in b2b
  type AvailabilityMap,
  // AvailableFilters -> used in PLP advisoris, that are not part of this lib and uniq to discovery
  type AvailableFilters,
  // FilterValuesObject -> general type object used to work with filtering data outside this lib in b2b and discovery
  type FilterValuesObject,
  // Filters -> general type object used to work with filtering data outside this lib in b2b and discovery
  type Filters,
  // FiltersResponse -> used mostly to share filter data between external hooks
  type FiltersResponse,
  // InitialFilterKeysMap -> used mostly to share filter data between external hooks
  type InitialFilterKeysMap,
  // FilterKey -> use in discovery and b2b, mostly to define some default filters
  FilterKey,
  // ImplicitFilterProvider -> used to provide default filters when PLP used outside of regular page
  // for example in PLPGrid used on landing pages
  ImplicitFilterProvider,
  FilterProvider,
  FilterContext,
  // FILTERS_RESPONSE_SWR_FALLBACK_KEY -> used to define some initial filters in discovery mostly
  FILTERS_RESPONSE_SWR_FALLBACK_KEY,
  // VehicleViewKey -> used to define some initial filters in discovery mostly
  VehicleViewKey,
  // VehicleView -> used to define some initial filters in discovery mostly
  VehicleView,

  // getInitialFilterKeysMap -> used to warm up PLP pages
  getInitialFilterKeysMap,
  // getSingleSelectItem -> just a helper function, used around FINN

  // as advisory used only in discovery, we need to export this helper
  getAdvisoryContentSlug,
  // getContentPieces -> used to generate plp page content
  getContentPieces,
  getUrlFromFilter,
  getNoIndexNoFollow,
  defaultFilterValues,
  useFiltersResponse,
  useFilterValues,
  useReplaceFilterValues,
  useGetFilters,
  stringifyFilter,
  useMergeFilterValues,
  trackFilterOptionClicked,
  useAvailableFilters,
  useTrackFilterCategoryClicked,
  parseFilterValues,
} from './modules/filters-management';

// temporary helper to export, TODO
export { getSingleSelectItem } from './modules/content';

// here some global helpers, that are used around FINN
export * from './helpers';
