import { SERVER_DATE_FORMAT } from '@finn/ua-constants';
import dayjs, { Dayjs } from 'dayjs';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useSWRConfig } from 'swr';

import { useRemoteData } from './useRemoteData';

// The type `Deal` defines a set of similar resources from different systems having the same idea:
// - Subscriptions (Ops, Green Dragon aka the New World): based on https://apidocs.finn.auto/api/subscriptions/#operation/getSubscription
// - Un-migrated Subscriptions (Ops, Airtable via Noco aka Old World). These will never be migrated to Green Dragon but are all state = 'ENDED' and their cars are defleeted.
// - Leads (Growth, living inside Hubspot, synced into Noco and retrieved from there). This is the pre-subscription step until Ops takes over.
export type Deal = {
  id: string;
  created_at: string;
  contact_id: string;
  state: 'CREATED' | 'ACTIVE' | 'CANCELED' | 'INACTIVE' | 'STOPPED' | 'ENDED';
  variant?: 'LEAD' | 'SUBSCRIPTION';

  car_id?: string;
  type?: 'B2C' | 'B2B' | 'MINIB2B';

  // retention info
  subscribed_as?:
    | 'EXPANSION'
    | 'NEW_CUSTOMER'
    | 'PROLONGATION'
    | 'REACTIVATION'
    | 'SWAP_DIFFERENT_DAY'
    | 'SWAP_SAME_DAY';
  subscribed_to?:
    | 'PROLONGATION'
    | 'REACTIVATION'
    | 'SWAP_DIFFERENT_DAY'
    | 'SWAP_SAME_DAY';
  followup_subscription_id?: string;
  previous_subscription_id?: string;

  checkout_hash?: string;
  belongs_to_subscription_service?: boolean;

  // only if type === 'SUBSCRIPTION'
  damage_deductible_vollkasko?: number;
  damage_deductible_teilkasko?: number;
  days_left_to_schedule_return: number | null;
  is_return_window_open: boolean;

  // contractual
  term_type?: 'FIXED' | 'OPEN_ENDED';
  term?: number;
  amount?: number;
  mileage_package?: number;
  mileage_package_fee?: number;
  additional_mileage_fee?: number;
  signing_date?: string;
  end_date?: string; //deprecated in favour of minimum_commitment_end_date
  minimum_commitment_end_date?: string;
  auto_renewal_end_date?: string;
  probable_handover_date?: string; // derived date for low-confidence handover date

  // handover preferences
  handover_firstname?: string;
  handover_lastname?: string;
  handover_housenumber?: string;
  handover_street?: string;
  handover_city?: string;
  handover_zip?: string;
  handover_address_extra?: string;
  handover_phone_number?: string;
  preferred_handover_date?: string;
  actual_handover_date?: string;
  lead_handover_date?: string; // field populated from checkout api endpoint

  // return preferences
  preferred_return_date?: string;
  return_type?: 'SELF_RETURN' | 'PICKUP';
  return_phone_number?: string;
  // client only property taking everything into account but the currently planned delivery date
  return_date?: string;
  actual_return_date?: string;

  // only from Noco (serving un-migrated old world subscriptions)
  handover_appointment_date?: string;
  // NOT available on new world Subscriptions
  recurrent_payment_method?: string;

  external_product_id?: string;
  car?: {
    oem?: string;
    model?: string;
    external_product_id?: string;
    picture?: string;
    license_plate?: string;
  };
  // derived properties
  has_auto_renewal?: boolean;
  is_pre_auto_renewal?: boolean;
  is_post_auto_renewal?: boolean;
  auto_renewal_end_date_formatted?: string;
};

const BufferWeeksBefore = 4;
const BufferWeeksAfter = 1;

export const getSwapDateRange = (
  endDate: Dayjs
): { available_from: string; available_to: string } => {
  const availableFromDate = endDate.subtract(BufferWeeksBefore, 'week');
  const availableToDate = endDate.add(BufferWeeksAfter, 'week');

  const isAvailableFromDateInPast = availableFromDate.isBefore(
    dayjs().add(1, 'day')
  );

  return {
    available_from: isAvailableFromDateInPast
      ? dayjs().add(1, 'day').format(SERVER_DATE_FORMAT) // if availableFrom date is in the past set to tomorrow
      : availableFromDate.format(SERVER_DATE_FORMAT),
    available_to: availableToDate.format(SERVER_DATE_FORMAT),
  };
};

export const parseSubscriptionEndDate = (subscription: Deal | null) => {
  const endDateInSubscription =
    subscription?.preferred_return_date ||
    subscription?.return_date ||
    subscription?.minimum_commitment_end_date;

  const endDate = dayjs(endDateInSubscription);

  return {
    hasEndDate: !!endDateInSubscription,
    parsedEndDate: dayjs(endDate, { format: SERVER_DATE_FORMAT }),
    parsedEndDateString: endDate.format(SERVER_DATE_FORMAT),
  };
};

const useCachedDeal = (id: string) => {
  const { cache } = useSWRConfig();
  const subscriptions = cache.get('deals')?.data?.data?.data as Deal[];

  return useMemo(
    () => subscriptions?.find((item) => item.id === id),
    [id, subscriptions]
  );
};

// we use function as useRemoteData may pass some params for us
const dealEndpoint = (id: string) => () => `portal/api/bff/deals/${id}`;

export const useDeal = (id: string, onError?: (httpStatus: number) => void) => {
  const cacheDeal = useCachedDeal(id);
  const router = useRouter();
  const { data, mutate, status, httpStatus } = useRemoteData<Deal>(
    cacheDeal || !id || id === 'no-deal' ? null : `deal/${id}`,
    dealEndpoint(id)
  );

  useEffect(() => {
    if (router.isReady && onError && httpStatus && status === 'error') {
      onError(httpStatus);
    }
  }, [httpStatus, onError, router, status]);

  return useMemo(() => {
    return {
      mutate,
      data: data || cacheDeal,
      status: cacheDeal ? ('success' as const) : status,
    };
  }, [mutate, data, cacheDeal, status]);
};
