import { Button } from '@finn/design-system/atoms/button';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { FilterKey } from '../../../filters-management/hooks/useGetFilters';
import { FilterTestID, type Range } from '../../helpers';
import { PriceInput } from '../atoms/PriceInput';
import { PriceSlider } from '../atoms/PriceSlider';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

type Props = {
  selectedRange: Range;
  availableRange: [number, number];
  isPriceSelected: boolean;
  onPriceSelection: FilterOptionClickFunction;
  maxPriceKey: FilterKey;
  minPriceKey: FilterKey;
};

export const PriceSelector = ({
  selectedRange,
  availableRange,
  isPriceSelected,
  onPriceSelection,
  maxPriceKey,
  minPriceKey,
}: Props) => {
  const i18n = useIntl();

  const adjustedAvailableRange = [availableRange[0], availableRange[1]];

  const minRange = availableRange[0];
  const maxRange = availableRange[1];

  const [currentRange, setCurrentRange] = useState<Range>([
    selectedRange[0] || availableRange[0],
    selectedRange[1] || availableRange[1],
  ]);

  const newMax = useMemo(() => {
    if (selectedRange[1] && selectedRange[1] > adjustedAvailableRange[1]) {
      onPriceSelection({ [maxPriceKey]: undefined });

      return adjustedAvailableRange[1];
    }
  }, [selectedRange[1], adjustedAvailableRange[1], onPriceSelection]);

  const newMin = useMemo(() => {
    if (selectedRange[0] && selectedRange[0] < adjustedAvailableRange[0]) {
      onPriceSelection({ [minPriceKey]: undefined });

      return adjustedAvailableRange[0];
    }
  }, [selectedRange[0], adjustedAvailableRange[0], onPriceSelection]);

  useEffect(() => {
    if (isPriceSelected) {
      let min;
      let max;

      if (!selectedRange[0] && selectedRange[1]) {
        max = newMax || currentRange[1];
        setCurrentRange([adjustedAvailableRange[0], max]);
      } else if (selectedRange[0] && !selectedRange[1]) {
        min = newMin || currentRange[0];
        setCurrentRange([min, adjustedAvailableRange[1]]);
      } else if (selectedRange[0] && selectedRange[1]) {
        min = newMin || currentRange[0];
        max = newMax || currentRange[1];
        setCurrentRange([min, max]);
      }
    } else {
      setCurrentRange([adjustedAvailableRange[0], adjustedAvailableRange[1]]);
    }
  }, [availableRange]);

  const minPriceLabel = i18n.formatMessage({ id: 'plp.minPrice' });
  const maxPriceLabel = i18n.formatMessage({ id: 'plp.maxPrice' });

  const updateTimeoutRef = useRef<number | null>(null);

  const handleMin = useCallback(
    (nextValue: number) => {
      if (
        nextValue < minRange ||
        nextValue > maxRange ||
        (currentRange[1] && nextValue > currentRange[1])
      ) {
        return;
      }

      onPriceSelection({
        [minPriceKey]: nextValue === minRange ? undefined : nextValue,
      });
    },
    [onPriceSelection, minRange]
  );

  const updateRangeMin = useCallback(
    (nextValue: number) => {
      setCurrentRange([nextValue, currentRange[1]]);
      if (updateTimeoutRef.current) clearTimeout(updateTimeoutRef.current);
      updateTimeoutRef.current = setTimeout(
        () => handleMin(nextValue),
        1000
      ) as unknown as number;
    },
    [setCurrentRange, currentRange[1], handleMin, updateTimeoutRef]
  );

  const handleMax = useCallback(
    (nextValue: number) => {
      if (
        nextValue < minRange ||
        nextValue > maxRange ||
        (currentRange[0] && nextValue < currentRange[0])
      ) {
        return;
      }

      onPriceSelection({
        [maxPriceKey]: nextValue === maxRange ? undefined : nextValue,
      });
    },
    [maxRange, onPriceSelection]
  );

  const updateRangeMax = useCallback(
    (nextValue: number) => {
      setCurrentRange([currentRange[0], nextValue]);
      if (updateTimeoutRef.current) clearTimeout(updateTimeoutRef.current);
      updateTimeoutRef.current = setTimeout(
        () => handleMax(nextValue),
        1000
      ) as unknown as number;
    },
    [setCurrentRange, currentRange[0], handleMin, updateTimeoutRef]
  );

  const handleChange = useCallback(
    (value: [number | undefined, number | undefined]) => {
      setCurrentRange(value);
    },
    [setCurrentRange]
  );

  const handlePriceSelection = useCallback(() => {
    onPriceSelection({
      [minPriceKey]: currentRange[0] === minRange ? undefined : currentRange[0],
      [maxPriceKey]: currentRange[1] === maxRange ? undefined : currentRange[1],
    });
  }, [onPriceSelection, currentRange, minRange, maxRange]);

  const handleResetForm = useCallback(() => {
    setCurrentRange([minRange, maxRange]);
    onPriceSelection({
      [minPriceKey]: undefined,
      [maxPriceKey]: undefined,
    });
  }, [setCurrentRange, minRange, maxRange, onPriceSelection]);

  return (
    <div className="-mx-2 flex w-full flex-col overflow-hidden pl-2 pt-1">
      <div className="m-3 p-1" data-testid="price-selector-slider">
        <PriceSlider
          min={minRange}
          max={maxRange}
          value={currentRange}
          onChange={handleChange}
          onChangeCommitted={handlePriceSelection}
        />
      </div>

      <div
        className="m-px mb-3 flex items-center justify-between gap-6 px-0.5"
        data-testid="price-selector-inputs"
      >
        <PriceInput
          label={minPriceLabel}
          min={minRange}
          max={maxRange}
          value={
            currentRange[0] ? Math.round(currentRange[0]) : currentRange[0]
          }
          defaultValue={minRange}
          currentRange={currentRange}
          onChange={updateRangeMin}
          testId={FilterTestID.MinPriceFilter}
        />
        <PriceInput
          label={maxPriceLabel}
          min={minRange}
          max={maxRange}
          value={
            currentRange[1] ? Math.round(currentRange[1]) : currentRange[1]
          }
          defaultValue={maxRange}
          currentRange={currentRange}
          onChange={updateRangeMax}
          testId={FilterTestID.MaxPriceFilter}
        />
      </div>

      <Button
        size="sm"
        variant="action"
        className="w-full"
        onClick={handleResetForm}
        disabled={!isPriceSelected}
      >
        {i18n.formatMessage({ id: 'plp.reset' })}
      </Button>
    </div>
  );
};
