import { useDebouncedValue } from '@finn/ui-utils';
import { useMemo } from 'react';

import { useProductListItems } from '../../fleet';
import { useFilterValues } from '../data/lib';
import {
  FilterValuesObject,
  parseFilterValues,
} from '../helpers/filter-parser';
import { useImplicitFilters } from './useImplicitFilters';

const REQUEST_DEBOUNCE_MS = 250;

export const useResultsCount = (
  nextFilters?: FilterValuesObject,
  delay = REQUEST_DEBOUNCE_MS
) => {
  const filterValues = useFilterValues();
  const implicitFilters = useImplicitFilters();

  const filters = useMemo(
    () => ({
      filters: parseFilterValues({
        ...implicitFilters,
        ...filterValues,
        ...nextFilters,
      }),
      zipCode: undefined,
      limit: 0,
    }),
    [implicitFilters, filterValues, nextFilters]
  );

  const params = useDebouncedValue(filters, delay);

  return useProductListItems(params).total ?? 0;
};
