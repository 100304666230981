import { getCosmicObjectWithApi } from '@finn/ui-cosmic';
import { slugify } from '@finn/ui-utils';
import useSWR from 'swr';

import { DATE_IN_14_DAYS } from '../../../helpers';
import { FilterKey } from '../../filters-management/hooks/useGetFilters';
import { Filters } from '../../filters-management/hooks/useInitialFilterValues';
import { getTermUrlName } from '../helpers/seo-header';

const getSlugSegment = (filterValue: string[] | undefined): string => {
  return filterValue ? `-${filterValue[0]}` : '';
};

export const isAdvisoryContent = (
  filters: Filters,
  filterKeys: (keyof Filters)[]
): boolean => {
  // Check that each specified key has exactly one item and is an array
  const singleItemFilters = filterKeys.every((key) => {
    const value = filters[key];

    return Array.isArray(value) && value.length === 1;
  });

  // Consider the available_to filter if present without the available_from filter
  const quickAvailabilityFilter =
    Object.keys(filters).includes(FilterKey.AVAILABLE_TO) &&
    !Object.keys(filters).includes(FilterKey.AVAILABLE_FROM);

  // Ensure no extra filters are present beyond what's specified in filterKeys
  const onlySpecifiedFilters = Object.keys(filters).every((key) =>
    filterKeys.includes(key as keyof Filters)
  );

  return (singleItemFilters || quickAvailabilityFilter) && onlySpecifiedFilters;
};

export const getAdvisoryContentSlug = (filters: Filters): string => {
  const slugBase = 'plp-advisory';
  let slugSuffix = '-fallback';

  const FilterKeys = {
    Fuels: 'fuels',
    Terms: 'terms',
    Deals: 'deals',
    Hitch: 'has_hitch',
    YoungDriver: 'is_young_driver',
    Brands: 'brands',
    Models: 'models',
    CarTypes: 'cartypes',
    Gearshifts: 'gearshifts',
    Availability: 'available_to',
  } as const;

  const advisoryChecks = [
    [FilterKeys.Brands, FilterKeys.CarTypes],
    [FilterKeys.Brands, FilterKeys.Models],
    [FilterKeys.Brands, FilterKeys.Fuels],
    [FilterKeys.Brands],
    [FilterKeys.CarTypes],
    [FilterKeys.Gearshifts],
    [FilterKeys.Fuels, FilterKeys.CarTypes],
    [FilterKeys.Fuels],
  ];

  for (const check of advisoryChecks) {
    if (isAdvisoryContent(filters, check)) {
      slugSuffix = check
        .map((key: string | number) => getSlugSegment(filters[key]))
        .join('');
      break;
    }
  }

  // if the deals filter is present, we need to append the deals slug
  if (filters.has_deals) {
    slugSuffix = getSlugSegment([FilterKeys.Deals]);
  }

  if (filters.is_young_driver) {
    slugSuffix = getSlugSegment([FilterKeys.YoungDriver]);
  }

  if (filters.has_hitch) {
    slugSuffix = getSlugSegment([FilterKeys.Hitch]);
  }

  if (slugSuffix === '-fallback') {
    // If the terms filter is present, we need to append the terms slug
    if (isAdvisoryContent(filters, [FilterKeys.Terms])) {
      slugSuffix = `-${getTermUrlName(Number(filters.terms![0]))}`;
    }

    // If the 14-day availability filter is present, we need to append the 14-days slug
    if (isAdvisoryContent(filters, [FilterKeys.Availability])) {
      if (filters[FilterKeys.Availability] === DATE_IN_14_DAYS) {
        slugSuffix = `-14-days`;
      }
    }
  }

  return slugify(`${slugBase}${slugSuffix}`);
};

export const useAdvisoryContent = (locale: string, filter: Filters) => {
  const advisoryContentCosmicSlug = getAdvisoryContentSlug(filter);
  const hasAdvisoryContent = !advisoryContentCosmicSlug.includes('fallback');

  return useSWR(
    hasAdvisoryContent && {
      slug: advisoryContentCosmicSlug,
      showMetafields: true,
      locale,
    },
    getCosmicObjectWithApi
  );
};
