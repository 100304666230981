import { usePagination } from '@finn/design-system/atoms/pagination';
import React, { useCallback } from 'react';

import { PaginationNavLink } from './PaginationNavLink';
import { PaginationPageLink } from './PaginationPageLink';

type Props = {
  page: number;
  totalPages: number;
  disabled?: boolean;
  shallow?: boolean;
};

export const Pagination = ({
  page,
  totalPages,
  disabled = false,
  shallow = false,
}: Props) => {
  const { items } = usePagination({
    page,
    count: totalPages,
  });

  const renderNavItems = useCallback((): [
    prevBtn: React.ReactNode,
    nextBtn: React.ReactNode,
    pageItems: React.ReactNode[],
  ] => {
    let prevBtn: React.ReactNode = null;
    let nextBtn: React.ReactNode = null;

    const pageItems: React.ReactNode[] = [];

    for (const item of items) {
      if (item.type === 'previous') {
        prevBtn = (
          <PaginationNavLink
            page={item.page}
            direction={item.type}
            disabled={disabled || item.disabled}
            shallow={shallow}
          />
        );
      } else if (item.type === 'next') {
        nextBtn = (
          <PaginationNavLink
            page={item.page}
            direction={item.type}
            disabled={disabled || item.disabled}
            shallow={shallow}
          />
        );
      } else if (item.type === 'ellipsis') {
        pageItems.push(
          <span className="body-16-semibold inline-block px-0.5 py-2 text-center sm:px-2 sm:py-1">
            ...
          </span>
        );
      } else if (item.type === 'page') {
        pageItems.push(
          <PaginationPageLink
            page={item.page}
            selected={item.selected}
            disabled={disabled || item.disabled}
            shallow={shallow}
          />
        );
      }
    }

    return [prevBtn, nextBtn, pageItems];
  }, [disabled, items, shallow]);

  const [prevBtn, nextBtn, pageItems] = renderNavItems();

  return (
    <nav className="mt-16 flex select-none items-center justify-center sm:justify-between">
      {prevBtn}
      <ul className="m-0 flex list-none p-0">
        {pageItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      {nextBtn}
    </nav>
  );
};
