import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { TrackingEventName } from '@finn/ua-tracking';
import {
  App,
  isMobileApp,
  slugify as slugifyUtil,
  useCurrentApp,
  useCurrentLocale,
} from '@finn/ui-utils';
import Link from 'next/link';
import { ReactNode, useEffect, useState } from 'react';
import slugify from 'slugify';

import { usePreDiscount } from '../../product-pricing';
import { triggerEcommerceOrderingTrackingEvent } from '../../tracking';
import { GenericVehicleDetails } from '../types';

type Props = {
  vehicle: GenericVehicleDetails;
  baseURL?: string;
  urlParams: string;
  isForIframe: boolean;
  className?: string;
  target?: 'details' | 'listing';
  position?: number;
  children?: ReactNode;
};

export const ProductLink = ({
  children,
  baseURL = '',
  vehicle,
  urlParams,
  isForIframe,
  className,
  target = 'details',
  position,
}: Props) => {
  // TODO: Remove after Deals experiment is complete
  const preDiscountPrice = usePreDiscount({ vehicle });
  const isRelaticConfigExp = useIsABVariant(Features.ExpRelatedConfigs);
  const isPDPLeapExp = useIsABVariant(Features.ExpPDPLeap);
  const isExpPDPLeapV2B = useIsABVariant(Features.ExpPDPLeapV2);
  const isExpPDPLeapV2C = useIsCVariant(Features.ExpPDPLeapV2);

  const isB2BApp = useCurrentApp() === App.B2B;
  const { locale } = useCurrentLocale();

  let pdpPath = 'pdp';
  if (isRelaticConfigExp) pdpPath += '1b';
  if (isPDPLeapExp) pdpPath += '2b';
  if (isExpPDPLeapV2B) pdpPath += '3b';
  if (isExpPDPLeapV2C) pdpPath += '3c';

  let href: string = `${baseURL}/${pdpPath}/${vehicle.id}${urlParams}`;
  let vehicleModel = vehicle.model;

  const handleClick = () => {
    triggerEcommerceOrderingTrackingEvent({
      eventName: TrackingEventName.PRODUCT_CLICKED,
      vehicle,
      productInfo: {
        position,
        preDiscountPrice,
      },
    });
  };

  const [windowTarget, setWindowTarget] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    setWindowTarget(
      !isB2BApp && !isMobileApp() && target === 'details' ? '_blank' : undefined
    );
  }, []);

  if (isB2BApp) {
    href = `${baseURL}/product/${vehicle.id}`;
  } else if (target === 'listing') {
    if (vehicle.model.includes('.')) {
      vehicleModel = slugifyUtil(vehicleModel);
    }
    href = `${baseURL}/${BASE_SUBSCRIPTION_PATH}/${slugify(vehicle.brand.id, {
      lower: true,
    })}_${slugify(vehicleModel, { lower: true })}`;
  }

  if (isForIframe) {
    href = `${baseURL}/${locale}/pdp/${vehicle.id}${urlParams}`;

    return (
      <a href={href} target="_blank" rel="noreferrer" className={className}>
        {children}
      </a>
    );
  }

  // Disable prefetching as it generates too many requests on Product API
  return (
    <Link
      href={href}
      prefetch={false}
      className={className}
      target={windowTarget}
      legacyBehavior
    >
      <a onClick={handleClick} className={className} target={windowTarget}>
        {children}
      </a>
    </Link>
  );
};
