import { useIntersectionObserver } from '@finn/ui-utils';
import { useEffect, useRef } from 'react';

export const BottomMarker = ({ onReached }: { onReached: Function }) => {
  const markerRef = useRef<HTMLDivElement | null>(null);

  const isInView = useIntersectionObserver(markerRef, 0, '0px');

  useEffect(() => {
    if (isInView) {
      onReached();
    }
  }, [isInView, onReached]);

  return <div ref={markerRef} className="w-full" data-appid="bottom-marker" />;
};
