import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { useCallback, useState } from 'react';

const useCheckoutHelperActivation = () => {
  const [isCheckoutHelperActive, setIsCheckoutHelperActive] = useState(
    Cookies.get(CookieKeys.DEV_PASS) === Cookies.get(CookieKeys.CHECKOUT_HELPER)
  );
  const toggleCheckoutHelper = useCallback(() => {
    if (isCheckoutHelperActive) {
      Cookies.set(CookieKeys.CHECKOUT_HELPER, 'no');
      setIsCheckoutHelperActive(false);
    } else {
      Cookies.set(CookieKeys.CHECKOUT_HELPER, Cookies.get(CookieKeys.DEV_PASS));
      setIsCheckoutHelperActive(true);
    }
  }, [isCheckoutHelperActive]);

  return { isCheckoutHelperActive, toggleCheckoutHelper };
};

export default useCheckoutHelperActivation;
