import { Deal } from '@finn/platform-modules';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type PlpRetentionStoreState = {
  lastOpenedSubscription: Deal | null;
  setLastOpenedSubscription: (subscription: Deal) => void;
};

export const usePlpRetentionStore = create<PlpRetentionStoreState>()(
  devtools(
    immer((set) => ({
      lastOpenedSubscription: null,
      setLastOpenedSubscription: (subscription) => {
        set((state) => {
          state.lastOpenedSubscription = subscription;
        });
      },
    })),
    { name: 'PlpRetentionStore' }
  )
);
