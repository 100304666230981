import { FilterKey } from '../filters-management';

export type Range = [number | undefined, number | undefined];

export type FilterToggleData = {
  value: string | number;
  label: string;
  image?: any;
  selected?: boolean;
  disabled?: boolean;
  shouldReturnArray?: boolean;
  hidden?: boolean;
};

export type FilterData = {
  filterKey: FilterKey;
  brands?: string[];
  selectedValues?: any;
  availableFilterValues: FilterToggleData[] | FilterToggleData[][];
};

export type TestProps = {
  testId?: string;
};

// TODO remove re-export
export { FilterTestID } from './testing';

export const SHORT_DEALS_FILTER = 'deals';
export const SHORT_HITCH_FILTER = 'hitch';
export const SHORT_YOUNG_DRIVER_FILTER = 'young-driver';
export const SHORT_14_DAYS_FILTER = '14-days';
export const SHORT_30_DAYS_FILTER = '30-days';
export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';
