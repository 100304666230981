import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FiltersResponse, FiltersState } from '../../../filters-management';
import { FilterKey } from '../../../filters-management/hooks/useGetFilters';
import { TestProps } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { FilterAccordion } from '../atoms/FilterAccordion';
import {
  PopularFilterData,
  PopularFiltersOptions,
} from './PopularFiltersOptions';

type Props = TestProps & {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onOptionClick: (filterObject: FiltersState) => void;
  expanded?: boolean;
};

const FILTERS = [
  { value: 'Elektro', key: FilterKey.FUELS, label: 'Elektro' },
  { value: 'Automatik', key: FilterKey.GEARSHIFTS, label: 'Automatik' },
  { value: 'Audi', key: FilterKey.BRANDS, label: 'Audi' },
  { value: true, key: FilterKey.HAS_DEALS, label: 'Deals' },
  { value: 'BMW', key: FilterKey.BRANDS, label: 'BMW' },
  { value: 'Benzin', key: FilterKey.FUELS, label: 'Benzin' },
  { value: 'SUV', key: FilterKey.CAR_TYPES, label: 'SUV' },
];

export const PopularFilters = ({
  selectedFilters,
  availableFilters,
  onOptionClick,
  expanded,
}: Props) => {
  const trackFilterOpen = useTrackFilterCategoryClicked(FilterKey.POPULAR);
  const i18n = useIntl();

  const isFilterAvailable = useCallback(
    (filterKey: FilterKey, value: string | boolean) => {
      if (filterKey === FilterKey.HAS_DEALS) {
        // This one is currently not controlled by the backend
        return true;
      }

      if (filterKey === FilterKey.BRANDS) {
        return availableFilters.brands.find((brand) => brand.id === value)
          ?.available;
      }

      if (Array.isArray(availableFilters[filterKey])) {
        return availableFilters[filterKey].includes(value);
      }

      return !!availableFilters[filterKey];
    },
    [availableFilters]
  );

  const filters = useMemo<PopularFilterData[]>(() => {
    return FILTERS.map((filter) => {
      const { key, value, label } = filter;

      const selectedVal = selectedFilters?.[filter.key];
      const selected = Array.isArray(selectedVal)
        ? selectedVal.includes(filter.value)
        : selectedVal === filter.value;

      const disabled = !isFilterAvailable(key, value);

      return {
        key,
        data: { value, label, selected, disabled },
        selectedValues: selectedFilters[key],
      };
    });
  }, [selectedFilters, availableFilters]);

  const selectedCount = filters.filter((filter) => filter.data.selected).length;

  return (
    <FilterAccordion
      title={i18n.formatMessage({ id: 'plp.populars' })}
      count={selectedCount > 0 && selectedCount}
      expanded={expanded}
      onOpen={trackFilterOpen}
    >
      <PopularFiltersOptions filters={filters} onClick={onOptionClick} />
    </FilterAccordion>
  );
};
