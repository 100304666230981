import { TAX_RATE } from '@finn/ua-constants';
import {
  Features,
  useDownPayment,
  useIsABVariant,
} from '@finn/ua-featureflags';
import { CookieKeys, getClientBooleanCookie } from '@finn/ui-utils';
import { useCallback, useMemo } from 'react';

import {
  SortKey,
  useAvailableFiltersForState,
  useFilterValues,
} from '../../filters-management';
import {
  GenericVehicleDetails,
  ItemType,
  Model,
  ModelCardWithType,
  VehicleWithType,
} from '../../product-cards';
import { calculateTotalPrice } from '../../product-pricing';
import { useIsOutOfStock } from './useIsOutOfStock';

const getModelKey = (brandId: string, modelId: string) =>
  `${brandId}::${modelId}`;

const MINIMUM_RESULTS_FOR_GROUPING = 7;

export type VehicleOrModelDetails = VehicleWithType | ModelCardWithType;

export const useVehiclesByModel = (vehicles: GenericVehicleDetails[]) => {
  const isVariant = useIsABVariant(Features.ExpPLPModelCards);
  const { isDownPaymentEnabled } = useDownPayment();
  const forBusinessCookie = getClientBooleanCookie(CookieKeys.IS_FOR_BUSINESS);
  const filterValues = useFilterValues();
  const availableFilters = useAvailableFiltersForState(filterValues);
  const isOutOfStock = useIsOutOfStock();

  const isEnabled = useMemo(() => {
    const allowedFiltersApplied =
      !filterValues?.models?.length && !filterValues.has_deals;

    return isVariant && allowedFiltersApplied && !isOutOfStock;
  }, [isVariant, filterValues?.models, filterValues.has_deals, isOutOfStock]);

  const getModelInfoMap = useCallback(() => {
    if (!isEnabled) {
      return {};
    }

    const map = {};
    availableFilters.brands.forEach((brand) => {
      brand.models.forEach((model) => {
        map[getModelKey(brand.id, model.id)] = model;
      });
    });

    return map;
  }, [isEnabled, availableFilters]);

  const getDisplayPrice = useCallback(
    (modelInfo: Model, vehicle: GenericVehicleDetails) => {
      if ([SortKey.ASC, SortKey.DESC].includes(filterValues.sort)) {
        // Because the API returns them sorted, this will be the price price to show in the card
        return calculateTotalPrice({
          vehicle,
          term: filterValues?.terms?.[0],
          isDownPayment: isDownPaymentEnabled,
        });
      }

      const price = isDownPaymentEnabled
        ? modelInfo.cheapest_downpayment_price
        : modelInfo.cheapest_price;

      return forBusinessCookie ? price * (1 - TAX_RATE) : price;
    },
    [filterValues, forBusinessCookie, isDownPaymentEnabled]
  );

  const mergedVehicles = useMemo<VehicleOrModelDetails[]>(() => {
    if (!isEnabled) {
      return vehicles.map((vehicle) => ({
        ...vehicle,
        type: ItemType.VEHICLE,
      }));
    }

    const modelInfoMap = getModelInfoMap();

    const result: VehicleOrModelDetails[] = [];
    const alreadyMerged = new Set<string>();

    vehicles.forEach((vehicle) => {
      const modelKey = getModelKey(vehicle.brand.id, vehicle.model);
      const modelInfo =
        modelInfoMap[getModelKey(vehicle.brand.id, vehicle.model)];

      if (!modelInfo) {
        // It is possible that the model became unavailable (i.e. out of stock) while the user was browsing
        result.push({
          ...vehicle,
          type: ItemType.VEHICLE,
        });

        return;
      }

      if (alreadyMerged.has(modelKey)) {
        return;
      }

      alreadyMerged.add(modelKey);

      const configsCount =
        modelInfo.configs_count < MINIMUM_RESULTS_FOR_GROUPING
          ? modelInfo.configs_count
          : modelInfo.grouped_configs_count;

      if (configsCount < 2) {
        result.push({
          ...vehicle,
          type: ItemType.VEHICLE,
        });

        return;
      }

      const displayPrice = getDisplayPrice(modelInfo, vehicle);

      result.push({
        type: ItemType.MODEL_CARD,
        brand: vehicle.brand.id,
        model: vehicle.model,
        imageURL: modelInfo.picture?.url ?? '',
        configsCount,
        displayPrice,
      });
    });

    return result;
  }, [isEnabled, vehicles, getModelInfoMap, getDisplayPrice]);

  return {
    mergedVehicles,
  };
};
