import { REFERRAL_VOUCHER_COOKIE_EXPIRATION } from '@finn/ua-constants';
import {
  clearClientCookie,
  CookieKeys,
  getClientCookie,
  setClientCookie,
  traceabilityHeadersBrowser,
  useCurrentLocale,
} from '@finn/ui-utils';
import { useCallback, useEffect } from 'react';

import { useVoucherStore } from '~/modules/checkout/stores/useVoucherStore';
import { getVoucherByHash } from '~/services/voucher';

import { VoucherClass } from '../types/voucher';
import {
  isValidPartnerLP,
  isValidRelativePartnerVoucher,
} from './usePartnerDiscount';

const VoucherParam = 'VoucherCode';

export const useProcessVoucherParam = () => {
  const setHasVoucher = useVoucherStore((state) => state.setHasVoucher);
  const { locale } = useCurrentLocale();

  const cleanUpAndShowToast = useCallback(() => {
    clearClientCookie(CookieKeys.REFERRAL_VOUCHER_CODE);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const voucherInCookie = getClientCookie(CookieKeys.VOUCHER_CODE);
    const voucherHash = urlParams.get(VoucherParam) || voucherInCookie;
    const { pathname: path } = window.location;

    if (voucherHash) {
      getVoucherByHash(voucherHash, locale, traceabilityHeadersBrowser())
        .then((data) => {
          if (isValidRelativePartnerVoucher(data) && !isValidPartnerLP(path)) {
            return;
          }

          if (data.status === 200 && data.is_valid) {
            setClientCookie(
              CookieKeys.REFERRAL_VOUCHER_CODE,
              JSON.stringify(data),
              REFERRAL_VOUCHER_COOKIE_EXPIRATION
            );
            // CUG_ID and REFERRAL_VOUCHER_CODE/closed_user_group are mutually exclusive
            if (data.class === VoucherClass.closed_user_group)
              clearClientCookie(CookieKeys.CLOSED_USER_GROUP_ID);

            setHasVoucher(true);
          } else {
            cleanUpAndShowToast();
          }
        })
        .catch(() => {
          cleanUpAndShowToast();
        });
    }
    // run once
  }, []);
};
