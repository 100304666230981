import { useMediaQuery } from '@finn/design-system/helpers/media';
import { useDeals } from '@finn/platform-modules';
import { useCurrentLocale } from '@finn/ui-utils';
import { RefObject, useEffect, useMemo, useRef } from 'react';

import {
  FilterKey,
  FilterValuesObject,
  useFilterValues,
  VehicleViewKey,
} from '../../filters-management';
import {
  DEFAULT_LIMIT,
  GetVehiclesResponse,
  useProductListItems,
} from '../../fleet';
import { GenericVehicleDetails } from '../../product-cards';
import { useCurrentPage } from './useCurrentPage';

const MOBILE_SCROLL_OFFSET = 120;
const SCROLL_OFFSET = 60;

type UseProducts = {
  infinite?: boolean;
  initialFilters?: FilterValuesObject;
  limit?: number;
  offset?: number;
  fallbackData?: GetVehiclesResponse;
  gridRef?: RefObject<HTMLDivElement>;
};

const getStatus = ({
  isLoading,
  isValidating,
  offset,
  products,
}: {
  isLoading: boolean;
  isValidating: boolean;
  offset: number | null;
  products: GenericVehicleDetails[];
}) => {
  if (isValidating) {
    return 'validating' as const;
  }
  if (isLoading && !offset && products.length === 0) {
    return 'loading' as const;
  }

  return 'success' as const;
};

export const useProducts = ({
  infinite,
  initialFilters,
  gridRef,
  limit = DEFAULT_LIMIT,
  offset,
  fallbackData,
}: UseProducts) => {
  const isMobile = useMediaQuery('md');
  const { isUSA } = useCurrentLocale();
  const filtersFromContext = useFilterValues();
  const page = useCurrentPage() ?? 1;
  const offsetToUse = infinite ? offset : limit * (page - 1);

  const { deals: subscriptions } = useDeals();

  const filters = useMemo(() => {
    const result: FilterValuesObject = {
      ...filtersFromContext,
      ...initialFilters,
    };

    const hasSubscriptions = subscriptions?.length > 0;
    const hasRetentionFilter = result[FilterKey.RETENTION];

    const isViewAlreadySet = Boolean(result[FilterKey.VIEW]);
    const isRetentionCustomer = hasRetentionFilter || hasSubscriptions;

    if (!isViewAlreadySet) {
      result[FilterKey.VIEW] =
        isRetentionCustomer || isUSA
          ? VehicleViewKey.AVAILABLE_AND_COMING_SOON
          : VehicleViewKey.AVAILABLE;
    }

    return result;
  }, [filtersFromContext, initialFilters, subscriptions?.length, isUSA]);

  const productsRes = useProductListItems(
    {
      zipCode: undefined,
      filters,
      offset: offsetToUse,
    },
    { infinite, fallbackData }
  );

  const isInitRef = useRef(false);

  // Scrolling back to the top of the product list when the filters get changed
  useEffect(() => {
    if (gridRef.current) {
      if (!isInitRef.current) {
        // give it couple of seconds to apply filters from saved cookie (e.g. is_for_business) or url
        setTimeout(() => {
          isInitRef.current = true;
        }, 2000);

        return;
      }
      let offsetTop = 0;
      let element: HTMLDivElement = gridRef.current;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent as HTMLDivElement;
      }

      if (isMobile) {
        offsetTop -= MOBILE_SCROLL_OFFSET;
      } else {
        offsetTop -= SCROLL_OFFSET;
      }

      if (window.scrollY < offsetTop) {
        return;
      }

      window.scrollTo({
        top: offsetTop,
      });
    }
  }, [isMobile, filtersFromContext]);

  return useMemo(
    () => ({
      ...productsRes,
      filters,
      page,
      status: getStatus(productsRes),
    }),
    [productsRes, filters, page]
  );
};
