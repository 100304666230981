import { useMemo } from 'react';

// inspiration https://github.com/mui/material-ui/blob/d0a59894122d209958beda64e17f5987fef141b2/packages/mui-material/src/usePagination/usePagination.js#L4

const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

const buttonPage = (type: string, page: number, count: number) => {
  switch (type) {
    case 'first':
      return 1;
    case 'previous':
      return page - 1;
    case 'next':
      return page + 1;
    case 'last':
      return count;
    default:
      return null;
  }
};

export const usePagination = ({
  count,
  page,
}: {
  count: number;
  page: number;
}) => {
  const boundaryCount = 1;
  const siblingCount = 1;
  const startPages = range(1, Math.min(boundaryCount, count));
  const endPages = range(
    Math.max(count - boundaryCount + 1, boundaryCount + 1),
    count
  );
  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      page - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );
  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      page + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    count - boundaryCount - 1
  );

  return useMemo(() => {
    // Basic list of items to render
    // for example itemList = ['first', 'previous', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next', 'last']
    const itemList = [
      'first',
      'previous',
      ...startPages,

      // Start ellipsis
      ...(siblingsStart > boundaryCount + 2
        ? ['ellipsis']
        : boundaryCount + 1 < count - boundaryCount
          ? [boundaryCount + 1]
          : []),

      // Sibling pages
      ...range(siblingsStart, siblingsEnd),

      // End ellipsis
      ...(siblingsEnd < count - boundaryCount - 1
        ? ['ellipsis']
        : count - boundaryCount > boundaryCount
          ? [count - boundaryCount]
          : // we ignore it as right now this case not possible
            // but it can become possible with dynamic sibling count
            /* istanbul ignore next */ []),

      ...endPages,
      'next',
      'last',
    ];

    return {
      items: itemList.map((item) => {
        return typeof item === 'number'
          ? {
              type: 'page',
              page: item,
              selected: item === page,
              disabled: false,
              'aria-current': item === page ? 'true' : undefined,
            }
          : {
              type: item,
              page: buttonPage(item, page, count),
              selected: false,
              disabled:
                !item.includes('ellipsis') &&
                (item === 'next' || item === 'last'
                  ? page >= count
                  : page <= 1),
            };
      }),
    };
  }, [
    count,
    page,
    boundaryCount,
    startPages,
    endPages,
    siblingsStart,
    siblingsEnd,
  ]);
};
