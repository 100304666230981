import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@finn/design-system/atoms/select';
import { cn, OptionType } from '@finn/ui-utils';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

type SelectFieldProps = {
  name: string;
  placeholder: string;
  options: OptionType<string | number>[];
  disabled?: boolean;
  className?: string;
  onChange?: (value: string) => void;
};

export const SelectField = ({
  name,
  placeholder,
  options,
  disabled,
  className,
  onChange,
}: SelectFieldProps) => {
  const i18n = useIntl();
  const form = useFormContext();
  const errorMessageId =
    !isEmpty(get(form.formState.errors, name)) &&
    get(form.formState.errors, name).message;

  if (!options) {
    return null;
  }

  return (
    <div>
      <Controller
        control={form.control}
        name={name}
        render={({ field }) => (
          <Select
            value={field.value}
            onValueChange={(value) => {
              onChange?.(value);
              field.onChange?.(value);
            }}
          >
            <SelectTrigger
              disabled={disabled}
              error={Boolean(errorMessageId)}
              className={cn('w-full', className)}
            >
              <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
              {options?.map((option, index) => (
                <SelectItem key={index} value={String(option.value)}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
      {errorMessageId ? (
        <p className="body-12-regular text-red mt-3">
          {i18n.formatMessage({
            id: errorMessageId,
          })}
        </p>
      ) : null}
    </div>
  );
};
