import { Drawer as DrawerPrimitive } from 'vaul';

import { Button } from '../button';
import { ArrowBackIos } from '../icons/generated/arrow-back-ios';
import { Close as CloseIcon } from '../icons/generated/close';

const { Close } = DrawerPrimitive;

export const renderDefaultHeader = ({ onBack }: { onBack?: () => void }) => (
  <div className="flex justify-between">
    {onBack ? (
      <Button variant="ghost" className="h-max p-0" onClick={onBack}>
        <ArrowBackIos aria-label="back" />
      </Button>
    ) : (
      <div />
    )}
    <Close asChild>
      <Button variant="ghost" className="h-max p-0">
        <CloseIcon aria-label="close-icon" />
      </Button>
    </Close>
  </div>
);
