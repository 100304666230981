import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { slugify } from '@finn/ui-utils';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import toLower from 'lodash/toLower';
import { stringify } from 'query-string';

import { DATE_IN_14_DAYS } from '../../../helpers';
import { getTermUrlName } from '../../content/helpers/seo-header';
import { FilterKey } from '../hooks/useGetFilters';
import { Filters } from '../hooks/useInitialFilterValues';
import {
  firstLvlPathParamsKeys,
  secondLvlPathParamsKeys,
  thirdLvlQueryParamsKeys,
} from './constants';
import { FilterValuesObject } from './filter-parser';

export const stringifyFilter = (filterValues: Filters) => {
  return stringify(filterValues, {
    arrayFormat: 'comma',
    skipNull: true,
  });
};

// This function take lvl filters & current filter config
// Loop through the lvl filters & get the current values from the filter config
// Then if the filter key has a value(Array not null) it will join them to string by "."
// then it will join the filter group by "_"
const getUrlLvl = (filterKeys: string[], filters: Filters) =>
  filterKeys
    .map((filterKey) => {
      let filterValueList = get(filters, filterKey);

      if (!filterValueList) {
        filterValueList = [];
      } else if (!Array.isArray(filterValueList)) {
        filterValueList = [filterValueList];
      }

      return filterValueList
        .map((filterValue: string) => {
          if (filterKey === 'terms') {
            // Because we need to add "Monat, Monate" wording to the term in the url
            return getTermUrlName(Number(filterValue));
          } else {
            return slugify(filterValue);
          }
        })
        .join('.');
    })
    .filter((filterGroup) => Boolean(filterGroup))
    .join('_');

// This function use getUrlLvl function for every lvl
// Then if the returned value is valid it will push it to the url
// For lvl3 it will pick the lvl3 values from the filter config && stringify the result and add them to the url
// in the end it will check if it should add fahrzeuge/Kaufen to the url or no
type GetUrlFromFilter = (
  filters: Filters,
  useQueryParamsOnly?: boolean
) => string;

export const getUrlFromFilter: GetUrlFromFilter = (
  filters,
  useQueryParamsOnly
) => {
  let url = '';
  if (useQueryParamsOnly) {
    const query = toLower(stringifyFilter(filters));

    if (query) {
      url = `?${toLower(stringifyFilter(filters))}`;
    }

    return url;
  }

  const lvl1 = getUrlLvl(Object.values(firstLvlPathParamsKeys), filters);
  if (lvl1) {
    url = `${url}/${lvl1}`;
  }

  const lvl2 = getUrlLvl(secondLvlPathParamsKeys, filters);
  if (lvl2) {
    url = `${url}/${lvl2}`;
  }

  const lvl3Filter = pick(filters, thirdLvlQueryParamsKeys);
  const lvl3 = toLower(stringifyFilter(lvl3Filter));

  if (lvl3) {
    url = `${url}?${lvl3}`;
  }

  return `/${BASE_SUBSCRIPTION_PATH}${url}`;
};

// This function used to get noindex-nofollow when the filter change
// It depend on hte structure ofn the url && results
export const getNoIndexNoFollow = (
  filter: FilterValuesObject,
  url: string,
  totalCount: number
) => {
  const lvl1Filter = pick(filter, Object.values(firstLvlPathParamsKeys));
  const lvl2Filter = pick(filter, secondLvlPathParamsKeys);
  const lvl3Filter = pick(filter, thirdLvlQueryParamsKeys);

  const avToFilter = lvl3Filter[FilterKey.AVAILABLE_TO];
  if (avToFilter && avToFilter === DATE_IN_14_DAYS) {
    return false;
  }

  //NOINDEX,FOLLOW filtering by params included
  const isLvl3Empty = every(lvl3Filter, isEmpty);
  if (!isLvl3Empty) {
    return true;
  }

  // NOINDEX,FOLLOW multiple filters in URL - multiple selections from same filter in lvl1 or lvl2
  const isMultipleFeatures = url.split('.').length > 1;
  if (isMultipleFeatures) {
    return true;
  }

  // INDEX,FOLLOW /subscribe page - unfiltered PLP
  const isLvl1Empty = every(lvl1Filter, isEmpty);
  const isLvl2Empty = every(lvl2Filter, isEmpty);
  const isNoLevels = isLvl1Empty && isLvl2Empty;
  if (isNoLevels) {
    return false;
  }

  const isLvl1Only = !isLvl1Empty && isLvl2Empty;

  //INDEX,FOLLOW single brand / single brand + single model
  const isSingleBrandWithSingleOrZeroModel =
    isLvl1Only &&
    lvl1Filter.brands?.length === 1 &&
    (lvl1Filter.models?.length === undefined ||
      lvl1Filter.models?.length === 1) &&
    totalCount;
  if (isSingleBrandWithSingleOrZeroModel) {
    return false;
  }

  // NOINDEX,FOLLOW single brand + multiple models
  const isSingleBrandMultiModel =
    lvl1Filter.brands?.length === 1 && lvl1Filter.models?.length > 1;
  if (isSingleBrandMultiModel) {
    return true;
  }

  // INDEX,FOLLOW single brand + single car type / single brand + single fuel type
  const isSingleBrandOnlyWithFuelOrCarType =
    lvl1Filter.brands?.length === 1 &&
    lvl1Filter.models?.length === undefined &&
    ((lvl2Filter.fuels?.length === 1 &&
      lvl2Filter.cartypes?.length === undefined) ||
      (lvl2Filter.cartypes?.length === 1 &&
        lvl2Filter.fuels?.length === undefined)) &&
    totalCount;
  if (isSingleBrandOnlyWithFuelOrCarType) {
    return false;
  }

  // INDEX,FOLLOW /fahrzeuge/feature pages if total count > 0
  // (it was > 2 but looks like that removes puts electric for US as noIndex so changing)
  const isLevel2Only = isLvl1Empty && !isLvl2Empty;
  if (isLevel2Only && totalCount) {
    const secondLvl = url
      .replace(`/${BASE_SUBSCRIPTION_PATH}`, '')
      .split('/')[1];
    if (secondLvl) {
      const secondLvlGroupsLength = secondLvl.split('_').length;
      const featureGroupLength = secondLvl.split('.').length;
      const singleFuelAndCarType =
        secondLvlGroupsLength === 2 &&
        Object.keys(filter).every((key) => ['cartypes', 'fuels'].includes(key));

      if (
        singleFuelAndCarType ||
        (secondLvlGroupsLength === 1 && featureGroupLength === 1)
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};
