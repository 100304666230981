import { useCurrentLocale } from '@finn/ui-utils';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FilterKey } from '../../../filters-management/hooks/useGetFilters';
import { FilterTestID } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { MiscFilter } from '../atoms/MiscFilter';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

type OptionsFilterProps = {
  selectedFilters: boolean[];
  onOptionClick: FilterOptionClickFunction;
};

type FilterData = {
  filterKey: FilterKey[];
  availableFilterValues: string[];
};

export function OptionsFilter({
  selectedFilters,
  onOptionClick,
}: OptionsFilterProps) {
  const i18n = useIntl();
  const { isDE } = useCurrentLocale();
  const trackFilterOpen = useTrackFilterCategoryClicked('miscellaneous');

  const filterData: FilterData = useMemo(() => {
    const allKeysAndValues = [
      {
        key: FilterKey.IS_YOUNG_DRIVER,
        value: i18n.formatMessage({ id: 'plp.isYoungDriver' }),
      },
    ];

    // Define filters to be excluded when isDE is false
    const excludeForNonDE = [FilterKey.IS_YOUNG_DRIVER];

    const filteredKeysAndValues = isDE
      ? allKeysAndValues
      : allKeysAndValues.filter((item) => !excludeForNonDE.includes(item.key));

    return {
      filterKey: filteredKeysAndValues.map((item) => item.key),
      availableFilterValues: filteredKeysAndValues.map((item) => item.value),
    };
  }, [i18n, isDE]);

  if (!filterData.availableFilterValues.length) {
    return null;
  }

  return (
    <MiscFilter
      data={filterData}
      onOpen={trackFilterOpen}
      onOptionClick={onOptionClick}
      selectedValues={selectedFilters}
      title={i18n.formatMessage({ id: 'plp.miscellaneous' })}
      testId={FilterTestID.OptionsFilter}
    />
  );
}
