import { Features } from '@finn/ua-featureflags';
import { isServer, useCurrentLocale } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { useRef } from 'react';

import { Brand, Color } from '../../product-cards';
import { useFilterValues } from '../data/lib';
import { FilterValuesObject } from '../helpers/filter-parser';
import { useFiltersResponse } from './useFilterResponse';
import { FiltersResponse, useGetFilters } from './useGetFilters';

type UseAvailableFilters = {
  filters?: FilterValuesObject;
  isOutOfStockPage?: boolean;
  ssrFilters?: FiltersResponse;
};

export enum VehicleView {
  AVAILABLE = 'available_cars',
  AVAILABLE_AND_COMING_SOON = 'available-and-coming-soon',
  COMING_SOON = 'coming_soon',
  DISPLAYED_CARS = 'displayed_cars',
}

export type AvailabilityMap = {
  [date: string]: { startDate: string; endDate: string };
};

export type AvailableFilters = {
  availability: string[];
  brands: Brand[];
  cartypes: string[];
  models: string[];
  gearshifts: string[];
  terms: number[];
  fuels: string[];
  colors: Color[];
  has_hitch: boolean[];
  is_young_driver: boolean[];
  min_price: number;
  max_price: number;
  min_price_msrp: number;
  max_price_msrp: number;
  is_for_business: boolean[];
  view?: VehicleView;
  total_results: number;
};

/**
 * Returns the next set of filters you could apply, based on already applied filters. (eg. only type SUV when filtering for Humvee)
 *
 * @param filtersObject Object containing currently filters applied in the client and when initially loading the page (ssr)
 * @returns The next set of filters that can be applied
 */
export const useAvailableFilters = (
  filtersObject: UseAvailableFilters
): FiltersResponse => {
  const { locale } = useCurrentLocale();
  const filterValues = useFilterValues();
  const filterResponse = useFiltersResponse();
  const responseRef = useRef<FiltersResponse>(null);

  const filters = filtersObject?.filters;
  const ssrFilters = filtersObject?.ssrFilters;

  const selectedFilters = filters || filterValues || {};

  const hide_related =
    !isServer() &&
    Cookies.get(`${locale}_${Features.ExpRelatedConfigs}`) === 'b';

  const { data: nextFilterResponse } = useGetFilters(
    {
      filters: { ...selectedFilters }, // need to spread to create a copy otherwise a bug appears
      locale,
      zipCode: undefined,
      hide_related,
    },
    filtersObject?.isOutOfStockPage
  );

  responseRef.current =
    nextFilterResponse ?? ssrFilters ?? responseRef.current ?? filterResponse;

  return responseRef.current!;
};
