import { FinancingType, VehicleOfferType } from '@finn/ui-utils';

import { CampaignCardProps } from './hooks/useCampaignCards';

export type Model = {
  id: string;
  picture?: {
    url: string;
  };
  available: boolean;
  cheapest_price?: number;
  cheapest_downpayment_price?: number;
  configs_count?: number;
  grouped_configs_count?: number;
};

type AvailabilityArea = {
  zip_area: string;
};

type Config = {
  link: string;
  name: string;
};

export enum ItemType {
  CAMPAIGN_CARD = 'campaignCard',
  VEHICLE = 'vehicle',
  MODEL_CARD = 'modelCard',
}

export type VehicleWithType = GenericVehicleDetails & {
  type: ItemType.VEHICLE;
};

export type CampaignCardWithType = CampaignCardProps & {
  type: ItemType.CAMPAIGN_CARD;
};

export type CombinedItem =
  | CampaignCardWithType
  | VehicleWithType
  | ModelCardWithType;

export const isModelCard = (item: CombinedItem): item is ModelCardWithType => {
  return item.type === ItemType.MODEL_CARD;
};

export type ModelCardWithType = {
  brand: string;
  model: string;
  imageURL: string;
  configsCount: number;
  displayPrice: number;
  type: ItemType.MODEL_CARD;
};

export type GenericVehicleDetails = {
  default_term: number;
  default_downpayment_term: number;
  related_configs?: number[];
  product_desktop_sorting: number;
  co2emission: number;
  uid: string;
  description: string;
  available_from: string;
  available_from_deviation_in_weeks?: number;
  availability: number;
  indexable: boolean;
  available_states: Record<string, AvailabilityArea[]>;
  available_zip_codes: string[];
  brand: VehicleBrand;
  cartype: CarType;
  color: Color;
  consumption: number;
  consumption_city?: number;
  consumption_highway?: number;
  earliest_expected_available_from?: string;
  equipment: Equipment;
  equipment_packages: Equipment;
  equipment_delimiter?: string;
  fuel: string;
  gearshift: string;
  id: string;
  product_label: ProductLabel;
  model: string;
  picture: Picture;
  pictures: Picture[];
  power: number;
  single_payment_method_list: string[];
  seo_canonical_product_id: string;
  config?: Config;
  config_id: number;
  config_drive: string;
  engine: string;
  equipment_line: string;
  offer_type: VehicleOfferType;
  isBlackFriday?: boolean;
  price: Price;
  downpayment_prices: DownpaymentPrices & Price;
  purchasing_deal_name: string;
  vehicle_identification_number: string;
  has_real_pictures: boolean;
  available_terms: number[];
  model_year: string;
  seats: string;
  doors: string;
  ev_range: string;
  tires: string;
  efficiency_class: string;
  efficiency_image: Picture;
  energy_class_file: { url: string };
  coming_soon?: boolean;
  source: 'airtable' | 'blue_dragon' | 'nocodb';
  trim_name?: string;
  available_to: string;
  color_list: VehicleColor[];
  recurrent_payment_method_list: string[];
  product_stock_preorder: number;
  product_stock_webflow: number;
  financing_type: FinancingType;
  residual_values?: Record<string, number>;
  depreciation?: Record<string, number>;
  gross_capitalized_cost?: Record<string, number>;
};

export { VehicleOfferType };

type ProductLabel = {
  label: string | null;
  label_color: string | null;
  label_inverted: string | null;
  label_text_color: string | null;
};

type Equipment = {
  [key: string]: string;
};

export type Price = {
  [key: string]: number;
};

export type DownpaymentPrices = {
  downpayment_discount_percentage: number;
  downpayment_fixed_amount: number;
  available_price_list: { [key: string]: number | null };
  extra_km_price: number;
  msrp: number;
};

export type VehiclesResponse = {
  offset?: number;
  results: GenericVehicleDetails[];
  total_results: number;
};

export type CarType = 'SUV' | 'Kleinwagen' | 'Kombi' | 'Van';

export type VehicleBrand = {
  helper_brand_logo: Picture;
  id: string;
};

export type Brand = VehicleBrand & {
  available: boolean;
  models: Model[];
};

export type Picture = {
  url: string;
};

export type Thumbnail = {
  height: number;
  url: string;
  width: number;
};

export type Color = {
  color_hex: string;
  id: string;
  specific?: string;
};

export type VehicleColor = {
  car_id: string;
  color_hex: string;
  id: string;
  availability: string;
  specific: string;
  color_specific: string;
  picture_front_url?: string;
};

export type VehicleDealInfo = {
  vehicleId: string;
  term?: number;
  kilometerPackage?: number;
  monthlyPrice: number;
  vehicleBrand: string;
  vehicleCartype: string;
  vehicleModel: string;
  vehicleColor?: string;
  list?: string;
  position?: number;
  minPrice?: number;
  gearshift?: string;
  stock?: number;
  label?: string;
  dealId?: number;
  color?: string;
  driveType?: string;
  contactId?: number;
  paymentMethod?: string;
  customerType?: string;
};

export type FirstVehicleImageAsBase64 = {
  lgImage: string;
  mdImage: string;
  smImage: string;
};

export type VehiclePictureUrlOnly = {
  picture: {
    url: string;
  };
};

export enum FUEL {
  ElectricDE = 'Elektro',
  ElectricEN = 'Electric',
  PlugInHybrid = 'Plug-In-Hybrid',
}

export type CompanyDiscount = 0 | 0.3 | 0.5 | 0.8;
