import { cn } from '@finn/ui-utils';
import Link from 'next/link';
import { useEffect, useState } from 'react';

type CampaignBannerProps = {
  title: string;
  ctaText?: string;
  imageUrl?: string;
  linkUrl?: string;
  backgroundColor?: string;
  onBannerClick?: () => void;
};

export const CampaignBanner = ({
  title,
  ctaText,
  imageUrl,
  linkUrl,
  onBannerClick,
  backgroundColor,
}: CampaignBannerProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), 100);

    return () => clearTimeout(timeout);
  }, []);

  const callToAction = ctaText && (
    <p
      className={cn(
        'body-16-regular',
        '-translate-y-[100px] opacity-0 transition-all',
        { 'translate-y-0 opacity-100': visible }
      )}
    >
      {ctaText}
    </p>
  );

  const children = (
    <div
      className={cn(
        'group flex max-h-[289px] flex-col items-center justify-between overflow-hidden rounded text-white md:max-h-[150px] md:flex-row md:items-start',
        {
          'bg-black': !backgroundColor,
        }
      )}
      style={{ backgroundColor }}
    >
      <div className="flex flex-col gap-4 p-6">
        <p
          className={cn(
            'global-t6-semibold',
            '-translate-y-[100px] text-left opacity-0 transition-all',
            { 'translate-y-0 opacity-100': visible }
          )}
        >
          {title}
        </p>
        {callToAction}
      </div>
      {imageUrl && (
        <img
          src={imageUrl}
          className="h-[137px] w-full rounded-bl rounded-tr-none object-cover transition-transform group-hover:scale-105 md:h-[150px] md:w-[376px] md:rounded-br md:rounded-tr"
          alt={`Banner depicting ${title}`}
        />
      )}
    </div>
  );

  if (linkUrl && linkUrl.startsWith('/')) {
    return (
      <Link
        href={linkUrl}
        onClick={onBannerClick}
        className="cursor-pointer"
        legacyBehavior
      >
        {children}
      </Link>
    );
  }

  if (linkUrl) {
    return (
      <a onClick={onBannerClick} className="cursor-pointer" href={linkUrl}>
        {children}
      </a>
    );
  }

  return children;
};
