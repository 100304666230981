import { Slider } from '@finn/design-system/atoms/slider';

type Props = {
  min: number;
  max: number;
  value: [number?, number?];
  onChange(value: [number, number]): void;
  onChangeCommitted?(): void;
};

export const PriceSlider = ({
  min,
  max,
  value,
  onChange,
  onChangeCommitted,
}: Props) => {
  return (
    <Slider
      variant="secondary"
      value={[value[0] ?? min, value[1] ?? max]}
      min={min}
      max={max}
      className="-ml-3.5 mb-4 w-[calc(100%+1.75rem)]"
      onValueChange={onChange}
      onValueCommit={onChangeCommitted}
    />
  );
};
