import { getTermLabel, VehicleOfferType } from '@finn/ui-utils';
import { IntlShape } from 'react-intl';
import { z } from 'zod';

import {
  FilterKey,
  FiltersResponse,
  VehicleViewKey,
} from '../hooks/useGetFilters';

function arrayOf<T>(zodType: z.ZodType<T>) {
  return zodType
    .or(z.array(zodType))
    .transform((value) => (Array.isArray(value) ? value : [value]));
}

function date() {
  return z.string().regex(/\d{4}-\d{2}-\d{2}/);
}

export const enum SortKey {
  ASC = 'asc',
  DESC = 'desc',
  AVAILABILITY = 'availability',
  DEFAULT = 'default',
  LAST_ADDED = 'last_added',
}

const FilterValuesObjectSchema = z
  .object({
    [FilterKey.BRANDS]: arrayOf<string>(z.string()),
    [FilterKey.CAR_TYPES]: arrayOf<string>(z.string()),
    [FilterKey.GEARSHIFTS]: arrayOf<string>(z.string()),
    [FilterKey.MODELS]: arrayOf<string>(z.string()),
    [FilterKey.FUELS]: arrayOf<string>(z.string()),
    [FilterKey.COLORS]: arrayOf<string>(z.string()),
    [FilterKey.TERMS]: arrayOf<number>(z.coerce.number().positive()),
    [FilterKey.MIN_PRICE]: z.coerce.number().positive(),
    [FilterKey.MAX_PRICE]: z.coerce.number().positive(),
    [FilterKey.MIN_PRICE_MSRP]: z.coerce.number().positive(),
    [FilterKey.MAX_PRICE_MSRP]: z.coerce.number().positive(),
    [FilterKey.SORT_BY_POPULARITY]: z.coerce.boolean(),
    [FilterKey.HAS_HITCH]: z.coerce.boolean(),
    [FilterKey.IS_FOR_BUSINESS]: z.coerce.boolean(),
    [FilterKey.IS_YOUNG_DRIVER]: z.coerce.boolean(),
    [FilterKey.AVAILABLE_FROM]: date(),
    [FilterKey.AVAILABLE_TO]: date(),
    [FilterKey.SORT]: z.enum([
      SortKey.DEFAULT,
      SortKey.LAST_ADDED,
      SortKey.ASC,
      SortKey.DESC,
      SortKey.AVAILABILITY,
    ]),
    [FilterKey.OFFER_TYPE]: z.enum([VehicleOfferType.SUBSCRIPTION]),
    [FilterKey.VIEW]: z.enum([
      VehicleViewKey.AVAILABLE,
      VehicleViewKey.AVAILABLE_AND_COMING_SOON,
      VehicleViewKey.COMING_SOON,
      VehicleViewKey.DISPLAYED_CARS,
    ]),
    [FilterKey.RETENTION]: z.enum([FilterKey.RETENTION]),
    [FilterKey.HAS_DEALS]: z.coerce.boolean(),
    [FilterKey.PRODUCT_GROUP]: arrayOf<string>(z.string()),
    [FilterKey.HAS_RETENTION_DEALS]: z.coerce.boolean(),
    [FilterKey.FEATURES]: arrayOf<string>(z.string()),
    [FilterKey.TOTAL_RESULTS]: z.coerce.number().positive(),
    [FilterKey.POPULAR]: arrayOf<string>(z.string()),
  })
  .partial()
  .nullable();

export type FilterValuesObject = NonNullable<
  z.infer<typeof FilterValuesObjectSchema>
>;

export function parseFilterValues(input: object): FilterValuesObject {
  const parsed: FilterValuesObject = {};

  for (const [filterKey, filterValue] of Object.entries(input)) {
    const result = FilterValuesObjectSchema.safeParse({
      [filterKey]: filterValue,
    });

    if (!result.success || !result.data) {
      continue;
    }

    const parsedValue = result.data[filterKey as keyof FilterValuesObject];
    if (
      !parsedValue ||
      (Array.isArray(parsedValue) && parsedValue.length === 0) ||
      (typeof parsedValue === 'object' && Object.keys(parsedValue).length === 0)
    ) {
      continue;
    }

    (parsed as unknown as Record<string, unknown>)[filterKey] = parsedValue;
  }

  return parsed;
}

export type Option<T = string> = {
  label: string;
  value: T;
  disabled?: boolean;
};

const getTermsFilterData = (
  terms: Array<number>,
  intl: IntlShape,
  flexTermLabel: string
): Option[] =>
  terms?.map((term: number) => ({
    label: getTermLabel(term, flexTermLabel, intl),
    value: String(term),
  }));

export const getFeaturesFilterData = (
  features: Array<string>,
  intl: IntlShape
): Option[] => {
  return (
    features?.map((feature: string) => ({
      label: intl.formatMessage(
        { id: `plp.features.filters.${feature}` },
        { defaultMessage: '' }
      ),
      value: feature,
    })) || []
  );
};

export const parseFiltersResponse = (
  filterKey: FilterKey,
  filtersResponse: FiltersResponse,
  i18n: IntlShape
) => {
  if (filterKey === FilterKey.TERMS) {
    return getTermsFilterData(filtersResponse[filterKey], i18n, 'Flex');
  }
  if (filterKey === FilterKey.FEATURES) {
    return getFeaturesFilterData(filtersResponse[filterKey], i18n);
  }

  return filtersResponse[filterKey];
};
