import { VEHICLE_VAT } from '@finn/ua-constants';
import { parsePathAndLocale, slugify } from '@finn/ui-utils';
import get from 'lodash/get';
import trim from 'lodash/trim';

import {
  AvailableFilters,
  FilterValuesObject,
  InitialFilterKeysMap,
} from '../../filters-management';
import { getHeadline } from './headline';
import { getMetaDataKey, getSeoHeader } from './seo';

export const getMinGrossPrice = (minPrice: number, isForBusiness: boolean) => {
  if (isForBusiness) {
    return Math.ceil(minPrice * VEHICLE_VAT);
  }

  return minPrice;
};

// We can't use react-intl because we are using this function in server-side
export const getTermUrlName = (term: number) =>
  `${term}-${term > 1 ? 'monate' : 'monat'}`;

/*
- This function create a filter values map from lvl1 + lvl2 filters
- Output example:
    {
      : { type: 'gearshifts', name: 'automatik', displayedName: 'Automatik' }
    }
- We need this to check if this word in url is valid or no & check in which lvl
*/

export const getInitialFilterKeysMap = (
  allAvailableFilters: AvailableFilters
): InitialFilterKeysMap => {
  const unavailableBrands = allAvailableFilters.brands
    ?.filter((brand) => !brand.available)
    .map((brand) => ({
      brands: brand.id,
    }));
  const availableBrands = allAvailableFilters.brands
    ?.filter((brand) => brand.available)
    .map((brand) => ({
      brands: brand.id,
    }));
  const cartypes = allAvailableFilters.cartypes.map((cartype) => ({
    cartypes: cartype,
  }));
  const models = allAvailableFilters.models.map((model) => ({ models: model }));
  const fuels = allAvailableFilters.fuels.map((fuel) => ({ fuels: fuel }));
  const gearshifts = allAvailableFilters.gearshifts.map((gearshift) => ({
    gearshifts: gearshift,
  }));

  const initialFilterKeys = [
    ...unavailableBrands,
    ...availableBrands,
    ...cartypes,
    ...models,
    ...gearshifts,
    ...fuels,
  ];
  const initialFilterMap = initialFilterKeys.reduce((acc, curr) => {
    const [filterInfos] = Object.entries(curr);
    const filterKey = slugify(filterInfos[1]);
    acc[filterKey] = {
      type: filterInfos[0],
      name: filterInfos[1]?.toLowerCase(),
      displayedName: filterInfos[1],
    };

    return acc;
  }, {} as InitialFilterKeysMap);

  // Add terms to map
  allAvailableFilters.terms?.forEach((term) => {
    // We can't use intl because we are using this in server-side
    const termUrlPath = getTermUrlName(term);
    const termDisplayedName = `${term} ${term > 1 ? 'Monate' : 'Monat'}`;

    initialFilterMap[termUrlPath] = {
      type: 'terms',
      name: `${term}`,
      displayedName: termDisplayedName,
    };
  });

  initialFilterMap['coming-soon'] = {
    // TODO: Remove after launching retention
    type: 'coming-soon',
    name: 'coming-soon',
    displayName: 'coming-soon',
  };

  initialFilterMap.retention = {
    type: 'retention',
    name: 'retention',
    displayName: 'retention',
  };

  return initialFilterMap;
};

export const getCustomTitleAndSeoHeader = <T>(
  routerPath: string,
  pageData: T
): { title: string; seoHeader } => {
  const entries =
    (pageData as any)?.metadata?.custom_title_and_header_entries?.metadata
      ?.plp_custom_title_and_header_entries || [];
  let title = '';
  let seoHeader;
  for (let i = 0; i < entries.length; i++) {
    const pathAndLocale = parsePathAndLocale(entries[i].plp_url);
    if (pathAndLocale.path === trim(routerPath, '/')) {
      title = entries[i].title_for_matching_url;
      seoHeader = entries[i].seo_header_for_matching_url;
      break;
    }
  }

  return { title, seoHeader };
};

type GetUpdatedSeoHeader<T> = {
  pageData: T;
  filters: FilterValuesObject;
  totalVehiclesCount: number;
  allAvailableFilters: AvailableFilters;
  nextAvailableFilters: AvailableFilters;
  routerPath: string;
};

export const getUpdatedSeoHeader = <T>({
  pageData,
  filters,
  totalVehiclesCount,
  allAvailableFilters,
  nextAvailableFilters,
  routerPath,
}: GetUpdatedSeoHeader<T>) => {
  const customSeoHeader = getCustomTitleAndSeoHeader<T>(
    routerPath,
    pageData
  ).seoHeader;
  if (customSeoHeader) {
    return customSeoHeader;
  }
  const metadataKey = getMetaDataKey(filters);
  const isForBusiness = filters?.is_for_business;
  const vehiclesCount = totalVehiclesCount;
  const initialFilterKeysMap = getInitialFilterKeysMap(allAvailableFilters);
  const seoHeaderTemplate = get(pageData, `metadata.${metadataKey}`, {});

  if (metadataKey !== 'fallback_template_plp') {
    const minPrice = `${
      isForBusiness
        ? getMinGrossPrice(nextAvailableFilters.min_price, isForBusiness)
        : nextAvailableFilters.min_price
    }`;

    return getSeoHeader({
      vehiclesCount,
      minPrice,
      seoHeader: seoHeaderTemplate,
      filters,
      initialFilterKeysMap,
    });
  }

  return null;
};

export const getContentPieces = async ({
  pageData,
  initialFilter = {},
  totalResults,
  minPriceInFilter,
  initialFilterKeysMap = {},
  isForBusiness = false,
}) => {
  const metadataKey = getMetaDataKey(initialFilter);
  let seoHeader = get(pageData, `metadata.${metadataKey}`, {});
  if (metadataKey !== 'fallback_template_plp') {
    const minPrice = `${getMinGrossPrice(minPriceInFilter, isForBusiness)} €`;
    seoHeader = getSeoHeader({
      vehiclesCount: totalResults,
      minPrice,
      seoHeader,
      filters: initialFilter,
      initialFilterKeysMap,
    });
  }

  const headline = await getHeadline(
    pageData,
    initialFilter,
    initialFilterKeysMap
  );

  const countTemplate = get(pageData, 'metadata.count_placeholder', '');

  const fallBackSeoHeader = get(pageData, 'metadata.fallback_template_plp', {});

  return {
    seoHeader,
    headline,
    countTemplate,
    metadataKey,
    fallBackSeoHeader,
  };
};
