// TODO: Remove `null` from fetcher.
// TODO: Disable the following rule in the ESLint config as it never works correctly.
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

import { useDeals } from '@finn/platform-modules';
import { Features } from '@finn/ua-featureflags';
import {
  config,
  createFetcher,
  FetcherRequestPayload,
  generateLocalizationHeaders,
  isServer,
  Locale,
} from '@finn/ui-utils';
import Cookies from 'js-cookie';
import union from 'lodash/union';
import { SWRConfiguration } from 'swr';
import useSWR from 'swr/immutable';

import { Brand, Color } from '../../product-cards';
import { PRICING_TYPE, PricingType } from '../../product-pricing';
import { FilterValuesObject } from '../helpers/filter-parser';

export const AVAILABILITY_KEY = 'availability';

export const enum VehicleViewKey {
  AVAILABLE = 'available_cars',
  AVAILABLE_AND_COMING_SOON = 'available-and-coming-soon',
  COMING_SOON = 'coming_soon',
  DISPLAYED_CARS = 'displayed_cars',
}

export enum FilterKey {
  SORT = 'sort',
  BRANDS = 'brands',
  CAR_TYPES = 'cartypes',
  MODELS = 'models',
  GEARSHIFTS = 'gearshifts',
  FUELS = 'fuels',
  COLORS = 'colors',
  HAS_HITCH = 'has_hitch',
  VIEW = 'view',
  IS_FOR_BUSINESS = 'is_for_business',
  IS_YOUNG_DRIVER = 'is_young_driver',
  MIN_PRICE = 'min_price',
  MAX_PRICE = 'max_price',
  MIN_PRICE_MSRP = 'min_price_msrp',
  MAX_PRICE_MSRP = 'max_price_msrp',
  AVAILABLE_FROM = 'available_from',
  AVAILABLE_TO = 'available_to',
  TERMS = 'terms',
  OFFER_TYPE = 'offer_type',
  SORT_BY_POPULARITY = 'sort_by_popularity',
  RETENTION = 'retention',
  HAS_DEALS = 'has_deals',
  PRODUCT_GROUP = 'product_group',
  HAS_RETENTION_DEALS = 'has_retention_deals',
  FEATURES = 'features',
  TOTAL_RESULTS = 'total_results',
  POPULAR = 'popular_filters',
}

export type FiltersResponse = {
  [AVAILABILITY_KEY]: string[];
  [FilterKey.BRANDS]: Brand[];
  [FilterKey.COLORS]: Color[];
  [FilterKey.CAR_TYPES]: string[];
  [FilterKey.MODELS]: string[];
  [FilterKey.GEARSHIFTS]: string[];
  [FilterKey.FUELS]: string[];
  [FilterKey.TERMS]: number[];
  [FilterKey.MIN_PRICE]: number;
  [FilterKey.MAX_PRICE]: number;
  [FilterKey.MIN_PRICE_MSRP]: number;
  [FilterKey.MAX_PRICE_MSRP]: number;
  [FilterKey.HAS_HITCH]: boolean[];
  [FilterKey.IS_FOR_BUSINESS]: boolean[];
  [FilterKey.IS_YOUNG_DRIVER]: boolean[];
  [FilterKey.TOTAL_RESULTS]: number;
};

const oldFetcher = createFetcher({
  baseURL: config.FLEET_API_URL,
  withCredentials: false,
});

const fetcher = createFetcher({
  baseURL: config.PRODUCT_API_URL,
  withCredentials: false,
});

/**
 * SWR doesnt allow us to switch fetcher directly, as it doesnt detect the change.
 * This work-around allows us to query once the correct API without re-rendering.
 */
const dynamicFetcher = <Response>(request: FetcherRequestPayload) => {
  const locale = request?.headers?.['X-Language-Tag'];

  if (locale === Locale.ENGLISH_USA) {
    return oldFetcher<Response>(request);
  } else {
    return fetcher<Response>(request);
  }
};

type GetFiltersParams = {
  hide_related?: boolean;
  filters: FilterValuesObject;
  zipCode?: string;
  locale: string;
  pricingType?: PricingType;
};

type GetFiltersResponse = FiltersResponse;

function makeGetFiltersPayload({
  filters,
  locale,
  zipCode: zipcode,
  pricingType = PRICING_TYPE.DOWNPAYMENT,
  hide_related,
}: GetFiltersParams): FetcherRequestPayload {
  if (!filters.view) {
    filters.view =
      locale === Locale.ENGLISH_USA
        ? VehicleViewKey.AVAILABLE_AND_COMING_SOON
        : VehicleViewKey.AVAILABLE;
  }
  const cugId = !isServer() ? Cookies.get('cug_id') : null;

  return {
    url: '/filters/cars',
    query: {
      ...filters,
      hide_related,
      zipcode,
      pricing_type: pricingType,
      cug_id: cugId,
    },
    headers: generateLocalizationHeaders(locale),
  };
}

/**
 * Returns possible values for Product API cars' filters.
 *
 * Flow explained:
 *
 * 1. It fetches two sets of filters – one for the `available` view, and one for
 * the `displayed-cars` view. The reason behind this is the fact that `displayed-cars`
 * contains more data and is in fact a superset of the `available` filters.
 * We need to extract all possible brand values to make the filter URL parsing for
 * the out-of-stock brands and models possible.
 *
 * 2. It merges brands and models of both `displayed-cars` and `available` filters
 * and fixes the `available` property for both brands and models. All the brands and models
 * from the `available` view should remain being available, however there are other brands
 * in the `displayed-cars` view that come with `available: true`, which is incorrect since
 * they are not listed in the `available` view and have no available cars. There are also
 * models inside the available brands of the `displayed-cars` view that are unavailable in the
 * `available` view, but still come with `available: true`, which is incorrect by the same reason.
 * So while merging happens, the difference between `displayed-cars` and `available` views
 * is merged into the response from the `available` view with the property `available: false`.
 *
 * @param params
 * @returns possible filter values required for filter parsing and UI
 */

export const processFilters = (
  availableFilters: FiltersResponse,
  displayedFilters: FiltersResponse
) => {
  const filtersResponse: FiltersResponse = {
    ...availableFilters!,
    brands: [],
    models: [],
  };

  const availableBrandsMap = new Map(
    availableFilters![FilterKey.BRANDS].map((brand) => [brand.id, brand])
  );

  const displayedBrandsMap = new Map(
    displayedFilters![FilterKey.BRANDS].map((brand) => [brand.id, brand])
  );

  const allBrandIds = union(
    displayedFilters![FilterKey.BRANDS].map((brand) => brand.id),
    availableFilters![FilterKey.BRANDS].map((brand) => brand.id)
  );

  for (const brandId of allBrandIds) {
    const availableBrand = availableBrandsMap.get(brandId);
    const displayedBrand = displayedBrandsMap.get(brandId)!;

    if (!availableBrand) {
      filtersResponse.brands.push({
        ...displayedBrand,
        available: false,
        models: displayedBrand.models.map((model) => ({
          ...model,
          available: false,
        })),
      });

      continue;
    }

    const models = [
      ...displayedBrand.models.map((model) => ({ ...model, available: false })),
      ...availableBrand.models,
    ];

    filtersResponse.brands.push({
      ...availableBrand,
      models,
    });
  }

  filtersResponse[FilterKey.MODELS] = [
    ...displayedFilters![FilterKey.MODELS],
    ...availableFilters![FilterKey.MODELS],
  ];

  return filtersResponse as GetFiltersResponse;
};

export const useGetFilters = (
  params: GetFiltersParams,
  isOutOfStock?: boolean,
  swrConfig?: SWRConfiguration
) => {
  const { deals } = useDeals();
  const hideDownpayment =
    !isServer() &&
    Cookies.get(`${params.locale}_${Features.HideDownPayment}`) === 'b';

  const hideRelated =
    !isServer() &&
    Cookies.get(`${params.locale}_${Features.ExpRelatedConfigs}`) === 'b';

  if (!params.filters) {
    params.filters = {};
  }

  if (deals && deals.length > 0) {
    params.filters.view = VehicleViewKey.AVAILABLE_AND_COMING_SOON;
  } else if (isOutOfStock) {
    params.filters.view = VehicleViewKey.DISPLAYED_CARS;
  }

  params.pricingType = hideDownpayment
    ? PRICING_TYPE.NORMAL
    : PRICING_TYPE.DOWNPAYMENT;
  params.hide_related = hideRelated;

  return useSWR<GetFiltersResponse>(
    params && makeGetFiltersPayload(params),
    dynamicFetcher,
    swrConfig
  );
};
