export default {
  availability: [
    '2023-01-19',
    '2023-01-20',
    '2023-01-21',
    '2023-01-22',
    '2023-01-23',
    '2023-01-24',
    '2023-01-25',
    '2023-01-26',
    '2023-01-27',
    '2023-01-28',
    '2023-01-29',
    '2023-01-30',
    '2023-01-31',
    '2023-02-01',
    '2023-02-02',
    '2023-02-03',
    '2023-02-04',
    '2023-02-05',
    '2023-02-06',
    '2023-02-07',
    '2023-02-08',
    '2023-02-09',
    '2023-02-10',
    '2023-02-11',
    '2023-02-12',
    '2023-02-13',
    '2023-02-14',
    '2023-02-15',
    '2023-02-16',
    '2023-02-17',
    '2023-02-18',
    '2023-02-19',
    '2023-02-20',
    '2023-02-21',
    '2023-02-22',
    '2023-02-23',
    '2023-02-24',
    '2023-02-25',
    '2023-02-26',
    '2023-02-27',
    '2023-02-28',
    '2023-03-01',
    '2023-03-02',
    '2023-03-03',
    '2023-03-04',
    '2023-03-05',
    '2023-03-06',
    '2023-03-07',
    '2023-03-08',
    '2023-03-09',
    '2023-03-10',
    '2023-03-11',
    '2023-03-12',
    '2023-03-13',
    '2023-03-14',
    '2023-03-15',
    '2023-03-16',
    '2023-03-17',
    '2023-03-18',
    '2023-03-19',
    '2023-03-20',
    '2023-03-21',
    '2023-03-22',
    '2023-03-23',
    '2023-03-24',
    '2023-03-25',
    '2023-03-26',
    '2023-03-27',
    '2023-03-28',
    '2023-03-29',
    '2023-03-30',
    '2023-03-31',
    '2023-04-01',
    '2023-04-02',
    '2023-04-03',
    '2023-04-04',
    '2023-04-05',
    '2023-04-06',
    '2023-04-07',
    '2023-04-08',
    '2023-04-09',
    '2023-04-10',
    '2023-04-11',
    '2023-04-12',
    '2023-04-13',
    '2023-04-14',
    '2023-04-15',
    '2023-04-16',
    '2023-04-17',
    '2023-04-18',
    '2023-04-19',
    '2023-04-20',
    '2023-04-21',
    '2023-04-22',
    '2023-04-23',
    '2023-04-24',
    '2023-04-25',
    '2023-04-26',
    '2023-04-27',
    '2023-04-28',
    '2023-04-29',
    '2023-04-30',
    '2023-05-01',
    '2023-05-02',
    '2023-05-03',
    '2023-05-04',
    '2023-05-05',
  ],
  brands: [
    {
      available: true,
      helper_brand_logo: {
        filename: 'audi.svg',
        height: 0,
        id: 'attfYmKGIIRQ3naBl',
        size: 732,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/CkkskjEklbUMPdDasn3i5Q/7JvGUlOMuyEX6uoszOp4vBHrCHuEPGOHUrzcrvBe0VbZNQ4uM8gZCnXHT5aq7-R0GD4bl-NuhcVVuo1VltM1xIOIixDPhDeQJxouztoYJp8/s_yeUnXRiSxY-ghJhXnirQi72FRC9DFi2R0fJ7XlPRg',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/3j1BYY3ZoNZ-PfUW0jmobA/PSEsp6h02mBsCha1qTp5-VUHTTNOMjYsPCZOYkRjnNLf3DxWleYysA854ewkIslpIX1_FFkXL99hqBlBsWPgLqtLZxwPcmXDlb0lP3dGDkk/_-BkQFsVngi0oR3S070wZTjbMB8YTc5rVWTfxLuGOW0',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/q02z-2WdzKVwt-KxWRLYrQ/CV7RJqpWjqTzPgZhuWi6bAen-EVsMZQ7obgHbelRPohz_qRHdG4BwJ-XxMT0KdTUDOctnsf0NbTgr5GLqQfTqCJEHgo-zXbGzdrw47j-pjY/B-TAVt61ujPk5DuPJYMAk0K3ec6l-_8oFThZWcJrv6M',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec1rv5m0mxqbrXrz/fldn1PBkvAK6H68vG/attfYmKGIIRQ3naBl',
        width: 0,
      },
      id: 'Audi',
      models: [
        {
          available: true,
          id: 'A3 Limousine',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'att1ah5qdxVmGbWNv',
            size: 103278,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/U0uIwbK3BusDa7G8XT71iQ/d2Oj_yUs_fOa90MXyjiNGIugxLEdGQtzDWJt77gu13vuAAGf7oPxA-taf4BDzdkIJYlBb65s7Ys9v0bW0xGV4wMS0GPa_SOTee5zqqr_tyU/2MESu8iYlx54PYg-k44LwT9iaYD50zs5AVpCKQ6nTCU',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/WQ18I6hOSUKZonD8eKf9Bg/W6GiK6ngDPaUO6AZsZPggXJUb7o5IItIxPA7n2CMBuWcQb5iO-xxfrcVAiWUH-oOTlposLwYutSgqerrHx_rEsyjM-UhldD9znSv1wtzXds/9v1xEppCrkcuKk8G8rJGeW17R52Xei_WMnydhd6pJsM',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/MqkwGk8fErpX5ixzRd9kcQ/JHxqQ0NJsjNVYGp0FN6CAh2leJ4JGihOTk4qY2JDbnn7v0ZaE5EZ0z3wcPBXpmen308-tXAYHmT8Ju-AsepPrQUz5UtkoliwuHFxvr93ss4/Odt-AcNY1yrcaqRp5REhGkSf2El4OBfxOyLVb3T4M00',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec7VXaBT5sYdq1QK/fldI49uIeYXTkPPfU/att1ah5qdxVmGbWNv',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'A3 Sportback',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'attL66OxaJtSWSipf',
            size: 110982,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/C623gu4w-d-UXVDK1vTEMA/7hfmKB9H4mBv4V_b4blFqhavRYv0IN90s_xQQGhrrFSjIR8J1Al3fU_zuDg-hgJk1kuPIv0k1o4Y1PvtlGozbyZ0qWeF5TYEwxZgtdKPrWg/V6Xc8ynvTZ9W4Xq_mDszdHxlXaPMx9mfweXdCSXOlTw',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/8V8-jMxd59-Nh_d4229PFg/8uBo5EOr6LBDGSzAomtsqMLSyn3mZZm05aUQWKSYJWLwBqb9jHTUekWdy4yX8NgD0WWijgI0uG7uqKVmbJAI3rQNfviND0ST74sjYHQz6F8/WDA-e_x_O2BuraYMBOg-JaZ3BHCwXoMirEciT7NoBy0',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/mF43Ew-S-MQbANaTm1PkDQ/hXk3e0rKYybqzA4QOeKXHQD-UBrDT_MJLjZhD1lL9iCGzx-amlk0Vh07fZGTG-bYz8GyRSRXVQOYJ5ZzKafesReTOCUwmj3UpPblT4I7R04/BRtZjpndnTp4e5pLT6GiawUUnZtcpA_JW50BEGW8ZiQ',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recQ9QHZQ9tbOtwVe/fldI49uIeYXTkPPfU/attL66OxaJtSWSipf',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'A4 Avant',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'atttV9P59rRcQr65e',
            size: 98548,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/hc0HtbMoaNzFaEOP0_BTsw/0hqvU-ClN0RVBIDhANguFEj5Q0STzCVCoie9jrKX2JR1ViE8BHGfScPN_7Dj4E_VV-0QtWqkagc4e31MUUkrffMo31bXbI6tobTiR5SU9CI/h0ISn1DHZ26eKI4KXrFSrDECOv9MFs2K2dQbNMKTFy0',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/5YNIDn3elSkN_sEGwYDm-A/Xbc1eE2iQXHYeOZTFdUtx3eiDLdcc3XApg0SPFgn2YTTh7mX2IVn4EGM5Hl84QNPqLMeH2Ir6mUnST3bIrwhy392VGvW496qSAzFVNlu3oY/5qZRJ5gzBUEzMFJf3ytKOZSZWciNSNq07EaYQq0eNMQ',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/ezuLn5TyLvBkNbee4sj-Aw/nVuculMJVbaMUkVBsooaoPXXnK285IQBFdfnuKAz-eZACPk04TQtA3_AryyegamsVjELGg6D2kStMuTmyfF_i4geVFazyrNe8h9QoAt6bUo/ZSiESBzZ-6O227kRuckfhHTZ1sYT02HPlvCcAey3QvM',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recw8JSsUmMaKY8Nt/fldI49uIeYXTkPPfU/atttV9P59rRcQr65e',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'A4 Limousine',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attmRwhAGBicSIK3x',
            size: 94720,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/WkK-M863d8DYxSSR0fqinw/zAEJXA2aQok_CIul_JFQ3DFnhaMFgCoRo8Cq7lJxixYBSKCsCZXdhHHgqo3HVPXLqf6u4tiWx-bPkSPGevgdBJNK3bf9AZhQAY3k452if5k/CwcSTWT2r4MTSvEZoF74YZm4x3yiMRpnUDOveCl9RmM',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/9kObcLMG7gGqCI59KUgpjA/BqlCZrD6HlMg7UllVOn5HuNRwryacqNS-Olq24_ZIs1Bidx_ZTuXgh3WuAVZ9qRCalZfremb6F8Hj_s15ZvsQVKbsbWWDR09QvEiOc-Oy9I/AtiSI4xLcKQChhkGQ_DC7QfZKnlmJHAdazlwX543Cvk',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/11VgCifgduszyqrQMSpnFA/dqJexhUiO1OH6vPIhRY8N1itlHVV1t9fzRKqao_rcJzI0ulC8WbGzJoeVS2hQQaTEiHYF5nUruK7kHOZ4XWuzTuP8yWFafLCIef4iXmIAQg/po8Cu66MTegK-M5GWUjzXlRSnRT4jtIN8vTnjqmM7vM',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recYkAGz7Dgvmb10O/fldI49uIeYXTkPPfU/attmRwhAGBicSIK3x',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'A5 Cabriolet',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'attaOq64j1h75UNcA',
            size: 96636,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/OyOQ3VhBFZW647LBp_X1vw/mXHMqYK967wUOO7E4r5FcdqW_mjkOW6ljFFh-C3942eKn7cEGHExIYBsUejCtLaqMd9JPGvYMLk_i7WJWbhXOsKW_QgefhNmFUe4oLp0j7Q/9BnMZDw6EBe5cPlmeSSxrrqFBM-RKhScZGJZZOLWTP0',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/XQcUcaoOztDn0Nxozy_Efw/q-hOsZWZp_1g7AwlSCsriKgUhZFa1rSaMdhGDpMxA7iF9yQ3l8oLqiqhvc_JHi5o2V1i8IltCaXdtFJlm9tKQSK15ZYmxunvJuAdTnybicU/n5dMaWI9f6qgsyaMpwCqyPL89BTvgkp4TVl4a2GvuRs',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/KJEn5bCaTYA6hHls0Ed9MQ/GMm4KlhPWM04gXGGRnh4dDal1sN9Etk1VvajTX3NEtYYCZoHV0Ze2xXYIrUmHM2--UmweOojP5g4NKt-fQESKQ6rLwJNaFJIP4v-ZTpEaCs/lefQpHCTgovrzcbkCBgPWnjeRKd1qWnTvHjQ5iaH8BA',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recY0ecKDn388v8A6/fldI49uIeYXTkPPfU/attaOq64j1h75UNcA',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'A5 Sportback',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'attbpcAgl881cP4iV',
            size: 91876,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/avHKCpBIQxUBgdfDry7-Eg/0YkQS8xWII2wuPiJ157MCkPEE8cxFqw4x7iB8XT7YtyX-P2PEEyZvcdnBlTrxv5IMpMeBHPedx3vTNWCtdXHMH-8g3m6-mq2JnscGmyoFls/wAgRytqZo46XBbGCW37es4bwDH2UxOcF32c3Pq9J0T0',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/NV-hFGWFmEgKo7Nm0GHzug/H_3AWN0hbFObp4KK2cGyJJWlJ00plk-9uhsfxjxchl57I40kVmU1SVTWKWRL1XrOqMiqWvbp_FAloqpwMUdW575d6g3161A8LtJLrxNUTfY/3MPNkQ0mqcPHKf3lAqZegUwwPc_uDc7-yBsTmCAqIm4',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/q2SNAGSsiPClYBYH_NbFUQ/D14eRfVZsqVAGyj7qUm-7PSxF5s-PleKAQaWNV9WMgL4nwcFoKIvv59dlk4nrEx9vHHHxcFl-rqfpy7RdvXjUeyE3FG0aUmMrSjaDNRBFjc/J5G6MlG4a3ACIfV1hjshr9bO1H5kM6sMOKwfKzP9g9g',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recMNFwuMwvsBTd7j/fldI49uIeYXTkPPfU/attbpcAgl881cP4iV',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'A6 Avant',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'attaOpHbyF4siwQvD',
            size: 99054,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/OyKWPpf9oDRjslxqY7I6iQ/cabNTIGXEOpYKaG3JBpEgn7eibRHTsonHP0-1QaCUQMdpsLDE4OtI_rXii8mVCUHEOvn7YWtTruOekhLuF6vdQ8seW_K-mgZe8dFg-1Afsw/AIHh8r5IAErzYb1AqNw6yyE2g9z0SHEGdFRTCraXD4A',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/YcTGBeFH_E_xnccyGJB2dg/YOWhCHWv6TZHDhQLvRtbOBw2M7BN9kP_Qlnc1r041tN0UrSHg0Xoa9ofO92xZZB3zOY60CPq3QjB6UhkJyC7-2modWYlCI3CsW71ZgJ8BrA/nD_lRI6RkIgRDkiixy9fW_GlBvW5WQ4dbmkViaIhHT8',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/jdoxCigktILiCLFBv9qCMg/3P1dc9QPQYRuekAWheA1MhPlCsPRG9Kdl7DSMVtQhsqylyPkb550FRjxjfUhszwGAPuH1Hs9sI1YKLxV__H2s2qcstnxyqbxpK42wrBRVC0/QVV2KVMxBSMTaQId272N5JdtbPZn5s7B0BU5ZUxsx8k',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recToVRQRz28RhLKk/fldI49uIeYXTkPPfU/attaOpHbyF4siwQvD',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'A6 Limousine',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'att95DIfDnlhWBcko',
            size: 87848,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/EJkcjgPmzh3JqQidBEbeMA/niWgyfMiz_f_9IqB9PA61gHMW_tZxzCiHtJJh6u-o0vqvzJkRt_xEel7d1UB78kjYR8hWk4L7PfWv-ixf_xooZqcbTbQ1yPRymc0-SwrJ94/4z2RZp7HnTbnwo4I_Mx1LfHjgFTpnCFQ7FVHXYizz7c',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/7HbsHJOROP1wns5oIrOqUA/hNAKlHe5konMr2jr1QCKULAFyrGH5MN-pga04s45ec4Cl7L7aLRdGrlv0R51PpK_tZKqt8pFi-7AGxqRAX6p4E4IiZ5wOd3BJVlHlewLnKQ/3UVJ-rH3kbKG-wuDPA9_14HIX2H5szeJilZCoGv65wE',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/yuYE-5v4NrKNPX_uKmHKGQ/qznuDPbucLoMIoE28inJqcPgmTohRV4OBNjJYRO9q84CrpHOEQUhcF2S4EzuasHz4gaBzgEdKmETnVk6Uqgs2HEmDiLMYaSEEB4JleGiqTQ/hOCPvSsUBqThKwWxbjJhBFHCnqGkZLGozW6DiP1VxZA',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recO28ZKuNkDpb0C9/fldI49uIeYXTkPPfU/att95DIfDnlhWBcko',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'A6 allroad quattro',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'att5NcfENGyloMIoD',
            size: 102208,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/AYGSVUMpdF5vlMRGIP5auQ/BuPO_VURI4aU3t6_SUA119XXjS-iUqbVhjZeqmORGP6iNyGi5t6ymNUw6QHcWyVDueZKWZqyzAUxTeycM7Pe9AXGtLu0Xd_stBqO1nS00qk/nfkLfeJihqWRbi7igKWnSnpSwjnyhuyNAGSOhg-9Hvw',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/T6kBy1UeyqsbyP8cLxrGHQ/pyXzKt6EvHMorW2qDxGyxSuFL--EE_d9SAHn6KBpfQqS6A3Yy4yj1HzcLp3DmLeUsOfmPDsIJWnawSWJtl5RjsM9opnNTUWF5gyHNs9JBTs/nrJ4eoUkNIkve0R5Kmy3Oh2M6DTk_InNP9I6hgMB9Ww',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/PguZ6O7sU4TEuo0bumYYhg/ISyTUIEdepTivPq-aPpYx5LcPmskrwHq21QShhrzCUXxS_OGZwO3Tww7IPtZTGLQAc4wnRmeuznK6vlhfk0DijnsbTZ5GXc_VvzjilmaJl0/3ylkZK8Vq_yRd4oKik7TkkK-Imw_e9C_djLZ5SQQr5c',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recnyLWmTVbPyNsC9/fldI49uIeYXTkPPfU/att5NcfENGyloMIoD',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'A7 Sportback',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attVnKZQJm9wXDToJ',
            size: 76372,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/ES34a5yVptIa3QtRm4P9JQ/xfvTxEER54kW-yRugOHq_qX_0bMtCvgrySwhqWFA-AUhV2qVZ50hOXvSnSm3YAKnXU6tshyxpsRvePwWn1ne9WxqhGzXoc2CZBkwcYyexmc/vM74TKiEfBe4MUE4cJpoj_TULBWpo78MVx-xq8IqGOo',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/SvpSUCiEi5nCsEBJleAwFg/h55rTIhTG4Cup-nRcRrE_t70tzdaAdqsEn_-XsUARySq3EWee0Y1uBooIhLpDFtAK87LWeiPAEqBo8dw6bt7YrZF1KJhg-K2Pqk-_w1qXJU/IDXGNjIhXupJ--SkboJ68mRFapZMRfWIzuPymoscy1M',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/ipSDvzfa8TsCnauVGXZctg/qFduP9M9vV3DKO7oKxiqj3Coq3GJmX4wUFcMyKWY9xysjOWFGXzyPd3bADMfIGKwKygN-Sbwzn0CL4vyx7smB7V0tjP-jx9vyOW_jflprtc/kh6fpt5rq14SVHsTVJLKPUDIhIgVFbjJNBQu5qU2HLs',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recvvxz20FbDje1d6/fldI49uIeYXTkPPfU/attVnKZQJm9wXDToJ',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'A8 L',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attNUVrYySitK1wbM',
            size: 110702,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/HJEV4wsGfHranldr0ZfnHg/UBkI9k-xQjsfDJ_RUdegp24YyMZJOu03csnxMyFgpvc7BlHDDMqIv1oz_NvM0-mycdVE9Jeas1838oUsTAtxPiTmhFbpkEAq6I9kYDniRyI/3HyY0uAUQ9Hl0wWyRsSqPb0oQnO3PFbfDc2bXWlW_1w',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/80wsY1yJ0GvyhSmFn9ZRuA/0zBfa_6fTwxvhiaOMEe09AGbIIVme76TAOOjFZS3pHAL2o6QVXGj1jf-EHQ7cP-9hv0n-m3141dIjXqkp_QSJXrUahkgIprvrObMaDXb_KA/eFa9SIEVDZkyyEfjlSxgQtrTi-uGenaHIMPjraJFOMc',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/x-P9ESs-p_gRt4qUMsMIoA/2Y1Y8QAO1BKC2Fn0SNFjFBQnYpej0EBU7hrc4A-gfQ2KLn8ptYt0w0OItQucCcxGTlmWp3q1kKF3DF8RHJiLSaN58mmaauRNXLcOaV23NoA/NDccP1mRJ2_XO_XeM72mysHv7yzik9Cbd3UaX22mEC8',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recZK0uk1osYbmjtW/fldI49uIeYXTkPPfU/attNUVrYySitK1wbM',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'Q2',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attURzJLsRUJJfvyM',
            size: 82660,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/qK4Rb5GXtaqKmhy0b1ZclA/hmZE3SWHRwB3CZSjxuSdVub5Oeqp2LuzS1GqkfwSxO28C0FTnWjDK3S6463of3WLesnHUmD246TJqWEt2noLM3_1vSMvO5tOsv8PFFH0Bnw/MVZbGhAVDio7078gXfbYzGIUC84jO1G7blRK0KO6oSs',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/0YTwb_vGBfzL61k1yFMopw/nvQUMrWCCYSs8c3RE1ulpaxZfDrmPt1rqChxBAv7SA25V00O38fM_AdJDVCr4Eu92C1qBYR054lVGtd-KjlXkbv5BRO4UqJyjIOZKYUm8kk/JXE30sI1LyTrnadxt0Erm6D6zq9vMdv9DzepCeND1iM',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/KEto_bxO8GGKsIiAt3mzlQ/RC1DkB8ZJSD0Hrsd4jqtA2xIcdVl4szO0CzwJX73Xpf9XUxOUXNhtrODpIrMy6jsjqcLX45KMo_aoON0eV0ditda8EpOGdQ3aNTYagFMYJg/ubasxygZRfYeZkt0SzpD9Fcly5t5zW5YXQuWFDsJ8S4',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec0uIIGmUX4Ot7Ob/fldI49uIeYXTkPPfU/attURzJLsRUJJfvyM',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'Q3',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'attvI9RA4hHkfpyID',
            size: 103462,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/Wl2MgTkkfEynlxxdGCmdgg/fnb7d2InjiXNyKtBFf-Hnsyu7l0AXJGAFT04IzUziDwChhPI_-yQ9q9MPzDqb2wquG4aesTsg5Jg-SwxIkwcoyhnTQdKsjEEMl_H6IDPez8/dMKklm5W_qJzKBjEv8weI3EfPJnnpxiXBXTdOA-Q84E',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/gH0D1G8IhBFklKnsyg5M_A/XEtGtOsLIQKXw2lDmoqZ9JFp2jlqDX41HV6V3TOlYd4_mx2B7UdP5G30T8ky6-h3q1Z9NFGTInrqzF5t21OvIdnWnAbojKpBDIGdGLYU-44/zmLubMHC6gdtlbFM68eyz8Xo6tOoaRSq708lPaF6bYE',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/7_SQRnNCtO-8ntteeYCHQQ/ZEqzew36gIQ1Q2P_pyxoU0cIMbEphppm5gS4ubuzAejK-JnAnkgvouvcTGlS5gGlvHQUs4Nk5r7r2QWwO1-CL4whmGWBve3thMfdw4SRBU4/6sJenj5qQyrayZ-mEguYLxCQNWdJeVr39kxIAQSwqgI',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec1lbR90lX1Lh2uy/fldI49uIeYXTkPPfU/attvI9RA4hHkfpyID',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'Q3 Sportback',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'att4I3OaSmucjBfvH',
            size: 106512,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/t6TrNQM1Kz4uzZAJwsOifw/JBD7PbTj1JCFG8YPsBmwfm4XcxhwMxn_6qGvNST3q7K31MJ4svuwJFtcB7HFCwFd6-JCmh3rRrDVujyI0jlzHJTFSqzs-1UFLrtlAt6wiw8/maHnFNw7K0_dNIOFb_cL8nEqAwo4L-OwaGN8Tfcz0R8',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/lM7u28mON-RU9VI69A1yyQ/WxBikVwqtHL2WePgnVGJI8d4oKKSU5BfJd2j1jKN2ZqFZEUegvW4c44WwdONs2AWIwLeS2UM5-TkkERwqpTKKcbYeJnGbSJ2av1v34F5UVY/GEOVCf-_4pFHbe-HtjfTD-QNfBcsOpuZd2BbGWA3gDc',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/YuxJ2ROEDOes3tD_qeK1XA/-dwxqnoMLex6H79VkI2rtWD0SLJ3FgDa0_0GougG9stMwKEqKnoNtmyFnr5G0uW8x9Aw_JcIwecm51O8D55m5fvuwj2CbKE7QeS62bXm-dA/Cms3QT7F4RTpIc5MyJfZl6IaxcVINPY4caLDSawaSAc',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recPse0JL8vbVz6sl/fldI49uIeYXTkPPfU/att4I3OaSmucjBfvH',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'Q5 Sportback',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attMHBqMWTOIpln4T',
            size: 98130,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/P2G1u95WNIGSBkcO7lgK9g/2p8D81iNCJhfFiGlUtpxDrRJP2ro42RI9kYZxb09DgYelJ6Lm0IL5x913mj4fchcACSFFBYg8X5d4N7cljChMnMhdOSs5VyU6V3h1CfiyuA/VGsQOQK8CiuGNFy5_X2hOTodop2Sj2yeEBL8oR232Ek',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/k9_2fwbtlAAlgrw7t8uHSQ/m0uZ0fe0G9poCtOvFcnnCteZjpMpS8rONJsbNN67CO6-lKIHd96eIvW5N0MTtOc77scEys13NhwK45bh6s6qnycPbUqHKr2waN63ahzm3CM/2dCeGb6W6SdUfNzdSM8nSqz5F1_NES3S7_WKWlRCz5Y',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/UM5_OfYbtDBLR9ahPlmqdg/p0-gU3TzET4SxhnCBVbBBwZ-F1tlCktKaMu6GvIDHw_eMzAM1EIgKcdqUT9RbxURgXgKDM1ofw1A9rSbLBQUDH32a25ZU4w5rtGS7Y4ZCwc/5WIp9SbjFRUqNuokFs_Zrw6OCkCi0Fx1oAjG3XIDxWA',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec0426ZbhFgVxdHy/fldI49uIeYXTkPPfU/attMHBqMWTOIpln4T',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'Q8',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attFjgSKIwhyjZgmM',
            size: 87204,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/Y1iF56J9AdPWgODRBebRVA/aHJ6HjB5vWCsLpnpmafvP5bO2LSQcNl_zIizcMEFgqv8F8WN33pGuo9eLdCfn6-e8x57WxJxT_VCtkf_tnxC6f_rhp2H-FWAdcSLNRM40VU/TaDFaWPfkgA0kg_t_GYTu5xFYV3AEbSgqhxlY8eGGXY',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/up5KGAUyNBZxEJAd-MsiKg/X7b1nCdk2Jz-d6K-od5-aw63bmw6PgZtuvLXzl-JK6F_f627M-d745B20ZlduP5R9nIhC9OZpeeTlH6Si063UHD5hZ3hgH1SjfJzOkgtYEI/bGVeUzVX7l8G1o6-AWKu3hk3cE6jmo8Oh2Z6HvUGJdE',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/QR83caAjLrN1x3-wj7lEtg/p8PpA22OP4ksZpLltKZKCJkYK15KpUGN8kpu2gerQfvDXUBRuCe6YAkCcyc9uqFSBq7aVN1qD0_U7OaFctJAi5j6vcYr_gwfH3OR16KVXVQ/Gj2uXoX0z1PTIJePClYsxDSEYR8PWrCfAu_yZA0C8Sw',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recPnUS8kYS7coKna/fldI49uIeYXTkPPfU/attFjgSKIwhyjZgmM',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'RS 4 Avant',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attgS3F4Y4yKmmgE9',
            size: 97622,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/Y-s9zN9WYth4Ij2OuwsTuw/ruJqyB347fakKXAE7iygzaf1c7EGRQYHzFFxtpLwlwRmmcTz92dygfH1MGdnSNJw5mqvhaT2vjkiP_Ic1shiEiK3X5Hb9EZLr_pCChMamVA/52pyDZzz8V4vYhRQxIGF2-uyPoJ5qi59D7R-POCgQcM',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/TIH4iRjUVnOvX2FiW6xN_A/oeh3mWydfAyX6u-HeHtl8j2bb1K_jUUeq5G6w_eoGR2RhtilLAKMaobnfDaotCTdO081XmrBzHAEZVVI9KHg80TFNZ8bHyjChRE_UJ0tEJg/J2p7wsd2QNXVVvjxSupUjz-_BLQIkl7iTfJmPeROErk',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/UZoiJghXEmQ0HjNIwtgOkg/tJs343kNeCz8L-BpumUVOnfaYu0RqWL47zPCbi2F4a_KLShUlkBFed_T2GVHVHeND2_aINYzZAvZxkHzo2fJLWbtFgsGcUlkVnYZRUGxDlo/46R1ayIlvyVzZkybmVGce9jx87O9YmYQJ0UpshfCi3Y',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recLUnQz4xYCoeAZS/fldI49uIeYXTkPPfU/attgS3F4Y4yKmmgE9',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'RS 5 Coupé',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'atthHKEfyYpf63RHC',
            size: 91382,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/GzAi4emMYKqBoNRpfY4kEQ/oJlpXSgT2wAz4sc_-rFQzxTfoAsMZXS9SQWprU_UaFjrPF64bePe_AJhkUP9GJIJWLPM4BjU_AY-m0cUKniwf-5PI5nBZ5b0wV4LVp3qZc0/YOu3LjmxXBsvzvlQt7vFO4ddoUiG3AcdCIXFHkspOCs',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/sQ586KriBQEKKoRuFU5AFQ/wTtE_FZhWrfR0LVnodjQvDdM_0UiRC2VL0qzkcuaSwwDIP-mJ-Lf3BOmJFXU6sfQy_aotR_Iq_mI4HCa73CR1jiDe2CeEJNU1tn-O3U_4sU/TFIbo8vxiCir1j4VQFDCrXeDgMPdSqz_nYOAKHCuM_U',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/m4H53Expoyfmx2tSbwXW_g/SOK_J4ROLhTDckBz7OhHIEVfPNpr0sCwTiPrCAcxZyOH9lD70ymaFCAzos_dys03Ru60teqxmtuHoz3u7YUzRZ-U89BU76CXTu2tPRqd2q0/ZojPOL-LxYG_Hio27PuxfEtqf6B4_WzRBK3-21TdSzU',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recHJdkdPWMdrETgw/fldI49uIeYXTkPPfU/atthHKEfyYpf63RHC',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'RS 5 Sportback',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attmIeprLaZcM756v',
            size: 98598,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/R81zEQkM_IoUrR2G-KGjvQ/3O9j1PzOyFVQCG3BcWIxxPLzlmC13TYpuQIipz3v1FipGttld4WqLFU-4AzQwGSYA1TMuoGmSTYlTNfmlhAt4w8SBFijK6bZKFzy9XRrfLY/3Me0w6AIVLMA8Ep8sCSwsRxlVpIa5I7KJTnYgvANvbs',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/O0NoXCqlS_4URDsmJj0ZPg/kdNiKgaEW-fG8p2mxktUAExb9aY0lpSLGxOlQMsiB6sO2jJHCSH2a4HND-RP5jssQ_HUEPCwRgkjy-TzOMSSWxlKBBhvmZvlACNG6R80FKI/HHwWJbxoYGiGbyUwe_BvsiswECSceizYnX7CMhc72Qc',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/zXJYI2-iP9Tc_a7wpDc_oQ/oTzhpdUucBeCQkkR-a641d9LmCIAHsWLInLMX9XJkcUSRcc7wZmrw_lZSA5drrQcoYUGDgvcPQWGtt6O4GA_1HNKvDjV9fN7WCOUWiuasQM/hDEd0tahE9YHNG3PaDXNbL8wyyYueg9fFwiz8DkZlYU',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recG1hXKwalcTG4qC/fldI49uIeYXTkPPfU/attmIeprLaZcM756v',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'RS 6 Avant',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'attkVvGTfJtNyXbY1',
            size: 95516,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/ZBZSd_6_-H6kZJFHqwoVrg/TK5qjCj47WdhRdDCUHf31ocpTCGiNt1U3z9Qu0hv0VL7BQbXdsb-LUGmLwsDenoT6Bxo0Pb4eTCKMn1P-P3xQh5JrwtJpy4sj6VqRk8VPyk/qnLVAOk2zG3WhyRshma0i8A_sZORUybv0KOpLzea_3Y',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/d0qVWaDZZD6iYiHE7YS_1A/Mk-iX-FtD5KYVGjiEwDNIEX9Uz9RlqDw7BOInPX5shjvOvRlTu_BXIENj67w8EIsaZlkTI2bzyc8Y89zsAXno8c9HPXypS11L-6DAyoaGr4/PacpIQkgDQ57vAcnyfUUMtznrhtPd2OHD1bzt8jiOcw',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/qiYjNSFsnGJQg8eYrmMX7w/sOj7sANK8vMsWC1be1B3UTon58_FxdEsoFdHqGQEf3fY0-CtC5UWehDrM-wE32-xl4mnMbl0v3YeQEYhj1J6DRvwuwPigRbZkhZ22FIyAEc/hYjCrHfQyVtJrQsy6Fc4UDDGRNqihPQgwcpdaAPzgHk',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec7cTLV7LsVLD6hG/fldI49uIeYXTkPPfU/attkVvGTfJtNyXbY1',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'RS 7 Sportback',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attH2bYmhpv7JC3Qz',
            size: 120542,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/5_pQ2TX7zVwv1bjy_R0vng/UyFzZitFU9ZDGWBrWVYiE7nb-ty_rsj_nTEss1sKrI2qclWrsZ6ofbABd3htTbfqkSUpGDhnh8zASJeZEyGvFsm2B8QpR0i7wTxF0afZ67I/igpRuyLSWRHMJWLLosYgBnkjkoVi7GxibIMp0pZsOBA',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/qowaWEk1T5dE_HsiL-_U7g/KgAR47jK_4j1eA42qMN7vs2zI9_jrAhuIyP9oP6sgraM8OgP_4xKfE2Ae54ZxMqprKq9IPigS-piHNaa-gvVA8I4fRHf0Ds3eoVXwXmYYlA/e4beOFfiKRKc-oDgpo630_-fhASnA6TxN0bVd5qnJRY',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/jl57FqlnwXwcXrTs1kD2gA/X2tYt0m_TahmVO21OY53SLH_2gvkC9iuTW78Aidq7u6LLHypYYpCG3x3aAnRbS7-UdVk9Gd25lJJzTPx6ydYvIoHb_S_8wQO_oc8FY4drLg/oiNqrMI1icadYKZa3K5Guo8apv_A_gm-BAbYcRyw5jQ',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recPpAQPmfsTt1PhV/fldI49uIeYXTkPPfU/attH2bYmhpv7JC3Qz',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'RS Q3',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attlHww408qAuC2AK',
            size: 100882,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/ZNQVF59_UV9GKoSAYx91Mw/ZgD6vsFZN4YaIS282RUgeMbSAXiSHt7TqSXCLcVMtpNJgRBpeYH2v6qJKBbLr7q_Rw3cHbCy8-MtgJRV2HbZr-m-4bTXalPogyK5-EOQNGQ/jMsvm4R8Cqx1QFW_sXPG27LMaoVmh8ndWcEirk8Rjgs',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/gll9NSrzre4oCMQPI8WZoQ/nO2m7xEZPB9diFXmSDqlfmEGBiHxC31y7dMtqO6STeof7MmBc0mvOgydRuNeZ-fn1XKD72IfdwTfDL7RRtZFq44v5navZQojvNZkNTvhRNs/aLibyYG7NZVFVI18xKv5QQp_sLnL3al8jxIE_Pc2rsM',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/sv6gY5XEvWJzC6wqIJ44_w/UNw3ri2Bgg3H3ummszxQQX0i4qq7_ffFZqbD9mASp2aQ0OlkOVIc_0en7IvgXJjs9InRfElKjvBsCbulUQQNh-owY1G8UgJJi4d6hlmR7WE/YBtuPkZj1zUWip0iKbeFySOxzZxVLBG1iIiyLIZfjuc',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recXaTDlhOnoHUFRE/fldI49uIeYXTkPPfU/attlHww408qAuC2AK',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'RS Q3 Sportback',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attu8tzP9VEKRz1Hj',
            size: 104130,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/DthEdyqrzB4Igcj_Q4026Q/iShIQr2RYVjYBHGMAezbyzG_DBDfWCNeinX5AT-AHKmOc2YhKR4W9hU1_aaPiKYxig0cRu0zAeS6_WTJ1ZUz8k5t1RjerSF5rqnGIZWi2B0/xNAtWmPDzE1JHh2gHnCpZ_a8evE4XuymWyN1ocEMHS0',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/x29P4U4rtk8gylH1QwWH3w/vf0VBG43vP9OO1GlzPwWr03i3XxCwMIHl0ImldcWhwd64y8RDIdGY0ZSFSOuG4AOyp4JKNFlAnbQm0s8eq7_OMaoEOxBWN4Nftvf6Eegjq0/nmpvEzoOD76e7GdL3VfaUm6Akqy_lRCs_snl1YThWew',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/MngXgo5-6aqQ8n7TiN8q9Q/rxF5OnZmRenqujBDYEHA3Z5Tt2igxAVBCrQK-WyN6CiKw2V4eYc8N45fQw-kQOf0JJxLId0Pjm1-U9J6ui4gvI9T23usGbA1n80YruvlM_c/5YrFfyhe6pSeWyJb4sJN0BH_LTH4edTouHPyQoEmEyg',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recBpYFWxQFSi43Nu/fldI49uIeYXTkPPfU/attu8tzP9VEKRz1Hj',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'RS e-tron GT',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'att3sGl5FsU4fEQpw',
            size: 101528,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/q2Xay016pWl9GLjGvUYI3A/zV9CJhQT0D0BaULKrClcw8GE9ylldXUgo2rXboYG7r_4_B1-D40uK49_GfuLPgyV0dRmZJG5feceSHwObxwGBnHFhhhCoN3YSod5m81LpT0/8SUhFrvqyczbeeGr5sZQyEu41IDtUjQnNjIkll0WR3g',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/H2JxsMg686afR8Dj_H_mLw/rWFph7a6GXQSQ90xFexWAu-pdMR-oRA4q_RoyBHgpFWw5m4WiREDBRDhWD2FbkK1uNfdGZHjTZPBO_y_GOXWfqtB6w_etYGmGmAyuR3Pze0/1iqlwjLMXy2Yl8tQrWgtQw-uVo0epXGo4YCLNsL0N2k',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/1zo33qmVAFhRqGTNb-V2Tw/X78LpvGDu--DbP0aUCcpvd6RBiL2F6XIonxTBpXesPwCmQ4oSXSvnLZps6yG8Oyzq-PpoIWsjSzABriYMPhE7Qv2lIn5HElCNfOmqMTKo2U/8RazQzAGh99B-tXM1zuvptuStm82VVJMVNZI5JZIF0Y',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recEaSkOHHFZVvfeQ/fldI49uIeYXTkPPfU/att3sGl5FsU4fEQpw',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'S3 Sportback',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'atts3robwtP3Ywrz8',
            size: 110620,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/NrbwJJ0sudLvSdDesLLNZw/UQbjJ8iiA7sk1Zn3BQObuJ69PDBNDjOnNVRTzkl3e4701H0xtPImytI5HkL5y3TmzgJlA_0RvSieCFdFoV7j6hYMuUHXuhneLQEtWXoN-s8/fUI91dBChkpGcYyBnLdjPOZR5HHOrbSvGWhjj5G96P4',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/M_3iFjDAc_FUIOqPzQcT4A/D_6vW8_-RxJVmSpbq77H7Yh28mcPeb-MsifZ_7UovBiOuc_qkg-Q437FCCXYFCGNSUuhnMjqXcEUunVxf5_qCH1MiXrq-WQwrIrketjitAs/PhtnjHm3fsRIaEuFfM97NN702JQ3eVJw6dSguTHmC_w',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/pX0uapWnxKDdf1ZEyGoDiA/tNvew16mtzpuSM3WbYE1aanvmBYjHS2-oTtlfq_0eg0d_5DUOGgSKeaWE3SWHUhJOdpoEzn_-jWVE3lIXleyzVdGSQH_af_DUz40CuZTxuw/JoDWmjmMRuXRQCpYExtz69zp57XZ6ogrhqFbt-Q2PSY',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/reck0gzjpopuZHXI3/fldI49uIeYXTkPPfU/atts3robwtP3Ywrz8',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'S5 Cabriolet',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attvwPIoZ87n5FaBu',
            size: 99626,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/_YkrmcprNgZil6QFjaWuWA/ZwGMzHBh5wtCxza648hsQaHlkmWz2wFKulGOFs4EqnV52edQ4R0lM9SE_87c7pO7eBDQGikTUIOv1-q6zyXosyhVDGtLKPeDKGkA2i4JeoQ/YFfSlwmcdA0BXHVXEy18FK_VH3xh9NGCEYfDyZ10qF4',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/-4HqUR2z3--B0br4S2ZD7Q/oayQjfEPEoH0nc0MgSwlqMJ0CkWntyJMqTRF7FhVw5vcA2aqs5sS_ekXVGXHa1GZITMFcBA4Hey_tAlNRjTp0Bza1zkTDJqHRQsiKI6Ktr4/1edrAp_4OAwzlDiB7i3CLWW5N4oyb4YRGBYuMLKVX5c',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/F6E0390rePdoHRL9fCtoKQ/S2PJDHrEfEkoRpn-i9TS_V6pEKb1z4m4fc1pq_LYC9iCgDFDwPNJ8YwAyR82hGNN-jMbUqVDMFik_7OgnKzNeqCVosfz6bD75X88wLheqpM/YkLIT0C6yVNWkJu1XM-XV_xz9eqWndREt7hSQGWTFss',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recX2xZVtM0VYIp18/fldI49uIeYXTkPPfU/attvwPIoZ87n5FaBu',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'S8',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attXa0uZ7mFYUAQjI',
            size: 115294,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/JTcb-AFUS7R6mprFljDinQ/zs9lzBH5dwPHejb6G4Ihu0JSlJ1oJvTV7ziqXWJVjWkcUCvJtmiuS3Jf35p7aTtgV2E9UFyMiFeeHWAPQ7tklzyhMLTElnFCPDOn0NgYlLU/cfFnG9Zxog_oowXf1Bux7GkH_21S2Egst54gWEG00TI',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/exE6s5IeiAvPl-xZ_fAbYQ/EpyeniVWQY70wXHpzPYt0askUGkCRLEtGOosN1dg8TZUXoFybt6JIGQv9PNtfNivuhZeYXbH_5fnjaDJT-_kKKjEB3pNN4vSH5cRCjieby4/L91AUl9ImqKjoZ_Sl28xpOfXyHBCA89U1rIzE2UlP-M',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/alXHcKIPfS5MheLABttYIA/JKcfJVFeaGVAIIZHHKV1cKk-n8Xl3x7PjdRNa2FoVrqTfUl_L1W9Nx0p4pf2M2KTsuDPlGti6ztsIClZPdLOIimzEJMb3xKxqQv0dek0fO0/GdIVdud4Lrwvb648yqvYUFXGiMOXL0U18pb69uS-WTU',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recqEfI6hdxHAgmG1/fldI49uIeYXTkPPfU/attXa0uZ7mFYUAQjI',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'SQ5',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attnoRcTSKie8lnek',
            size: 89538,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/NtEcRaIf2g4OqgYbOspYPQ/1ujtdXkhJwokhvSceBXStE51Xit9XgbSfTxydNcl2bU8v62AUIQQW4cWIV5s1897X3iBB3k7HdOBujcQV1HGFt1rkaZs-s95jN7QM-XnDUI/ti3zWMYO_8GPpxVYQWMrdyDJA0UP4T1yrhYuW9UtviA',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/1Ax8TykKeSSWrtgw9wtSwg/NmWI5OLeFWjEhC5LLFcJOeqll68csLBrhyKOhKhAjGXCiBSOSP_15xf6JlR6aiUWsV5EAr5bkcgRUB-ZsgwtrvwY0kLRCau58pnckGV3Axc/u-Pc1k4fUJHlFhv_jbkjiPPMEKJVS49bz_Gpin01yck',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/c1NzZrT0tirYjSr4iY126A/HkCmErjmNtpblkUvX_8xbcYHISaXZbDd2vlyPmW2uY7Kfxlj5ToTMq2fu7mpn9rReP9qvkgikO82qW1tZy2qqXXjPJNAHw72QC7JTpz3M6o/kUA_8-pJp4y_0ZNq9TFldudRdDXDQcudCDjMz1WoPGQ',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec4QlOJEn2o53Psi/fldI49uIeYXTkPPfU/attnoRcTSKie8lnek',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'SQ5 Sportback',
          picture: {
            filename: 'front.webp',
            height: 958,
            id: 'attIf8ytvYPk0lyjz',
            size: 82028,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/mG29U6OGOtIX1aEztSWnmQ/BEIZSvPgM-h6arzggatTp4rb07XuBb62UGA4LCefmF-kJZqF749FsmiWXX1wBC-CgYW6McWXEiKNXxXAZLjubKdyQidLqRBbYJEFIrhNJ4k/5a7LVX5AQy_gGaXzG_RgpiGrrWYZ1ZZgSWujk929DYc',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/18ij1xPYbcy4NkjGe6O84w/MLS-T-Q9Bgoi5OSWrDHuoZ4CWLnsqtM7uAqEv-Dg6DNCaam51onem_3TRUYtt_s2U5ZmMM-xR-dOHvl29nJrlLZVDxPAhtCfmDzwkGmPTT4/jY0uZLnjnAuw8_8itq9ZVH1QUMOoWMjhzbpw3-xNyzE',
                width: 855,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/58rTkgEv-dVF2SSNUyICqg/P5UFlnwVK67uQCRXbEFqE1Bab7p_tsdCgGv6Zt6F_QztIzrZDhDVaz158gkW023x5tegjsFVFpH1gZ53_mpJi-RQLTrxsvU7vZJLlEjFBos/D7AYPcwLINa92i66gJsa2o3OktYos2FQaDsLP9o4Go0',
                width: 60,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recPHtsL56F2aopXG/fldI49uIeYXTkPPfU/attIf8ytvYPk0lyjz',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'SQ7',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'atty06xiYDj0BZlpM',
            size: 103326,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/eE2XydcgPSnCFKrp2OwiSQ/fyvP7egGBBHbLO47QuZy-6RJrf7pmiB-pu3sc1PtMTbcuf_mN5kQtLUojOoDcORDeNlpdeyXMTr1dXbiO1oc_36AGENChIjWJK1IUgf7tkg/vMnCJXqxvg4vCDYTxssKn6M8Io7GXfet36DytutYWF0',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/zU87kuQGF5wOfOlQipDr6Q/fwspi6A1H-s0KeiZ0BO-4Ftrk3FGgnpJWRKWZvYgrEW8_FRj209aSImDsa7bww78Hm-azPbPH2T2Cq8G0RzfgZkjGgXI7cFaSj9DiMnVHik/jcaxZsj7yF0sjrSiv5egj7m4uxdYM-Q6wP5BlFX1u8s',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/y8rEHQyi9IzLZQrwz2SkUA/bqDmdLxgDiDAKPEM_hX4I5-NNeCR1qVnWOshRha98vB0Nkj3DnAazPq1Q3AfG0PVXb72r9odo43jVifhw3wmuIwBQ_gqVrtHZPz99U3KsD0/FYBXt1eJj1p0Za3rsU07SI3KQCOkaz6hXzeVYoafCMo',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec8SyB62YR6M9juv/fldI49uIeYXTkPPfU/atty06xiYDj0BZlpM',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'SQ8',
          picture: {
            filename: 'front.webp',
            height: 875,
            id: 'attGeMb5krdfap38I',
            size: 86926,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/NqzvXIMou2FDINpyaVxA9Q/PrF2dwPApOKu_aYlEgXE4aL3l-uxoNLXwc2mai2FK-ZYq-0BVP_24lPc-LdBh6mY__Kw8BGL7sUp0oiFQYJc-CJV2Y0PwtUYaq0wnTFj-4Q/KGsWEbzloXO8Qt-kVrjhP9RdL5oYRosjJLFfVzjxA18',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/iIycbLzEkgTSBIo-nKiGWw/d9juq60fK1eH_6jkLvJn7OiUEFuA-_taOAy6rpAvqG3uQBF8VvGomatXT1mN3_69u8ErwsPY6CmHId7A7WXDmRkssWqOEo5nTprN9Pg9ySg/wKKWaa73nsqFmy5zj-kp7VjOunkYTwVkSu-yiIX8DY8',
                width: 1638,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/8b8x6Ei3SUfR_c0kzBLimw/foX9PBb3z8ixkz3r9GC8tG_m4mp_oA-tLGLNb4sqHouHykbmWGx4avVvilWuef6BV9f5KyBl1_aECrHTuO7dHoiuK-uFoNzJag3nYCr30OI/F1mdgmLIrL-xYkRJctoOQN9p01tyb7OKKuWy77stHKI',
                width: 115,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec4bTJgQnXvBa6n0/fldI49uIeYXTkPPfU/attGeMb5krdfap38I',
            width: 2800,
          },
        },
        {
          available: true,
          id: 'e-tron Sportback',
          picture: {
            filename: 'picture_front',
            type: 'image/webp',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/zalVdq.webp',
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'bmw.svg',
        height: 0,
        id: 'atts6rfjF2mCOR9x7',
        size: 1784,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/sw5tdNa_rKYB_agmWEXFkw/c_PaJEtRuVaZw0TL8IPQt8KhqTRyPCOC6QhbgtJR966FPgEjSQ7MNzy0oI81yrjQUKY-b5YjPypoCWehvrzJK-jUc-qryp9bvIhIqAbbKTs/UhYRq-4TVRO35pxMGat7aQ1UHfeIyrgqdJUS6VZZ9rE',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/PxTBoYnZMz2n_qprnqiekg/Rz_QWCHuAx_h5d2vUwyR2mZifF0nDDFE32EYe0mnIKo3b_cEtob2IPp9-8EuLjTB71Wx78sIMOSqXyPjU0noR8HY6AUBfHYD4oAg2qt1x0Y/ALjSb3-7W-E-k2-7pe_dbU_H7W2LvPJ1XAoXue2eXD4',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/D_WP0M1CLTl2_P370LRCrA/GGbx32FsSj5MtAabyf16vPy8KC3bnYcYK3cKjicBdtFVViBJa9batLQV503yjccV8XcB84fh5MAbRNt7Ead53voS624FkjzSSv468OFCN0k/o5a3U2gtv6hiKpG0i5YhQQ51BP9SvM5MGzwbLLtSlwk',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recrWY4iQkLckyDKf/fldn1PBkvAK6H68vG/atts6rfjF2mCOR9x7',
        width: 0,
      },
      id: 'BMW',
      models: [
        {
          available: true,
          id: '1er',
          picture: {
            filename: '65b484f4',
            height: 900,
            id: 'attQCYjDVxNgPcBTW',
            size: 436804,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/Zn4XuMk9EEmGPK-bHO_L5Q/ecbtUjn1hx68-rzoD3eVOAs4-hjo04b2bb2vydLMdZF6IZMEQLslvUicfAh_TePD1lVDuqmk60nZFVQukdPRFA/xG_dMPkFv9iTbkSQ8BMWw4-oWiLy-bJb_clu9pW4AJs',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/lsm0z47qUa4jSH96ATeK-g/eORyBfo14S7GtIRPgGqMXFFCus3pk1n4aUNDNEuJ_YhCk8W-q_sI2IO9fQzmTINtktSeE4b_ZWe0OL96Vs4tEA/tNF2yuJw88AK8MIa0RLO1yZJpnS9w6C6Tetmz4OE0UY',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/VwogGxC4oJSjRi2EjhQaLg/zH75gTdpWr111xkxDX_Yx0eRveKsp4w_0C_WI_1jwfH_3PDdY-_mQTgwZhbjxNhe3reIukTT219S7vK3FP0XyQ/K3k0pWHXm1YXChwU_JUW8kCdfpI8xlUfDEE1V4oN9Q4',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recMbqqOkLlgVtncc/fldI49uIeYXTkPPfU/attQCYjDVxNgPcBTW',
            width: 1576,
          },
        },
        {
          available: true,
          id: '1er M',
          picture: {
            filename: 'ecccc69f',
            height: 900,
            id: 'attGbFNVD7JpagYf7',
            size: 671803,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/O0-C_QWL5RjrHPKAu72RpQ/D1KktM90xPHXPikJOearJGhwmAD31x-2SKGYp1q9mH_g4t29-Vk25vKEoRVSbAnVjUblVwfdcKxwZs5_HDAmnQ/0HEHjxUPrRSQTnZJhe4_JpaM_eIF1J-j-bm705xHMmE',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/16vtjvXNVkYz7Zk5wEV_Hg/DRGfWKxPLVBdA5tzMDgW3CJYU1FoRyWp9xxxYZCvNuyYayVo8g6mtv8AGvQaGl9D9lxDCLWvYaenX7955DyXhQ/57JHUl_SAvVZ8K_hlMd-vE67NOWeUvYrnbP2LJ9Xl3A',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/51eyRIhuKhUs8jP5PyvU2Q/py1ogVPXnXVA_2Xa2AUSe7Z_oQ-o7mNcGiWyblEDQyP9OZqo32g-YIIeSec-fPhFSUgcJmI-Tfre9ym68VD1eQ/E01uiq0Ao1W1jn-_NuyYSWmnEtW4NCveDagrkL1wtdg',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec19z08KxuAx4cG5/fldI49uIeYXTkPPfU/attGbFNVD7JpagYf7',
            width: 1576,
          },
        },
        {
          available: true,
          id: '2er Active Tourer',
          picture: {
            filename: '87112f3d',
            height: 900,
            id: 'attBm8Uz1S2LV2k3A',
            size: 434681,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/PEDONo4_Le0TYgmIp7LIzA/ohRk8B64m7vG3S3wqspyXpu3H6xOwyUct8Cpdty1yZ8msmREbn8TkCufn6SEEvvv93D_TacdeMvJkHCcG5JjBg/Ll4El8MFu3U9DREtomBPQJNLEyC6wr_sUvf1l1HTmi0',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/bAkL1T_jB5FJ2sGQ-s9NiA/zObPX6ssR_MvVjlmWEe8xK1Z2VSAarBqYu73i15PNJpHoCP3vxnQ_LHreaWuAqhOpo6MqWy6Du5T9QhKxnUQ7g/y1egNK8gXeM2Z3HY97Qburi2yLkJh3ZmM1TDrMfMd2M',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/q2TIRPdoOc3ADpcoZH9Kng/rAiZ3LWqIFXrd4U41PCsY2nRlRn-yFqOW6YspwYQENIGvqlaMu4b0g-qG1SfEkhhZkO2eyVUkI-2C7OovWLooA/vSKfcqj02mTwkkT6HdHI8qCaRgTJoRljYs8ikQsIuT0',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recowuAxlQtuCiCc6/fldI49uIeYXTkPPfU/attBm8Uz1S2LV2k3A',
            width: 1576,
          },
        },
        {
          available: true,
          id: '2er Coupé M',
          picture: {
            filename: 'c3af113b',
            height: 900,
            id: 'attN1cBLFOkiv45ku',
            size: 299933,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/MjSB7hEuVU8FLxebV5bAPg/givWZ4IgITsqbhV8ZNg0QzndYcQuwQojVFwxZI5dq6_X7vzG2FNRXdw2X1zdA5R-skfDekTARgYGZ9GqMZ6Ovw/5qxwLVK71mHwCkjrZVRuCxpzZEN4ASVBtUZ83fjCRZ0',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/2Cm1wZT7rKrs00epzZT61w/NqlCuOkpOlU4Jc8etimm0acugIkoiQhdiXFdS0o9z0SdZPJxMbGTgPD33ACxcsLkMdpTrStlh3On_ay8Cepv2w/08Zknp-mPtFbjUg-1T85p_r0NuOi28X__H-IXEXJ544',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/pENLfg5HTpZ7wU7JMeqMrw/0FiFA02fv-lbOYtDJ952JNKG93KyJ960Rq8kuAdraj2vitqa2ULcZk-nz2-KoI8aJfTsbGkNcbP1v-4JtMVaYA/Znft4yw7DxyLNsBxoNBZ_rXmGxlOnXnBc3FzArR5EMI',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/reciTCLoiQbUBEQUu/fldI49uIeYXTkPPfU/attN1cBLFOkiv45ku',
            width: 1576,
          },
        },
        {
          available: true,
          id: '2er Gran Coupé',
          picture: {
            filename: '756a7d0c',
            height: 900,
            id: 'attTKnLQPAlk2J2Tp',
            size: 439345,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/N2cm7z6_d7rqkThW1TQl0Q/VsWiMbkEO2808KtkHzaechmpBNgf5zZaIVohP-62HGlJL_KDy2NeRCP--xt5Scvr9Kv4nwD4rMUADi1pSopX3w/wN1GLxmdM5pN2UgSevDFlavgBLdf8_T_nnhoGW60GpA',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/RF85eMTYM7kFxRmiBK4kSA/q0djUJaogYsQO3urUFcLaISwfjLR2G10UttrWPtLPwnYzK8r1O2kr1FjM9wCNKl5of2ba_lYJtW9Y12Rx_bE_A/q4gLr_faFOqiAbpEMIth5LqGUfrGP1T61bUC8KnUXcg',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/5mexwheYOq2TCW9kaQmkJA/7tHCR0nRAqpOCVnh3orTK-fsIE6URcS_tu90K2uMs1oGy1zDw40aicRrJHVmIonDPb20Z9fLizJ5QcAt5A6Pqg/XD8RORIpD2SG-LGk1pRlpX7RaORvfa8caq69h8J5bsI',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recuut10YpYx48xUh/fldI49uIeYXTkPPfU/attTKnLQPAlk2J2Tp',
            width: 1576,
          },
        },
        {
          available: true,
          id: '2er Gran Coupé M',
          picture: {
            filename: 'e7b073e4',
            height: 900,
            id: 'attBcZbP7Ey0Q9bIo',
            size: 439364,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/B-V-DKTVNXwFWo2SL4Inrg/rDU7PF66PwQ_DQs2ImeXLHWIIlzYNMkL6uL20ehUaRtQRVcaNSaJJqmcKx0KI7ApRab_aKIpSeDIfF002geSHw/8sqjjasCwO0F5RKtX0xQOJPcKSdblZJ-05UPP3ZjNBc',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/hbGCZ9cmaiOuJmBCtcNc9Q/JsDpypmYB6A3Osz_JtPOgrOajeo4E2ikGEO_Sej4rN9qAaTm3R_YKRuAArIG4JIzDwZ5M8_u58Q2h7WBDZATTQ/dQkzMCl8TBZdlRY3YwWrMWBclzThmZNIWSQNbj0JCag',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/nX6enm-_vN8T9-neBm5oIA/BvQWZs2CP4eMWb0lQZht5EV0hsnOjh-5d17eK-II9-AEg7oZ1qBGz_VaN_GerTNdzTOmg9HnpIWOi2u7wC86LQ/JzXd9vcu6rZMmDI5XZi2h2X6W1uz1xSM_Rr8QVfGN5U',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recpOQD7VrdkTMCsj/fldI49uIeYXTkPPfU/attBcZbP7Ey0Q9bIo',
            width: 1576,
          },
        },
        {
          available: true,
          id: '3er Limousine',
          picture: {
            filename: 'be86ee0f',
            height: 900,
            id: 'attallodjbcWefITk',
            size: 378484,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/0y0bNRUyPtO-gxSwQ7vxZQ/0rZfQCJ3O9LejZxCNf1caYJMYtNZiPQoTuS87uGNoRyzsfbkUI75f4By-2iTB08tMV9ueIOx7-TdZnC0NTg5Yg/w669P6iwxVtaFg8Zzl07YZm26ikKqfjVcXOpPVTqF20',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/b2UzPmV3o0JGQsk7zW9VNA/rRPwhuFyhAQLjIryLya4Ydpl32fFKZl1yxdfYvY3cKQtY9gMFc5rNS5BLxsJZs5o_5LNeQikOcI1TmcVBFEq5w/ldYiJArrPGLKVi_jFeh6KiWbjXGU0GuR6oD6E3NqGWs',
                width: 876,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/wvgbnFNMmWo6tVjmWUrFVA/qH4xmVSPnpWLM0kF4Ybvng7QHhgv8rMUgqvS0uOaK_KRBq5khPDsYzymKU7N2Fcg2c0Kufl7HKOAxffQA0_KQA/vKQr2f_3nRc_PUH111F6v7SrVrjWdtH7eLtjjlh5bOw',
                width: 62,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recE94jdv2Szlh7TT/fldI49uIeYXTkPPfU/attallodjbcWefITk',
            width: 1540,
          },
        },
        {
          available: true,
          id: '3er Touring',
          picture: {
            filename: 'a614ce51',
            height: 900,
            id: 'attXeOQgXsz1AK8B4',
            size: 483345,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/J8VxhU1faaO2DkuFZzsdDQ/UPiHf93uo63_y46geG5QA9mjTzfAQZtHrpgp8pd4HM4dWuEmbU_C7jqbBXYgLTDlEkFE8H_-_5LO3DRS6sx89w/gklMjfz4gO9JLoauD7RCha-lt20IDNVE9n_qODMUpwM',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/fZwcEktk8NvRQJVWBlFYSA/5NzNfXf5TIGuArnxWOKlUiKou4hCExvoYiquE5MnlT08I5582ZffaGkJk-IT5vUypvuM5-eKgnPmFOIYUiCy8Q/fx9UZeAOrUDzWZuXTqM6H1-jJUT1E3PYH2RNzW9ek5E',
                width: 876,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/OK3vWHsoCjyfU1MDbegdQw/JR5V4dXdsZ7gfxVxQjEfUH23EpdHnuU1yoIswGLOrijEeAKMpGAhorFZ1ruH9jrZEFoj9jihS1DEYVphkzAPHg/obwrKt9NDnhbc07mjtX0RnpHV0uo8FTAuKeM2YrYklA',
                width: 62,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec9Fa36y2aTycAru/fldI49uIeYXTkPPfU/attXeOQgXsz1AK8B4',
            width: 1540,
          },
        },
        {
          available: true,
          id: '3er Touring M',
          picture: {
            filename: '94d5e6bd',
            height: 900,
            id: 'attoL3LndzSFVvaQq',
            size: 507823,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/TCtsGqo82LGTpzUo9TN7XA/9VwdmgRApeTPhGPWLpiw3HXCulaEIkaOJjmsoO23sb5XfcgH5FRYciy6fbTUqrbqVPQ54xllwwVGN8Hw58CelA/SxhclxIGUn-7g1gMFLy_u2ieKAX7w8uILow3S6Jqcsw',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/Sf-MBilGKru_OW9zYdxRCA/9asSAWueYS09rZ1N3PaEdK7fVGERgfLOxENHGXGX7wmNeZ4VVIyZvOcRYh_vF5jWQHYIUnjJK6K2N_yfxsWxmA/CpX7OapJoncNZBSJvBSwNErX2Cea2LBvD-0KPwpQQIg',
                width: 876,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/1sZoB7EPCRiHF4qqJxobig/4dParI49juyO2GQL1wCtwqonc1JISQYHcTjjIhHy7UzioJyV3Tg94jNnwINnrGG8PqcsaaL4VT05Pg7VsJr3Wg/IYf2zCOmPnUV1Ewcng3o4xaW-uGqfwZIgDNWSTsTAig',
                width: 62,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recti2YpM1Xm3dG4v/fldI49uIeYXTkPPfU/attoL3LndzSFVvaQq',
            width: 1540,
          },
        },
        {
          available: true,
          id: '5er Limousine',
          picture: {
            filename: 'fc1f2efb',
            height: 900,
            id: 'attp8LCrdRL9344nH',
            size: 341380,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/5an6W0gwAj6CH0wvsT2GEQ/b1xFObPLExufP54L76tNGLe58ArbDMxKPfCHmLN9aCAfkreFbZW0YdnRES7ku6YCK3YO7cj9KpMkg56wePwZqg/2-i89LDwtyVii-my4-S99iMmnNyKGRlBRKmR2JCHB0Y',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/XlfxkkvLHbUeq27MDmqyLw/P9ravaQZ9NXF3s71fQ4IVQMNEjcwoxwBTNcXPyeEnmIdN3hCJSYcJykwmt3_5FTIkVBZ8Ib5Kf0kG9TctU2QVQ/o_DfTK_fiKcslvmvQ7IsId3dMPmRa2YhD9qUnH0DoUs',
                width: 876,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/qKpSEgg3GWFWoZKXHoLU9Q/_o77eC23Z4Vawi6kOz3LsRfIQ-fce19rJK1_srys2S6CjnQY4C89T4x6ZG0L4v9zW2GMJ0dKmGMG2qIrC8RMWg/OuvX7g6Y4x6LILNj3JUkv4XRL8mdF7oBoc8ys-zZo1o',
                width: 62,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec2dtjCgNpK62ca2/fldI49uIeYXTkPPfU/attp8LCrdRL9344nH',
            width: 1540,
          },
        },
        {
          available: true,
          id: '5er Touring',
          picture: {
            filename: 'PKB2tvHKFqzxRSROYuar_y12FB-5qM5AkzwzADTGtQY',
            height: 900,
            id: 'attTsYgRsS0LJddxi',
            size: 418736,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/O8ZhH_skRuHx51t0TKiRrg/6IykSJVcu6QL4YKQ6CrW2FVFodhiRIeMsy1N-8zoryZM-oqxNINrpR8_GRoWmS68d8sekckxJ65RfaxzL9UU4A/qA3mgC4nRsXDg_by0HfhhUm8oaocvtTKYR0slez5S9Y',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/7EUj4eLgzf0xcAbJqkDbIg/xP-8KfN7UtxmhCR_P3v1xIHcP-d_UtoL0RXxIhEu4q7LoA-P-ZfSoiI59bIgPN39wmi0BykNZDKxu8ZF-Jqr3Q/iel3ilqQe75r-dGtOhHRGTgl1Jj9Ac8y6zxxbwfM1W4',
                width: 876,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/FfiyZupbZEijG8rRPU-Qlg/DGGJnxs7_vVXHkOlIKUad7Je9ya2cUXXaINjyJdvwzBoZsGEn1AQEAoZGZL4OhA5eUzPEsVCHVut6BCcBvSnrQ/5-eRYU4C0Wzt3-LDQFd6ZA4a7U23lUBaq34XTnFqC7s',
                width: 62,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recw6yxqzfqKYq3SH/fldI49uIeYXTkPPfU/attTsYgRsS0LJddxi',
            width: 1540,
          },
        },
        {
          available: true,
          id: 'X1',
          picture: {
            filename: '08b4eb1e',
            height: 900,
            id: 'attKplExDbmTfjRMf',
            size: 386308,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/kAHWKX7-tw1DPvUW7wODQw/26nwruY17CZWpugSWVtr6Ky2TgzUWU8USRiWYQtaDJVUgsFprFg2d6k-LOnSN_lp-oeDqTjV-fbvasBSGcmg_w/LCquoeUhBUVKwow-XZbVSWKBb75oeRf2Glfcm2h7XQw',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/Gcb6ih3Zdswhg4uECMX23A/Wq7LCaYnyaoG0ZDAkqo11i-k6iMNRC2Ms-oB9ygh4HWB6SG4SciUSmsBZ4_0D0g62w42v-YwJ78uNXW26TBaAQ/8ztkcoKOXZJPbzzznvrMIGHwZ2QxxVuZ4lWrW8A6FFA',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/IOz5lDGTofKLV1VHx26SZA/gBMPHoV5TbnAvEGq8j16nbfUoSLOLZKsbjIJ_taozpgZfubH8ePgRxgELCZMGQtcmyN66fH2YKmurp2vStegmA/x7rZjpbfrgtJX_W8fYX9IBKZr_d0MT92aSBw9WmnXI8',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recOHinrhXMJcE6Vs/fldI49uIeYXTkPPfU/attKplExDbmTfjRMf',
            width: 1576,
          },
        },
        {
          available: true,
          id: 'X2',
          picture: {
            filename: 'd9d65d6e',
            height: 900,
            id: 'attRyiHwENFYQa9hk',
            size: 482591,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/LG7fmcTbtcxdlgkoo7fP7g/Lf3ARLSUARq3osPCsR6jROKHX7UNX_Nkahte0oUttECfPgADxgnGuEvn8ElpzK5nI3Q_yym5gJQBTfIWBlRPng/OJy0AnJ4i_FGKQE2WQGEb0fBZP7DyD8aPfXqd5WuleI',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/vQfWZ0K9TYW14pedj_1EhA/KWT3WOZuY6qN05hmWhtp-49tjVVR6Z3_lVs5YlSRmwjNIuwapVhPZ2H47GbKUBxVgEDHRxFsX84VaPD7PK5W6g/b-3tPwgOrOXLDavUeOBkdUsRuMn3jaPvMuZj7ZaD8yM',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/R3OsA5ldp6aXlbjSexk5kw/Oo83hxQx8Fk7-2C0BMph00r93LHBRdMukK_ICqtStW10XSQx4wo3MMLJ5aghKa6houbtUq_49ZZU8L3STD9G3A/bIpe-r_fURGpBfYLD3If5v5lpsv94ePVx4hvURAm7hM',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recxGIEhGhkCFNlve/fldI49uIeYXTkPPfU/attRyiHwENFYQa9hk',
            width: 1576,
          },
        },
        {
          available: true,
          id: 'X3',
          picture: {
            filename: 'if27XIiVpdlOBUBpwe5PrLqNDq3P6I5h6NVDIdJ4omc',
            height: 900,
            id: 'attx0vpV849DeW88c',
            size: 485323,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/6rkgcZyXQ4toC6qrAw2P0w/BptGOy1UWKD-FYmjfof29PN3Vo2NunmUYMrnenMplH2ebtwgFZouNeMVQ0vkfh-zMhm4kR5qK0ad6SDONF_e5A/UivksGTmpGo3zu5CStXG2ceQ4UAJakZH7xzaNWYMss4',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/DZwpKE2ZQtUN6QSHFt1S7A/3_GpeFXP9Ky7XAKEVr2u1obkNJ8V-8yZuDvKLfpaeMT7cFcWb4jD9MDqq485FPkpU7Q7HieHZUuZgfzLTzB3Dw/2VqITQxOMqzyYLku77I-A1WGGTJWSlZc1iAWB4TPK3w',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/77JrdWUacfT9lE75m2-UHQ/e404a41DLBcQ7zNiddPHEr0Acl8_eagtF4CiEW20jf-b8siPgEOBHPHQjIP47HQ1kbWvqBCmFUQgItuq30tfEQ/FXL2xyMQpXKiRRlpuCGt11waGbTBCS2qWeJZ46N1RFQ',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec9o9WSTX4b7g3Fc/fldI49uIeYXTkPPfU/attx0vpV849DeW88c',
            width: 1576,
          },
        },
        {
          available: true,
          id: 'X4',
          picture: {
            filename: 'g6hBnUvxk_1yAspnw7VD3THFKWqHurG3mSkr2GpQSg0',
            height: 900,
            id: 'attffs3EWSDA2uN4M',
            size: 370770,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/9mWoF_lMXjyYnJfYbfQjaA/twWl_Lo-1_6IGx2dsJsce6WqDR_dbCZnmNs2pb7E6_T1wWWojiUBL0iB_z6saP5jhfX5hV9qwhwarUV5qdrCEA/hnUJLaxQZHjZqDRx06jSsi2nW7TUHwLYeYH18Qlqg50',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/tlBcO5t4QrzA7UMIoX8NQQ/XlY6Z4FW_kYS6AN5sYSvpghnu1x_9-2dzLpE2FSi3bH6VCPyPHdS36K2AOhwHSYQ3xrjPORsdLc9L2FNJpxHZA/IRmVpB2BbDeCB9TezQOn_2y1wBzv7I-gdxjTy0rx0yY',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/_VsAxOzyDb9Ghe-A1is21w/5_1vxzKs--36dCsxwNIYCbqXNAciQuzUEeDaRRMgbaDUIPJt_fThdJ4urJS5KWUHy96-Mrvkp353CVRegYAvig/wB8MuyPpIaJtJNvzLg_XQKo_KtlZvSFp1glxDT22WTg',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recLYq4TeKqTsaPxl/fldI49uIeYXTkPPfU/attffs3EWSDA2uN4M',
            width: 1576,
          },
        },
        {
          available: true,
          id: 'X5',
          picture: {
            filename: '45a8d712',
            height: 900,
            id: 'attqGnvshusNdapAf',
            size: 463793,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/fnMcjJ9ChshcBBjOFpgi8g/UrdADsnzKShA1Z4VJ2P9FuiT_MnqGdEHyuJBmB8ZtYlarCXzeGQQjdBc5rgq67FaduRni6fxSk2u-qav9UfXag/0h15X4uvbjNKLyMKExhu7QZOgpST93sgGhxSThyd7Zg',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/kdZBRIcIxlqkr8rPaXN-5w/ifSUl82NE2O8nYUl7UpocSlF7hXRpltruWAyObGOfy0fuQly_xCXnoFNwu3dK9dnmkDqqQRXjXm23t4vbwc1Rg/g0e4WEhQuQTOCWdr43P3MmKto1uLULmy5vABy6x461Y',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/X3i9FAcJHzmh-GhmT1ciwA/IS_IIRRhtETAeBU3Hdtu0CLTy2p7-mrOm6bVXzMvwtMDbbl7rZajMDu6P7FIBxBeDVZAVh5HmtuVslTqTo8vVw/HnHEQRcTxxe_BRYpix86M_WaqH4ihMFN7-JsEGeOahk',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recyfSUyN1EbG3Q2p/fldI49uIeYXTkPPfU/attqGnvshusNdapAf',
            width: 1576,
          },
        },
        {
          available: true,
          id: 'iX',
          picture: {
            filename: 'hlIXiocb1RowMQN5YE3sYhkCbyoL-LGTY64wAmusT6s',
            height: 900,
            id: 'attLMR9TT1WPav50M',
            size: 416808,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/ejLiP10hFSQxxX5tTXpwyw/HYwJDFeI2wcSNvR5P4qbwiFM0hWym_MSvvgoVe_5diir64uJv4-SfQmw_RFkEW71SC3fHJ61zbx4wzQGDe68xQ/H5xGP9KQ8YhvcUXm83Ve26QsHMkkOXTZVAJIIOvJJnU',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/mFoabFzijBGOp5e_1_jb_Q/2s8tUueVuKqzJc4vU5MAWX5efqielxXZH-CiHjMReICkZKzLuINzyHX-yPK-Ik82CV4_K-R1s6j0Bta9eQtaPw/I9eGIgdHX3_4IZzy2Ugh-ZClS5r2-TVQKdzRSBKDkMI',
                width: 897,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/VbYXTYf7MS8gp6JA2DDYGQ/tWi-BrtTAkJJ7Ps7_ueVYAmzOGkLAZWOQXzXYZ3xrdhlBEEHTRcxkHMA7q8C3KiSpXICgX6yYiQWRn7oLbm8AA/mpQodndrzlp-Zf3Xo6VBSronmetZT842pY2kouGY-lc',
                width: 63,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec0GNVIQfoHC16Ov/fldI49uIeYXTkPPfU/attLMR9TT1WPav50M',
            width: 1576,
          },
        },
        {
          available: true,
          id: 'iX3',
          picture: {
            filename: 'o3Nf0K8b-_9aKjWJPWIgLXq1p-y8wqOGfe85q5ODr1w',
            height: 900,
            id: 'attUsz6o2TKXeYIaU',
            size: 451176,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/26UKIqh4nlwdb7L5qjwgAw/7u0GfrLXU8qkl1-g6782tyZquU58mFp-TemaW2eKjE7aalNWTSfagtrXNdm_lbdv4mXG2FhOvDz-R87JRwXrgg/BXR7sk-r4BEZi1n3PNoDUvWM8cUJYghH8YMwsRe1_18',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/OIL9WZiHYf0bf9M1We1O7g/i7UTmj0DBkR-PPfT5eOkrV_kG8ab1WBmNYnumWt5T4eteSeNt6DXtYL53sc7GlJ7QA1DK3xLo-sSsefRC-PLug/L9OZ5LbZKdJXtJxWZrxCVucCyldDsmDkk6t1K2ilk-Y',
                width: 868,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/SCiYKwylszVSo7dKVPrHdg/pgqnmUPCWazLJUDnwwMVAMd9lfarMjwiHqbTJW_aGhmKnde7kVminklJPgsRTjRFoHG3LPTMH8teRc3C000nag/0uhxObXJ6K9CtdIGJqewCiXbb-WvD630hz0eE6dVukU',
                width: 61,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recIdCMq5oMmUAfDs/fldI49uIeYXTkPPfU/attUsz6o2TKXeYIaU',
            width: 1526,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'cupra.svg',
        height: 0,
        id: 'att90Dljk7PSVXPRJ',
        size: 2086,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/xiif-7NLyjVrSz6SrFsmQQ/decR3esKS-tdVMZfG7vCNAao1NhyrXA_E8wZr_IQ2wqINvS1iqd5gdLqDIfn4hBjrYA5hq4faFFjp4ILHBa6Q2OxaaeAlCatxOHoAw3aAjo/WFn5F-cL6XgfUvrugVQkLI_YT24krnFkdDBQX2nJ_Ok',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/zpm_QZexbxU_2HTbI1PQNg/vm3cUuIYyOkVw9Z5OnwJUZxP6p1S8DKOd51v1H9IWYImSP54xTdfyqbWOycWnrtCS7DyUGEoLs9BBzzrFJprcqVO00mHhj1pyKmE1qZS3EE/IqiGoQLLNH7C9ObVPEqKnYdLIldAyQpq7mawNaVyyAs',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/HwDr6fTZ3PM9T8PrENYXmQ/RDvSYz72-kkQjQqcLtgWDmeHxFCKfie3-dYuuJcCqxyY7Lz81NaXfwrjASsTFB82v7idO68Sn0fwsOv9EXpSRfMP84DxykCbsti7HdbJ6MA/0PRAX5aDaIY68vlBiy6WWG0xbqEnpAWw7jN15tA2Tec',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/reca3MvduBWkAjqIt/fldn1PBkvAK6H68vG/att90Dljk7PSVXPRJ',
        width: 0,
      },
      id: 'Cupra',
      models: [
        {
          available: true,
          id: 'Formentor',
          picture: {
            filename: 'cupra-formentor-3338-petrolblaumatt_picture_front.png',
            height: 1080,
            id: 'attzyqRjQRIVV5J6C',
            size: 488051,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/0m2kmh3dv0kLLusCEmuMUw/RMvXjZLzA-MA47p4wcZsftW93AOqMdwMwFy5DWIEyah7L75Es_CQ6oiuD9R7E3rDjz06ng5fIIUKS0Oq3JAiQw/we__XUhkSWoRo2alpnbwydjHJt-zE6CMixfszULoPAs',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/KPfT-MQs3HtkSNVaxP8GoA/0g2XapAzBJ5IJ8RR0ySTOo60yvDLbCj-GZXBZOZMtY1yAfTqJGxtikCoWyqpWp55-zY0U303yy_9KW9WZHlqCA/xmWdw0F633ke8MKJKhnzcdFo7MP0jOb7N3KpfV79YbM',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/K0s0pTB7b3HRsVrRIUmHyA/P4tppELo44PjzcGIZb8APkn_Sf6lT730EHbBFw-noMMLGKhBekoM4AlI1HvP7oQjkUtOHyS_fEm-kpO5W6m8Vg/ll5txSuGKRh323p0WVFHn44jTKTvBnxTiNOfwlGryWs',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recHvKRW3DhxsINX3/fldI49uIeYXTkPPfU/attzyqRjQRIVV5J6C',
            width: 1920,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'dacia.svg',
        height: 0,
        id: 'attbAMMuXMVfSnbhe',
        size: 1394,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/9VEmMhAkMeBjQMfY1-d5PA/PB31sS4ov6bmWnuGDkTXOOlNLM7MeM_9UQPLfS7mAqpqOmOKXPJzodkMqnblZu084xYgU79mW7RNlpi8rjkZZ6TlZRV5tsy8ViZMD5GXATg/dNs13-BLlHPiPtHH2AcyLlS9CjWDbEGqor_U1xNdPJU',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/5YlO4IsZQ1OFmcgwxxmxnQ/f7WoYepIKvI_OBEk71viiPLBMmnzRY5SdxUZXCdOPMBzC1uWVEnHeYI-BGxe6aAdRJsECqYbCh6os-FD0K5lJtlAe0hCmFOzP15D76dScWA/ILfdEdQ705jjmA8CGJZCWE2gKxoQQceiXieUWHkYcy4',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/KSO48y9FSMAtzbhLE4fynQ/_nsWqtWfIAqd6T6IhxMaL3hEGdrLG0h-N_tCFzIAtf_uc2XneoHOZ6cYBYrhIs59qyaG0NhgvGAkhpmbQUT9jHOtEGOISU4afAhZZ-XAAJ4/eBFSbID91E7Zm-Nf7Rl3gbzyj529696YDw98Ou3DpHM',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec6Td3NZ5UJjvMgg/fldn1PBkvAK6H68vG/attbAMMuXMVfSnbhe',
        width: 0,
      },
      id: 'Dacia',
      models: [
        {
          available: true,
          id: 'Spring',
          picture: {
            filename: 'dacia-spring-2038-lightning-grau_picture_front.png',
            height: 780,
            id: 'att7WhdOkN15yW8ev',
            size: 419422,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/pFAAQUKCsVpJRk-uqdUbGQ/y7d54TPlLKp8dfVM-l7Tpi7F-LNGkyhiV7sFRl2Xc-ZxpUJ46Z2Qq4r93xkVMxJkTq9GkujuDLo7SfwPAUbZ2Q/xp8ySR5SXjMcjIx--xh6EwxrCxC16Smgt8x2F0b8M98',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/7hrQd8Syi00DR1tn3a1dvg/ZL67y5ddbGkGomapwC4BCzdbkFfaauxTJ6IWGk6IseOUSYm2Sgle5rHEvtd1oJ2uTX3nrOod3LPqME0-kjuQ3Q/-f94c5_Dzf29wT3o0R4IV3VxRD4eO-q1xqyL1G-nDXc',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/BygARnNyhKk1wNOslrH1ew/HlHKsJuNFsF0s9FZmhEQfA5AWnvNGsJhtABrhCUPW7JFBDdPb6Tm-d_ozzVFtBM5ax_W1g7fOnlsW1h0Z21v_A/WzGUUTi8w9qbI2Z5eODALdsCjrOkYM9EhZFGRcGyAWs',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec6Td3NZ5UJjvMgg/fldI49uIeYXTkPPfU/att7WhdOkN15yW8ev',
            width: 1200,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'fiat.svg',
        height: 0,
        id: 'attRNcDHsttNds2wq',
        size: 495,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/9no2_CBsEYhdeJpEIkQobA/eAwOkSjembWi3JsObOu8Ks6lJJmoMPxQP1Le8nQyn3n9A5zemfr0xYBf78bo8I5z6rLUeKDR4iBN9YXnpZrix1eOAuFO5K2vvgE0rTk-RTk/7HQJNIpudxUfltxADGpPMYbXS7j6wAvGDp7TBBVRtTA',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/nCFR1c86h6uMzlW6CcCmFA/RYPFqNUlWRNdviLsCzDAgsnqrheOffSKNDzYz7eqD_IhDRYevS6v_tcbqMCtX2kgtEpEqBst5De9og6uv-zdkRJoCbZKfTLMxKpQsYfos1w/4FQElVaRvqn30eYNk8e12-SUm0lK5P4ObiVn7-IjILg',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/-dLpuBDp3PEKyBoBTjMRhg/2FGV1O1hZvcjXfX3qqRPTYjSq5CYeKQCD5pT4-cc0pFiYDzfEerKBz0q9ItFbaHNXEEzdeTnb_5qeMKiWxX2h24uDD6qeDPBbqJaiq8eeR8/mmMDYvKwUTbJlszpPo9lUEINBqEKsZKEUo82saehsAI',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recEHeDtDymOkFmJ5/fldn1PBkvAK6H68vG/attRNcDHsttNds2wq',
        width: 0,
      },
      id: 'Fiat',
      models: [
        {
          available: true,
          id: '500',
          picture: {
            filename: 'fiat-500-2938-gelatoweiss_picture_front.png',
            height: 1000,
            id: 'attuqOc9mNUsFiwDA',
            size: 469295,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/KQkXKjrJNvngDNM4tKGd9Q/j0gq9j_9Tza0aUz13tTXmeWFjqzRRD0Wadkq04AeJWkzLFabO68COMSaLjoxowL1EoHbzMVs5ZZGY8p36l2bsA/M98ez5e0MvkUpoSjUV0YKaCsNkrZgqSoMdvgkBpTg6o',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/jFwM4iXIecFbYSNInAWKNA/mwl0DkbVyxGEJ_Sqx1yDhzPyQB3gmhrzHbvwDFtToaJ5EKR3XiwU84nDof_8yHui6jVSNNvQqxoXWAS03_1GHw/1mfGIYJ5EiZXQqaDzIFvLE9c_U6sX9NU5D3qZ5Fhu8U',
                width: 819,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/54hCtViKLfOfdVS6ZJWRkQ/LataEG9SyFgVnn4vXqXLsNwfXpGoNE1hFwUyw1yOcCkmhsplgibKResTcGAOVd96NlcQ8EI_hpXneVo1qq2x9Q/-WGygpz0ZyZ3FT1z_8GRjqXDH2FQxMfx08loRuL4F5g',
                width: 58,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recvXllaSbyZKfQE7/fldI49uIeYXTkPPfU/attuqOc9mNUsFiwDA',
            width: 1600,
          },
        },
        {
          available: true,
          id: '500 e',
          picture: {
            filename: 'fiat-500e-2749-arktisweiss_picture_front.png',
            height: 910,
            id: 'attS0Yf2U4POF7WRy',
            size: 448022,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/KdAOXZDCw255Ni4L26RG_A/Nh9_RDk2hBHEyaYhiptJVC7KrGMcgHD88OyNUJb1oow68EnlxpfDY__LUjYGOUUy35d9b3OZvy7m255vBmkLiQ/YuKkh4Cngl269cTvdVX6eylimHjeKUkp4Il24qcfvZg',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/koMZGwQgMp-Cf56o1oXkDw/1JRquADseUNFmzejU9_Gsmc2h53wglhxVmKNU-gVNPD7g-v2X41--fQyFAjEm058ORetxA9IfNbAj0cmWte31w/kX3HvNvdkAVf9kAhkAK8MXA7hSYlofrcyUkRC0t8O5U',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/dNPi_s7N4TN2_g4BLVlUCA/x-3FJrULeZKDK0eJIW96-wml9C1m7cf6Iykj9UybkYWGqluLQe1LdKjQ_K8pAlXwnvnxZiKkDyREuFBgSL-0LQ/qm8kYgW43nvxSaeqyMLd-Wjiv_F70kzNvNqoHOcic7Y',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recEHeDtDymOkFmJ5/fldI49uIeYXTkPPfU/attS0Yf2U4POF7WRy',
            width: 1400,
          },
        },
        {
          available: true,
          id: '500C',
          picture: {
            filename: 'fiat-500ccabrio-2939-pompeigrau_picture_front.png',
            height: 1080,
            id: 'attCU26qKXbGqqTiq',
            size: 622226,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/QU1qwche6k5Qs9EoWCBJTA/rdoxcXNgQTEWKeV2iSwwBPc5MwdR5xhZlaqSy4kRHzlLXKnwCsfbZQ3P2ZPkGAYNS4r-QdUGAAYOzYXXINR5zw/TrIwdsAGPsEKGDGdBGKLJ0Y6U4KPTxuYskEK-Nue74g',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/Ttm2x6_RQFSQxqcrc_URwA/i6SjvxEfZ9PrfwnEU4x27dx6r0JhIJ9QSgowF80FuUeGasHQUbJ9sMI1-xTSFqayGlv4mQvLoAABmXPisL1zSw/W9iokZ0sAKQNuUyQV3r66b7amKDS101KCE5fIT-TluY',
                width: 963,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/jIgvfVZWwLOC8ToZYO1cow/_j5ZIgJCGBkNf2KmczVZe2RmgKmTVl9iMv8PquPLvo0OqT3QGPM9WcN4Rdmm5WeMBVhh-Aat9aFg0F_aWGFqRg/BEZf6IpOghdUFi8y2u9CvxAAjlrwe33TtP5G7vBzJfg',
                width: 68,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recjp7MsS34wsRQns/fldI49uIeYXTkPPfU/attCU26qKXbGqqTiq',
            width: 2032,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'hyundai.svg',
        height: 0,
        id: 'attEE1Ldv55uLK3lY',
        size: 3568,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/r7AwZZPq7-KEU7HtVMb7hA/JTRDX8rCNT8rz91Avqz4gIv_VG_PWc1oayLg0kLrG2ZTWUIS6VlvRKPN_f-9KlbUd3Zz-ZRdm4NEFrtkU1xYHyw_ND5GBj8q8dmzqFeiH-k/nurHUkuZiavzJE01vQgY25EJbCqMPWoiwjcqu0VYUh0',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/oeEZt0rmgD_KuMb248XTIw/WPz6-L25oqhd-ChkDhFIn7jSIAzL052XR7tEgW__UCF7S0IBTKVu-AJnMIuwmAwdzFwxOJd_PGDlqsLakxBPymmKsViOLRHS-1NEOvpHohU/bCYJ35CGA-mAf2VM1obeDW3M3ynd6S3d-IawLKpLIB0',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/CmxTD_Xa5RV1RlAM17NVPQ/mGshcHzIMcee8oj2_hhCpQG9GFeDN-B1koeki5xXLxh3N2cqXHMmM9ErxVbtzKrRJF61qbOTDfUyguN295Ec-bRNUO4YFc4UvogN3uvptsA/FrPDR8nKL3ky70FdBeWB-nHp2OSOgy6Kge3hurvqTYQ',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/reczVgq3r4zXusvkS/fldn1PBkvAK6H68vG/attEE1Ldv55uLK3lY',
        width: 0,
      },
      id: 'Hyundai',
      models: [
        {
          available: true,
          id: 'Tucson',
          picture: {
            filename:
              '3B1FC060DDE9DD47537E4A6BB8D1C19F59F5260DA714836711AB4B9047E42D1F.png',
            height: 540,
            id: 'attKtGiwojvKB3ddd',
            size: 243090,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/1ybJMsQQMhpcyVrnGM3p1Q/SWTYPNbapnUcnjfNzRk07d27qqzesutayTZO9VzrraSTI6Ebtt0GUJESAreWnDCaF1g2cZquHcEKTMzHBsOTLA/VAm2eTuyFtmkzX5YIHe1dKrDqESVaqbKIvQwav80TKQ',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/qAtg_lcNPIgHbCMyEOcCKg/qHe4R9r9iCJO2vxLLkQf_hwoImLUOmH1YCD48_JBjbY7TSWjsob4hi5BiQZXwGe6eh9i5V2X9tJH97y7FVUgKQ/oZ1NpcfNQaIko8rajoRMvM2FVr3POuSruHVW1uj4AO4',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/_eJLIDZ8uCTWUFgYUqO8kQ/N2-l6r33U1so5RncdOZFdlduU4rcKCqypLMi3vyQ-BacjNUVwopG5oTrHnADSh_6HSV3FXCaKqmL_38YOsb7ZA/Ia07lxz1fd5OQ-UWfwrkMIzLa35WfT7GF7_0cpWgDCs',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rect9EHYZynEk0fo7/fldI49uIeYXTkPPfU/attKtGiwojvKB3ddd',
            width: 960,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'jeep.svg',
        height: 0,
        id: 'attCKwTNfmW3NuKs2',
        size: 1805,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/nIFuTorPohb6kqEjm0J7Yg/eboDW4mg5XZtiIXE1-grGGKvZywNo541yhzqAek6nPgPYt_FCTVLVAZ7ArlomU1euxOSXX4CBTh3eycgnJdlx6udUQr2AZAlH4bl8uUnLdQ/r1jpEHiPNcwivXNkAQxTklQAUm32Z4q4pJh3Gt6Ypgo',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/cHSei0MRLbnrtV-FQTmMFg/zx-lWQcFVJ-OPrgH5yBg7YY1Hk8tm_riZV9NpT5frwBx1AtN-xzNiPMfqEo9oDDsm5RzL2gS1pjWqi1slebrxK6YEE64rD-H_iqqPylvfHc/3REPNcUDd7NCBO4lApDNdd8cqq5XkbZ7NzueaV5k16c',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/lOCEEQ0D3FoU3mjqlrf-gg/y_iW0jF8fE2GZlvdpV5QlgLXoR_sBitPClpzWl7y4T97nC74VxpW6KTSwNveOXXdZHXumL5cF4g85AGWcx7Mv3YlrfNUW4L_NcBSQhdMx8E/XCedCU_diiROsD3GCIAnpFCv4kRiblchamz6GRfeoB8',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec7ikHLJyHYGHUQ9/fldn1PBkvAK6H68vG/attCKwTNfmW3NuKs2',
        width: 0,
      },
      id: 'Jeep',
      models: [
        {
          available: true,
          id: 'Compass',
          picture: {
            filename: 'jeep-compass-3004-alpinewhite_picture_front.png',
            height: 670,
            id: 'attsP6b3yUn148jEy',
            size: 292407,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/lhD_MKg-4GP7Y8_QFTE7KA/guSBLMoBW9cTnfojsIU7bO29eWgVcML_EnXVkM4YKij_h8bXDemOlglwsE854NQGMgP-833xqcuWVf1RonEP1w/6LIsIsqV-2UJDQ1iQUdC6nCoFRmTPARc-yunlKWpy1s',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/NpRK7-2EARkgKLT6F_mahA/8qOxew2353Yq_DFBkncg3K4TWcSLmJ6W3tCYB2LKqj79DwPf5pa7TMS95wP3t2t-5S_XYw4YRB8J6NjDEyyIGw/4zoIsreO8j3RzixEx50fcpeHqK5R3_60E9xkS-F7gMg',
                width: 841,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/MOUybnyoeHhf7iboQo0pQw/fWIJk-MZTesYzHuv7AXnXWIwOLZOhm_7GnR8GYPjKimGKQzivfrlvqiTLeNTo895i0sVXgzZ2_BuelHJaDzH2Q/x4fjZbOzGuXV1tKFF11FNMphyvzwxrNKndZ_ilQjRH0',
                width: 59,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec50R0hHzEdgQelc/fldI49uIeYXTkPPfU/attsP6b3yUn148jEy',
            width: 1100,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'kia.svg',
        height: 0,
        id: 'attyQxs12o7FBc8Kk',
        size: 961,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/2pFqMevG4fFKQE2aJkNWsQ/cK74ugj-O-u2O6kYKdEfOxywBS6qPDPdbgYGLc6mYEJkoIjls1u9kOypJ7qWLqqiUfAJKR66MaxIb2vlEzx3tHuS0G-dFOkTD1X1as-2TnI/ntLojUiHSLrZrPBiIqaqZnu16IA603eSOBT-onJR0No',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/vnvVFglQEurVKvd2BiGMQg/6Vc5d4h-HfEcY3MEYbIVgXT0aG78wzSGuZ9syHscBUKZluOilJVwUocVMLFppRanAtVKmId_DTuWNha82ka0rbCNJLTZyx4OYsUuH0zQfTE/YxxhFJ3gZQVPz7erEWgr7kMOzJXyUiqe2rk0DoWIw3U',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/dBeB0dvGunGy85d6uFyXOQ/dU9atkWq_OMqGDFjnqtWTW_O8GIMbaCh2Hb2xMI4GU8Y54FH0_WdUb9fcjpecT0nin-k-yv3jT3lNv08vgWPOtu6UlWKLGP-CqoNgXNjtrY/OaTdi3-qBRkqBOojTxpcXgD2-KLc-rvBAsmLYyPDdqQ',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recGxYzqOONAwTmb7/fldn1PBkvAK6H68vG/attyQxs12o7FBc8Kk',
        width: 0,
      },
      id: 'Kia',
      models: [
        {
          available: true,
          id: 'Ceed',
          picture: {
            filename: 'kia-ceed-2643-lunarsilber_picture_front.png',
            height: 1080,
            id: 'att6l2seffPFwFsdH',
            size: 491671,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/qJ4r6GN43OH4p2blMPoJpQ/PUs_8k_kBMr2RCbjRO_LKxQ6GIygpP2GZlL3JUcW2pQhD4MXkdZkj6wlBLHVD2268IR0IYi1L7ujMivbmMT22g/JdRPEjTtd37dQ-Vw328wXvkw4TWaHc0PX-GIkMrjijY',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/tde2K4kkDym5J3J_MfC0sw/dIqXYFUsadq40SoC_uZ0zsGNqnNzChfLS6sMFVjpUwes5w0jamo9ZiV6uo4_M6ZoIKqTMjnqFLHNC8Ce1a3keg/CIVcOkK39q_SG1CbqYk2_XL18uWVXLNaYaPy05qUv9I',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/0bZZheAjTuGKvvkHXkaPwQ/XIRoDnXzH1BggyOHq9dTCI72fKi_RZS9SUw0GJLO3swPJeT6HE62_bCYflBFiEY9PpqhrW9MrVUY7_PilL3JrA/DcK4lecX6oB9eaKaY6yya1L91hA7ouAio_RIMtpHZsg',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recQIlICL1w7C8EPR/fldI49uIeYXTkPPfU/att6l2seffPFwFsdH',
            width: 1920,
          },
        },
        {
          available: true,
          id: 'Niro',
          picture: {
            filename: 'kia-niro-3364-snowwhitepearl_picture_front.png',
            height: 1080,
            id: 'attdIOfqngCM3GNit',
            size: 501562,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/eo0xDylf5HLEjYshOT_fgg/vo3hRWErbbOiecA70R2G0v-jM4CtnCTm0KXYnH9wDhriztfB8R_JRJ3towubjB193kzRSLjRuW_Q_Zlo5ZX4LA/KRlmQ_jiBzz53qrQH2wMGOgz4fMAxFalpu89yUTqFAU',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/u_TJ0BK5zEMPYVgcJejY-g/nt-lEwseVyMKh9gGiph4XKJWF2Y3241LRxl2UCgRyN8jmDbFQU-9Dd2fpJofOtL4RrBIHbOEdIJFUo6KKWvIlw/QLDrYjIu33BYaFM_GcrbPpx-RoszHjdeGe7nvhGiKDU',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/5d2DWc2PyuaNEeQa-dxjoA/sueHjdwO_lX7lcaXM3PYEYaMhheyhue-Aa56REslxuXIR3mCl_dXjj1qSU5tHYRifUnkBq_z_VoiibwXKAWGIA/N84t-FHQ1xBACqgpf4hguaq1rGelg8EXzl-uO_14IhY',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/reczuygIp4AMsAHfv/fldI49uIeYXTkPPfU/attdIOfqngCM3GNit',
            width: 1920,
          },
        },
        {
          available: true,
          id: 'Picanto',
          picture: {
            filename: 'kia-picanto-2633-astrograu_picture_front.png',
            height: 1080,
            id: 'attlCtqO2io1Nnnk5',
            size: 422228,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/VHRSEsnDHr4wBaWHDbGh6g/9LomJ30ddwRhtjMc4Kqw5MdV2sEI6HiWR7eeWz6EVok5T8PzNJMs8beo-nIX5hBSRWigVpRkHfwEQBr1goJD_Q/8ZGOx9SUqDQoxL1Ow0qCjRCCrXalMvXEWYXRhW6wrdU',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/GYwCUgWjKLCDdDsN4HWkIQ/2YmpGKWtCRgDNXwB3PUwMmTTpeFqpCzMNzRwTECdA4Qfqm6qwBI6jPelLH1q55h0G6WaYo-EyTVNL97nUdNTFg/_J492Pz5QWJdYc_vsa-4TcwrvwcTjKYWD92266t6_bI',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/MZ2-PZCtF3DdI-mwPsLiHA/j9tQC4pLkelMF2RTAbwyfzx8iUWsynKpEkQSRCil2mcC26lyzE2VIKxe7YOWoVAJVC9wrMiv58BiqvdN68YPbw/fnGEKFKqeeVMaSxzmeAD4dkm3QKyFYHMJZ027ZYiXTE',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recPsw2hCurJiP1Db/fldI49uIeYXTkPPfU/attlCtqO2io1Nnnk5',
            width: 1920,
          },
        },
        {
          available: true,
          id: 'Rio',
          picture: {
            filename: 'kia-rio-2637-schneeweiss_picture_front.png',
            height: 1080,
            id: 'attJdM2M8GXX3uIwd',
            size: 373963,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/kGuc21lfiCnSZfwIacPnIA/NnyaA15mLX1Amy7xev3MoGZ3-A8fbDipR1EPAO8GwLBR1RHOsEwjYhzNROSAGcyRUfG3iIgezu-YyXpVrCir_A/qRjFrN-JDFgq--4xi6CN2sxajqINcSXhSD1ngTClqyw',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/QFcv8aEPdmOmArSKFBZ7FQ/GQJahJM8cnvukHtCNLmEA6nVivipufLPdNLTgQeu02nNMirJkk9Y07ymTa93tap4ckT8JBf8QzPKmjSoVvNlRg/wbkBLU8XL7sIzGsGTgU1iaholX10vS4j2uK_B5ivGb8',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/5lFA9AAE2UECEuGaVZu8_w/R031HJ4Y1Vttqds1vd1lO7HtediY4ffGyfQ-qOzvvllaW8U7-A7pknn5_o5CtkKDh0vy26RgiwtKqkEMrTjdrQ/2r_VwwygWwa06akyjzh8jgsy4QCTJqX21DYEkocGv5M',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recfv8ZX8ByTCyAVn/fldI49uIeYXTkPPfU/attJdM2M8GXX3uIwd',
            width: 1920,
          },
        },
        {
          available: true,
          id: 'XCeed',
          picture: {
            filename: 'kia-xceed-2646-lunarsilber_picture_front.png',
            height: 1080,
            id: 'att0vNY2IzOtdPeKw',
            size: 494961,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/HoJQe26CEwx9cGvJY9fQWQ/1Oq09208R_q2MMrutta48akgEjuxSft5sK-IjEVzP13amQDNwg53KLt-bhC-n23ZUZRzQWpOmrhzx2CGHEYUIQ/TKJm307RcWhvX-6EopUfV7zhIMY9l6wYTszZ4eCj11g',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/x5w7oquI73aMruh4PPe5zQ/9KvaijS1gd_7G-mAWPlr2H1ay0dsNUmiG2Wcy2QASSNT_9CN0Mg-hw2EETHd0wTtgLm1n02gCLgvIgjPySkKAQ/sjw8Ojsc1NTVjqCxRCqE14feI9nawBDDj99HBYqyKcA',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/bkugC5ARbLgly6rx3fqJQA/sRpkDUaoZMXYphcGk5SDeGa6m-AqwYgLVLdTZGY-DgT0yNeuBJeO_JXfjXqada7vJ_tYP9xt9vkCYOAr_djpLg/1dQ6W3u3XnYTcH0y0V2cN1v5B73-FaWG-NjHzJm41z0',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/receRMHlUkyI3nvVu/fldI49uIeYXTkPPfU/att0vNY2IzOtdPeKw',
            width: 1920,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'landrover.svg',
        height: 0,
        id: 'att8jONhBoJQ2NmKx',
        size: 3929,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/29l-uU9CNOcxAAfUlNlxLQ/03EXqmUJwbqUPUn3R9ITWOg-3ty9-N08mvjzCegpFO7VsK6-bU6umZYbgDrmrCUN6ATTyy81daPkg40WOEpiIjtBUEN6NRNx99sKVe1w84U/FqQdBCHSTGGf_p2ufVq9edOIfT9FaI_SDO41ke3qbiA',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/LczqlgfvS4o9FNZk_Ohdbg/spYdp77e1GuFQN5BjpgRAapQA6ec2lfNvrWX8SR0s2pDieeNANksDsDRaIBIZpH641wspxJe6vEl8JeEp-dVHWgvvowfQ1TYwrpsEYAshwA/8Zwxsl0khuWWqS9GIR3MYnd70LnFopgexyQtlFAvyQg',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/hTubYu3kHhvGjIjyuEXZ-w/PRQEKzcI4X5XqPY-LgS_KCqpH9e8or6CxGAxwLfcJppkTdbS94K1khxqjvVh1JpicL_2BpNL7KgKbL9-x_V7zxnO843CvE5GnM4J6eZoVUo/3mbw8Z95kD-NHquxDV3XMEQKfzTJmYWWN9z5-gwhN44',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recuGw3SgRcreGPTK/fldn1PBkvAK6H68vG/att8jONhBoJQ2NmKx',
        width: 0,
      },
      id: 'Land Rover',
      models: [
        {
          available: true,
          id: 'Defender 110',
          picture: {
            filename:
              'landrover-defender110-3322-santoriniblack_picture_front.png',
            height: 1633,
            id: 'attIY3dfoNxXWcSKb',
            size: 1604196,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/J9dfEFenAu14gZyJA9_I0Q/Yl7SBI51QBqwbc3_S30qm60_nZEGngJf7KD21SKtBD3HPT4mD_FwE5zzPQNEof6gGFKZ16rCj__3RhQn1URPew/rK6lcNQtRrnEBtD9TesjLXEZMIUOYysI_VAX05volYk',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/StuxyKKxLPEqMAjWS9--ug/PCRzQu334GyRHHtAoMeyoJ-HZK3G_ry0rsrCR11h7LCruRpOHSa02XphuCUbwD7HeZRGYjPRz6l53MoXH9Lh5w/Ro-E26XH8Fmh7EgzFaSnFlAaCHjrZjbGkclSucmlDLE',
                width: 784,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/PWXTzlA11ndV-atEmMHz0g/rUllWH0GpLqjw19m3fhOPICZuJkAghMLrDMV-ZKttUMSfPbM8WpiZJQQ9SThQHr-0TswkJyekeL8bc-kh2hN9w/cM3hoGNxrxtVX2JDRXvDDtvFCeq8lBrcCtrY30x_VuU',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recy8NIFsMvUa1yuV/fldI49uIeYXTkPPfU/attIY3dfoNxXWcSKb',
            width: 2500,
          },
        },
        {
          available: true,
          id: 'Discovery',
          picture: {
            filename:
              'landrover-discovery-3324-santoriniblack_picture_front.png',
            height: 1633,
            id: 'attsDkiaO94LT9BbN',
            size: 1528079,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/YUMkzbIQeQXBvIW6DKIW-g/W2KYTMMNWpAB9vV8veZPr2nn30yiLz_qEwwEuUChchX7exAi7g_RaGMGse8h71v80kfeQOvYSWimTCVqf3fvxA/i4kSnMHMxgxu9cfH4bZ2W0qs8xn2DxrTE2XllDpKF_4',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/RVkSDH5s7w16NSf1lxdqdQ/dsO6b0gpyEnbtjLTfLpjNrzFu4AI5WZbgF-LdJgtc6B0Dz4sBj105gvUyO-Q-cDNVopxGnQ8MYYwDzm1CIpgDg/_XSSukVo7GEtH_Eq8AoT4vHAR-ZXd83rE6bnruI7GJ4',
                width: 784,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/RJoU1xsUrWeUGmnxeKqmgQ/T37kTNeYZA8ywvPrtSO5YswIdDujt9WW-dnXnc_fd5VfESCCs2yKFIvvy1AYhr62z7GEjtOcEIc5nGJGOGQj2A/k0nwj42Zo0sX7FNzi1ptUMxjMDtzpFjFZWflj8xTa-Q',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recclLuWRST9Ij5gP/fldI49uIeYXTkPPfU/attsDkiaO94LT9BbN',
            width: 2500,
          },
        },
        {
          available: true,
          id: 'Discovery Sport',
          picture: {
            filename:
              'landrover-discoverysport-3325-fujiwhite_picture_front.png',
            height: 1633,
            id: 'attgE84soSD8Plw5k',
            size: 1339697,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/6G82woJkAznOOIG7HtQ2yA/-Eauax_bswRIkowrQ43R1dTKcIj2AruAJDOt_7o0oGohf3vPmR2vEkmgd6BpEhAonIubR5ot7g5R8EHydhElMw/WLv9TDv1T6-GhjWmIRlG4sTYsuTpgcnBqp0WnAVDKW4',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/lb5yghYiw3X_4Mhd7cF7Ig/hfWdQ8LBG8VVE2OpkZvDTASbYemvU5Q1oANdtEBf86W3rwi1BRNnjeUCKMINtc4DRi1toMjVcihPwesEUt-ieQ/xiNEjqlNb1te3r-kWQSDDtzEd-F5BSY6MdfuvxVoiKk',
                width: 784,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/Qu6H_SLGtUsc_M1xXsiA8g/qwrqL6dZTzn8WlPtmogL1hVNnT6mqycjkgFKPA1E3gW4va6vt8hnatEm0Foxsl8lOBfjAChvP4wBoDI_YJzxkg/zkRaIBdG1xX0OyBZeslZEItx0mFr9584GyDPz3cusDU',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recpuU5EA36hJwuyW/fldI49uIeYXTkPPfU/attgE84soSD8Plw5k',
            width: 2500,
          },
        },
        {
          available: true,
          id: 'Range Rover Evoque',
          picture: {
            filename:
              'landrover-rangeroverevoque-2671-fujiwhite_picture_front.png',
            height: 1334,
            id: 'attaTO0kJYmyiE24f',
            size: 459826,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/sYdgDhOXnICpGukop4MjCg/5FSLGR8Ao71kSUmxwJ6lFGZqhe0ic8OA1XN27--TdBH8C1t-pHqXZLXdyLbR8JGmmvHKd3hOKL7HFjZp_6xJNw/K-HoDjy6PRMi5UN6BH3xKp5Zm_rYAIikWidjVCoVwo4',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/RYvdwtVP2vZWgt-wki3pSg/CXvTSqezROPSHaYcQFBs4gsNf8-k9twnVUkEcHIY-TVcaPskpQpiVhC0McJzuxJnSYxNorPX68N2X4WgSjqFNg/QKrPso0CdGu3qRrdO4XfGbt72qfcFjm46tLA4TxdCbg',
                width: 819,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/UBoPwzWeG1mkJrJu3Vhb5Q/KIxhkYV4E4FS8hUjkW6HztnmoscXChRDFuckHG4Ht_6YSUsNTT9U-EhSLmUSVr7EgiaHrvzA4BpkqOQ4t0UsEw/fJ97z8Y5j4bmBMv4PnftNF3XpOo_wilJgrmreEvvVXI',
                width: 58,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec5fp9ClC3oywSDP/fldI49uIeYXTkPPfU/attaTO0kJYmyiE24f',
            width: 2134,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'MG.svg',
        height: 0,
        id: 'attOBcKmCPQQvFRxC',
        size: 560,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/BTemz2DF3ujtL6XlWtKVdA/Y0Sl1qOy6j4cCbg6K_bW76ABiFrYyQ7dgEXPqmTKkMPO5kr7fqxKuhATzfy7B_-5RDbdcQVnOA5ubu0V95iVY6yAWDcKwGcS9hmDrswaqOg/FRGqaUFt1bX7Eu8RxNKqw-A9OvdrG7pk1djRVD0yNNQ',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/9HClGMEc3x1jjpdAilBX3A/PuWj0ElS_sQYhiGorwubZLHiQ0P-DMsRJP08iPKDP9xKCO1zkudNN4V4YJcHFfo4gwgSZKwJ0otL38h9-5XxnOqwKqBPrF_IMDghgGlityA/zTa5RjEJlVw2jmig133FEhwmPj19_fPKkRjaxKXtyVU',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/VggL78ZdWdx_fFeP22Ykzw/AOOzJKfcdDetiRrLb0zzB7f4cgLp4AhVR5ZPA49bvwEyUMeh3JtWeOu6R-DIZgYhILTsdu2UmIi6FU7eLRdX9EAb9xu7V1-yaRopr1ThUFU/0r18oKDXvGLW_WlwU-Jf6bcPP0QnckOPfTUqHHak_Bw',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recvHO1po7Rh5Wfkp/fldn1PBkvAK6H68vG/attOBcKmCPQQvFRxC',
        width: 0,
      },
      id: 'MG',
      models: [
        {
          available: true,
          id: '4',
          picture: {
            filename: 'mg-4-3371-fizzyorange_picture_front.png',
            height: 754,
            id: 'attGPC8KmjevyGkkZ',
            size: 524776,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/GsVT1hq9Mb5kOlQuWcnS-g/QFQK9Jyfd59_NxUwy8v4ob0kX1LUEkaGGPaEUGCi3eZEHPPFneSyiYG9aisoKb9WNfF9jMrbF2iqBss-9cBf9Q/nj5pyi9ZhEAAPb3b8RMvhK5xUOZP4mlVD4-_awa-z-o',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/A0XQetRgHDIfqASe6MFM3w/0MZeoi4f2DezbRbNsyxzmAY7wpb0tp6SNNiV5PsBuygol2ZIywWt1KiTWRMz92Ujp1Op3a3u-BdVg2pM1oPvrg/iRh96oarV6ANWO_J8pN_wmoFceW7IS1uQwh825rGHr8',
                width: 917,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/iwwvV38tXqu2KpjOT9x6hw/X9VJTTqiKkPPwYTFuzuX1PnxdImE6wtcSSKCX7ldqC_n7zPJmOtl8bj_D759zfZV9hQiWJxp-xnSs_F-EzRkgw/deIRI2aAK4GGUKU7ddsdoH25yoztjkWH_CP2Q4vMSRw',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recLqzr18yQKRRkOo/fldI49uIeYXTkPPfU/attGPC8KmjevyGkkZ',
            width: 1350,
          },
        },
        {
          available: true,
          id: 'Marvel R',
          picture: {
            filename: 'cumulus-white.png',
            height: 996,
            id: 'attC6Ujfa7qpleZmb',
            size: 533437,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/4N7PcljO5gwAq85TM8PBBw/AjgVXjwi18SrFoBao72bu-i8aLzWXl5QRAhQKwV7EeZ7nZIUSwwByzxMraY6I5tx-N2eba7ir98hrveCERAvlg/QWq_NGQwWuCsQQJvZbgFrLbQW5qsVu_qZP5EtN6t4Ss',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/k-xjenotvJrrYG8j-hAFbA/ynEZ2wrUN6TcA3nBxFtjjmbnY5yOkKp_WNoW-WMTzdvZIVFPxO4t_hyO7vhJ1Q-2idWGmAUKOXFMzkS4tfHn8w/prmi8OqZGkuIrO4RJA85Crrd2JpWf8_KChRtC2wOd0w',
                width: 694,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/bhqxQkiggCc5agLOhYj-Qw/2nev1W_6H0IE3CsskQuYkPM115yoHWo5MuTc7HRz0j4MAo7GI3vQFNqvGFEIATOhyNCvNyznkUGA2CFJQ49Fww/43C4jyTaGLSuCFDosgVoGIQjSPnp1LAu9TU7VCeqv0E',
                width: 49,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recEc952ohiXyLmYz/fldI49uIeYXTkPPfU/attC6Ujfa7qpleZmb',
            width: 1350,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'mini.svg',
        size: 1049,
        type: 'image/svg+xml',
        url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/ua_main_restapi_de/brands/logo/gQNGDt.svg',
      },
      id: 'MINI',
      models: [
        {
          available: true,
          id: '3 Türer',
          picture: {
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tbl45r0jXPu5CCd5b/recGoBJHdVl0BY5vj/fldAKDVZyFoN1SI9w/attzNyUSFQbwerLjR',
          },
        },
        {
          available: true,
          id: '5 Türer',
          picture: {
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tbl45r0jXPu5CCd5b/recvzdPL1Ua6XuZcT/fldAKDVZyFoN1SI9w/attY4Esor6UsO4dep',
          },
        },
        {
          available: true,
          id: 'Cabrio',
          picture: {
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tbl45r0jXPu5CCd5b/rec5QnpPen4Qsid57/fldAKDVZyFoN1SI9w/attDhhDsKkDLxH50R',
          },
        },
        {
          available: true,
          id: 'Clubman',
          picture: {
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tbl45r0jXPu5CCd5b/recKfgNZdU6RurYAN/fldAKDVZyFoN1SI9w/attvKG6RVrO3dSoJK',
          },
        },
        {
          available: true,
          id: 'Countryman',
          picture: {
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tbl45r0jXPu5CCd5b/recBVM3lTFCnTpJcz/fldAKDVZyFoN1SI9w/attpWrq0XGeRAcpsn',
          },
        },
        {
          available: true,
          id: 'Electric',
          picture: {
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tbl45r0jXPu5CCd5b/recFzTa27ev8xGX7A/fldAKDVZyFoN1SI9w/attF6oNYffBxhw2Ik',
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'nissan.svg',
        height: 0,
        id: 'attE00dwDo7fdbESg',
        size: 1793,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/Uj57xqg5yfUEsQDYOHWK5Q/pyprGRDEfj5grEBZWJUf2TZgwCfepbHYnN7sroSUgzf7JUUVePY6jGzM8jdRxpdB8rxsdQx-nnpqKmvqEkDQQDsZsm2wKKVZ2gn7JH0rsTA/r_Fp_0BM5iHVofPa1RCePRUng7rV1fSn8LfjCg-ZLjM',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/TfgUYoM7Az1zFiyhFqiPjg/7sUTjwW4i4zlbY_ivBb3KrorzkkyxgOF67L9PJD5bpnUXEfXfqznb6Raou_VH6AV2srkCiAnDqcPqnz8T7x1vn74LaqONoOcX53S-FRRDYg/c2LC3nlMNphogaOiYFV6gbyPKE2LvWRmO7aocVPVSa8',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/IHmwE-vg2wKWgbcyA4orwg/0ttwztZc_HpgBLBOcpSzzHEC8pm7AvG9aVPTTgT9SLitwgQgTE72k5DZHgc8LHZyUWpYB-r1d7L_L_MPY6FsAkSveEXVgdSHYCDvOJyB0S8/ckrscFy9KB0I0mQr6V2-K9Qe97A5scloMXquHxrHS8w',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recdnzB4HM68VKjwI/fldn1PBkvAK6H68vG/attE00dwDo7fdbESg',
        width: 0,
      },
      id: 'Nissan',
      models: [
        {
          available: true,
          id: 'Juke',
          picture: {
            filename:
              'nissan-juke-3343-fujisunsetredcontrastblack_picture_front.png',
            height: 900,
            id: 'attvTaXqS4x2LPI5A',
            size: 601194,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/WzK29YjjSjKcAzVxuNY-nQ/wkwGpP7909Tb_HVKzr6IcnRf1IPbdGrABjbv6iDS29gu8DvKx1oBfWpTUvS09BreOI2-VSggGM01okPCDNT7Sw/cEEMYjDSs5RtE8RHYhkgntOrareLP8Jo7gr_12QkPuQ',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/l4_Ho1F8XUXn8aRgsKej5g/PqmpS6-EjxO_gahzP_qw3Jr0twltmaGCMh2RN-HJfbtYNf2_SklCf1a8G0nNyQzHyC-bibIERgkP9acju5qhlw/9QYzbH2Sn2Y7-4uv2fvlaAEUUhirbGB0PuGWxZ6-Uvo',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/AOzTaDJdxUnz7XDXDBUqug/_qUGFhhdeUYWzILvZxCtH-psZWkViYWOipZ8OHFHcmzmZkhWLIAeiYAbac2dTkFCMU2r1BA7fxVHbQ3dl9wugw/ySspzPS-dff3oMoIbHgzLd6U_fuGtr1RlTIEM98JJgM',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recljyZ900B7GjCM8/fldI49uIeYXTkPPfU/attvTaXqS4x2LPI5A',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'Leaf',
          picture: {
            filename:
              'nissan-leaf-2821-pearlwhitecontrastblack_picture_front.png',
            height: 900,
            id: 'attYrRNgoA9ziUWM8',
            size: 493279,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/bgmMoVmAubqBEeOxmOw7MA/jAztFtqQzELlOiDqi_uCulAJaTp5GQwBQvj6Se2nJV9eTyeVnInY4w3a6JyULtUG2v1xbLvysFZVAOJL8mkXWA/KbLnJMdyabf4tfqafRYyL4DxxSjd3jW-OMctZkITPxk',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/M0lsR8LPncAyn8_qIxYl5A/lOQaeTcopj_M60E1Jx7Ky-24QZtMOr8GB9iI12kBLfDJ1hVZxXCWmhJf6eb16ozONBUgOp39QParlLSpSThU0Q/GSI94EbWIU7ry0Y_MbS06ldskipMLRID3CrYEgzZ2u0',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/JMnu6n7-advtkvpp8yTs4w/YfsVIEGVvFKyJzYfBaDeKuxuC8sYwFJ-Ge2yZn0daPuSPMi_vo4FdksbOVcCFAPn_N1F-GTijLQpxO75hcyrxA/PiTW6gAUDlhvbt7ZPLIAlxYJpFff4PzZlRzfiiPG4Nc',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recfBSFkbpAfcpXbP/fldI49uIeYXTkPPfU/attYrRNgoA9ziUWM8',
            width: 1600,
          },
        },
        {
          available: true,
          id: 'Primastar',
          picture: {
            filename: '1.png',
            height: 1080,
            id: 'attkboX0Ojoeq4R6T',
            size: 446901,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/s7w3k7AM01WCxvR5qgllTw/Hu1E3I_NI_zfCzDayFfmdOTMzd88v-WnYfWslQNkkFa_O5YZx9ZXLubKCPTeBnyns3HLXOlDJ0koJc1tK_LPIg/zZGvgrqNtZ_UUtu4-74b8rxeFuh1F7FQfedzpOh1Re8',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/seOYf7fk77KlxyZ-M2UJHQ/_etCfHAissEbSHrIriZgv34MLVlXToNF0DsLOJ2GsYB0etZmh1wOQUHb9x4xrUVol6ajITuN7AyANrQze153lg/yVuSV_xGP5ESUgSvN8lIwN9azxFC8ET3nWG2ybcJPnU',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/iQMxOvLdcV1QHVeZ4l-Dug/3HuvRSLStnNBgBnv1y4I5ePlus4ZN4n7ACGZFWSqmpWhV5zEudsaA85irA2INL00piGy_5tl73g9RMfMYhxsrA/ia_ibLA2ZCM0Wqim1x8Dtojy5joHv2A2QBBKKLh4fzo',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recp6zS1Ve5x5I9dn/fldI49uIeYXTkPPfU/attkboX0Ojoeq4R6T',
            width: 1920,
          },
        },
        {
          available: true,
          id: 'Qashqai',
          picture: {
            filename:
              'nissan-qashqai-3353-blackcontrastdarkgrey_picture_front.png',
            height: 0,
            id: 'attuUMOJouDyxGOnJ',
            size: 419410,
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recSO5CyjxaW2n6y3/fldI49uIeYXTkPPfU/attuUMOJouDyxGOnJ',
            width: 0,
          },
        },
        {
          available: true,
          id: 'Townstar',
          picture: {
            filename: 'black_front-removebg.png',
            height: 738,
            id: 'att1HWmqHh9nk9lZY',
            size: 444710,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/jULAdVI1I4Qd-3ODsyCTOQ/E4P7zd3vycjjOs10M_1jAjs-mTpE8N0v7KkjEtxDnwgjkGiwYOOn-UgRDY5cY06bjrAhs3gaXj8HxFwGqGhstA/Pry01lB1Ijr512D20CKkEM8SoY7mbrIEs8f4f4_VZ3A',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/ndlxnplqYLUO7rgdaUMpcw/GSNkcceigYpO0hDOWjY3HfhMdfzEPNt7YOJu3LOTn2to3J7wPHVhu0ILRrs28p09La3kjccAh1ndTxhUicjCnQ/fbNzYlgwTS-RjJK8cnV3itKoCKSMPPKit-cHGzVwcI4',
                width: 799,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/jaZf5MVwVtNId7UjN_pQpA/-OW08W_gUCgESBh7coZgfxl3uAQNLOMZfgc_UqOPL-AT96_CoJiO0UYCzgXjA6G_yzSXEWhGjHixioneSrJGhw/H735WeCs_lkVth7rY7UoVGMHrS-4zrCM4Eg21_GBrzY',
                width: 56,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recYljEoqf8Uze17U/fldI49uIeYXTkPPfU/att1HWmqHh9nk9lZY',
            width: 1152,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'opel.svg',
        height: 0,
        id: 'attvwAamvNmey7ofX',
        size: 982,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/y3SY15n5r-o68BciqqIfFw/oGLFSuR249Pc3WfsI3tKpxcOWwHdaan6CVBU4qVxjT-ikfJ5wpw67eROBE98lQwI4QQPd7hliUjLJFRJtcuZawlZnCMjmaD0z_97Uk-afzY/ViDEiFwPSqZ_HnJBpowyNwCA8DL3dSXiO2GS6zkOhJA',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/f6nHYNGm3no3iRgVH2Hc0g/jJAA1mbmjsxTPke9opoj7suKTzTDZAUkArjgKw6n1aTKPH81Ryi7jLauC3stfglBFb06GDGzveaqK2ykmfw7DK_D-z45bRj9fUkGjlD3FZY/nVIwPXhvs_DW5blZLBBmPrHJbiqOyAhcxK8E8JCOWAE',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/spctIws0hDCOQTWi27wujw/tHcrU7IeLZ1yRjn6lbvUdKLSKdu0s63O3fv8DJDl5wGaHStTzfO8tb7ocmq9YWbBOEqpI2LIwBtrdpgwhSmi_0yDLL55V44UEZmZIfuD-08/INDmkLzgp3jOhhiS-0vOldyVUkQyH3yPTStWN_cfiq0',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recwZK8iyNvhqhTb1/fldn1PBkvAK6H68vG/attvwAamvNmey7ofX',
        width: 0,
      },
      id: 'Opel',
      models: [
        {
          available: true,
          id: 'Corsa',
          picture: {
            filename: 'opel-corsa-3211-kardiorot_picture_front.png',
            height: 650,
            id: 'attbPC1J9Y3XuTgI9',
            size: 302370,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/KNHHHyqBXkGaifWhnLRJKQ/S4pDsGiRvhYYNh7LwZSxegV-yopWGjSuVt1E6MZJ6eQtiF0UVPTjNaE6MdLsctc2R-hY-5xSWsy-bZJpoNOm3w/TiZDgbK0OIq_6LoR2oOHN0tBPuRe-7VwUziMpZpMm7I',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/OyiB-njjFwUhUU3Jraj1iw/c5Ytra0RhjEDOfjrhrUlqKy2NauuCq-XCnx9Ug4r0D34dvufDa7z_G_hnY4q67viDL26KeyWthWgKU9fna6YHA/Ix2nMD98Nq6vJA2rPwEWVzjSXGAEPywIxTCTVsZv2Fo',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/njY6IaMyVBeu3KPGJWONhQ/h8RxiGdv4yrth0ziLphpOnYiOXnA8_RImOTIkJlxowf_JAm4T-ZCij_WvQSixzmWk8mAk4bRHDyok3dTOMWbBw/OpdNSvlqJNCbxbi_NTVY1utDNaYDcqRbJQ9N73P2hQo',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recKaJK5gznSSuTPh/fldI49uIeYXTkPPfU/attbPC1J9Y3XuTgI9',
            width: 1000,
          },
        },
        {
          available: true,
          id: 'Crossland',
          picture: {
            filename: 'opel-crossland-2736-diamantschwarz_picture_front.png',
            height: 650,
            id: 'attVD3zKWPcHEWbux',
            size: 221137,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/lp5G1KJzirvvV9DG2QTKTQ/iGwi1igAnlX5Nv-SS-e3VCZWgM1sMHSkkF3pUXY9tfamLLeUt-eBwsZx0rA6GUnQLSHmLC1T2QKAsEVLz3iXxA/IOYTHgcEIHf43xmIJo73CD5A-wbrVP6AK_A46kmOYXQ',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/wSnxl9zERmBwCK2wh8_IFA/I-CdK4ffcnxmRW43XEu2Tu-5AH61mHcH-Wax_Q4lMCoVIdz9d-Crmd_AZeblk__WJsJ0HSMRAG1JQkx0DBbT_w/yDCpy04SELcCSB-2Qau36dfdu4_NdGrqEYSB5PDK92E',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/pxrIHAe0d5ED9YLmhKD7Hw/fQesqXG-hDqUgVvelbzQ_bHMhinACx3RqAxCyM9PfEiB1ojD-6pJz-hDrlrpdFCfVpzhCUcCOwb3_VCoF3v0WA/fGEVKQE9HDiexuyHV7xpN14h_CxbS7Q7D2Nc5ftj3no',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recKg3oxiP6ytaNOE/fldI49uIeYXTkPPfU/attVD3zKWPcHEWbux',
            width: 1000,
          },
        },
        {
          available: true,
          id: 'Insignia ST',
          picture: {
            filename: 'opel-insigniast-2745-argonsilber_picture_front.png',
            height: 624,
            id: 'attOXS5PMKKBKKfsK',
            size: 235669,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/i0heOJMk9ShRymKD8gbafw/Fp1Fvh_zMcrNacbWtlJW4jrQ6cU11WE-t6apKT35GtQXnJJbYgaZCezJj2_nvEVcyzJHUXSCBN2NV6aarokkyw/S6LeUqeJLtOXtzavG76pB-vDF92LP_Pnl5nHSlHEnHA',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/z3YpzpMQPrVxCAa4J0X2hw/fzAjxPUCsMpWvtOFPbKkzgeEvA-PzChHRh6rI2YdVJnO0aVclDq3e5DyEvTfxeh-gounr75Du8JwoWuOqopFlg/RYxngyw0wEyQgxqRMTjfjdh8Y1tKLiJpsfu0yMtc5TU',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/Xy6qgR_PIyp4ADFspYyOmQ/ALWkbjny4FbwsBSb5zylhpgNsjV-aaljtj-3onHetNIB8KVWXuM0PVETkocCWyfkohauRaWrJ3Jf31jTAw-1Cw/lC7dmfjbcjLZUsEWrDaWvdebP7kckmoCJwNQEAiYrRY',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recIDK5cPyWmTIjSa/fldI49uIeYXTkPPfU/attOXS5PMKKBKKfsK',
            width: 960,
          },
        },
        {
          available: true,
          id: 'Mokka e',
          picture: {
            filename: 'W1sJLqlgNiWqg9xt8gpPXFpd5FufYEuCDPblbFbgSkk',
            height: 650,
            id: 'att2nmBZJPIuDTOZk',
            size: 48562,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/XgCv2eDmXVY0_892KVxUqA/fGN0s_eT_ewqtcbpeFCvNpqZZBheNJhKczhjeFoehxoE3VYRroO4Gaf0T4nvIyx_ywqPABzDzIkilNij3C1_Xw/S53LnJtNP3SGgo7QAEuI0cPBCK2pBnzhv8SgIm1pOVE',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/3w46Z7XthvmV_TVL4g10Ng/D_XVRTYZ5QLK7OkGAOKKaA--91lALj8yRvlBMdCD9gRug3w3w-uETHE2JGYw1IGVBSqcTh5_yaSObRBDD7vd8g/Hf6Y2qm3eKjjsjXwjqc7-lbdAxuDZs5yWENgAlBGmE0',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/8nGRtC2GCrjG3pNHsXwuSA/ZLnVir256ELd8HG-EP3VT1AuZPiaA7u3e0sSp7rXlmusNQHthfb3BMhjR7amoqeZOMZWIPbTmRsN2Zr2xkZzEg/PJH3rgPaqoXd2Y21c5z3DQFWgfyGnVURMluiKk_p4mI',
                width: 55,
              },
            },
            type: 'image/jpeg',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recsuNYKKaVVjzqSX/fldI49uIeYXTkPPfU/att2nmBZJPIuDTOZk',
            width: 1000,
          },
        },
        {
          available: true,
          id: 'Grandland',
          picture: {
            filename: 'opel-grandland-2735-rubinrot_picture_front.png',
            height: 650,
            id: 'attsMZK0ddXmmFBMQ',
            size: 346295,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/3zXplELLMCQnUarmavsqcg/STrTdbFpQ3Dj7zLcsTl1vxor8ACXwEuQOmaDGX0k7YKifUAx4qqSOILZXqll4499ifTd1EyVM_u3_Lj61YjE4w/FV2-bOGUO811K_25mc3Grqef5OsOB_3qZQ9BUkhxg4A',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/6HXaxH_yEbreQQ5L28vOpw/1DcpcfW0_inlbIZS4B3KYHWFT3Uef4XT8wPs3q8RWsce8ve9c54wknQj2aKTjeyLyf_h58h5L0gsWufUmPsLjw/x6C8HvHrJ2-SOZpIrTzYC9siQKkSmnIBEhxvLprDd2g',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/FdcRUa8mhcWaYc6whQ7Zag/XYk6lntD-7eiM4AhkviMdQUC48YY4xu7c9_NMeS-OlYHhx8CWqIS7L4QsMZOSoVom8Lb2QsBwaBMXcRVFkJSEg/-2vlkICDtgE9b1WFQrvqrbSOWigx2kSFUaHz5nglFpM',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec2ePQNIpfAA2r4w/fldI49uIeYXTkPPfU/attsMZK0ddXmmFBMQ',
            width: 1000,
          },
        },
        {
          available: true,
          id: 'Insignia',
          picture: {
            filename: 'opel-insignia-2259-diamantschwarz_picture_front.png',
            height: 624,
            id: 'attoS2Y3LmomhAL76',
            size: 222956,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/pl2iQNmZz6HFgcVTI-82OQ/wCuqHkna-CHYxxRk9anOkDk0B4RNzg6MdKNG8S-Z7hjb5kHtHt5mielv49_Iohpbb8ZLVCZdLvVaFkj5aX022w/Fdw2I2vGSG4ps6VgAYFgR_I2SJg_n9cRzj7UzSz_wYQ',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/saPe9mvEIwpqVfnWrzh9Hg/PBjBSiaH-1jVWmcveztXtVYrmP_Sr5Lo-LUFabdfkR6LSLEA_UnWClORswxPiawjBPWbMg_0galKI1f-tjd4Hw/RmsMceGBcOcgoIeHU7Gc38ljQ5azarcS4vwKcUNaYyA',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/6LwRCrv7EUiNu43uSgQVmw/S5DaMq6I5oxNU-YTcPZ3jQ4CScYGGZO8d-Rw5QRYutz-Eu6tZHHTFdBWCjF3QSC91EHElu8j_U_Vlj5Ed0JvTQ/ODnK2c5ydQjxfQ3NnvYLvIEmJS-5cJGYHZ1VtY9x7Ss',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recZ09UooBpN7WU9B/fldI49uIeYXTkPPfU/attoS2Y3LmomhAL76',
            width: 960,
          },
        },
        {
          available: true,
          id: 'Mokka',
          picture: {
            filename: 'opel-mokka-3194-ikonegruen_picture_front.png',
            height: 650,
            id: 'att7zsOiOrAKI7aIM',
            size: 325639,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/V8tk_7gD5p4a8SBSnTS0rg/nN9OAJJcm_3PYCBBPdjsheD30fFeqyibXUxI92UYqyDw_3zgSIWVpOqtFtigjQ_mY_laTbzpy87aztRbz4h_RA/in52wkWU44LDbJLlfxw5HUwKmU5tdi2FLySDreSwX78',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/4EyjzkO2Dxrs1YwjTXsvTg/BtfMbz-ZML-FONI0IjImdNS1gdtu92Crfzho_wj1rM05jpobLXW2r9804PV1b6uLj-sT1cItEQFgLJXyTovKUQ/8CVCyuJVJhEZiDgOdskOA_kuNAOlhT2FZqPX2IMb9lA',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/tkhWWcA7jzTyScoHts6F1w/lBdnxg9_ezIWXepoxMtnZqWxrQYRi21knJ26qwWZr2nm6s23X6CXb91Lc2vyoZAcAZ9D-VdSXVy3Sy7RY2GKmA/QeCkdXWuX4NxOO7n2bgNU_QCti4LwCJbbpOjjvcNSxg',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recmJjNVXDMgkIzOh/fldI49uIeYXTkPPfU/att7zsOiOrAKI7aIM',
            width: 1000,
          },
        },
        {
          available: true,
          id: 'Corsa e',
          picture: {
            filename: 'opel-corsae-2764-perlblau_picture_front.png',
            height: 585,
            id: 'attaLjAZEeNwhXVEh',
            size: 301992,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/dai_J_iPfwANEBP9pzW8WA/XHubqG9J-Hwt99gZtsxLmsQaH8665JwUbNlf_ig-YbKcLBg5TGyfqH5xO6FYIMQApWeI55U0q_VZ0i8rkspDyw/yikXY22pGgDdWZOoeuL4uWKLcOzxyDM4qrVW-IPOoW0',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/E0UuXP6oIcl_2hmNc_e71Q/JAkoOiZUGdQPzUAu2gJLRQGeE0JHUsCBPRq20nECJ2MkfP_JxSEewMkBQTVdKS5OI1_4Vbs15T8uF3ySoedkyA/rmDsuKlHnw4svV_oyfCt2R1YSgZ1pVkXucbeNE5Zqv8',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/4EUuJjdIJrA9G5f1ZAHA8A/uxIO4_l2QXqvgvO2yB5rr5YIlhJXcULWDbWiR55i4R2RUJy1a-t206z8L5YVWzY1Mc1TUw5BAQ-2BShd7GoFIA/rN511L5xEOVvPGxAn_0BwRlLquKR0TcSYO3cgn_z8n8',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec9GpHKELGXdvxQl/fldI49uIeYXTkPPfU/attaLjAZEeNwhXVEh',
            width: 900,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'polestar.svg',
        height: 0,
        id: 'attsW6HbDeeH3Be42',
        size: 278,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/-rDPmpUHRoIoLMuQPA6tXQ/_fhCHx6OJrC53KM6vPurAR47UegCoUmW2u7XcBOF7X1VYPjkZxcx9AwYtFn_bYS4YBTVOXrlBoTx5FX4Q4-7fgFPq_39WLzfSVTaktuMBnE/AmnipRhaZ0JX4BC1x0_Ik1M5qI681_hQiVEJjywjpBk',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/AnVcZDmnTx7d8-gwNPs-LQ/qWXv089n4C5m-oEP3z3MuBpfvvPClUlri4VPxb8AQA62UJAY5hOxVjGO-DssAlqZ3vykczIVOTe3fSKbsG8nCC1uyUorJSghCCJqosKASpo/mqh3kBDYIWQQt_KHhM6QdVD8k0_YFxHhx7z9ow2lQ9s',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/QlZjBd_U5joMpmzzBA4sTg/d00rhloTQKWsfSerM9k4-rErpluy9NxhGFau2wS2XYOg62pm_k2-LROBILfeRYnBNcGl0JMAg_t7amwAd1gk-o8HE1ICN0JuTWhyL0usQb0/GXZOPdf11g8VhMA_pA8w7k15TGAo4i0GswCorpHvOOo',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec4FpfHtjJnBSARG/fldn1PBkvAK6H68vG/attsW6HbDeeH3Be42',
        width: 0,
      },
      id: 'Polestar',
      models: [
        {
          available: true,
          id: '2',
          picture: {
            filename: 'polestar-2-3104-magnesium_picture_front.png',
            height: 1300,
            id: 'attm6tVW8U4pDR2wH',
            size: 745994,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/COkUM4AGXn7QmcuKX-aFYw/TW8yR9x9oVVmHxWZHc9cyetKxviJI_Ggxkpkk81XRHtvBkZaWz2zD-QX2cv9seNo4BVHv2ISgp-nPaygD2A5dQ/NVQG1oOlO-clts_CQdVPkI3TdVNkFpveSsCWPe7hdcw',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/aX6UnC6osuuR_6wHZHHCZg/qToLwh3kgFGfWU5E_KSqyxOtlkN7XsR5675W4A53J0bfpnOaUS0dnD2JIrSbjv5ddImrlfOKsAvynJasUQe75A/TxTmtCc2rwb8g95YXPrxF_yD9mpWr-5HDCc36clEwl0',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/vMyOPUPPeYbU8pW1v_ZxoA/uafKtmgEzJ3YXeCDD8JnmC_N3fRdKAWaX0MpyF5TUSjnEQEHlmzo7tEStP0imOYFK63q-q7ygVNl4JDQnnKigw/vZZY3BMt-IcCMOaDolQLOtTZkQWXkxAll1R0hXKJavA',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/reczN1ncm5V6ideaH/fldI49uIeYXTkPPfU/attm6tVW8U4pDR2wH',
            width: 2000,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'renault.svg',
        height: 0,
        id: 'att55ds6JUpgPXTcK',
        size: 321,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/HdTHkryjbduVJ4djDQgh4g/yW_P1pRbGIqRoqJFeIohzWN5eF8-lIc9U2ybFiz_Clxr0c1n_i3NhV_k_NurAeJS1iUNcTY1G6dd8tKhzxccLQnh4hPBo7sOsRHw6WTQiN0/dndgc_OUUejYuXmmdRQnfTsuPTGESCw6yW4Q-ewIxEI',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/rUgKtJ2GRxgis4NmfS1rTQ/jVXiDEiB4Lilsf_oJiPwlataZ6MM-iv61aZ_gw_cJyhpTjcurgqOmhV94hJ-EnKo2S4nT6jdwvnHp-D8lb5kAEFpVxsv60B-muRQAg035F0/lRc_9fXNF7JkeSWboIHU8OnwoX6MVSI1wSJiP1xpTh0',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/JxaYovbi0G7Qu7xVz2-oCg/0VkSwRp_sRs11nBFy8U6qnmhepJ1E9XQQ5SeAo8nguANSNyYqJqPbA_APXxkJMWfsOI7lWj9-qVtuxc-K39j-PakEXurphwYim_FAN6ghFE/l5Tqb8jovaRiPqWpC-ZBhutUOXMgVp2_aN_aMO7f1_Q',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec0X2bktCpBLWRJM/fldn1PBkvAK6H68vG/att55ds6JUpgPXTcK',
        width: 0,
      },
      id: 'Renault',
      models: [
        {
          available: true,
          id: 'Captur',
          picture: {
            filename: 'renault-captur-3141-blackpearlschwarz_picture_front.png',
            height: 940,
            id: 'att2V1BRO0XQWk9BH',
            size: 589061,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/eQj3S_rwooCxELXIhSiU-w/wfBfr7_NnSYTO1wFXRQPaeJ5XaTlaUMP1CNFTr5DsMcrWB3xZ41tNI8tb-Pe3vq061qUuyELRd3Wq3kC0-VXMA/6QTLkX6cBNSHIsW47W2Jsu2Z5_JipGyk0awgM8rp-8c',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/NpWx-UkKoNcH_tDUJSqQYA/ZJchdN-8aPik21eBKaDARl2AVXt3TesLVzee8yLxYEtYXhXe3RTAFWPPhoek38HaFAW0EOmS2fJjpE5cRsQ48w/Ms0STE1tnrRyACM-cOh_SFj0-3sXEe5zEYmrq3BDwR8',
                width: 817,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/1SyaVNXSX5YOSS8uid_Ypg/CfNSOShWjGkFBc4MXM2Qc702io58WWJPZB-wXVotiVKUlRVXEt8RnNBYZFkwknx7592jW-uWC73w4G54T8FQvw/vdi5OW2Kv0FsoGiVKmpMzzJXuOMaMkEG5Lp_-htzZDs',
                width: 57,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recO0I13dYCFVNyI1/fldI49uIeYXTkPPfU/att2V1BRO0XQWk9BH',
            width: 1500,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'seat.svg',
        height: 0,
        id: 'attlrEFnGOorGFP9y',
        size: 974,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/f3wnIRXHQ41A8CSYMq5cjg/JUfRvP9sTg5c9BfigbUnWPSLQ98xEvmok9axn7TfQ6r_2OztvdGGwdZZrWcbcLU4PvicEHWZ_saFGonBA7jwHwAmumoxdNLMErO_tzJ-Eb8/OIf2nzwtV7rBzmN6wKzCD7ZZ5QbwoMCrKP8xUlgIgaA',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/9EXtPUzTOfrBxLX0PG4rTA/hIIlUmJ97s5PrPmKiafGi7VCT5BLCh0WVDw_76WxumXDt-LtIj6mOaKXEQpvZ8Jigsk2jkRbQ8BZPEmOMHrNmSj4LRSmrLg3iYBfPkfuwYE/9fpI0XqpEoNxRO9xsR-gsp6d001JWsSjwe-HBRePgL8',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/24dyTUnsCuwRUTWJwLiufw/tVMIQZJ_wUQVu3dOmhA9tyG6MeUYR5PeLDApVFgT444gGBqog4RNE9GXFOphEomc3uI0E5U_QnawvB8V2Jyt9qLBvY9O4s_dhTXM2VieokY/gWcM5j_RjsCbB82LPthCCLkF7VTcM45Vm0qhkU3dq_k',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recSstThIBrjVoCEb/fldn1PBkvAK6H68vG/attlrEFnGOorGFP9y',
        width: 0,
      },
      id: 'Seat',
      models: [
        {
          available: true,
          id: 'Alhambra',
          picture: {
            filename:
              'seat-alhambra-2303-deepschwarzmetallic_picture_front (1).png',
            height: 576,
            id: 'attlrpjIdxiNvom1G',
            size: 229306,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/I15DnycyJbp5gZW67k2NWw/vD6VurdGAt0o4eobv0v7m76iNx1gPAWc8pf9FZzTJbDQleYxy_jm3uC6wKpQNLlHWw3Yde2m6pH6wlK_h6uvgQ/qXnINQ57SI3wPiPBXdROHPL6u98zXG7XVUiwMUxCY8Y',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/SyeuFryfDXzNJBsWjK4mXQ/GOy4Wuz_ghjh7HvL_RlxyvhDO-iy8TTE1ichzL7T8UikW6Rp1XilWXptk39CKuIbwtArM3vgpQYD11OaM3q5yg/uolvaf2-xIHBkOafYuz3ZIGfdQPnVHV_a7kBwe6leAg',
                width: 910,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/Li5psfrQ94EBjOwhpP-qlQ/OEb586OLvLJrB-oS5U959X-IGIgpmWTRpseZgncQKeq_jzUp4-7QIKV4DL9fZ4gUjCsatfWfiQBhgVnSujTN-Q/tjNTHX4--nyeCi1HZ7pmsVpvSuCuKVsxYreKCt1vDKI',
                width: 64,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recSstThIBrjVoCEb/fldI49uIeYXTkPPfU/attlrpjIdxiNvom1G',
            width: 1024,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'tesla.svg',
        height: 0,
        id: 'attmSxR0OlikrTtAf',
        size: 558,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/OOyw6E3rJOqP_A6Y7ysR9Q/WU3rFcTJtGbXAIsSHAXbvFgdmWywpjCYUn4TNED8gDxoI7N12DGpUJCLsKKM65gggwPg6Hm6_NTi4BFb7OuIdpGSldVa4ptsdP-j0rk6PNQ/bOWLncld2-U_-6uEJyX2Yz-L983dGE-hOCj6X9DZxAw',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/NVsGydm3pTQ63Nj2eGMcbg/Jpduv-5iTTANPnmszqFnBLp5IjCux5dnEWQPyd4TVO8UsAY09The7ddej0HYrR1VomsEY6aRR0k_PsnqTAKjoEWFj3xlOe2jTfTGHsZ_dhs/oY02hwWcJezvTUbPRpty7dwdjrTWB0W6wH4ZUadQ4v0',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/cG4pWTi2O9WpwhSMfktwbQ/FPmsGGFoEk0o_q1IJoZMOO_QA7siHtf2Js1eMCxmm6dKLA_hN1kho6mwDHBGCsKasJXFGKCu_2PEAgzS8G_ZzET_p_6pp88auDFGKV_dH3c/1rR2VFlyJC5sv0iM9_WHcpp6DW_NkEINeTeOGkhBTAI',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/reczCluRDlh5PG4rF/fldn1PBkvAK6H68vG/attmSxR0OlikrTtAf',
        width: 0,
      },
      id: 'Tesla',
      models: [
        {
          available: true,
          id: 'Model 3',
          picture: {
            filename:
              'tesla-model3-3092-midnightsilvermetallic_picture_front.png',
            height: 845,
            id: 'attMiR80pUKyWqfaW',
            size: 325559,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/BGg3ZMb4_TJv68uTmQ1TuA/HmwAi6o-eaTu6A4wRTfdMssGjIC1MvshAGiBkbOsGmSSvBunCScTNB-zw4QDGsRUHp1UnXcq9cvfyBqIE8sFlg/fqQBQgr39ERLhNNQ6Ao6aIHM7gCKfB-LWTrMrwIgmQw',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/V1iEBZqUE_5hJy51svYr8Q/jqQz0ebA0_PHTHxocfDu9rKzM3lyP7oCjpkM7ZqnfHT2mR7VhlprVyElomTHN42omfv-89iD5VDViHNWYRluVQ/MUu8sJbYB0Fgu1R-c8BdXI7_70ST2LRgl1TbV9aWq_M',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/czTYmihLnYooqo5_Mw3dbA/IIhRf1Hk9Rofa0u2mN7esRxyaN6Iul86d3OonzlxDF6wXmVs-VRexcBVgVL08AyTdxid2NwRm4GcnyGxzyfHVA/F2pgvI0F00r8bVxx8kGtttjoIG7esoUMLBFwzNBPLxY',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recanioJGn4UZqSVa/fldI49uIeYXTkPPfU/attMiR80pUKyWqfaW',
            width: 1300,
          },
        },
        {
          available: true,
          id: 'Model Y',
          picture: {
            filename: 'tesla-modely-2421-solidblack_picture_front.png',
            height: 845,
            id: 'attu0L2kbjepEiIzc',
            size: 310350,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/oFlXieFMrF0MzKqC30yHmQ/OsdrdG6QS4zAewMduVw4tm1IoDFT7EMZsGFI_Sz0x6Ou_fDR0Uv5pG0xIvtHLBadvCs3b-QXn4SWc5W38gSlvQ/8CYesxbmDrvTooYkFj7-rWoySqDoFJFQo-veIEzX5zY',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/T74fVrrxxaqfkcMRDrJBGw/YBpCGEk1ANWIy9ITRbC8Oyj9VcEc5wGzqQ5Vi6Gu2mFpLWpIab_UWIl_dsOBOdUUSmgtXnOmE3_DcCgopNSSPw/Wp7Ftga0SCYmgbWN36ify1p7Vq7buwQBJ7RqGmZIypI',
                width: 788,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/anJQF7j6XKpzQPgBm91sdg/tuJDbRzoV9m2TFDuIQ_JcDw07SOplrwfEptT92oaqr_t55Nz1fT2720JNjPxJXOoWZhfEpaefCgRDi5SUgOW4A/MtIuTBxXnQ-BZGaO-AKTokxk5yzdDFfDTmQ0VwiMepE',
                width: 55,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recLqxQKXCXoZKxZI/fldI49uIeYXTkPPfU/attu0L2kbjepEiIzc',
            width: 1300,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'toyota.svg',
        height: 0,
        id: 'attdjTVdKt5zaUask',
        size: 926,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/1UIp_06r2k48zVHxcJWPDQ/EMelMkoybALqdD02vBw4cOYhVvI8WK5WcobvTcavP1Lp8wgNRTAzJVoYkKPM1j0w-e_laDtP7X6W1QI2aFw4oylJ3bORLqSbBC8w0-6ndk0/nztgUFflXmPeOnqNAzFl7PhzQLxJpYrpGtd-nNi7LYk',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/obxT9qw2r7687X9VPb_G7Q/YikK31rcLhLkt49Dr2P1-HHlTosW8f4lqkqv1p-ND4bwdqPp5_eN0I6UsQImYPlhYwx7dBpRszHnNPvszzfYisKY3pxAVgE_moWTtvHpA7Q/9OtZLPLCC1kRnhbLqGGM5NL7laWqny_aSkkLab3ovTA',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/PNLVwpGbNX-Xg4-HtT8dTg/mwFS7UwEShvchXpieIzZUnVpt4jq0xjugQwHPWuvhtSLGRjY9hPAS_SPQ4Eys0XPOjbYGNDNCvchnaaTfWH6fWdlRx5ou2p0acaTrMTYNlQ/O1D9sir4ppn2zGN_o3iN_1ioSh40Ll6ZonQpqT3vU7s',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recFWD1pJmw06kZp5/fldn1PBkvAK6H68vG/attdjTVdKt5zaUask',
        width: 0,
      },
      id: 'Toyota',
      models: [
        {
          available: true,
          id: 'Aygo X',
          picture: {
            filename: 'toyota-aygox-3336-gingerbeigemetallic_picture_front.png',
            height: 960,
            id: 'attSyQupaaqCQZZyt',
            size: 752552,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/S-nCE_CPTJIOZwLzfWRw7Q/4J0Uri4ZfKpErthvRjv-cMD294vKWzNmyakhN3kG5cGMIG1w8l7_jxFnvvBN2-dF6tH6SH9XqYu5CNBDnmdsXg/st7xd-EM23WsFZx98TsavOiUcxssFKEAfAKzqu8spGE',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/m0ec__VehHku4m6GU04W-w/wjjafXvXtC5JEmWPq_44t1WBYpTPG3XQWeOJhvDUxAAZO0-l0Ua8_QXGBl-R3-ym2UE10MWAHMsZh3x49VSsww/xHG8CFn4AJfllrqejP_RN3WHfxktc-EDm0rvZAThav4',
                width: 1333,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/fNasB9hXx4Zq_tuTJFVJJg/SVKCVWyAG0cJ4-Wl8GgeD8JvEymraAmwiixdv8mZWVt1tUoN5UkuhMaH1QxW1Mf7GLVQBvyG4S_0oTrPxBwUDw/wUBEqVtx23TI5qmAAve7lIwE-OZwTMmgWaxQRNvPg0w',
                width: 94,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recYI0qrG3EypUVuI/fldI49uIeYXTkPPfU/attSyQupaaqCQZZyt',
            width: 2500,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'vw.svg',
        height: 0,
        id: 'attN5hOcnPRUzjbQb',
        size: 1192,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/a339ixKCr0IbZs7gwD3WBQ/9rHZqrExqjM59tOD_p3542YFEzfdVlMXOqJAS5RcERm3xTEsSbFyhNvQD_efl2JRrqStXQyO7yObRPrajQg-yHZhHLpKcrvPJLiLOqEaYa4/_-W5qN_YPURhGJ8Gq9eOWITQLz0Upq00DCQIGDAHUNk',
            width: 3000,
          },
          large: {
            height: 200,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/MF2n3k0xzJAP4z89dgRu5A/hfgejaMR7YIc5ypdT-KFza9GFzcHpBB4RbSfmG_rbd2QpN4f7V1nynstYw0hGwjuOV7SA9WQbYkSGFh2YaYvKQaNUVLjpamzUTsU1ijNRIY/I6psiJyn-VkNPz_cgMy1TfPc0xYYSOK8mXGHOeQVFt4',
            width: 200,
          },
          small: {
            height: 36,
            url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/F0JjYwZhozDxcXAbopBFxA/YvtEvfXfoziPPttvMM6CGetgarHOFSl9ppr_3ymBFEQJ5T9nV7pT1bTG_rtnQjH6VC9T4XIPutnC5gCkM-0hmgHPTMcB_o80kgr-RKSwpO4/pdnvBIGhWLCdIhtM6lRXW769Uyp2HvpBbcNvVGkCRGY',
            width: 36,
          },
        },
        type: 'image/svg+xml',
        url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recj8L1JoYg8SJ2AL/fldn1PBkvAK6H68vG/attN5hOcnPRUzjbQb',
        width: 0,
      },
      id: 'VW',
      models: [
        {
          available: true,
          id: 'Arteon Shooting Brake',
          picture: {
            filename: 'front.webp',
            height: 756,
            id: 'att4mIrzZtECHNj1A',
            size: 82158,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/B4cDJbDDRDlLuPclz0l-cg/XRa0EjNcI8yensJRna9UpeRir8YWomZD8n5M7SRW4Gbvk0aSjo7Wdz6enVLh_txzA9LjE7ZXQi8rw5IyA9-2Nfx2GjdmDsGUkNtnBUL1mFc/yZAgqYpSCwR44PXgTlihcsV4j05hkOeWsEBBqu8vpjs',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/QE1q6vXiw-mjmheeZMQ2Vg/gUV34YzbSrLSRNlsINZwreCaw4m2K2xZkYzcq54gklHg4-LTkNCKA25lSSJE19vX9KyZ2P50ngi-x2Bwejs7cf9aJhYYcih2QDAwJGFEof4/VB9HQhZbDOCU0MZbI5NqqYY2F7NwWrYRMlOXAkRa4N4',
                width: 819,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/P2uqPlPurVfgQyRSrmU6Ew/-PTdiXOXhfL9RJ5dFwCkJY7_yEKG57Q8Um1_Np0ORsKkxtx4-N_-5fd7acLpBUGBArflTqt__qWmmSpphv2KryPRhj1G4a6uh_nCmQ0zVhc/u_QbWR1W23ajKSEzBMXepzxqh6MbDDnRuTzVeJxsHGE',
                width: 58,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec9omqNPDKcKLXRZ/fldI49uIeYXTkPPfU/att4mIrzZtECHNj1A',
            width: 1209,
          },
        },
        {
          available: true,
          id: 'Golf Variant',
          picture: {
            filename:
              'vw-golfvariant-2386-atlantikbluemetallic_picture_front.png',
            height: 880,
            id: 'att0sQr39gi9ghXuC',
            size: 192462,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/k51RMZGqND8Y0_oVdvx6lQ/IKWHXlH4vGb89YkwF3Z-p3Y1tQPUzjVHve2D7fjs17VTIJHuh-ZfFoD02fFscRjp3BmHl8T8KzbJfl-uI3Az0g/kSCq9AGt4GsNGKU3l28vUa70Ji8Z23YkDTBeUsPwZhM',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/c7kBJI1jUB9D3VpQJtoncw/aWtZEFDUQvP5GA8mZFJcI81iEP0pwjHj4b2TtVHL5ERJFj6lE3316XZszh3MJ7gnGyXeCcG9CXJfoebMAMyHSw/IT0tQkqZSHjUa3RPAREtBKcdf3rt5dagtka13WH0npw',
                width: 756,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/Gx6X5pDPDhaSkIWEQNdXjA/Bwh2VuBVtMI3SInC6FK5DWNNErxh37vNfBvTaFtcQ7BG4z8H3uIvBNeNj901uCk6moebm1s325YxmLOXmgjlbQ/vAHpTr-YNAVUfLf8FFApKaFwAPLeiQdZlgX3k8mWhkY',
                width: 53,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/recWLit32ZUs13KLM/fldI49uIeYXTkPPfU/att0sQr39gi9ghXuC',
            width: 1300,
          },
        },
        {
          available: true,
          id: 'Multivan T6 1',
          picture: {
            filename: 'front.webp',
            height: 756,
            id: 'attwpR1g8jtAFdzpg',
            size: 78626,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/GZz64jywpgHkc2yDg_o0qw/pzfiCogHTA8Cv5PYLPBQm7xGsMgZB73V0fO05YzofO97DHuuCxHKnSC9gUjJwdg4fDhUZjbf_Uu8YXXRtbiPTOC4y_-qafCvZirO166f4VM/zCk4gnmS8QIuzhP3PhThrfO3SkGvVJNkT1PVXbKrW5E',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/Gt9d0YS32END56Q4oJ-rgA/041kL2hFJohOsVlXc7QBHlVijaIP1aWUG7D06eI_Xxh_n-1-s852c4g0BjgmZWuP9HYhrzjSaLCAi_qCL536XQCIDZ0ZApWDJQsgLLtbPQ8/AoZLdGuh9SaeNm0BYfYCplqBMNkaPqnbr_F4RLH_KII',
                width: 819,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674216000000/ZMghBOKNp6EqoW-M2Sohog/w2WLkC9_gu9qkOstAmNMlWY_FJXtF-nLMew1GPwcP4e6lGALpHvUtnWIYZ9g7__Kd2tE6p0QGwsv2NFoOro1i_6cJNvu5OKRQtsNgxSInhw/A0B1NE2XFYt-Mc4Wsaz87FA_0FEhGBUVlKS5DHEfQIQ',
                width: 58,
              },
            },
            type: 'image/webp',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/reckxsy6vb0IEblbk/fldI49uIeYXTkPPfU/attwpR1g8jtAFdzpg',
            width: 1209,
          },
        },
        {
          available: true,
          id: 'Passat Variant',
          picture: {
            filename:
              'vw-passatvariant-2404-pyritsilbermetallic_picture_front.png',
            height: 880,
            id: 'att9Dl68M8ZMuyigt',
            size: 223249,
            thumbnails: {
              full: {
                height: 3000,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/aV-BX8gP1GI5oAFmgYXYTg/w4mmW2aLekXoHyb3iS3gmquHoHfWE__BSeya4oZ0XUR9cZPqk0QpwYVIcyL6yE2uoJeYfgaZLUqUdz5gfIdMeQ/AboK9uT00l7629lJEq-UHT7lZl7KpkGycObJTJih3Ho',
                width: 3000,
              },
              large: {
                height: 512,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/ewUe5iLiKBYQWfL7EPQ-xw/pscwl0wnSS7C4vymRlj-SNzIYNOpkOL80XwaWKVbBMW11DCr84yJLM8omWRNrZ-BCFJLGk14pWeyZpjCAGt6WA/sdV0dvlcpymYc2Zn5h8RMPe-DSPFwkcC0c_pfy7T71I',
                width: 815,
              },
              small: {
                height: 36,
                url: 'https://v5.airtableusercontent.com/v1/14/14/1674165600000/as1Itk2XV99jnfkKFCr-Wg/LJGoyv5qjyfyoPJxXsG2LoecgOa6cyc3KjbqFtgwKTT0Agd7URr-57-7Yz5C4JU4tGpRLUn0lK6h5PD30-Ez9Q/Nftk-f5KnPgYdCMoxYYYEO5Mrx236mltJLxI7egDafM',
                width: 57,
              },
            },
            type: 'image/png',
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tblcpXz2D83bVzkbz/rec8daIsob74GO6s5/fldI49uIeYXTkPPfU/att9Dl68M8ZMuyigt',
            width: 1400,
          },
        },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'mazda.svg',
        size: 799,
        type: 'image/svg+xml',
        url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/ua_main_restapi_de/brands/logo/r4KRzT.svg',
      },
      id: 'Mazda',
      models: [
        {
          available: true,
          id: '3',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/G4cg23.png',
          },
        },
        {
          available: true,
          id: '6 4-Türer',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/dKlvlf.png',
          },
        },
        {
          available: true,
          id: '6 Kombi',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/Q7takT.png',
          },
        },
        {
          available: true,
          id: '6 SW',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/FeAD0S.png',
          },
        },
        {
          available: true,
          id: 'CX-30',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/cLcmHX.png',
          },
        },
        {
          available: true,
          id: 'CX-5',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/jn16b2.png',
          },
        },
        {
          available: true,
          id: 'CX-60',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/KQAUgJ.png',
          },
        },
        {
          available: true,
          id: 'CX-80',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/kqeWc0.png',
          },
        },
        {
          available: true,
          id: 'MX-30',
          picture: {
            url: 'https://files.finn.auto/v2/redirect/appWKbiA9i66Pk4qV/tbl45r0jXPu5CCd5b/recUb7vh9YsW0XJ00/fldAKDVZyFoN1SI9w/att74JSvpyE3sTbaP',
          },
        },
        {
          available: true,
          id: 'MX-5',
          picture: {
            filename: 'picture_front',
            type: 'image/png',
            url: 'https://dataops-nocodb-s3-bucket-production.s3.eu-central-1.amazonaws.com/nc/uploads/noco/fleet_blue_dragon/car_images/image/mLZNab.png',
          },
        },
      ],
    },
  ],
  cartypes: [
    'Cabriolet',
    'Coupé',
    'Kleinwagen',
    'Kombi',
    'Limousine',
    'SUV',
    'Van',
  ],
  colors: [
    {
      color_hex: '#FFFFFF',
      id: 'Weiß',
    },
    {
      color_hex: '#C4C4C4',
      id: 'Silber',
    },
    {
      color_hex: '#000000',
      id: 'Schwarz',
    },
    {
      color_hex: '#3C3C3C',
      id: 'Dunkelgrau',
    },
    {
      color_hex: '#9fafae',
      id: 'Hellgrün',
    },
    {
      color_hex: '#1D478A',
      id: 'Blau',
    },
    {
      color_hex: '#5E5E5E',
      id: 'Grau',
    },
    {
      color_hex: '#939c99',
      id: 'Army Green',
    },
    {
      color_hex: '#3c524b',
      id: 'Dark Green Gray',
    },
    {
      color_hex: '#8F1E1E',
      id: 'Rot',
    },
    {
      color_hex: '#92c5fc',
      id: 'Hellblau',
    },
    {
      color_hex: '#009fab',
      id: 'Turquise',
    },
    {
      color_hex: '#000000',
      id: 'Grün',
    },
    {
      color_hex: '#c2a72e',
      id: 'Gold',
    },
    {
      color_hex: '#570f17',
      id: 'Dunkelrot',
    },
    {
      color_hex: '#FF8D00',
      id: 'Orange',
    },
    {
      color_hex: '#c6c2ba',
      id: 'Beige',
    },
    {
      color_hex: '#044F0D',
      id: 'Dunkelgrün',
    },
  ],
  fuels: [
    'Benzin',
    'Benzin (mild-hybrid)',
    'Diesel',
    'Diesel (mild-hybrid)',
    'Elektro',
    'Hybrid-Benzin',
    'Plug-In-Hybrid',
  ],
  gearshifts: ['Automatik', 'Manuell'],
  has_hitch: [false, true],
  is_young_driver: [null],
  max_price: 2809,
  min_price: 279,
  models: [
    '1er',
    '1er M',
    '2',
    '2er Active Tourer',
    '2er Coupé M',
    '2er Gran Coupé',
    '2er Gran Coupé M',
    '3',
    '3 Türer',
    '3er Limousine',
    '3er Touring',
    '3er Touring M',
    '4',
    '5 Türer',
    '500',
    '500 e',
    '500C',
    '5er Limousine',
    '5er Touring',
    '6 4-Türer',
    '6 Kombi',
    '6 SW',
    'A3 Limousine',
    'A3 Sportback',
    'A4 Avant',
    'A4 Limousine',
    'A5 Cabriolet',
    'A5 Sportback',
    'A6 allroad quattro',
    'A6 Avant',
    'A6 Limousine',
    'A7 Sportback',
    'A8 L',
    'Alhambra',
    'Arteon Shooting Brake',
    'Aygo X',
    'Cabrio',
    'Captur',
    'Ceed',
    'Clubman',
    'Compass',
    'Corsa',
    'Corsa e',
    'Countryman',
    'Crossland',
    'CX-30',
    'CX-5',
    'CX-60',
    'CX-80',
    'Defender 110',
    'Discovery',
    'Discovery Sport',
    'e-tron Sportback',
    'Electric',
    'Formentor',
    'Golf Variant',
    'Grandland',
    'Insignia',
    'Insignia ST',
    'iX',
    'iX3',
    'Juke',
    'Leaf',
    'Marvel R',
    'Model 3',
    'Model Y',
    'Mokka',
    'Mokka e',
    'Multivan T6 1',
    'MX-30',
    'MX-5',
    'Niro',
    'Passat Variant',
    'Picanto',
    'Primastar',
    'Q2',
    'Q3',
    'Q3 Sportback',
    'Q5 Sportback',
    'Q8',
    'Qashqai',
    'Range Rover Evoque',
    'Rio',
    'RS 4 Avant',
    'RS 5 Coupé',
    'RS 5 Sportback',
    'RS 6 Avant',
    'RS 7 Sportback',
    'RS e-tron GT',
    'RS Q3',
    'RS Q3 Sportback',
    'S3 Sportback',
    'S5 Cabriolet',
    'S8',
    'Spring',
    'SQ5',
    'SQ5 Sportback',
    'SQ7',
    'SQ8',
    'Townstar',
    'Tucson',
    'X1',
    'X2',
    'X3',
    'X4',
    'X5',
    'XCeed',
  ],
  payment_method: ['stripe_debit_sepa', 'stripe_credit_card'],
  prices: {
    items: [
      {
        count: 3,
        value: 279,
      },
      {
        count: 1,
        value: 289,
      },
      {
        count: 2,
        value: 309,
      },
      {
        count: 10,
        value: 319,
      },
      {
        count: 2,
        value: 329,
      },
      {
        count: 1,
        value: 339,
      },
      {
        count: 4,
        value: 349,
      },
      {
        count: 1,
        value: 359,
      },
      {
        count: 3,
        value: 369,
      },
      {
        count: 7,
        value: 379,
      },
      {
        count: 18,
        value: 389,
      },
      {
        count: 12,
        value: 399,
      },
      {
        count: 8,
        value: 419,
      },
      {
        count: 21,
        value: 429,
      },
      {
        count: 3,
        value: 439,
      },
      {
        count: 22,
        value: 449,
      },
      {
        count: 1,
        value: 459,
      },
      {
        count: 2,
        value: 469,
      },
      {
        count: 4,
        value: 479,
      },
      {
        count: 9,
        value: 489,
      },
      {
        count: 17,
        value: 499,
      },
      {
        count: 4,
        value: 509,
      },
      {
        count: 17,
        value: 519,
      },
      {
        count: 9,
        value: 529,
      },
      {
        count: 6,
        value: 549,
      },
      {
        count: 18,
        value: 559,
      },
      {
        count: 12,
        value: 569,
      },
      {
        count: 8,
        value: 579,
      },
      {
        count: 23,
        value: 589,
      },
      {
        count: 19,
        value: 599,
      },
      {
        count: 5,
        value: 609,
      },
      {
        count: 9,
        value: 619,
      },
      {
        count: 6,
        value: 629,
      },
      {
        count: 4,
        value: 639,
      },
      {
        count: 16,
        value: 649,
      },
      {
        count: 2,
        value: 659,
      },
      {
        count: 7,
        value: 669,
      },
      {
        count: 4,
        value: 689,
      },
      {
        count: 10,
        value: 699,
      },
      {
        count: 4,
        value: 709,
      },
      {
        count: 3,
        value: 719,
      },
      {
        count: 2,
        value: 729,
      },
      {
        count: 22,
        value: 739,
      },
      {
        count: 8,
        value: 749,
      },
      {
        count: 11,
        value: 759,
      },
      {
        count: 13,
        value: 769,
      },
      {
        count: 13,
        value: 779,
      },
      {
        count: 6,
        value: 789,
      },
      {
        count: 14,
        value: 799,
      },
      {
        count: 11,
        value: 809,
      },
      {
        count: 17,
        value: 819,
      },
      {
        count: 13,
        value: 829,
      },
      {
        count: 9,
        value: 839,
      },
      {
        count: 24,
        value: 849,
      },
      {
        count: 17,
        value: 859,
      },
      {
        count: 9,
        value: 869,
      },
      {
        count: 5,
        value: 879,
      },
      {
        count: 2,
        value: 889,
      },
      {
        count: 12,
        value: 899,
      },
      {
        count: 6,
        value: 909,
      },
      {
        count: 9,
        value: 919,
      },
      {
        count: 1,
        value: 929,
      },
      {
        count: 2,
        value: 939,
      },
      {
        count: 9,
        value: 949,
      },
      {
        count: 3,
        value: 959,
      },
      {
        count: 7,
        value: 979,
      },
      {
        count: 2,
        value: 1009,
      },
      {
        count: 1,
        value: 1029,
      },
      {
        count: 8,
        value: 1049,
      },
      {
        count: 3,
        value: 1059,
      },
      {
        count: 2,
        value: 1069,
      },
      {
        count: 1,
        value: 1079,
      },
      {
        count: 1,
        value: 1089,
      },
      {
        count: 3,
        value: 1099,
      },
      {
        count: 2,
        value: 1119,
      },
      {
        count: 4,
        value: 1129,
      },
      {
        count: 1,
        value: 1139,
      },
      {
        count: 7,
        value: 1149,
      },
      {
        count: 10,
        value: 1169,
      },
      {
        count: 1,
        value: 1179,
      },
      {
        count: 1,
        value: 1189,
      },
      {
        count: 2,
        value: 1199,
      },
      {
        count: 1,
        value: 1229,
      },
      {
        count: 10,
        value: 1249,
      },
      {
        count: 6,
        value: 1269,
      },
      {
        count: 9,
        value: 1289,
      },
      {
        count: 7,
        value: 1299,
      },
      {
        count: 1,
        value: 1309,
      },
      {
        count: 2,
        value: 1329,
      },
      {
        count: 5,
        value: 1349,
      },
      {
        count: 1,
        value: 1389,
      },
      {
        count: 1,
        value: 1399,
      },
      {
        count: 1,
        value: 1409,
      },
      {
        count: 1,
        value: 1419,
      },
      {
        count: 2,
        value: 1429,
      },
      {
        count: 1,
        value: 1449,
      },
      {
        count: 1,
        value: 1469,
      },
      {
        count: 2,
        value: 1489,
      },
      {
        count: 3,
        value: 1499,
      },
      {
        count: 1,
        value: 1549,
      },
      {
        count: 1,
        value: 1569,
      },
      {
        count: 1,
        value: 1599,
      },
      {
        count: 5,
        value: 1619,
      },
      {
        count: 7,
        value: 1629,
      },
      {
        count: 4,
        value: 1649,
      },
      {
        count: 3,
        value: 1709,
      },
      {
        count: 2,
        value: 1719,
      },
      {
        count: 3,
        value: 1729,
      },
      {
        count: 4,
        value: 1739,
      },
      {
        count: 4,
        value: 1769,
      },
      {
        count: 1,
        value: 1779,
      },
      {
        count: 2,
        value: 1799,
      },
      {
        count: 7,
        value: 1829,
      },
      {
        count: 3,
        value: 1859,
      },
      {
        count: 1,
        value: 1869,
      },
      {
        count: 2,
        value: 1899,
      },
      {
        count: 3,
        value: 1949,
      },
      {
        count: 1,
        value: 1989,
      },
      {
        count: 2,
        value: 1999,
      },
      {
        count: 2,
        value: 2089,
      },
      {
        count: 2,
        value: 2159,
      },
      {
        count: 6,
        value: 2199,
      },
      {
        count: 2,
        value: 2209,
      },
      {
        count: 2,
        value: 2239,
      },
      {
        count: 6,
        value: 2269,
      },
      {
        count: 6,
        value: 2319,
      },
      {
        count: 3,
        value: 2399,
      },
      {
        count: 3,
        value: 2439,
      },
      {
        count: 4,
        value: 2479,
      },
      {
        count: 1,
        value: 2529,
      },
      {
        count: 2,
        value: 2809,
      },
    ],
    prices_step: 0,
  },
  product_id: [
    'audi-a4limousine-3222-mythosschwarzmetallic',
    'audi-q3-3272-nardograuuni',
    'audi-q3sportback-2969-chronosgraumetallic',
    'audi-rsq3sportback-3119-nardograuuni',
    'audi-rsetrongt-3131-daytonagrauperleffekt',
    'audi-q2-2828-daytonagrauperleffekt',
    'audi-q5sportback-2983-daytonagrauperleffekt',
    'fiat-500e-2749-arktisweiss',
    'nissan-qashqai-3354-pearlwhite',
    'audi-q5sportback-2981-ibisweiss',
    'audi-rsetrongt-3052-mythosschwarzmetallic',
    'audi-rs7sportback-3127-mythosschwarzmetallic',
    'opel-mokka-3215-kosmosrot',
    'toyota-aygox-3336-juniperbluemetallic',
    'bmw-5ertouring-3435-mcarbonschwarzmetallic',
    'audi-q3-3281-mythosschwarzmetallic',
    'bmw-x4-2702-mbrooklyngraumetallic',
    'audi-a4avant-3223-ibisweiss',
    'audi-q3sportback-3288-mythosschwarzmetallic',
    'nissan-juke-3343-blackcontrastsilver',
    'audi-a5sportback-3293-mythosschwarzmetallic',
    'jeep-compass-3004-alpinewhite',
    'audi-a5sportback-3252-daytonagrauperleffekt',
    'audi-q3sportback-2969-nardograuuni',
    'audi-etronsportback-2326-taifungraumetallic',
    'audi-etronsportback-2326-gletscherweissmetallic',
    'jeep-compass-1695-carbonblackmetallic',
    'nissan-juke-3345-ceramicgreycontrastblack',
    'audi-a5sportback-3294-mythosschwarzmetallic',
    'audi-rsq3sportback-3129-mythosschwarzmetallic',
    'nissan-qashqai-3352-pearlwhitecontrastblack',
    'jeep-compass-2904-graphitegrey',
    'bmw-ix-3319-mineralweissmetallic',
    'vw-multivant61-2995-deepblackmojavebeige',
    'kia-xceed-2646-lunarsilber',
    'audi-a5sportback-3292-daytonagrauperleffekt',
    'audi-q2-2828-mythosschwarzmetallic',
    'mg-marvelr-3207-nightwatchgrey',
    'audi-q3sportback-2510-mythosschwarzmetallic',
    'audi-s8-3225-gletscherweissmetallic',
    'audi-q3sportback-2479-navarrablaumetallic',
    'mg-marvelr-3207-cumuluswhite',
    'nissan-qashqai-3353-ceramicgreycontrastblack',
    'nissan-qashqai-3355-burgundy',
    'nissan-qashqai-3353-darkgrey',
    'vw-passatvariant-2398-mangangraumetallic',
    'audi-q3-2500-daytonagrauperleffekt',
    'audi-q3sportback-2473-mythosschwarzmetallic',
    'audi-q3sportback-3290-daytonagrauperleffekt',
    'audi-q5sportback-2984-mythosschwarzmetallic',
    'audi-q2-2828-gletscherweissmetallic',
    'bmw-2eractivetourer-3193-alpinweissuni',
    'nissan-qashqai-3348-pearlwhitecontrastblack',
    'audi-q5sportback-2932-daytonagrauperleffekt',
    'vw-arteonshootingbrake-3019-mondsteingrau',
    'opel-grandland-3217-rubinrot',
    'audi-rsq3sportback-3129-gletscherweissmetallic',
    'nissan-qashqai-3354-fujisunsetredcontrastblack',
    'audi-q5sportback-2998-daytonagrauperleffekt',
    'nissan-juke-3345-black',
    'opel-corsa-3209-dynamikorange',
    'landrover-discoverysport-2668-eigergrey',
    'nissan-qashqai-3352-black',
    'audi-q8-2269-nardograuuni',
    'opel-corsa-3195-karbonschwarz',
    'audi-q3sportback-3288-navarrablaumetallic',
    'audi-rs6avant-3258-mythosschwarzmetallic',
    'audi-q3sportback-3235-mythosschwarzmetallic',
    'audi-a5cabriolet-3241-daytonagrauperleffekt',
    'audi-rs5sportback-3053-gletscherweissmetallic',
    'audi-q5sportback-2983-mythosschwarzmetallic',
    'hyundai-tucson-2334-phantomblack',
    'tesla-model3-3092-solidblack',
    'nissan-qashqai-3353-ceramicgrey',
    'audi-q3sportback-2966-mythosschwarzmetallic',
    'landrover-rangeroverevoque-3060-eigergrey',
    'opel-corsa-3212-karbonschwarz',
    'audi-a6limousine-3048-brillantschwarz',
    'bmw-ix-3316-phytonicblaumetallic',
    'audi-a3limousine-2792-gletscherweissmetallic',
    'nissan-juke-3343-ceramicgreycontrastblack',
    'audi-q3-2476-chronosgraumetallic',
    'audi-q3sportback-3236-mythosschwarzmetallic',
    'audi-a3limousine-2793-mythosschwarzmetallic',
    'kia-ceed-2643-lunarsilber',
    'audi-q3sportback-2508-navarrablaumetallic',
    'audi-a4avant-3223-daytonagrauperleffekt',
    'landrover-rangeroverevoque-3061-carpathiangrey',
    'audi-q5sportback-2980-mythosschwarzmetallic',
    'kia-ceed-2641-zilinaschwarz',
    'kia-ceed-2641-lunarsilber',
    'audi-q3sportback-3288-daytonagrauperleffekt',
    'opel-grandland-2735-jadeweiss',
    'audi-rs6avant-3174-tangorotmetallic',
    'audi-rsq3sportback-3123-mythosschwarzmetallic',
    'opel-insigniast-2745-mondsteingrau',
    'audi-q3sportback-2338-nardograuuni',
    'tesla-modely-3309-solidblack',
    'nissan-juke-3343-darkgreycontrastblack',
    'audi-sq5sportback-2999-mythosschwarzmetallic',
    'opel-corsa-3214-kontrastgrau',
    'opel-mokkae-2249-diamantschwarzmetallic',
    'opel-corsa-3195-voltaikblau',
    'audi-s5cabriolet-3171-navarrablaumetallic',
    'audi-rs5coupe-3124-daytonagrauperleffekt',
    'audi-sq5-3001-mythosschwarzmetallic',
    'audi-q5sportback-2997-mythosschwarzmetallic',
    'audi-q8-2275-mythosschwarzmetallic',
    'audi-sq7-3224-gletscherweissmetallic',
    'audi-q3sportback-3200-mythosschwarzmetallic',
    'kia-rio-2638-auroraschwarz',
    'audi-q3sportback-3235-daytonagrauperleffekt',
    'nissan-qashqai-2820-ceramicgrey',
    'audi-sq5-3002-mythosschwarzmetallic',
    'nissan-qashqai-3355-pearlwhitecontrastblack',
    'audi-q5sportback-3041-mythosschwarzmetallic',
    'tesla-model3-2415-pearlwhite',
    'nissan-qashqai-3350-ceramicgrey',
    'audi-rsq3sportback-3129-kayalamigrünuni',
    'opel-corsa-2728-diamantschwarz',
    'audi-q3-3233-mythosschwarzmetallic',
    'vw-golfvariant-2386-atlantikbluemetallic',
    'audi-q8-2271-mythosschwarzmetallic',
    'opel-crossland-2736-jadeweiss',
    'renault-captur-3141-blackpearlschwarz',
    'nissan-qashqai-3350-pearlwhite',
    'audi-rsq3sportback-3128-mythosschwarzmetallic',
    'audi-q5sportback-2984-gletscherweissmetallic',
    'opel-corsa-3214-karbonschwarz',
    'audi-q3-3237-daytonagrauperleffekt',
    'audi-a5cabriolet-3242-mythosschwarzmetallic',
    'polestar-2-3103-snow',
    'tesla-modely-2421-solidblack',
    'audi-sq8-3307-mythosschwarzmetallic',
    'vw-arteonshootingbrake-3019-lapizbluemetallic',
    'audi-a3limousine-2787-tangorotmetallic',
    'nissan-qashqai-3356-black',
    'kia-ceed-2642-zilinaschwarz',
    'audi-q8-2269-mythosschwarzmetallic',
    'opel-corsa-3209-kardiorot',
    'audi-a5cabriolet-3251-gletscherweissmetallic',
    'nissan-qashqai-3351-black',
    'audi-rs5sportback-3117-mythosschwarzmetallic',
    'opel-grandland-3217-quarzsilber',
    'bmw-ix-3319-bmwindividualstormbaymetallic',
    'audi-a3sportback-3256-turboblau',
    'audi-q3sportback-2508-mythosschwarzmetallic',
    'opel-grandland-3217-diamantschwarz',
    'bmw-x1-2717-saphirschwarzmetallic',
    'tesla-model3-2417-midnightsilvermetallic',
    'audi-q3sportback-2505-daytonagrauperleffekt',
    'opel-corsa-3209-kristallsilber',
    'audi-sq7-3224-mythosschwarzmetallic',
    'audi-a6allroadquattro-3047-manhattangraumetallic',
    'audi-q3-2501-daytonagrauperleffekt',
    'audi-q8-2263-nardograuuni',
    'audi-q5sportback-2981-gletscherweissmetallic',
    'bmw-ix3-3359-mcarbonschwarzmetallic',
    'nissan-qashqai-3356-blackcontrastdarkgrey',
    'opel-grandland-3218-jadeweiss',
    'opel-grandland-3218-diamantschwarz',
    'nissan-qashqai-3356-silver',
    'bmw-x4-2702-alpinweissuni',
    'audi-sq5sportback-2475-mythosschwarzmetallic',
    'audi-q5sportback-2933-florettsilbermetallic',
    'polestar-2-3102-space',
    'audi-a6avant-3246-gletscherweissmetallic',
    'tesla-model3-2682-deepbluemetallic',
    'audi-s8-3225-mythosschwarzmetallic',
    'audi-q5sportback-2980-daytonagrauperleffekt',
    'mg-marvelr-3207-pebbleblack',
    'bmw-2ergrancoupe-3161-alpinweissuni',
    'landrover-defender110-3321-carpathiangrey',
    'audi-q3sportback-3282-daytonagrauperleffekt',
    'opel-corsa-3211-kardiorot',
    'bmw-3ertouringm-3155-mbrooklyngraumetallic',
    'mg-4-3371-brightonblue',
    'audi-sq7-3175-gletscherweissmetallic',
    'bmw-ix-3318-bmwoxidgraumetallic',
    'opel-corsa-3195-kardiorot',
    'nissan-qashqai-3353-burgundy',
    'audi-sq8-3305-daytonagrauperleffekt',
    'bmw-3ertouring-3098-alpinweissuni',
    'audi-rs7sportback-3127-gletscherweissmetallic',
    'nissan-qashqai-3354-ceramicgrey',
    'nissan-qashqai-3356-ceramicgrey',
    'audi-sq5sportback-2999-daytonagrauperleffekt',
    'opel-mokka-3216-karbonschwarz',
    'bmw-1erm-3154-mmisanoblaumetallic',
    'audi-a3sportback-3256-mythosschwarzmetallic',
    'opel-corsa-3211-arktisweiss',
    'audi-a5sportback-3252-mythosschwarzmetallic',
    'opel-mokka-3216-arktisweiss',
    'nissan-qashqai-3354-black',
    'audi-q8-2263-mythosschwarzmetallic',
    'nissan-qashqai-3351-burgundy',
    'audi-sq7-3300-daytonagrauperleffekt',
    'bmw-2ergrancoupem-3162-alpinweissuni',
    'bmw-2eractivetourer-3157-saphirschwarzmetallic',
    'nissan-qashqai-3353-black',
    'audi-s3sportback-3253-turboblau',
    'nissan-juke-3343-ceramicgrey',
    'audi-a3limousine-2784-mythosschwarzmetallic',
    'opel-corsa-2728-kristallsilber',
    'landrover-discovery-3324-santoriniblack',
    'audi-rs5sportback-3112-mythosschwarzmetallic',
    'audi-rs5sportback-3053-tangorotmetallic',
    'fiat-500-2938-taugruen',
    'audi-q3sportback-2341-daytonagrauperleffekt',
    'audi-rsetrongt-3052-suzukagraumetallic',
    'audi-a6avant-3244-daytonagrauperleffekt',
    'audi-q8-2266-mythosschwarzmetallic',
    'landrover-rangeroverevoque-2868-fujiwhite',
    'audi-s5cabriolet-3172-daytonagrauperleffekt',
    'audi-rsq3-3054-gletscherweissmetallic',
    'landrover-discoverysport-2668-santoriniblack',
    'nissan-qashqai-3352-magneticbluecontrastblack',
    'audi-rs5coupe-3124-mythosschwarzmetallic',
    'kia-picanto-2636-astrograu',
    'audi-q3sportback-3287-mythosschwarzmetallic',
    'audi-rs5coupe-3124-gletscherweissmetallic',
    'audi-q3-3274-chronosgraumetallic',
    'opel-mokkae-2249-powerrot',
    'bmw-ix-3317-mineralweissmetallic',
    'nissan-qashqai-2818-darkgreymetallic',
    'audi-q5sportback-2998-mythosschwarzmetallic',
    'audi-a3sportback-3254-gletscherweissmetallic',
    'audi-q5sportback-3042-mythosschwarzmetallic',
    'audi-q3sportback-3290-gletscherweissmetallic',
    'audi-rs6avant-3257-daytonagrauperleffekt',
    'landrover-discovery-3323-santoriniblack',
    'audi-etronsportback-2326-manhattangraumetallic',
    'fiat-500c-2939-taugruen',
    'audi-rs6avant-3257-mythosschwarzmetallic',
    'tesla-model3-2682-solidblack',
    'landrover-rangeroverevoque-2672-fujiwhite',
    'audi-a3limousine-2789-mythosschwarzmetallic',
    'bmw-3ertouring-3264-saphirschwarzmetallic',
    'audi-sq7-3224-nardograuuni',
    'vw-arteonshootingbrake-3019-mangangraumetallic',
    'audi-a4avant-3099-daytonagrauperleffekt',
    'landrover-discovery-3107-eigergrey',
    'audi-q3sportback-2506-mythosschwarzmetallic',
    'fiat-500-2938-vesuvioschwarz',
    'nissan-qashqai-3352-silver',
    'opel-corsa-3209-voltaikblau',
    'opel-corsa-3211-karbonschwarz',
    'opel-mokka-3215-quarzsilber',
    'nissan-juke-3345-blackcontrastsilver',
    'audi-rs5sportback-3126-tangorotmetallic',
    'audi-q3sportback-3291-daytonagrauperleffekt',
    'kia-xceed-2646-zilinaschwarz',
    'polestar-2-3100-snow',
    'audi-q3sportback-2967-mythosschwarzmetallic',
    'audi-rsq3sportback-3122-mythosschwarzmetallic',
    'audi-q3sportback-3291-gletscherweissmetallic',
    'audi-q3sportback-2508-chronosgraumetallic',
    'audi-a5cabriolet-3250-daytonagrauperleffekt',
    'nissan-qashqai-3354-pearlwhitecontrastblack',
    'audi-q3sportback-3285-daytonagrauperleffekt',
    'audi-sq8-3305-mythosschwarzmetallic',
    'fiat-500-2938-gelatoweiss',
    'fiat-500c-2939-pompeigrau',
    'opel-corsa-3213-karbonschwarz',
    'audi-q3-3199-daytonagrauperleffekt',
    'audi-q2-2827-pfeilgrauperleffekt',
    'audi-rs6avant-3258-daytonagrauperleffekt',
    'bmw-3ertouring-3266-mbrooklyngraumetallic',
    'audi-rs5sportback-3053-mythosschwarzmetallic',
    'audi-a5sportback-3239-daytonagrauperleffekt',
    'audi-q3sportback-2967-nardograuuni',
    'audi-q3sportback-2339-nardograuuni',
    'opel-insigniast-1249-argonsilber',
    'audi-a5cabriolet-3297-chronosgraumetallic',
    'nissan-qashqai-2819-ceramicgreycontrastblack',
    'opel-corsa-3209-arktisweiss',
    'landrover-rangeroverevoque-2673-fujiwhite',
    'bmw-x5-3334-alpinweissuni',
    'nissan-qashqai-3348-black',
    'kia-picanto-2633-auroraschwarz',
    'opel-corsa-3213-kardiorot',
    'bmw-ix3-3359-sophistograubrillanteffektmetallic',
    'audi-s5cabriolet-3171-daytonagrauperleffekt',
    'opel-insigniast-2745-argonsilber',
    'audi-a3limousine-2787-navarrablaumetallic',
    'audi-q8-2269-daytonagrauperleffekt',
    'audi-rsq3sportback-3055-daytonagrauperleffekt',
    'audi-q8-2274-mythosschwarzmetallic',
    'opel-corsa-3211-kristallsilber',
    'audi-rs6avant-3231-mythosschwarzmetallic',
    'audi-rsq3-3054-daytonagrauperleffekt',
    'nissan-qashqai-3352-darkgrey',
    'audi-s5cabriolet-3171-gletscherweissmetallic',
    'audi-rs7sportback-3056-mythosschwarzmetallic',
    'audi-rsq3sportback-3121-mythosschwarzmetallic',
    'kia-picanto-2635-schneeweiss',
    'nissan-qashqai-3355-darkgrey',
    'audi-sq5-2474-mythosschwarzmetallic',
    'bmw-3ertouring-3265-mineralweissmetallic',
    'opel-mokka-3215-karbonschwarz',
    'audi-a3sportback-3255-mythosschwarzmetallic',
    'tesla-model3-2412-midnightsilvermetallic',
    'audi-rsetrongt-3130-suzukagraumetallic',
    'landrover-rangeroverevoque-3063-santoriniblack',
    'audi-q8-2274-carraraweiss',
    'audi-a5cabriolet-3234-mythosschwarzmetallic',
    'nissan-qashqai-3350-ceramicgreycontrastblack',
    'fiat-500-2294-gelatoweiss',
    'opel-crossland-2736-mondsteingrau',
    'audi-a5cabriolet-3296-gletscherweissmetallic',
    'opel-corsa-3214-arktisweiss',
    'audi-rsq3sportback-3120-nardograuuni',
    'audi-q3sportback-3289-daytonagrauperleffekt',
    'nissan-qashqai-3353-pearlwhite',
    'tesla-model3-2415-midnightsilvermetallic',
    'audi-rsq3sportback-3128-gletscherweissmetallic',
    'audi-a3limousine-2787-gletscherweissmetallic',
    'audi-a4limousine-3222-daytonagrauperleffekt',
    'audi-a5cabriolet-3296-mythosschwarzmetallic',
    'audi-a3limousine-2788-navarrablaumetallic',
    'cupra-formentor-3338-petrolblaumatt',
    'opel-corsae-2764-perlblau',
    'kia-picanto-2634-auroraschwarz',
    'audi-rsetrongt-3132-daytonagrauperleffekt',
    'tesla-model3-3092-midnightsilvermetallic',
    'jeep-compass-2904-alpinewhite',
    'nissan-qashqai-3339-blackcontrastdarkgrey',
    'audi-q5sportback-2983-gletscherweissmetallic',
    'bmw-x4-3342-saphirschwarzmetallic',
    'nissan-juke-3344-blackcontrastsilver',
    'audi-rsq3-3054-mythosschwarzmetallic',
    'audi-rsq3sportback-3113-kayalamigrünuni',
    'nissan-qashqai-3355-blackcontrastdarkgrey',
    'mg-marvelr-3207-betongrey',
    'nissan-qashqai-3354-magneticbluecontrastblack',
    'nissan-juke-3343-fujisunsetredcontrastblack',
    'audi-a5cabriolet-3298-mythosschwarzmetallic',
    'audi-q3sportback-2473-nardograuuni',
    'audi-q5sportback-2932-mythosschwarzmetallic',
    'audi-q3sportback-2372-gletscherweissmetallic',
    'kia-ceed-2642-lunarsilber',
    'audi-a3sportback-3255-turboblau',
    'bmw-3ertouring-3142-alpinweissuni',
    'audi-a3limousine-2788-gletscherweissmetallic',
    'audi-q3sportback-2374-mythosschwarzmetallic',
    'nissan-juke-3345-fujisunsetredcontrastblack',
    'nissan-qashqai-3351-pearlwhite',
    'audi-sq7-3175-mythosschwarzmetallic',
    'audi-sq8-2973-nardograuuni',
    'audi-e-tronsportback-34-mythosschwarzmetallic',
    'audi-sq5-2474-daytonagrauperleffekt',
    'audi-a5sportback-3295-mythosschwarzmetallic',
    'mg-4-3371-pebbleblack',
    'opel-mokkae-2249-jadeweiss',
    'opel-mokka-3194-ikonegruen',
    'landrover-rangeroverevoque-3182-fujiwhite',
    'vw-passatvariant-2404-pyritsilbermetallic',
    'bmw-3ertouring-3148-saphirschwarzmetallic',
    'kia-picanto-2633-astrograu',
    'opel-corsa-3212-arktisweiss',
    'nissan-qashqai-3339-ceramicgrey',
    'opel-mokka-3215-ikonegruen',
    'nissan-leaf-2821-blackmetallic',
    'opel-grandland-3320-jadeweiss',
    'bmw-ix-3317-blueridgemountainmetallic',
    'bmw-x2-3179-alpinweissuni',
    'bmw-3ertouring-3266-mineralweissmetallic',
    'nissan-qashqai-2819-blackmetallic',
    'opel-corsa-3195-arktisweiss',
    'audi-q2-2827-mythosschwarzmetallic',
    'audi-a8l-3226-mythosschwarzmetallic',
    'audi-sq7-3175-daytonagrauperleffekt',
    'audi-rsq3sportback-3129-turboblauuni',
    'audi-rs6avant-3257-gletscherweissmetallic',
    'audi-q5sportback-2932-gletscherweissmetallic',
    'audi-a6limousine-3049-ibisweiss',
    'bmw-x5-3335-alpinweissuni',
    'audi-rsq3sportback-3110-mythosschwarzmetallic',
    'audi-q8-2266-daytonagrauperleffekt',
    'opel-corsa-3213-arktisweiss',
    'opel-grandland-2735-rubinrot',
    'audi-a4avant-3198-mythosschwarzmetallic',
    'landrover-rangeroverevoque-3063-eigergrey',
    'nissan-townstar-3085-blackmetallic',
    'opel-corsae-2764-quarzsilber',
    'polestar-2-3102-snow',
    'bmw-2ercoupem-3190-saphirschwarzmetallic',
    'nissan-qashqai-2817-blackmetallic',
    'audi-a3sportback-3254-mythosschwarzmetallic',
    'nissan-qashqai-3348-magneticbluecontrastblack',
    'nissan-qashqai-3339-black',
    'audi-q3sportback-3287-gletscherweissmetallic',
    'opel-grandland-2735-vertigoblau',
    'renault-captur-3150-ironblau',
    'bmw-x5-3333-alpinweissuni',
    'nissan-qashqai-2819-ceramicgrey',
    'audi-sq7-3300-mythosschwarzmetallic',
    'audi-a5cabriolet-3238-mythosschwarzmetallic',
    'audi-a5cabriolet-3251-tangorotmetallic',
    'audi-sq7-3175-navarrablaumetallic',
    'opel-corsa-3211-voltaikblau',
    'audi-a6avant-3245-mythosschwarzmetallic',
    'audi-q3sportback-3291-tausilbermetallic',
    'audi-s5cabriolet-3172-gletscherweissmetallic',
    'audi-rsq3sportback-3114-mythosschwarzmetallic',
    'audi-a4avant-3099-gletscherweissmetallic',
    'nissan-qashqai-3356-burgundy',
    'audi-sq8-2973-mythosschwarzmetallic',
    'tesla-model3-3092-pearlwhite',
    'audi-a3sportback-3254-daytonagrauperleffekt',
    'hyundai-tucson-2334-sunsetred',
    'audi-a5cabriolet-3242-daytonagrauperleffekt',
    'bmw-5erlimousine-3030-saphirschwarzmetallic',
    'bmw-1er-3196-alpinweissuni',
    'audi-a6avant-3244-mythosschwarzmetallic',
    'nissan-qashqai-3350-pearlwhitecontrastblack',
    'kia-niro-3364-snowwhitepearl',
    'vw-golfvariant-2386-uranograu',
    'opel-corsa-3213-kontrastgrau',
    'bmw-3ertouring-3264-mportimaoblaumetallic',
    'tesla-model3-2417-solidblack',
    'nissan-leaf-2821-ceramicgreycontrastblack',
    'audi-s5cabriolet-3171-mythosschwarzmetallic',
    'audi-q8-2265-mythosschwarzmetallic',
    'audi-q3sportback-3291-navarrablaumetallic',
    'audi-q3-3199-mythosschwarzmetallic',
    'audi-q8-2271-nardograuuni',
    'kia-rio-2637-schneeweiss',
    'audi-q3sportback-3282-mythosschwarzmetallic',
    'audi-q3-2476-gletscherweissmetallic',
    'bmw-3ertouringm-3155-bmwindividualtansanitblaumetallic',
    'audi-q3sportback-3279-nardograuuni',
    'nissan-qashqai-3352-blackcontrastdarkgrey',
    'audi-q5sportback-3042-daytonagrauperleffekt',
    'nissan-qashqai-3355-pearlwhite',
    'audi-s3sportback-3253-tangorotmetallic',
    'opel-grandland-2735-diamantschwarz',
    'hyundai-tucson-2335-sunsetred',
    'audi-rs5sportback-3126-mythosschwarzmetallic',
    'nissan-qashqai-3353-pearlwhitecontrastblack',
    'audi-a3limousine-2786-mythosschwarzmetallic',
    'bmw-ix-3329-mineralweissmetallic',
    'tesla-model3-2684-midnightsilvermetallic',
    'bmw-3ertouring-3266-saphirschwarzmetallic',
    'audi-sq7-3302-mythosschwarzmetallic',
    'audi-sq7-3301-mythosschwarzmetallic',
    'opel-mokkae-2249-perlblau',
    'fiat-500c-2939-dipintodiblublau',
    'audi-q3-2476-daytonagrauperleffekt',
    'landrover-rangeroverevoque-3366-fujiwhite',
    'dacia-spring-2038-lightning-grau',
    'tesla-model3-2415-deepbluemetallic',
    'audi-a4limousine-3249-mythosschwarzmetallic',
    'bmw-3ertouring-3266-mportimaoblaumetallic',
    'nissan-juke-3343-magneticblue',
    'audi-rsetrongt-3131-kemoragraumetallic',
    'nissan-qashqai-3354-blackcontrastdarkgrey',
    'audi-q5sportback-2933-mythosschwarzmetallic',
    'audi-a4avant-3247-manhattangraumetallic',
    'audi-rs7sportback-3111-daytonagrauperleffekt',
    'tesla-model3-2416-pearlwhite',
    'audi-rsq3sportback-3128-kayalamigrünuni',
    'bmw-3ertouring-3264-mineralweissmetallic',
    'audi-a5sportback-3240-daytonagrauperleffekt',
    'audi-rs6avant-3174-ultrablaumetallic',
    'audi-a5cabriolet-3250-tangorotmetallic',
    'nissan-qashqai-3355-black',
    'polestar-2-3104-magnesium',
    'audi-q2-2826-gletscherweissmetallic',
    'nissan-qashqai-3339-fujisunsetredcontrastblack',
    'audi-rs7sportback-3127-daytonagrauperleffekt',
    'opel-corsae-2764-diamantschwarz',
    'audi-rsq3sportback-3128-turboblauuni',
    'audi-q3-3274-navarrablaumetallic',
    'audi-q5sportback-2932-ultrablaumetallic',
    'cupra-formentor-3337-midnightschwarzmetallic',
    'nissan-qashqai-3349-black',
    'nissan-qashqai-3349-pearlwhitecontrastblack',
    'audi-a5cabriolet-3251-daytonagrauperleffekt',
    'bmw-2eractivetourer-3157-skyscrapergraumetallic',
    'audi-a3limousine-2788-mythosschwarzmetallic',
    'audi-a6avant-3243-daytonagrauperleffekt',
    'landrover-rangeroverevoque-2671-fujiwhite',
    'tesla-model3-3092-deepbluemetallic',
    'audi-a6avant-3245-daytonagrauperleffekt',
    'jeep-compass-1694-alpinewhiteuni',
    'bmw-ix-3318-mineralweissmetallic',
    'audi-s3sportback-3253-daytonagrauperleffekt',
    'audi-q3sportback-3286-mythosschwarzmetallic',
    'audi-a3limousine-2776-mythosschwarzmetallic',
    'audi-q3sportback-2510-daytonagrauperleffekt',
    'audi-rs5sportback-3126-gletscherweissmetallic',
    'vw-arteonshootingbrake-3019-pyritsilbermetallic',
    'audi-q5sportback-2984-daytonagrauperleffekt',
    'tesla-model3-2682-pearlwhite',
    'opel-corsa-2728-jadeweiss',
    'audi-a4avant-3248-mythosschwarzmetallic',
    'audi-q2-2827-manhattangraumetallic',
    'nissan-leaf-2821-pearlwhitecontrastblack',
    'bmw-ix-3319-saphirschwarzmetallic',
    'audi-s5cabriolet-3171-tangorotmetallic',
    'opel-mokkae-2098-jadeweiss',
    'audi-q3-3273-mythosschwarzmetallic',
    'audi-a3limousine-2776-daytonagrauperleffekt',
    'audi-s8-3225-ultrablaumetallic',
    'audi-q3sportback-3200-daytonagrauperleffekt',
    'opel-grandland-3217-jadeweiss',
    'fiat-500c-2939-gelatoweiss',
    'opel-mokkae-3373-voltaikblau',
    'audi-a5cabriolet-3297-ibisweiss',
    'audi-rs7sportback-3056-nardograuuni',
    'nissan-qashqai-3350-silver',
    'nissan-leaf-2821-darkgreymetallic',
    'bmw-1er-3189-mmisanoblaumetallic',
    'audi-q2-2826-mythosschwarzmetallic',
    'audi-q5sportback-3042-ibisweiss',
    'audi-q5sportback-2933-daytonagrauperleffekt',
    'audi-q3sportback-2349-mythosschwarzmetallic',
    'nissan-juke-3344-ceramicgreycontrastblack',
    'opel-corsae-2764-jadeweiss',
    'opel-corsa-3214-kardiorot',
    'audi-a5cabriolet-3250-distriktgruenmetallic',
    'audi-q3sportback-3290-navarrablaumetallic',
    'audi-q3sportback-2374-nardograuuni',
    'kia-ceed-2642-carraraweiss',
    'opel-crossland-2736-quarzsilber',
    'nissan-juke-3345-burgundy',
    'nissan-qashqai-3349-blackcontrastdarkgrey',
    'nissan-qashqai-3356-darkgrey',
    'audi-a6allroadquattro-3050-brillantschwarz',
    'audi-rs7sportback-3111-mythosschwarzmetallic',
    'nissan-qashqai-3354-darkgrey',
    'opel-mokka-3215-jadeweiss',
    'audi-q5sportback-2981-mythosschwarzmetallic',
    'nissan-qashqai-3354-ceramicgreycontrastblack',
    'audi-q8-2263-daytonagrauperleffekt',
    'opel-corsa-3195-dynamikorange',
    'audi-rsq3sportback-3114-nardograuuni',
    'audi-a5cabriolet-3250-mythosschwarzmetallic',
    'audi-q5sportback-2933-ibisweiss',
    'audi-s3sportback-3253-mythosschwarzmetallic',
    'seat-alhambra-2304-deepschwarzmetallic',
    'vw-arteonshootingbrake-3019-deepblackperleffekt',
    'nissan-qashqai-3349-burgundy',
    'audi-q3sportback-2478-gletscherweissmetallic',
    'audi-a6avant-3244-gletscherweissmetallic',
    'audi-q3sportback-2343-daytonagrauperleffekt',
    'landrover-defender110-3322-santoriniblack',
    'opel-insigniast-2745-diamantschwarz',
    'audi-sq7-3224-daytonagrauperleffekt',
    'nissan-juke-3345-darkgreycontrastblack',
    'nissan-qashqai-3355-fujisunsetredcontrastblack',
    'audi-a5sportback-3239-mythosschwarzmetallic',
    'nissan-qashqai-3349-ceramicgrey',
    'landrover-rangeroverevoque-2671-eigergrey',
    'fiat-500-2938-dipintodiblublau',
    'audi-q3-2361-mythosschwarzmetallic',
    'landrover-rangeroverevoque-2671-santoriniblack',
    'audi-a6avant-3246-daytonagrauperleffekt',
    'opel-corsa-3212-kardiorot',
    'opel-corsa-2728-powerorange',
    'tesla-model3-2682-midnightsilvermetallic',
    'tesla-model3-2414-deepbluemetallic',
    'mg-4-3371-medalsilver',
    'bmw-x3-3438-mineralweissmetallic',
    'audi-a3limousine-2788-tangorotmetallic',
    'tesla-model3-2417-deepbluemetallic',
    'kia-ceed-2643-carraraweiss',
    'audi-a6avant-3245-gletscherweissmetallic',
    'nissan-qashqai-3348-fujisunsetredcontrastblack',
    'audi-q3sportback-3287-tausilbermetallic',
    'audi-a3sportback-3256-daytonagrauperleffekt',
    'bmw-3ertouring-3264-skyscrapergraumetallic',
    'bmw-3ertouring-3265-saphirschwarzmetallic',
    'fiat-500-1696-carraragrauuni',
    'audi-a3limousine-2788-daytonagrauperleffekt',
    'opel-corsae-2764-chilirot',
    'opel-corsae-2255-perlblau',
    'opel-insignia-2259-diamantschwarz',
    'mg-4-3371-doverwhite',
    'nissan-qashqai-3339-magneticbluecontrastblack',
    'landrover-rangeroverevoque-2673-eigergrey',
    'kia-xceed-2647-zilinaschwarz',
    'audi-a5cabriolet-3251-mythosschwarzmetallic',
    'audi-q3sportback-2508-daytonagrauperleffekt',
    'audi-a5sportback-3252-gletscherweissmetallic',
    'opel-corsa-3209-karbonschwarz',
    'audi-rs6avant-3257-nardograuuni',
    'nissan-townstar-3085-urbangrey',
    'audi-q8-3304-mythosschwarzmetallic',
    'nissan-qashqai-2818-silvermetallic',
    'nissan-qashqai-3353-blackcontrastdarkgrey',
    'audi-q3sportback-2479-chronosgraumetallic',
    'nissan-qashqai-3351-darkgrey',
    'kia-rio-2638-schneeweiss',
    'bmw-3ertouring-3265-mportimaoblaumetallic',
    'nissan-qashqai-3350-black',
    'nissan-juke-3343-burgundy',
    'nissan-qashqai-3350-fujisunsetredcontrastblack',
    'audi-rs5coupe-3115-mythosschwarzmetallic',
    'audi-a4avant-3248-navarrablaumetallic',
    'audi-q3sportback-2507-mythosschwarzmetallic',
    'audi-q3sportback-2503-daytonagrauperleffekt',
    'bmw-3erlimousine-3146-saphirschwarzmetallic',
    'opel-grandland-3320-quarzsilber',
    'audi-a4limousine-3222-ibisweiss',
    'audi-a4avant-3248-daytonagrauperleffekt',
    'audi-rsq3sportback-3128-daytonagrauperleffekt',
    'opel-corsa-2728-perlblau',
    'audi-q3sportback-3290-mythosschwarzmetallic',
    'fiat-500-609-vesuvioschwarz',
    'audi-q3sportback-2371-mythosschwarzmetallic',
    'audi-a4avant-3247-navarrablaumetallic',
    'audi-q3sportback-2503-mythosschwarzmetallic',
    'audi-a5cabriolet-3251-distriktgruenmetallic',
    'nissan-juke-3345-magneticblue',
    'audi-rs4avant-3230-mythosschwarzmetallic',
    'audi-q8-2274-daytonagrauperleffekt',
    'nissan-juke-3344-burgundycontrastblack',
    'nissan-qashqai-3352-pearlwhite',
    'bmw-ix-3347-alpinweissuni',
    'nissan-qashqai-3350-darkgrey',
    'kia-xceed-2647-lunarsilber',
    'bmw-ix-3318-saphirschwarzmetallic',
    'audi-q3sportback-2480-mythosschwarzmetallic',
    'vw-golfvariant-2386-deepblackperleffekt',
    'audi-a3sportback-3255-daytonagrauperleffekt',
    'audi-q3sportback-3285-mythosschwarzmetallic',
    'audi-a4avant-3247-mythosschwarzmetallic',
    'nissan-qashqai-3356-pearlwhitecontrastblack',
    'toyota-aygox-3336-cardamongreenmetallic',
    'audi-rsetrongt-3132-mythosschwarzmetallic',
    'audi-rs5coupe-3124-navarrablaumetallic',
    'audi-q5sportback-2980-ibisweiss',
    'nissan-qashqai-3339-pearlwhite',
    'audi-a3limousine-2784-daytonagrauperleffekt',
    'bmw-1er-3189-melbournerotmetallic',
    'bmw-ix-3316-alpinweissuni',
    'audi-rsetrongt-3132-suzukagraumetallic',
    'audi-a6allroadquattro-3051-gletscherweissmetallic',
    'audi-q5sportback-2981-daytonagrauperleffekt',
    'nissan-qashqai-3356-fujisunsetredcontrastblack',
    'mg-4-3371-fizzyorange',
    'opel-crossland-2736-diamantschwarz',
    'audi-a4avant-3198-daytonagrauperleffekt',
    'nissan-qashqai-2818-ceramicgrey',
    'audi-a6avant-3243-gletscherweissmetallic',
    'mg-marvelr-3207-prismblue',
    'audi-q3sportback-3232-mythosschwarzmetallic',
    'toyota-aygox-3336-gingerbeigemetallic',
    'audi-s5cabriolet-3172-mythosschwarzmetallic',
    'landrover-discovery-3107-santoriniblack',
    'audi-q5sportback-3041-daytonagrauperleffekt',
    'audi-q3-3280-mythosschwarzmetallic',
    'bmw-ix-3316-saphirschwarzmetallic',
    'kia-picanto-2633-schneeweiss',
    'audi-rsetrongt-3131-mythosschwarzmetallic',
    'audi-q5sportback-2933-gletscherweissmetallic',
    'nissan-leaf-2528-ceramicgrey',
    'audi-a3sportback-3255-gletscherweissmetallic',
    'audi-rs5sportback-3053-daytonagrauperleffekt',
    'audi-a4avant-3223-mythosschwarzmetallic',
    'nissan-qashqai-2447-darkgreymetallic',
    'tesla-model3-2409-pearlwhite',
    'audi-q3sportback-2510-gletscherweissmetallic',
    'polestar-2-3103-space',
    'nissan-qashqai-3353-magneticbluecontrastblack',
    'opel-corsa-2728-chilirot',
    'audi-rs6avant-3173-mythosschwarzmetallic',
    'audi-rs5sportback-3116-mythosschwarzmetallic',
    'nissan-qashqai-3339-ceramicgreycontrastblack',
    'audi-q2-2827-gletscherweissmetallic',
    'nissan-leaf-2528-pearlwhiteperleffekt',
    'nissan-qashqai-3339-pearlwhitecontrastblack',
    'audi-sq8-3306-mythosschwarzmetallic',
    'audi-rs6avant-3173-daytonagrauperleffekt',
    'fiat-500c-2939-vesuvioschwarz',
    'nissan-qashqai-2818-pearlwhitecontrastblack',
    'audi-sq5sportback-3000-mythosschwarzmetallic',
    'kia-ceed-2643-zilinaschwarz',
    'opel-mokkae-2249-quarzsilber',
    'audi-s5cabriolet-3172-navarrablaumetallic',
    'audi-q3sportback-2339-mythosschwarzmetallic',
    'tesla-model3-2415-solidblack',
    'fiat-500-2938-pompeigrau',
    'landrover-discoverysport-3325-fujiwhite',
    'audi-sq5-2514-gletscherweissmetallic',
    'audi-q3-2502-mythosschwarzmetallic',
    'landrover-rangeroverevoque-2673-santoriniblack',
    'nissan-qashqai-2820-ceramicgreycontrastblack',
    'audi-rsq3sportback-3110-nardograuuni',
    'audi-a4avant-3223-navarrablaumetallic',
    'audi-q3-2476-mythosschwarzmetallic',
    'audi-a3sportback-3256-gletscherweissmetallic',
    'nissan-qashqai-3354-burgundy',
    'nissan-juke-3344-fujisunsetredcontrastblack',
    'audi-sq8-2974-nardograuuni',
    'audi-a5sportback-3292-mythosschwarzmetallic',
    'audi-q5sportback-2982-mythosschwarzmetallic',
    'landrover-discovery-3107-fujiwhite',
    'audi-q8-2265-carraraweiss',
    'audi-a5sportback-3293-daytonagrauperleffekt',
    'vw-golfvariant-2386-reflexsilbermetallic',
    'audi-q5sportback-2933-ultrablaumetallic',
    'audi-sq7-3299-mythosschwarzmetallic',
    'audi-q5sportback-2932-florettsilbermetallic',
    'landrover-rangeroverevoque-3062-santoriniblack',
    'audi-rs5sportback-3126-daytonagrauperleffekt',
    'landrover-rangeroverevoque-2672-eigergrey',
    'audi-q3sportback-3278-mythosschwarzmetallic',
    'nissan-primastar-2705-urbangreymetallic',
    'audi-q3sportback-2963-mythosschwarzmetallic',
    'audi-q3sportback-2338-mythosschwarzmetallic',
    'polestar-2-3101-magnesium',
    'nissan-qashqai-2819-darkgreymetallic',
    'audi-q3sportback-2374-daytonagrauperleffekt',
    'kia-ceed-2641-carraraweiss',
    'nissan-qashqai-3349-darkgrey',
    'cupra-formentor-3337-magneticgraumatt',
    'audi-q3sportback-3282-nardograuuni',
    'nissan-primastar-2706-midnightblackmetallic',
    'audi-a7sportback-2348-mythosschwarzmetallic',
    'audi-rs6avant-3258-nardograuuni',
    'nissan-qashqai-3351-ceramicgrey',
    'audi-q3sportback-2478-mythosschwarzmetallic',
    'audi-rsetrongt-3131-ascariblaumetallic',
    'audi-rsetrongt-3131-tangorotmetallic',
    'nissan-qashqai-3356-magneticbluecontrastblack',
    'audi-rsetrongt-3131-suzukagraumetallic',
    'audi-q3sportback-2962-mythosschwarzmetallic',
    'landrover-rangeroverevoque-2672-santoriniblack',
    'audi-a4avant-3099-mythosschwarzmetallic',
    'vw-golfvariant-2386-purewhite',
    'nissan-qashqai-2819-silvermetallic',
    'audi-q3sportback-3288-gletscherweissmetallic',
    'landrover-rangeroverevoque-3433-fujiwhite',
    'nissan-leaf-2821-arcticsolidwhite',
    'nissan-qashqai-3350-magneticbluecontrastblack',
    'audi-sq5sportback-2475-nardograuuni',
    'nissan-qashqai-3353-silver',
    'opel-grandland-3320-diamantschwarz',
    'kia-picanto-2635-auroraschwarz',
    'nissan-juke-3343-black',
    'audi-s5cabriolet-3172-tangorotmetallic',
    'nissan-qashqai-3352-ceramicgrey',
    'audi-q3sportback-2510-tangorotmetallic',
    'audi-sq5-3001-daytonagrauperleffekt',
    'bmw-x2-3178-saphirschwarzmetallic',
    'audi-a6avant-3243-mythosschwarzmetallic',
    'audi-q2-2826-daytonagrauperleffekt',
    'opel-mokka-3215-voltaikblau',
    'audi-a5cabriolet-3250-gletscherweissmetallic',
    'audi-a6avant-3246-mythosschwarzmetallic',
    'audi-q3sportback-3291-mythosschwarzmetallic',
    'opel-crossland-2736-chilirot',
    'audi-a4avant-3248-gletscherweissmetallic',
    'audi-q3sportback-3289-mythosschwarzmetallic',
    'audi-s3sportback-3253-gletscherweissmetallic',
    'audi-rs6avant-3173-gletscherweissmetallic',
    'opel-corsa-3195-kristallsilber',
    'audi-a5sportback-3240-mythosschwarzmetallic',
    'audi-q3sportback-2970-nardograuuni',
    'nissan-qashqai-3350-burgundy',
    'audi-q8-3303-mythosschwarzmetallic',
    'tesla-model3-2412-deepbluemetallic',
    'audi-a4limousine-3249-daytonagrauperleffekt',
    'kia-rio-2638-perennialgrau',
    'audi-a5cabriolet-3241-mythosschwarzmetallic',
    'audi-rs7sportback-3118-mythosschwarzmetallic',
    'nissan-qashqai-2818-blackmetallic',
    'nissan-qashqai-3355-ceramicgrey',
    'opel-grandland-3217-vertigoblau',
    'hyundai-tucson-2335-phantomblack',
    'audi-rsq3sportback-3129-daytonagrauperleffekt',
    'nissan-qashqai-2439-blackmetallic',
    'audi-q3sportback-2968-mythosschwarzmetallic',
    'audi-q3sportback-2505-chronosgraumetallic',
    'audi-q3sportback-2481-daytonagrauperleffekt',
    'audi-rs6avant-3231-nardograuuni',
    'nissan-qashqai-3353-fujisunsetredcontrastblack',
  ],
  terms: [1, 6, 9, 12, 13, 18, 24],
  total_results: 331,
};
