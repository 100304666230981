import { CookieKeys, sha256 } from '@finn/ui-utils';
import cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { SetStateAction, useEffect, useState } from 'react';

const SavedPassHash =
  '8075cc17eaf2b1fd73e527dcdb3e914ac10f81f30e48a1dc32c7dfa87b6c3a1d';

export const useIsCheckoutHelperEnabled = () => {
  const [passHash, setPassHash] = useState('');
  const { query } = useRouter();

  useEffect(() => {
    const generateHash = async (val: string) => {
      const hash = await sha256(val);
      setPassHash(hash as SetStateAction<string>);
    };

    const queryValue = query[CookieKeys.CHECKOUT_HELPER];
    if (queryValue) {
      cookies.set(CookieKeys.CHECKOUT_HELPER, queryValue);
      generateHash(queryValue as string);
    }

    const featureValue = cookies.get(CookieKeys.CHECKOUT_HELPER);
    if (featureValue) {
      generateHash(featureValue);
    }
  }, []);

  return passHash === SavedPassHash;
};
