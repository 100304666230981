export * from '@finn/ua-tracking';
import { errorMessages, TEST_EMAIL_SUFFIX } from '@finn/ua-constants';
import {
  B2B_FORM_SUBMISSION_EVENT,
  B2B_LEAD_GEN_FORM_SUBMISSION_EVENT,
  CHATBOT_SESSION_STARTED,
  CONTACT_US_FORM_SUBMISSION_EVENT,
  CONTACT_US_PAGE,
  interactionTrackingEvent,
  trackingErrorHandler,
} from '@finn/ua-tracking';
import { ecommerceTrackingEvent, getEcommerceProduct } from '@finn/ua-vehicle';
import { config, toString, trackEvent } from '@finn/ui-utils';

import {
  EcommerceCheckout,
  EcommerceCheckoutStep,
  ReferralModalEvent,
  ReferralOptionEvent,
  TrackingEventName,
} from './tracking-types';

// no need to use this function on checkout flow after contact step
// as ajs_user_traits gets populated on contact submission, and we skip it anyway from the track function
export const skipTrackingTestEmail = (
  email: string,
  cb: () => void,
  fallback: () => void = () => {}
) => {
  const hostName = window.location.hostname;
  const isProduction =
    hostName === 'www.finn.auto' || hostName === 'www.finn.com';
  if (
    !!email &&
    (email === config.TRACKING_TEST_EMAIL ||
      !isProduction ||
      !email.includes(TEST_EMAIL_SUFFIX))
  ) {
    cb();
  } else {
    // intentional console log to see if a track call was skipped because of a test email
    console.log('Test email detected. Skipping a tracking call');
    fallback();
  }
};

export const checkoutErrorEvent = (
  message?: string,
  step?: string,
  error?: string | object,
  statusCode?: string | number,
  requestId?: string
) => {
  interactionTrackingEvent(TrackingEventName.CHECKOUT_ERROR, {
    step,
    label: message || errorMessages.SERVER_ERROR,
    error: toString(error),
    statusCode,
    requestId,
  });
};

export const referralModalTrackingEvent = <T>(
  eventName: TrackingEventName,
  properties: T
) => {
  trackingErrorHandler(() => {
    trackEvent(eventName, {
      ...properties,
    });
  });
};

export const triggerReferralTrackingEvent = ({
  eventName,
}: ReferralModalEvent) => {
  referralModalTrackingEvent(eventName, null);
};

export const triggerReferralOptionEvent = ({
  eventName,
  channel,
}: ReferralOptionEvent) => {
  referralModalTrackingEvent(eventName, { channel });
};

export const triggerEcommerceCheckoutStepEvent = ({
  eventName,
  ...properties
}: EcommerceCheckoutStep) => {
  ecommerceTrackingEvent(eventName, {
    ...properties,
    dealId: properties.checkout_id,
  });
};

// We need this to handle checkout complete for GTM as Segment destination
export const triggerEcommerceCheckoutCompleteEvent = ({
  vehicles,
  productInfo,
  ...rest
}: EcommerceCheckout) => {
  const products = vehicles.map((vehicle) =>
    getEcommerceProduct(vehicle, productInfo)
  );
  const price = rest.value;
  const tax = rest.tax;
  const revenue = price - tax;

  const properties = {
    ...rest,
    products,
    revenue,
    dealId: rest.order_id,
    tax,
  };
  ecommerceTrackingEvent(TrackingEventName.ORDER_COMPLETED, properties);
};

export const triggerB2BFormSubmissionEvent = (
  fleetSize: number,
  email: string
) => {
  interactionTrackingEvent(B2B_FORM_SUBMISSION_EVENT.name, {
    category: B2B_FORM_SUBMISSION_EVENT.category,
    action: B2B_FORM_SUBMISSION_EVENT.action,
    label: `Count of cars: ${fleetSize}`,
    email,
    value: fleetSize,
  });
};

export const triggerB2BLeadGenFormSubmissionEvent = (
  fleetSize: number,
  email: string
) => {
  interactionTrackingEvent(B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.name, {
    category: B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.category,
    action: B2B_LEAD_GEN_FORM_SUBMISSION_EVENT.action,
    label: `Count of cars: ${fleetSize}`,
    email,
    value: fleetSize,
  });
};

export const triggerContactFormSubmissionEvent = (
  email: string,
  requestSubject: string
) => {
  interactionTrackingEvent(CONTACT_US_FORM_SUBMISSION_EVENT.name, {
    category: CONTACT_US_FORM_SUBMISSION_EVENT.category,
    action: CONTACT_US_FORM_SUBMISSION_EVENT.action,
    label: `Contact Us Form Submitted`,
    email,
    request_subject: requestSubject,
  });
};

export const trackLogInBannerC2AClicked = () => {
  trackEvent(CONTACT_US_PAGE.LOGIN_BANNER_C2A_CLICKED, {});
};

export const trackRecommendedLinkClicked = (linkText: string) => {
  trackEvent(CONTACT_US_PAGE.RECOMMENDATION_LINK_CLICKED, {
    link_text: linkText,
  });
};

export const trackContactFormChatbotSessionStarted = (
  ultimateAiChatId?: string
) => {
  trackEvent(CONTACT_US_PAGE.CONTACT_FORM_CHATBOT_SESSION_STARTED, {
    ultimateAiChatId,
  });
};

export const trackChatbotSessionStarted = (ultimateAiChatId?: string) => {
  trackEvent(CHATBOT_SESSION_STARTED, { ultimateAiChatId });
};

export const trackFaqButtonClicked = () => {
  trackEvent(CONTACT_US_PAGE.FAQ_BUTTON_CLICKED, {});
};

export const trackContactUsButtonClicked = () => {
  trackEvent(CONTACT_US_PAGE.CONTACT_US_BUTTON_CLICKED, {});
};
