import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { showToast } from '@finn/design-system/atoms/toast';
import { ToggleGroup, ToggleItem } from '@finn/design-system/atoms/toggle';
import { ModalKey } from '@finn/ua-modals';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { parseToHtml } from '@finn/ui-utils';
import axios from 'axios';
import React, { useCallback } from 'react';

export type InsuranceUpgradeModalData = {
  title: string;
  description: string;
  standardTitle: string;
  standardPrice: string;
  standardDescription: string;
  premiumTitle: string;
  premiumPrice: string;
  premiumDescription: string;
  footNote?: string;
  confirmCtaText: string;
  successToastTitle: string;
  successToastDescription: string;
  cancelCtaText: string;
};

type Props = {
  open: boolean;
  onClose(): void;
  dealId: number;
  modalData: InsuranceUpgradeModalData;
  setReRenderKey?: React.Dispatch<React.SetStateAction<number>>;
  incrementRestoreCacheValue?: () => void;
  refreshOnSuccess?: boolean;
};
const insuranceUpgradeEndpoint =
  'https://hook.finn.integromat.cloud/et79a57xj9u2cprqibvr6sennpxvcvw4';

export const InsuranceUpgradeModal = ({
  open,
  onClose,
  dealId,
  modalData,
  setReRenderKey,
  incrementRestoreCacheValue,
  refreshOnSuccess,
}: Props) => {
  const [insuranceOption, setInsuranceOption] = React.useState<string | null>(
    'basic'
  );
  const [submitting, setSubmitting] = React.useState(false);
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const track = useTrackingStore((state) => state.track);

  const onModalClose = useCallback(() => {
    onClose();
    track(TrackingEventName.MODAL_CLOSED, { name: ModalKey.INSURANCE_UPGRADE });
    if (isSuccessful) {
      setIsSuccessful(false);
      setReRenderKey?.((prev) => prev + 1);
      if (refreshOnSuccess) {
        window.location.reload();
      }
    }
  }, [isSuccessful, onClose, setReRenderKey, track, refreshOnSuccess]);

  const onConfirm = useCallback(async () => {
    setSubmitting(true);
    try {
      track(TrackingEventName.DEDUCTIBLE_OPTION_SUBMITTED, {
        additionalProps: { dealId, insuranceOption },
      });
      incrementRestoreCacheValue?.();
      await axios.post(insuranceUpgradeEndpoint, {
        dealId,
        insuranceOption,
      });
      setIsSuccessful(true);
      track(TrackingEventName.DEDUCTIBLE_OPTION_SUCCESS, {
        additionalProps: { dealId, insuranceOption },
      });
    } catch (error) {
      track(TrackingEventName.DEDUCTIBLE_OPTION_ERROR, {
        additionalProps: { dealId, insuranceOption },
      });
      showToast({
        status: 'error',
        title: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
      });
    } finally {
      setSubmitting(false);
    }
  }, [
    dealId,
    insuranceOption,
    modalData.successToastDescription,
    modalData.successToastTitle,
    track,
  ]);

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(isOpen) => !isOpen && onModalClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            {isSuccessful ? modalData.successToastTitle : modalData?.title}
          </ModalTitle>
        </ModalHeader>
        <div className="flex flex-col gap-8">
          <div className="body-14-light">
            {parseToHtml(
              isSuccessful
                ? modalData?.successToastDescription
                : modalData?.description
            )}
          </div>
          {!isSuccessful && (
            <ToggleGroup
              value={insuranceOption}
              onValueChange={(val) => {
                track(TrackingEventName.DEDUCTIBLE_OPTION_SELECTED, {
                  name: val,
                });

                return setInsuranceOption(val);
              }}
              cols={1}
            >
              <ToggleItem
                value="basic"
                variant="extended"
                title={modalData.standardTitle}
                endAdornment={
                  <p className="body-14-semibold">{modalData.standardPrice}</p>
                }
                description={
                  <div className="body-14-light pr-24 pt-2 text-left">
                    {parseToHtml(modalData.standardDescription)}
                  </div>
                }
              />
              <ToggleItem
                value="premium"
                variant="extended"
                title={modalData.premiumTitle}
                endAdornment={
                  <p className="body-14-semibold">{modalData.premiumPrice}</p>
                }
                description={
                  <div className="body-14-light pr-24 pt-2 text-left">
                    {parseToHtml(modalData.premiumDescription)}
                  </div>
                }
              />
            </ToggleGroup>
          )}
          {!isSuccessful && modalData?.footNote && (
            <div className="body-14-light">
              {parseToHtml(modalData.footNote)}
            </div>
          )}
        </div>
        <ModalFooter>
          {isSuccessful ? (
            <div />
          ) : (
            <Button
              onClick={onConfirm}
              disabled={insuranceOption !== 'premium'}
              loading={submitting}
            >
              {modalData?.confirmCtaText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
