import { memo, ReactNode, useMemo } from 'react';

import { FilterValuesObject } from '../../filters-management';
import { FilterProvider } from '../../filters-management/data/lib';
import { QueryFilterMapper } from './QueryFilterMapper';

type QueryFilterProviderProps = {
  basePathname: string;
  defaultFilterValues?: FilterValuesObject;
  children?: ReactNode;
};

export const QueryFilterProvider = memo(function QueryFilterProvider({
  basePathname,
  children,
}: QueryFilterProviderProps) {
  const filterMapper = useMemo(
    () => new QueryFilterMapper(basePathname),
    [basePathname]
  );

  return (
    <FilterProvider filterMapper={filterMapper}>{children}</FilterProvider>
  );
});
