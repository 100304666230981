import 'lazysizes';

import { breakpointValues } from '@finn/design-system/tokens/sizes';
import { cn } from '@finn/ui-utils';
import React from 'react';

type Props = {
  draggable?: boolean;
  src?: string;
  dataSrc?: string;
  srcSet?: string;
  alt?: string;
  className?: string;
  dataExpand?: number;
  onLoad?: () => void;
  mdImage?: string;
  width?: number;
  height?: number;
  lgImage?: string;
  smImage?: string;
  lazyLoad?: boolean;
  loading?: 'eager' | 'lazy';
  variant?: 'lazy-loading' | 'responsive' | 'default';
};

const Image = React.forwardRef<HTMLImageElement, Props>(
  (
    {
      draggable = true,
      src = '',
      dataSrc,
      srcSet,
      alt = '',
      className,
      dataExpand,
      onLoad = () => {},
      mdImage,
      lgImage,
      smImage,
      lazyLoad = true,
      variant = 'default',
      width,
      height,
      loading,
    },
    ref
  ) => {
    switch (variant) {
      case 'lazy-loading':
        return (
          <img
            draggable={draggable}
            data-src={src || dataSrc}
            alt={alt}
            className={cn('lazyload', className)}
            data-expand={dataExpand}
            onLoad={onLoad}
            width={width}
            height={height}
          />
        );
      case 'responsive':
        if (lazyLoad) {
          return (
            <picture className="flex justify-center">
              {lgImage && (
                <source
                  media={`(min-width: ${breakpointValues.md}px)`}
                  data-srcset={lgImage}
                />
              )}
              {mdImage && (
                <source
                  media={`(min-width: ${breakpointValues.sm}px)`}
                  data-srcset={mdImage}
                />
              )}
              <img
                data-src={smImage}
                alt={alt}
                className={`lazyload ${className}`}
                width={width}
                height={height}
              />
            </picture>
          );
        }

        return (
          <picture className="flex justify-center">
            {lgImage && (
              <source
                media={`(min-width: ${breakpointValues.md}px)`}
                srcSet={lgImage}
              />
            )}
            {mdImage && (
              <source
                media={`(min-width: ${breakpointValues.sm}px)`}
                srcSet={mdImage}
              />
            )}
            <img
              src={smImage}
              alt={alt}
              className={className}
              width={width}
              height={height}
            />
          </picture>
        );

      default:
        return (
          <img
            draggable={draggable}
            ref={ref}
            src={src}
            srcSet={srcSet}
            alt={alt}
            loading={loading}
            className={className}
            width={width}
            height={height}
          />
        );
    }
  }
);
Image.displayName = 'Image';

export default Image;
