import { useRouter } from 'next/router';

export function useCurrentPage(): number | null {
  const router = useRouter();
  const pageNumber = Number(router.query.page);

  return Number.isFinite(pageNumber) && pageNumber > 0
    ? Number(router.query.page)
    : null;
}
