export enum FilterTestID {
  BrandFilter = 'brand-filter',
  ModelFilter = 'model-filter',
  FuelFilter = 'fuel-filter',
  CarTypeFilter = 'cartype-filter',
  PriceFilter = 'price-filter',
  TermsFilter = 'terms-filter',
  AvailabilityFilter = 'availability-filter',
  GearshiftFilter = 'gearshift-filter',
  OptionsFilter = 'options-filter',
  BusinessFilter = 'business-filter',
  DealsFilter = 'deals-filter',
  MinPriceFilter = 'min-price-filter',
  MaxPriceFilter = 'max-price-filter',
  Details = 'details',
  Tag = 'filter-tag',
  FeaturesFilter = 'features-filter',
}
