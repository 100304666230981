import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';

import {
  FilterValuesObject,
  parseFilterValues,
} from '../helpers/filter-parser';
import { useAvailableFilters } from './useAvailableFilters';
import { FilterKey } from './useGetFilters';

export type FiltersState = Pick<
  FilterValuesObject,
  | typeof FilterKey.BRANDS
  | typeof FilterKey.MODELS
  | typeof FilterKey.MIN_PRICE
  | typeof FilterKey.MAX_PRICE
  | typeof FilterKey.MIN_PRICE_MSRP
  | typeof FilterKey.MAX_PRICE_MSRP
  | typeof FilterKey.AVAILABLE_FROM
  | typeof FilterKey.AVAILABLE_TO
  | typeof FilterKey.TERMS
  | typeof FilterKey.CAR_TYPES
  | typeof FilterKey.GEARSHIFTS
  | typeof FilterKey.FUELS
  | typeof FilterKey.HAS_HITCH
  | typeof FilterKey.IS_FOR_BUSINESS
  | typeof FilterKey.IS_YOUNG_DRIVER
  | typeof FilterKey.SORT
  | typeof FilterKey.HAS_DEALS
  | typeof FilterKey.PRODUCT_GROUP
  | typeof FilterKey.HAS_RETENTION_DEALS
>;

export const useAvailableFiltersForState = (filterState: FiltersState) => {
  const filterStateRef = useRef(undefined);
  const [parsedValues, setParsedValues] = useState(undefined);

  useEffect(() => {
    if (!isEqual(filterStateRef.current, filterState)) {
      filterStateRef.current = filterState;

      setParsedValues(parseFilterValues(filterState));
    }
  }, [filterState]);

  return useAvailableFilters({
    filters: parsedValues,
  });
};
