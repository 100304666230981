import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@finn/design-system/atoms/select';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { SwapVert } from '@finn/design-system/icons/swap-vert';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  FilterKey,
  SortKey,
  useFilterValues,
  useMergeFilterValues,
} from '../../../filters-management';
import { trackFilterClicked } from '../../../tracking';
import {
  SortByOption as SortByOptionData,
  useSortByOptions,
} from '../../hooks/useSortByOptions';

export const SortBy = () => {
  const i18n = useIntl();

  const options = useSortByOptions();

  const filterSortValue =
    useFilterValues((values) => values[FilterKey.SORT] ?? undefined) ||
    SortKey.DEFAULT;

  const mergeFilterValues = useMergeFilterValues();

  const handleClick = useCallback(
    (option?: SortByOptionData) => {
      mergeFilterValues({
        [FilterKey.SORT]: option?.value,
      });
    },
    [mergeFilterValues]
  );

  const defaultLabel = i18n.formatMessage({ id: `plp.sort` });
  const isDefaultSorting = filterSortValue === SortKey.DEFAULT;

  const currentSortOption = options.find(
    (option) => option.value === filterSortValue
  );

  const currentLabel = isDefaultSorting
    ? defaultLabel
    : (currentSortOption?.label ?? defaultLabel);

  const isMobile = useMediaQuery('md');

  const triggerExtraProps = isMobile
    ? {
        wrapperClassName: 'w-full',
        className: 'w-full flex-row-reverse justify-center border-black',
        icon: (
          <SwapVert className="mr-2 max-h-4 max-w-4 fill-black transition-none" />
        ),
      }
    : { size: 'md' as const };
  const valueProps = isMobile
    ? {
        className: '[&_span]:body-16-semibold w-auto',
        placeholder: i18n.formatMessage({ id: 'plp.sort' }),
        forcePlaceholder: true,
      }
    : {
        placeholder: currentLabel,
        forcePlaceholder: currentLabel === defaultLabel,
      };

  return (
    <Select
      value={currentSortOption?.value}
      onOpenChange={(nextValue) => {
        if (nextValue) {
          trackFilterClicked({ type: 'sorting' });
        }
      }}
      onValueChange={(value) => {
        handleClick(options.find((option) => option.value === value));
      }}
    >
      <SelectTrigger {...triggerExtraProps}>
        <SelectValue {...valueProps} />
      </SelectTrigger>
      <SelectContent className="max-h-full">
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value as string}>
            <span>{option.label}</span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
