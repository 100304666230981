import { getCloudinaryImgUrl } from '@finn/ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ReactNode, Ref, useCallback, useMemo } from 'react';

import { FilterData, FilterToggleData } from '../../helpers';
import { trackFilterOptionClicked } from '../../tracking';
import { FilterCheckbox } from './FilterCheckbox';
import { SelectProps } from './SingleSelectFilter';

export const handleMultiSelect = ({
  value,
  selected,
  onClick,
  filterKey,
  selectedValues = [],
}: SelectProps) => {
  const updatedSelectedValues = selected
    ? selectedValues?.filter((item) => item !== value)
    : [...selectedValues, value];
  onClick({ [filterKey]: [...updatedSelectedValues] });
};

type Props = {
  data: FilterData;
  onClick: (value: object) => void;
  children?: ReactNode;
};

export const FilterOptions = ({ data, onClick, children }: Props) => {
  const { selectedValues, filterKey, availableFilterValues } = useMemo(
    () => data,
    [data]
  );

  const handleClick = useCallback(
    (value: string | number, selected: boolean) => {
      const selectProps = {
        value,
        selected,
        onClick,
        filterKey,
        selectedValues,
      };

      if (value !== undefined && value !== null) {
        trackFilterOptionClicked(filterKey, value);
      }

      handleMultiSelect(selectProps);
    },
    [
      onClick,
      filterKey,
      selectedValues,
      trackFilterOptionClicked,
      handleMultiSelect,
    ]
  );
  const animationRef = useAutoAnimate(null);

  if (!availableFilterValues) return;

  return (
    <div className="w-full" ref={animationRef as Ref<HTMLDivElement>}>
      {(availableFilterValues as unknown[] as FilterToggleData[]).map(
        ({ value, label, disabled, image, hidden }) => {
          const selected = (selectedValues as any[]).includes(value);

          if (hidden) return;

          return (
            <FilterCheckbox
              key={value}
              label={label}
              selected={selected}
              disabled={disabled}
              onClick={() => handleClick(value, selected)}
            >
              {image && (
                <img
                  className="h-8"
                  style={{
                    filter: disabled
                      ? 'invert(66%) sepia(0%) saturate(361%) hue-rotate(165deg) brightness(92%) contrast(84%)'
                      : null,
                  }}
                  src={getCloudinaryImgUrl(image, {
                    h: 32,
                    dpr: 2,
                  })}
                  alt={value.toString()}
                />
              )}
            </FilterCheckbox>
          );
        }
      )}
      {children}
    </div>
  );
};
