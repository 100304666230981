import { useCurrentLocale } from '@finn/ui-utils';
import { useSWRConfig } from 'swr';

import { FiltersResponse, useGetFilters } from './useGetFilters';

export const FILTERS_RESPONSE_SWR_FALLBACK_KEY = 'filtersResponse';

export const useFiltersResponse = (isOutOfStock = false): FiltersResponse => {
  // Ensure that always a value is returned
  const { fallback } = useSWRConfig();
  const fallbackValue = fallback[FILTERS_RESPONSE_SWR_FALLBACK_KEY];

  const { locale } = useCurrentLocale();

  const { data } = useGetFilters({
    filters: {},
    locale,
    zipCode: undefined,
  });

  // For out-of-stock pages, we want to use the Displayed Cars filters, which is already in the page-data
  return !isOutOfStock ? (data ?? fallbackValue) : fallbackValue;
};
