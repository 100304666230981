import { Fragment, JSX, ReactNode } from 'react';

import { getMessages } from './messages';
// as we use overloads we need to disable the no-redeclare rule for TS to work
/* eslint-disable no-redeclare */

// partially generated with O1

/*
  This function does the same thing as formatMessage from react-intl.
  Basically it allows you to format a message with placeholders(ICU format).
  formatMessage('hello {name}', { name: 'world' }) => 'hello world'.
  It also supports chunks which are JSX elements.
  formatMessage('hello <el>world</el>', { el: (chunks) => <strong>{chunks}</strong> }) => <strong>hello world</strong>.
*/

/**
  We use messages as singleton. Because server components are stateless 
  and does not support context. this pattern copied from next-18n package.
*/

type CB = (chunks: string) => ReactNode;
export function formatMessage(id: string): string;
export function formatMessage(
  id: string,
  props: Record<string, CB | string | number>
): JSX.Element;
export function formatMessage(
  id: string,
  props?: Record<string, CB | string | number>
) {
  let result = getMessages()?.[id] ?? id;

  if (!props) {
    return result;
  }

  Object.keys(props).forEach((key) => {
    result = result.replaceAll(
      `{${key}}`,
      (props[key] as unknown as string | null) ?? ''
    );
  });

  const tagRegex = /<(\w+)>(.*?)<\/\1>/g;

  // Array to hold the final parts (strings and JSX elements)
  const parts: (string | ReactNode)[] = [];
  let lastIndex = 0;
  let match;

  while ((match = tagRegex.exec(result)) !== null) {
    const [fullMatch, tagName, innerText] = match;
    const { index } = match;

    // Push the text before the current match
    if (index > lastIndex) {
      parts.push(result.substring(lastIndex, index));
    }

    if (
      tagName &&
      innerText &&
      props[tagName] &&
      typeof props[tagName] === 'function'
    ) {
      // Use the provided function to wrap the innerText
      const element = props[tagName](innerText);
      parts.push(element);
    } else {
      // If no corresponding prop, leave the tag as plain text
      parts.push(fullMatch);
    }

    // Update the lastIndex to the end of the current match
    lastIndex = index + fullMatch.length;
  }

  // Push any remaining text after the last match
  if (lastIndex < result.length) {
    parts.push(result.substring(lastIndex));
  }

  // If there's only one part, return it directly
  if (parts.length === 1) {
    return parts[0];
  }

  // Otherwise, return a fragment containing all parts
  return (
    <>
      {parts.map((part, index) => (
        <Fragment key={index}>{part}</Fragment>
      ))}
    </>
  );
}
