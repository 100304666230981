export const enum Features {
  FullStory = 'fullstory',
  ExpDeductiblesV3 = 'exp-deductibles-v3',
  HideDownPayment = 'feat-hide-downpayment',
  ExpPDPCarInfo = 'exp-pdp-carinfo2',
  ExpPDPAvailabilityLabel = 'exp-pdp-availabilitylabel-relaunch',
  HideFeatureFilter = 'feat-hide-features-filter',
  ExpJobAutoLink = 'exp-jobautolink',
  ExpNewHero = 'exp-newhero2',
  ExpPLPDateSelector = 'exp-plpdateselector',
  ExpPCPLogin = 'exp-pcp-login-v3',
  ExpHPHeroFilters = 'exp-hp-herofilters-v2',
  ExpInstantDownPayment = 'exp-instant-downpayment',
  ExpPLPPopularFilters = 'exp-plp-popular-filters-v3',
  FeatOnboardingFee = 'feat-onboarding-fee',
  ExpPLPModelCards = 'exp-plp-modelcards',
  ExpDisableAutoScrollDC = 'exp-disable-auto-scroll-dc',
  ExpRelatedConfigs = 'exp-relatedconfigs',
  ExpRemoveDiscountLabel = 'exp-remove-discount-label',
  ExpRemoveSidebarLinks = 'exp-remove-sidebar-links',
  ExpAddSchufaNotice = 'exp-add-schufa-notice',
  ExpPLPFilterButton = 'exp-plpfilterbutton',
  ExpPDPLeap = 'exp-pdp-leap-v1b',
  ExpPDPLeapV2 = 'exp-pdp-leap-v2',
  ExpPCPReducedMileage = 'exp-pcp-reducedmileage-v1',
  ExpSliderLinks = 'exp-sliderlinks',
}

// move the removed experiments here to clean up the cookie
export const RemovedExperiments = [
  'exp-pcp-multiplesteps2',
  'exp-morecarsinslider',
  'exp-fakedoor-downpayment',
  'exp-downpayment-options2',
  'exp-plp-sortandtags',
  'exp-pcp-no-toggle-prices',
  'exp-pcp-downpaymenttooltip',
  'exp-newhero',
  'exp-pdp-carinfo',
  'exp-pcp-multiplesteps',
  'exp-pay-later-v4',
  'exp-pdp-availabilitylabel-relaunch',
  'exp-pcp-login',
  'exp-plp-popular-filters-v2',
  'exp-pcp-login-2',
  'exp-hp-herofilters',
  'exp-plp-incentive-labels',
  'exp-pdp-leap-v1',
];
