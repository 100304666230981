import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { FC, useEffect } from 'react';

type Props = {
  Icon: FC<{ className?: string }>;
  title: string;
  onClick?: () => void;
  description?: string;
};

export const ActionButton = ({ Icon, title, description, onClick }: Props) => {
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    track(TrackingEventName.CTA_SHOWN, { name: title });
    //once on mount
  }, []);

  return (
    <a
      className="border-pewter grid cursor-pointer grid-cols-[min-content_1fr_min-content] rounded border p-4 hover:border-black sm:px-4 sm:py-6"
      onClick={onClick}
    >
      <Icon className="mr-4 min-w-6" />
      <span className="flex flex-col gap-4 text-left">
        <span className="body-16-semibold">{title}</span>
        <span className="body-14-light">{description}</span>
      </span>
    </a>
  );
};
