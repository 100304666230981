import { cn } from '@finn/ui-utils';
import {
  ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Button, ButtonProps } from '../button';
import { FirstPage } from '../icons/generated/first-page';
import { KeyboardArrowLeft } from '../icons/generated/keyboard-arrow-left';
import { KeyboardArrowRight } from '../icons/generated/keyboard-arrow-right';
import { LastPage } from '../icons/generated/last-page';
import { usePagination } from './usePagination';

const Pagination = ({ className, ...props }: ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('flex w-full justify-start', className)}
    {...props}
  />
);
Pagination.displayName = 'Pagination';

const PaginationContent = forwardRef<HTMLUListElement, ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => (
    <ul
      ref={ref}
      className={cn('m-0 flex flex-row items-center p-0', className)}
      {...props}
    />
  )
);
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = forwardRef<HTMLLIElement, ComponentProps<'li'>>(
  ({ className, ...props }, ref) => (
    <li ref={ref} className={cn('list-none', className)} {...props} />
  )
);
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
} & ButtonProps;

const PaginationLink = ({
  className,
  isActive,
  size = 'md',
  ...props
}: PaginationLinkProps) => (
  <Button
    aria-current={isActive ? 'page' : undefined}
    variant="ghost"
    size={size}
    className={cn(
      'h-10 w-10 px-2 no-underline [&_svg]:w-4',
      isActive ? 'bg-snow text-black' : 'text-steel',
      className
    )}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';

const PaginationFirst = ({
  className,
  ...props
}: ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to first page"
    className={cn('gap-1', className)}
    {...props}
  >
    <FirstPage />
  </PaginationLink>
);
PaginationFirst.displayName = 'PaginationFirst';

const PaginationPrevious = ({
  className,
  ...props
}: ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to previous page"
    className={cn('gap-1', className)}
    {...props}
  >
    <KeyboardArrowLeft />
  </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({
  className,
  ...props
}: ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to next page"
    className={cn('gap-1', className)}
    {...props}
  >
    <KeyboardArrowRight />
  </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationLast = ({
  className,
  ...props
}: ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to last page"
    className={cn('gap-1', className)}
    {...props}
  >
    <LastPage />
  </PaginationLink>
);
PaginationLast.displayName = 'PaginationLast';

const PaginationEllipsis = ({
  className,
  ...props
}: ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-10 w-10 items-center justify-center', className)}
    {...props}
  >
    <span className="body-14-semibold text-steel">...</span>
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export const PaginationBlock = ({
  totalPages,
  page = 1,
  onPageChange,
}: {
  totalPages: number;
  page?: number;
  onPageChange?: (page: number) => void;
}) => {
  const [currentPage, setCurrentPage] = useState(page);

  const handlePageChange = useCallback(
    (nextPage: number) => {
      onPageChange?.(nextPage);
      setCurrentPage(nextPage);
    },
    [onPageChange]
  );

  // we have internal state to keep track of the current page
  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const { items } = usePagination({
    count: totalPages,
    page: currentPage,
  });

  return (
    <Pagination>
      <PaginationContent>
        {items.map((item, index) => {
          if (item.type === 'first') {
            return (
              <PaginationItem key={index}>
                <PaginationFirst
                  disabled={item.disabled}
                  onClick={() => handlePageChange(1)}
                />
              </PaginationItem>
            );
          }
          if (item.type === 'previous') {
            return (
              <PaginationItem key={index}>
                <PaginationPrevious
                  disabled={item.disabled}
                  onClick={() => handlePageChange(currentPage - 1)}
                />
              </PaginationItem>
            );
          }
          if (item.type === 'next') {
            return (
              <PaginationItem key={index}>
                <PaginationNext
                  disabled={item.disabled}
                  onClick={() => handlePageChange(currentPage + 1)}
                />
              </PaginationItem>
            );
          }
          if (item.type === 'last') {
            return (
              <PaginationItem key={index}>
                <PaginationLast
                  disabled={item.disabled}
                  onClick={() => handlePageChange(totalPages)}
                />
              </PaginationItem>
            );
          }
          if (item.type === 'ellipsis') {
            return (
              <PaginationItem key={index}>
                <PaginationEllipsis />
              </PaginationItem>
            );
          }

          return (
            <PaginationItem key={index}>
              <PaginationLink
                isActive={item.selected}
                disabled={item.disabled}
                onClick={() => handlePageChange(item.page as number)}
              >
                {item.page}
              </PaginationLink>
            </PaginationItem>
          );
        })}
      </PaginationContent>
    </Pagination>
  );
};

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationFirst,
  PaginationLast,
};
