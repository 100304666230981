import { cn } from '@finn/ui-utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { handlePageTracking } from '../../tracking';

type Props = {
  page: number;
  selected?: boolean;
  disabled?: boolean;
  shallow?: boolean;
};

export const PaginationPageLink = ({
  page,
  selected = false,
  disabled = false,
  shallow = false,
}: Props) => {
  const router = useRouter();

  const linkHref = useMemo(
    () => ({
      pathname: router.pathname,
      query: {
        ...router.query,
        page,
      },
    }),
    [router, page]
  );

  return (
    <Link
      shallow={shallow}
      href={linkHref}
      className={cn(
        'mx-1 inline-block min-w-8 cursor-pointer py-2 text-center sm:mx-2',
        {
          'border-0 border-b-2 border-solid border-black': selected,
          'pointer-events-none cursor-default': disabled,
        }
      )}
      onClick={() =>
        handlePageTracking({ type: 'page-number', pageNumber: page })
      }
    >
      <span className="body-16-semibold">{page}</span>
    </Link>
  );
};
