let messages: Record<string, string> = {};

let currentLang = 'en';

export const setCurrentLang = (lang: string) => (currentLang = lang);
export const getCurrentLang = () => currentLang;

// as we compile our locales to AST, we need to temporary support it,
// so added small hack to always read value
export const setMessages = (newMessages: Record<string, string>) => {
  if (
    Object.entries(newMessages).some(([, value]) => typeof value === 'object')
  ) {
    messages = Object.entries(newMessages).reduce((acc, [key, value]) => {
      if (typeof value === 'string') {
        acc[key] = value;
      } else if (Array.isArray(value)) {
        acc[key] = (value[0] as any)?.value;
      } else {
        acc[key] = (value as any)?.value || value;
      }

      return acc;
    }, {});
  } else {
    messages = newMessages;
  }
};
export const getMessages = () => messages;
