import { ArrowBack } from '@finn/design-system/icons/arrow-back';
import { ArrowForward } from '@finn/design-system/icons/arrow-forward';
import { cn } from '@finn/ui-utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { handlePageTracking } from '../../tracking';

type Props = {
  page: number;
  direction: 'previous' | 'next';
  disabled?: boolean;
  shallow?: boolean;
};

export const PaginationNavLink = ({
  page,
  direction,
  disabled = false,
  shallow = false,
}: Props) => {
  const i18n = useIntl();
  const router = useRouter();

  const linkHref = useMemo(
    () => ({
      pathname: router.pathname,
      query: {
        ...router.query,
        page,
      },
    }),
    [router, page]
  );

  const handleClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (event, direction) => {
      if (disabled) {
        event.preventDefault();
      } else {
        handlePageTracking({ type: direction });
      }
    },
    [disabled, direction]
  );

  const prevLabel = i18n.formatMessage({ id: 'plp.pagination.prev' });
  const nextLabel = i18n.formatMessage({ id: 'plp.pagination.next' });

  return (
    <Link
      shallow={shallow}
      href={disabled ? router.asPath : linkHref}
      className={cn('flex cursor-pointer items-center', {
        'fill-pewter text-pewter': disabled,
        'flex-row-reverse': direction === 'previous',
      })}
      onClick={(event) => handleClick(event, direction)}
    >
      <>
        <span className="body-16-semibold mx-2 hidden sm:inline">
          {direction === 'previous' ? prevLabel : nextLabel}
        </span>
        <div>{direction === 'previous' ? <ArrowBack /> : <ArrowForward />}</div>
      </>
    </Link>
  );
};
