import { isMobileApp, useSession } from '@finn/ui-utils';
import Script from 'next/script';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ChatRef } from './index';
import { globalChatApi } from './UltimateChat';

interface CustomColors {
  brandColor?: string;
  conversationColor?: string;
  actionColor?: string;
}

interface Delegate {
  beforeDisplay: (
    message: Message,
    conversation: Conversation
  ) => Message | null;
}

interface SmoochConfig {
  integrationId: string;
  customText: { [key: string]: string };
  businessName?: string;
  soundNotificationEnabled?: boolean;
  businessIconUrl?: string;
  externalId?: string;
  jwt?: string;
  locale?: string;
  customColors?: CustomColors;
  delegate?: Delegate;
}

interface SmoochInterface {
  init: (config: SmoochConfig) => Promise<void>;
  login: (externalId: string, jwt: string) => Promise<void>;
  logout: () => void;
  open: () => void;
  destroy: () => void;
  createConversation: (options?: ConversationOptions) => Promise<Conversation>;
  loadConversation: (conversationId: string) => Promise<Conversation>;
  getConversations: () => Conversation[];
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: () => void) => void;
  sendMessage: (message: Message, conversationId: string) => void;
  isOpened: () => boolean;
}

interface Message {
  type: string;
  text: string;
  metadata: {
    hidden: boolean;
  };
}

interface Conversation {
  id: string;
}

interface ConversationOptions {
  iconUrl: string;
}

declare global {
  interface Window {
    Smooch?: SmoochInterface;
  }
}

type SuncoChatProps = {
  openOnLoad?: boolean;
  onChatOpened?: () => void;
  translations: { [key: string]: string };
};

async function fetchToken() {
  try {
    const response = await fetch('/api/generateZendeskToken');
    if (!response.ok) {
      console.error('Token fetch failed with status:', response.status);

      return null;
    }
    const data = await response.json();

    return data.token;
  } catch (error) {
    console.error('Error fetching token:', error);

    return null;
  }
}

export const SmoochChat = forwardRef<ChatRef, SuncoChatProps>(
  ({ translations, openOnLoad, onChatOpened }, ref) => {
    const fallbackChatRef = useRef<ChatRef>(null);
    const chatRef = ref || fallbackChatRef;
    const [isWidgetInitialized, setIsWidgetInitialized] = useState(false);
    const [token, setToken] = useState<string | null>(null);
    const queueRef = useRef<(() => void)[]>([]);
    const [session] = useSession();
    const contactId = session?.user?.hs_contact_id ?? '';

    function openChatAndStartConversation() {
      window.Smooch?.createConversation().then((conversation) => {
        window.Smooch?.loadConversation(conversation.id);
      });
    }

    const initializeWidget = useCallback(() => {
      window.Smooch?.init({
        integrationId: '64c7ba6f2e22a0b6aef3994c',
        customText: translations,
        soundNotificationEnabled: false,
        businessName: 'FINN',
        locale: 'de-DE',
        customColors: {
          brandColor: '191919',
          conversationColor: '191919',
          actionColor: '0086EA',
        },
        businessIconUrl: '/assets/favicon/apple-touch-icon.png',
        delegate: {
          beforeDisplay(message: any, data: any) {
            if (
              data.conversation.id &&
              message.metadata &&
              message.metadata.hidden
            ) {
              return null;
            } else {
              return message;
            }
          },
        },
      })
        .then(() => {
          setIsWidgetInitialized(true);

          const createConversation = () => {
            const convos = window.Smooch?.getConversations();
            if (!convos || convos.length === 0) {
              openChatAndStartConversation();
            }
          };
          window.Smooch?.on('widget:opened', createConversation);

          if (openOnLoad) {
            window.Smooch?.open();
          }
          if (queueRef?.current?.length) {
            queueRef.current.forEach((fn) => fn());
            queueRef.current = [];
          }
        })
        .catch((err) => {
          console.error('Smooch initialization failed:', err);
        });
    }, []);

    useEffect(() => {
      if (!session) {
        return;
      }
      fetchToken().then(setToken);
    }, [session]);

    useEffect(() => {
      if (isWidgetInitialized && token && contactId) {
        window.Smooch?.login(contactId, token);
      }
    }, [token, isWidgetInitialized, contactId]);

    useEffect(() => {
      if (!isWidgetInitialized) {
        initializeWidget();
      } else {
        globalChatApi.current = (chatRef as typeof globalChatApi).current;
      }

      return () => {
        if (isWidgetInitialized) {
          window.Smooch?.destroy();
        }
      };
    }, [isWidgetInitialized, initializeWidget, chatRef]);

    /*
    The current implementation uses a setInterval to continuously ensure that an event handler is attached to a button within an iframe. This approach is necessary due to the dynamic nature of the widget which reloads or changes its DOM frequently, particularly when navigating within the widget or opening/closing it, which causes the loss of attached event handlers.

    This polling method is chosen because the widget does not provide a reliable way to hook into its DOM changes or lifecycle events that would allow a more efficient, event-driven approach. Although this method is effective in ensuring the button always has the necessary event handler, it is not optimal in terms of performance and resource usage.

    It is important to note:
      - Polling with setInterval may lead to performance issues, especially on less powerful devices, due to continuous function execution.
      - Direct DOM manipulation bypasses React's virtual DOM, which can lead to potential inconsistencies and harder-to-maintain code.

    Potential improvements could include:
      - Investigating if the widget provider can offer more integration support or events that could be used to attach handlers more reactively.
      - Exploring the use of MutationObserver as a more resource-efficient way to respond to changes in the DOM if and when the widget's implementation allows.
      - Enhancing error handling and user feedback to gracefully manage and communicate issues that may arise from iframe access or DOM manipulation failures.
    */
    // When a new conversation is started manually by clicking the blue button,
    // the chat with the bot still needs to be manually triggered with this code.
    const attachButton = useCallback(() => {
      if (!isWidgetInitialized) {
        return;
      }

      const iframe = document.getElementById(
        'web-messenger-container'
      ) as HTMLIFrameElement;
      if (iframe && iframe.contentWindow) {
        const iframeDocument = iframe.contentWindow.document;
        const conversationFooter = iframeDocument.getElementsByClassName(
          'conversation-group-footer'
        )[0];
        if (conversationFooter) {
          const button = conversationFooter.querySelector('button');
          if (button) {
            button.removeEventListener('click', openChatAndStartConversation);
            button.addEventListener('click', openChatAndStartConversation);
          } else {
            console.log('No existing button found to attach the event handler');
          }
        }
      }
    }, [isWidgetInitialized]);

    useEffect(() => {
      const cb = () => {
        attachButton?.();
        onChatOpened?.();
      };
      window.Smooch?.on('widget:opened', cb);

      return () => {
        window.Smooch?.off?.('widget:opened', cb);
      };
    }, [attachButton, onChatOpened]);

    useEffect(() => {
      const intervalId = setInterval(attachButton, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }, [attachButton]);

    useImperativeHandle(chatRef, () => ({
      openWidget: () => {
        // we need to check if widget is initialized before calling open
        // if widget is not initialized we need to queue the open call
        // till moment when it will be initialized
        if (isWidgetInitialized) {
          window.Smooch?.open();
        } else {
          queueRef?.current?.push(() => {
            window.Smooch?.open();
          });
        }
      },
      closeWidget: () => window.Smooch?.destroy(),
    }));

    /**
     * Smooch SDK injects iframe and uses srcdoc attribute to load the content of frame.
     * Sadly scrdoc has limitations when used inside WebView on IOS devices - which is the case of FINN app.
     * To overcome this limitation we forked minified SDK and replaced srcdoc with src attribute.
     * and for src attribute we created own html that loads the content of the frame.
     * VERY DIRTY HACK - but it works. pls contact platform team if you want to change
     * any lines below this comment.
     */

    return (
      <Script
        id="Smooch"
        src={
          isMobileApp()
            ? `/smooch.5.6.0.min.js`
            : 'https://cdn.smooch.io/smooch.5.6.0.min.js'
        }
        strategy="lazyOnload"
        onLoad={() => initializeWidget()}
      />
    );
  }
);
