import { FiltersResponse, FilterValuesObject } from '../../filters-management';
import { PathFilterMapper } from './path-filter-mapper';

export function deserializeFiltersFromPath(
  urlPath: string,
  basePathname: string,
  locale: string,
  filtersResponse: FiltersResponse
): FilterValuesObject {
  const pathFilterMapper = new PathFilterMapper(
    basePathname,
    locale,
    filtersResponse
  );

  return pathFilterMapper.deserialize(urlPath);
}

export function serializeFiltersToPath(
  filterValues: FilterValuesObject,
  basePathname: string,
  locale: string,
  filtersResponse: FiltersResponse
): string {
  const pathFilterMapper = new PathFilterMapper(
    basePathname,
    locale,
    filtersResponse
  );

  return pathFilterMapper.serialize(filterValues);
}
