import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  FilterKey,
  FiltersResponse,
  FiltersState,
} from '../../../filters-management';
import { FilterTestID } from '../../helpers';
import { AvailabilityFilter } from '../individual-filters/AvailabilityFilter';
import { BrandFilter } from '../individual-filters/BrandFilter';
import { BusinessFilter } from '../individual-filters/BusinessFilter';
import { ConfigFilter } from '../individual-filters/ConfigFilter';
import { DealsFilter } from '../individual-filters/DealsFilter';
import { FeaturesFilter } from '../individual-filters/FeaturesFilter';
import { ModelFilter } from '../individual-filters/ModelFilter';
import { OptionsFilter } from '../individual-filters/OptionsFilters';
import { PriceFilter } from '../individual-filters/PriceFilter';
import { SortFilter } from '../individual-filters/SortFilter';
import { TermsFilter } from '../individual-filters/TermsFilter';
import { PopularFilters } from './PopularFilters';

export type FilterOptionClickFunction = (filtersObject: FiltersState) => void;

type Props = {
  filtersData: FiltersState;
  availableFilters: FiltersResponse;
  onClick: FilterOptionClickFunction;
};

export const FiltersGroup = ({
  filtersData,
  onClick,
  availableFilters,
}: Props) => {
  const i18n = useIntl();
  const shouldHideFeaturesFilter = useIsABVariant(Features.HideFeatureFilter);
  const isPopularFiltersB = useIsABVariant(Features.ExpPLPPopularFilters);
  const isPopularFiltersC = useIsCVariant(Features.ExpPLPPopularFilters);
  const isPopularFiltersExp = isPopularFiltersB || isPopularFiltersC;
  const isFilterButtonExp = useIsABVariant(Features.ExpPLPFilterButton);

  const { is_young_driver: selectedIsYoungDriver } = filtersData;

  return (
    <div className="mx-4 md:sticky md:top-0 md:ml-0 md:mr-4 md:max-h-screen md:w-[250px] md:self-start md:overflow-y-auto md:overflow-x-hidden md:pr-4">
      {isFilterButtonExp && (
        <div className="md:hidden">
          <SortFilter selectedFilters={filtersData} onOptionClick={onClick} />
        </div>
      )}

      {isPopularFiltersExp && (
        <PopularFilters
          availableFilters={availableFilters}
          selectedFilters={filtersData}
          onOptionClick={onClick}
          expanded={isPopularFiltersB}
        />
      )}

      <BrandFilter
        selectedFilters={filtersData}
        availableFilters={availableFilters}
        onOptionClick={onClick}
        expanded={isPopularFiltersC}
      />

      <ModelFilter
        selectedFilters={filtersData}
        availableFilters={availableFilters}
        onOptionClick={onClick}
      />

      <ConfigFilter
        title={i18n.formatMessage({ id: 'plp.fuels' })}
        filterKey={FilterKey.FUELS}
        selectedFilters={filtersData}
        onOptionClick={onClick}
        availableFilters={availableFilters}
        testId={FilterTestID.FuelFilter}
      />

      <ConfigFilter
        title={i18n.formatMessage({ id: 'plp.cartype' })}
        filterKey={FilterKey.CAR_TYPES}
        selectedFilters={filtersData}
        onOptionClick={onClick}
        availableFilters={availableFilters}
        testId={FilterTestID.CarTypeFilter}
      />

      <PriceFilter
        selectedFilters={filtersData}
        availableFilters={availableFilters}
        onClick={onClick}
      />

      <DealsFilter selectedFilters={filtersData} onOptionClick={onClick} />

      {!shouldHideFeaturesFilter && (
        <FeaturesFilter
          selectedFilters={filtersData}
          availableFilters={availableFilters}
          onOptionClick={onClick}
          testId={FilterTestID.FeaturesFilter}
        />
      )}

      <BusinessFilter
        selectedFilters={filtersData}
        availableFilters={availableFilters}
        onOptionClick={onClick}
      />

      <TermsFilter
        selectedFilters={filtersData}
        availableFilters={availableFilters}
        onOptionClick={onClick}
        testId={FilterTestID.TermsFilter}
      />

      <AvailabilityFilter
        selectedFilters={filtersData}
        availableFilters={availableFilters}
        onClick={onClick}
      />

      <ConfigFilter
        title={i18n.formatMessage({ id: 'plp.gearshift' })}
        availableFilters={availableFilters}
        filterKey={FilterKey.GEARSHIFTS}
        selectedFilters={filtersData}
        onOptionClick={onClick}
        testId={FilterTestID.GearshiftFilter}
      />

      <OptionsFilter
        selectedFilters={[Boolean(selectedIsYoungDriver)]}
        onOptionClick={onClick}
      />
    </div>
  );
};
