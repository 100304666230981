import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { TopCampaignBannerData } from '@finn/ui-cosmic';
import { getCloudinaryImgUrl } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { useReplaceFilterValues } from '../../filters-management';
import { FilterContext } from '../../filters-management/data/lib';
import { CampaignBanner } from './CampaignBanner';

type Props = {
  topCampaignBanner?: TopCampaignBannerData;
  location?: string;
};

export const TopCampaignBanner = ({ topCampaignBanner, location }: Props) => {
  const topCampaignBannerData = {
    title: topCampaignBanner?.metadata?.title,
    linkUrl: topCampaignBanner?.metadata?.linkurl,
    ctaText: topCampaignBanner?.metadata?.call_to_action_text,
    imageUrl: topCampaignBanner?.metadata?.imageurl?.imgix_url,
    backgroundColor: topCampaignBanner?.metadata?.background_color,
  };

  const optimizedImageUrl = getCloudinaryImgUrl(
    topCampaignBannerData.imageUrl,
    { dpr: 2, w: 376 }
  );
  const replaceFilterValues = useReplaceFilterValues();
  const filterContext = useContext(FilterContext);

  const handleOnBannerClick = () => {
    interactionTrackingEvent(TrackingEventName.BANNER_LINK_CLICKED, {
      location: location || 'campaign-banner',
    });

    if (topCampaignBannerData.linkUrl.includes(BASE_SUBSCRIPTION_PATH)) {
      const newValues = filterContext.filterMapper.deserialize(
        topCampaignBannerData.linkUrl
      );
      replaceFilterValues(newValues);

      return false;
    }

    return true;
  };

  const { asPath } = useRouter();
  const isLinkTarget = asPath.includes(topCampaignBannerData.linkUrl);

  if (!topCampaignBannerData.title || isLinkTarget) {
    return null;
  }

  return (
    <div className="my-4 mb-6">
      <CampaignBanner
        imageUrl={optimizedImageUrl}
        onBannerClick={handleOnBannerClick}
        title={topCampaignBannerData.title}
        ctaText={topCampaignBannerData.ctaText}
        linkUrl={topCampaignBannerData.linkUrl}
        backgroundColor={topCampaignBannerData.backgroundColor}
      />
    </div>
  );
};
