import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import React, {
  createContext,
  memo,
  ReactNode,
  useContext,
  useMemo,
} from 'react';

import { FilterValuesObject } from '../helpers/filter-parser';

// TODO: Move this outside `lib` to some place else
export const PRESERVED_QUERY_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'gclid',
  'gbraid',
  'msclkid',
  'campaign',
  'utm_term',
  'utm_content',
  'awc',
];

// TODO: Move this outside `lib` to some place else
function restorePreservedQueryParams(url: URL): void {
  const prevParams = new URLSearchParams(window.location.search);

  for (const [key, value] of prevParams.entries()) {
    if (PRESERVED_QUERY_PARAMS.includes(key)) {
      url.searchParams.append(key, value);
    }
  }
}

type FilterMapper<ValuesObject = object> = {
  serialize(input: ValuesObject): string;
  deserialize(input: string): ValuesObject;
};

type FilterContextValue<ValuesObject> = {
  readonly filterValues: ValuesObject;
  readonly filterMapper: FilterMapper<ValuesObject>;
};

type FilterContextType<ValuesObject> = React.Context<
  FilterContextValue<ValuesObject>
>;

function throwNoProviderError(): never {
  throw new Error(
    'FilterContext value is not provided or accessed outside of FilterContext.Provider'
  );
}

export function createFilterContext<
  ValuesObject,
>(): FilterContextType<ValuesObject> {
  return createContext({
    get filterValues(): ValuesObject {
      return throwNoProviderError();
    },

    get filterMapper(): FilterMapper<ValuesObject> {
      return throwNoProviderError();
    },
  });
}

type FilterProviderProps<ValuesObject> = {
  filterMapper: FilterMapper<ValuesObject>;
  children?: ReactNode;
};

export function createFilterProvider<ValuesObject>(
  Context: FilterContextType<ValuesObject>
): React.ComponentType<FilterProviderProps<ValuesObject>> {
  return memo(function FilterProvider({ filterMapper, children }) {
    const router = useRouter();

    const filterValues = useMemo(() => {
      return filterMapper.deserialize(router.asPath);
    }, [filterMapper, router.asPath]);

    const providerValue = useMemo(() => {
      return {
        filterValues,
        filterMapper,
      };
    }, [filterValues, filterMapper]);

    return (
      <Context.Provider value={providerValue}>{children}</Context.Provider>
    );
  });
}

type FilterConsumerProps<ValuesObject> = {
  children: (value: FilterContextValue<ValuesObject>) => React.ReactNode;
};

export function createFilterConsumer<ValuesObject>(
  Context: FilterContextType<ValuesObject>
): React.ComponentType<FilterConsumerProps<ValuesObject>> {
  return memo(function FilterConsumer({ children }) {
    return <Context.Consumer>{children}</Context.Consumer>;
  });
}

type NavigateOptions = {
  replaceURL?: boolean;
};

type FilterValueSelector<ValuesObject, SelectorResult extends unknown> = (
  values: ValuesObject
) => SelectorResult;

type UseFilterValues<ValuesObject> = {
  (): ValuesObject;

  <SelectorResult>(
    selector?: FilterValueSelector<ValuesObject, SelectorResult>
  ): SelectorResult;
};

type UseMergeFilterValues<ValuesObject> = () => (
  nextFilterValues: ValuesObject,
  options?: NavigateOptions
) => void;

type UseReplaceFilterValues<ValuesObject> = () => (
  nextFilterValues: ValuesObject,
  options?: NavigateOptions
) => void;

type CreateFilterHooksResult<ValuesObject> = {
  useFilterValues: UseFilterValues<ValuesObject>;
  useMergeFilterValues: UseMergeFilterValues<ValuesObject>;
  useReplaceFilterValues: UseReplaceFilterValues<ValuesObject>;
};

export function createFilterHooks<ValuesObject>(
  Context: FilterContextType<ValuesObject>
): CreateFilterHooksResult<ValuesObject> {
  return {
    useFilterValues<SelectorResult>(
      selector?: FilterValueSelector<ValuesObject, SelectorResult>
    ) {
      const { filterValues } = useContext(Context);

      if (!selector) {
        return filterValues;
      }

      return selector(filterValues);
    },

    useMergeFilterValues() {
      const router = useRouter();
      const { filterValues, filterMapper } = useContext(Context);

      return (nextFilterValues, options) => {
        if (isEqual(filterValues, nextFilterValues)) {
          return;
        }

        const nextURL = filterMapper.serialize({
          ...filterValues,
          ...nextFilterValues,
        });

        if (options?.replaceURL) {
          router.replace(nextURL, undefined, { shallow: true });
        } else {
          router.push(nextURL, undefined, { shallow: true });
        }
      };
    },

    useReplaceFilterValues() {
      const router = useRouter();
      const { filterValues, filterMapper } = useContext(Context);

      return (nextFilterValues, options) => {
        if (isEqual(filterValues, nextFilterValues)) {
          return;
        }

        const nextURL = new URL(
          filterMapper.serialize(nextFilterValues),
          window.location.origin
        );

        restorePreservedQueryParams(nextURL);

        const nextPath = `${nextURL.pathname}${nextURL.search}`;

        if (options?.replaceURL) {
          router.replace(nextPath, undefined, {
            shallow: true,
          });
        } else {
          router.push(nextPath, undefined, {
            shallow: true,
          });
        }
      };
    },
  };
}

export const FilterContext = createFilterContext<FilterValuesObject>();

export const FilterProvider = createFilterProvider(FilterContext);
export const FilterConsumer = createFilterConsumer(FilterContext);

const { useFilterValues, useMergeFilterValues, useReplaceFilterValues } =
  createFilterHooks<FilterValuesObject>(FilterContext);

export { useFilterValues, useMergeFilterValues, useReplaceFilterValues };
