import { getHubspotDealInfo } from '@finn/ua-auth';
import {
  CookieKeys,
  identifyEvent,
  setClientCookie,
  useCurrentLocale,
  useSession,
} from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { CartInfo, ContactInfo } from '~/types/checkout';
import { isFullStoryLoaded } from '~/utils/fullstory';

export const useUserIdentifierDealRelatedPages = (
  cartInfo: CartInfo,
  contactInfo: ContactInfo
) => {
  const Router = useRouter();
  const { region } = useCurrentLocale();
  const [session] = useSession();

  const isUnverifiedAccount =
    Boolean(session) && !session?.user?.email_verified;

  useEffect(() => {
    if (isUnverifiedAccount) {
      setClientCookie(
        CookieKeys.LAST_URL,
        btoa(`/${Router.locale}${Router.asPath}`)
      );
    }
  }, [isUnverifiedAccount, Router.asPath, Router.locale]);

  useEffect(() => {
    if (!Router.isReady) return;
    const dealInfo = getHubspotDealInfo(Router);
    const userId = `${dealInfo.contactId ? dealInfo.contactId : ''}`;

    // Add User ID to FullStory session recording
    if (isFullStoryLoaded() && !!userId) {
      window.FS.identify(userId);
    }

    // Identify users with Segment
    if (userId) {
      const email = cartInfo.email ?? '';
      const firstName = contactInfo.firstname ?? '';
      const lastName = contactInfo.lastname ?? '';
      const name = firstName ? `${firstName} ${lastName}` : '';
      const phone = contactInfo.phone ?? '';
      const birthday = contactInfo.birthday ?? '';
      const city = (contactInfo.city ?? '').toLowerCase();
      const state = (contactInfo.state ?? '').toLowerCase();
      const zip_code = contactInfo.zipcode ?? '';
      const birth_year = typeof birthday === 'string' ? birthday.slice(-4) : '';
      const birth_day =
        typeof birthday === 'string' ? birthday.slice(0, 2) : '';
      const birth_month =
        typeof birthday === 'string' ? birthday.slice(3, 5) : '';
      const country_code = region.toLowerCase();

      identifyEvent(userId, {
        name,
        email,
        firstName: firstName.toLowerCase(),
        lastName: lastName.toLowerCase(),
        birth_year,
        birth_month,
        birth_day,
        phone,
        country_code,
        state,
        city,
        zip_code,
        loggedIn: !!session,
      });
    }
  }, [session, Router.asPath, Router.isReady]);
};
