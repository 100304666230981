export default {
  availability: [
    '2022-03-15',
    '2022-03-16',
    '2022-03-17',
    '2022-03-18',
    '2022-03-19',
    '2022-03-20',
    '2022-03-21',
    '2022-03-22',
    '2022-03-23',
    '2022-03-24',
    '2022-03-25',
    '2022-03-26',
    '2022-03-27',
    '2022-03-28',
    '2022-03-29',
    '2022-03-30',
    '2022-03-31',
    '2022-04-01',
    '2022-04-02',
    '2022-04-03',
    '2022-04-04',
    '2022-04-05',
    '2022-04-06',
    '2022-04-07',
    '2022-04-08',
    '2022-04-09',
    '2022-04-10',
    '2022-04-11',
    '2022-04-12',
    '2022-04-13',
    '2022-04-14',
    '2022-04-15',
    '2022-04-16',
    '2022-04-17',
    '2022-04-18',
    '2022-04-19',
    '2022-04-20',
    '2022-04-21',
    '2022-04-22',
    '2022-04-23',
    '2022-04-24',
    '2022-04-25',
    '2022-04-26',
    '2022-04-27',
    '2022-04-28',
    '2022-04-29',
    '2022-04-30',
    '2022-05-01',
    '2022-05-02',
    '2022-05-03',
    '2022-05-04',
    '2022-05-05',
    '2022-05-06',
    '2022-05-07',
    '2022-05-08',
    '2022-05-09',
    '2022-05-10',
    '2022-05-11',
    '2022-05-12',
    '2022-05-13',
    '2022-05-14',
    '2022-05-15',
    '2022-05-16',
    '2022-05-17',
    '2022-05-18',
    '2022-05-19',
    '2022-05-20',
    '2022-05-21',
    '2022-05-22',
    '2022-05-23',
    '2022-05-24',
    '2022-05-25',
    '2022-05-26',
    '2022-05-27',
    '2022-05-28',
    '2022-05-29',
    '2022-05-30',
    '2022-05-31',
    '2022-06-01',
    '2022-06-02',
    '2022-06-03',
    '2022-06-04',
    '2022-06-05',
    '2022-06-06',
    '2022-06-07',
    '2022-06-08',
    '2022-06-09',
    '2022-06-10',
    '2022-06-11',
    '2022-06-12',
    '2022-06-13',
    '2022-06-14',
    '2022-06-15',
    '2022-06-16',
    '2022-06-17',
    '2022-06-18',
    '2022-06-19',
    '2022-06-20',
    '2022-06-21',
    '2022-06-22',
    '2022-06-23',
    '2022-06-24',
    '2022-06-25',
    '2022-06-26',
  ],
  brands: [
    {
      available: true,
      helper_brand_logo: {
        filename: 'Opel.png',
        height: 112,
        id: 'attDtKYOozkoyCtwS',
        size: 2833,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/f0c02babb214af05854cdba7ab186ac9/eeeeec20',
            width: 3000,
          },
          large: {
            height: 112,
            url: 'https://dl.airtable.com/.attachmentThumbnails/4dffacc95ab19dcf63cbd3109317a3b4/c562681a',
            width: 112,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/f23ed83de4eda7aca3d73989b30024b5/99c30852',
            width: 36,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/5098dd647d373939017dbc2cab6b7fa9/ccbb3f60/Opel.png',
        width: 112,
      },
      id: 'Opel',
      models: [
        { available: true, id: 'Mokka e' },
        { available: true, id: 'Crossland' },
        { available: true, id: 'Zafira Life' },
        { available: true, id: 'Corsa' },
        { available: true, id: 'Grandland' },
        { available: true, id: 'Mokka' },
        { available: true, id: 'Zafira e Life' },
        { available: true, id: 'Insignia ST' },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'Jeep.png',
        height: 112,
        id: 'attEviGMxjUNG62DA',
        size: 5379,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/4c2489cccbb17ddd0f3462b713ed737d/443e8b4a',
            width: 3000,
          },
          large: {
            height: 112,
            url: 'https://dl.airtable.com/.attachmentThumbnails/fa6e5c2bb7033617af5acc8682dce256/25d655e6',
            width: 172,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/ad2e0be5a3c9fe5964bf6d0b3255fe49/a70f406f',
            width: 55,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/a00ddb814b46427e77b15931c9423690/d31480f7/Jeep.png',
        width: 172,
      },
      id: 'Jeep',
      models: [{ available: true, id: 'Compass' }],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'Tesla.png',
        height: 112,
        id: 'att9LEiKJliqVh8Jj',
        size: 7052,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/146399ea75f98f9613d9329c0621e799/a46157d1',
            width: 3000,
          },
          large: {
            height: 112,
            url: 'https://dl.airtable.com/.attachmentThumbnails/a12283fb3aeea6542356fdf5b0a31906/ea4332ad',
            width: 276,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/d59975410492334d979a37437fbb311b/9f3c5683',
            width: 89,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/1aa3ba3c18099c3e69ec8400516cf77a/5495cfc8/Tesla.png',
        width: 276,
      },
      id: 'Tesla',
      models: [
        { available: true, id: 'Model 3' },
        { available: true, id: 'Model Y' },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'Fiat.png',
        height: 112,
        id: 'attEJM5YdfCT8y9QZ',
        size: 10300,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/b17d8573834c9315808afc4af9d04874/550cf7df',
            width: 3000,
          },
          large: {
            height: 112,
            url: 'https://dl.airtable.com/.attachmentThumbnails/c87c629366bd0d45a6ab801e8512d64e/6e30f214',
            width: 112,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/b517c81cba5b53c26aeba8e8ab427628/5b1ab7ab',
            width: 36,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/5a0c6774c9da5b6416d2863186738ac6/13fcdaa9/Fiat.png',
        width: 112,
      },
      id: 'Fiat',
      models: [{ available: true, id: '500' }],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'Mitsubishi_logo.png',
        height: 2048,
        id: 'attQkgTUzjWxlMXJH',
        size: 261892,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/a1a5da08fb033dab03bbe93e9ee39987/25daa4c9',
            width: 3000,
          },
          large: {
            height: 512,
            url: 'https://dl.airtable.com/.attachmentThumbnails/7a9f82644479e73cab71edb4074447af/f57908eb',
            width: 595,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/4b3e1d99a315cf7498e61f1d9e07c3b1/c3891b00',
            width: 42,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/22cfd227d5ccaa5d59b3df84585ff161/a9c9495f/Mitsubishi_logo.png',
        width: 2381,
      },
      id: 'Mitsubishi',
      models: [{ available: true, id: 'Eclipse Cross' }],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'skoda_bw.png',
        height: 704,
        id: 'attYSmWE4ffYQ0pS7',
        size: 62197,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/979e79c5d50f2527e06c954f56bd1572/8231818d',
            width: 3000,
          },
          large: {
            height: 591,
            url: 'https://dl.airtable.com/.attachmentThumbnails/3adaf63a1d7ffe9ac050f57f5099f11c/6521468d',
            width: 512,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/c1a5d2e9595aa77104ab07277477ef38/c3ba89d6',
            width: 31,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/d37592a301d7201447940afac3af3b8f/5a84bff9/skoda_bw.png',
        width: 610,
      },
      id: 'Skoda',
      models: [{ available: true, id: 'Scala' }],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'Polestar_wordmark_pos.png',
        height: 1363,
        id: 'attuJ3kj9XlTxPLEm',
        size: 45898,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/098e551202dfe665cc83bf35d05f0f4f/a9ecdece',
            width: 3000,
          },
          large: {
            height: 512,
            url: 'https://dl.airtable.com/.attachmentThumbnails/8189a6c094ef7e80fa3c2669f8f22d7b/26c0de82',
            width: 1128,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/85bd2c9756e0019e360f279abb66a633/1626025c',
            width: 79,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/3416c402a73eaa8913d1f84b9eb116be/62e87058/Polestar_wordmark_pos.png',
        width: 3003,
      },
      id: 'Polestar',
      models: [{ available: true, id: '2' }],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'NOUVEAU-LOGO-DACIA.jpeg',
        height: 675,
        id: 'att4rxDC9bzKx0AfT',
        size: 12227,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/510025ceae8e75d1c24a086ee795215e/c1f04826',
            width: 3000,
          },
          large: {
            height: 512,
            url: 'https://dl.airtable.com/.attachmentThumbnails/b7e388ace2826674d18f880155a2b1f9/4b0835b4',
            width: 910,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/e9c13dd9435cbae1b6edea26e65f2c33/1ada4d4a',
            width: 64,
          },
        },
        type: 'image/jpeg',
        url: 'https://dl.airtable.com/.attachments/2c5f524202291a9f7156bd9274ce2059/8c6456d3/NOUVEAU-LOGO-DACIA.jpeg',
        width: 1200,
      },
      id: 'Dacia',
      models: [{ available: true, id: 'Duster' }],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'Kia Logo .png',
        height: 603,
        id: 'attQAUEX0t4FAj8j1',
        size: 42263,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/c38fcbb6d9ae424e66b4e872cdcac088/8c55d9a4',
            width: 3000,
          },
          large: {
            height: 512,
            url: 'https://dl.airtable.com/.attachmentThumbnails/a473e08cfe75c19fdb7dafca2b5acebe/eb9128f0',
            width: 2174,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/a36f1da5f5e44c3ce7b67177524b2a99/9907f596',
            width: 153,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/2bdef05af391a222286b61f78215e8ff/bebe9c0d/KiaLogo.png',
        width: 2560,
      },
      id: 'Kia',
      models: [{ available: true, id: 'e-Niro' }],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'renault-2021-new4211.jpeg',
        height: 650,
        id: 'atte7C8iQs245aNZC',
        size: 25658,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/52ab4e4e861a8b24c0fbf655846e199c/5400e798',
            width: 3000,
          },
          large: {
            height: 512,
            url: 'https://dl.airtable.com/.attachmentThumbnails/60c48a940fec60a9de39dadc702d0581/07b90842',
            width: 682,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/d946fbabf4d4f68943705ce241ed9ad0/d9e6dfcb',
            width: 48,
          },
        },
        type: 'image/jpeg',
        url: 'https://dl.airtable.com/.attachments/1bb8ac7fef2359920b5e2541b69ac0f2/0113bbfd/renault-2021-new4211.jpeg',
        width: 866,
      },
      id: 'Renault',
      models: [
        { available: true, id: 'Kadjar' },
        { available: true, id: 'Zoe' },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'nissan-brand-logo-1200x938-1594842787.jpeg',
        height: 938,
        id: 'attbReqyUjrEWFnV8',
        size: 52416,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/2a3487abdb51b2e734dcfa2be75e4f3f/c294b40f',
            width: 3000,
          },
          large: {
            height: 512,
            url: 'https://dl.airtable.com/.attachmentThumbnails/de19afb47354e5194cb7ad07377f9538/7b5a39fb',
            width: 655,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/d0d3d818e5f893dc6533a436aca8c9e0/6e4034d7',
            width: 46,
          },
        },
        type: 'image/jpeg',
        url: 'https://dl.airtable.com/.attachments/d61f13b0624499e279baacde89c176e9/1e405f8a/nissan-brand-logo-1200x938-1594842787.jpeg',
        width: 1200,
      },
      id: 'Nissan',
      models: [{ available: true, id: 'Qashqai' }],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'BMW.png',
        height: 112,
        id: 'attfANzw9sVJenEjB',
        size: 3703,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/612656356e6b84966ea411496424bd6a/fca41a5a',
            width: 3000,
          },
          large: {
            height: 112,
            url: 'https://dl.airtable.com/.attachmentThumbnails/f0d17b00817d0a97f53c60fd327a0f62/102d112b',
            width: 112,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/f6542cf5eb446c5a857bf15e983c82ef/293bb1aa',
            width: 36,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/c046ef9e2f4fd88c7bd7b63643a4631f/7d14b18c/BMW.png',
        width: 112,
      },
      id: 'BMW',
      models: [
        { available: true, id: 'iX3' },
        { available: true, id: 'i3' },
        { available: true, id: '7er Limousine' },
      ],
    },
    {
      available: true,
      helper_brand_logo: {
        filename: 'Hyundai.png',
        height: 112,
        id: 'atthciDLARsA6jD7T',
        size: 7355,
        thumbnails: {
          full: {
            height: 3000,
            url: 'https://dl.airtable.com/.attachmentThumbnails/2c5f19d997db6c77fb37c1c968c2cd92/b52d5b17',
            width: 3000,
          },
          large: {
            height: 112,
            url: 'https://dl.airtable.com/.attachmentThumbnails/d783b327c59f4c654e6b4754e3f78229/eee76cfc',
            width: 176,
          },
          small: {
            height: 36,
            url: 'https://dl.airtable.com/.attachmentThumbnails/b9662b0de02f102408534bce9989e198/b546a5ad',
            width: 57,
          },
        },
        type: 'image/png',
        url: 'https://dl.airtable.com/.attachments/e017107d7550f948a50e35e7debc45c2/d3c7fcb9/Hyundai.png',
        width: 176,
      },
      id: 'Hyundai',
      models: [{ available: true, id: 'Tucson' }],
    },
  ],
  cartypes: ['Kleinwagen', 'Kombi', 'Limousine', 'SUV', 'Van'],
  colors: [
    { color_hex: '#FFFFFF', id: 'Wei\u00df' },
    { color_hex: '#3C3C3C', id: 'Dunkelgrau' },
    { color_hex: '#C4C4C4', id: 'Silber' },
    { color_hex: '#000000', id: 'Schwarz' },
    { color_hex: '#5E5E5E', id: 'Grau' },
    { color_hex: '#8F1E1E', id: 'Rot' },
    { color_hex: '#FF8D00', id: 'Orange' },
    { color_hex: '#1D478A', id: 'Blau' },
    { color_hex: '#000000', id: 'Gr\u00fcn' },
    { color_hex: '#570f17', id: 'Dunkelrot' },
  ],
  fuels: [
    'Benzin',
    'Benzin (mild-hybrid)',
    'Diesel',
    'Elektro',
    'Plug-In-Hybrid',
  ],
  gearshifts: ['Automatik', 'Manuell'],
  has_hitch: [false, true],
  is_young_driver: [null],
  max_price: 2199,
  min_price: 299,
  models: [
    'Compass',
    'Mokka e',
    'Crossland',
    '500',
    'Zafira Life',
    'Eclipse Cross',
    'Scala',
    'Model 3',
    'Corsa',
    '2',
    'Model Y',
    'Duster',
    'e-Niro',
    'Grandland',
    'Mokka',
    'Kadjar',
    'Qashqai',
    'Tucson',
    'iX3',
    'Zoe',
    'Zafira e Life',
    'Insignia ST',
    'i3',
    '7er Limousine',
  ],
  payment_method: ['stripe_debit_sepa', 'stripe_credit_card'],
  terms: [1, 6, 12, 13, 18, 24],
};
