import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalSubtitle,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { Skeleton } from '@finn/design-system/atoms/skeleton';
import { AddTime } from '@finn/design-system/icons/add-time';
import { SwapHoriz } from '@finn/design-system/icons/swap-horiz';
import {
  Deal,
  getSwapDateRange,
  parseSubscriptionEndDate,
} from '@finn/platform-modules';
import { ModalKey, useCloseModal, useOpenModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useMergeFilterValues } from '../filters-management';
import { ActionButton } from './components/ActionButton';
import { useProlongationData } from './hooks/useProlongationData';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedSubscription?: Deal;
  location?: string;
};

export const RetentionModal = ({
  open,
  onClose,
  selectedSubscription,
}: Props) => {
  const i18n = useIntl();
  const mergeFilterValues = useMergeFilterValues();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const prolongationData = useProlongationData(selectedSubscription?.id);
  const openSwappablePLPPage = useCallback(
    (subscription: Deal) => {
      const { parsedEndDate } = parseSubscriptionEndDate(subscription);

      interactionTrackingEvent(TrackingEventName.SWAP_OPTION_CLICKED, {
        location: 'Retention Campaign Card PLP',
        SubscriptionId: subscription?.id,
      });

      mergeFilterValues(getSwapDateRange(parsedEndDate));
    },
    [mergeFilterValues]
  );

  const openProlongationModal = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.PROLONGATION_OPTION_CLICKED, {
      location: 'Retention Campaign Card PLP',
      SubscriptionId: selectedSubscription?.id,
    });
    closeModal(ModalKey.RETENTION_MODAL);
    openModal(ModalKey.PROLONGATION_MODAL);
  }, [closeModal, openModal, selectedSubscription?.id]);

  const title = i18n.formatMessage({ id: 'plp.retentionModal.title' });
  const description = i18n.formatMessage({
    id: 'plp.retentionModal.description',
  });
  const prolongTitle = i18n.formatMessage({
    id: 'plp.retentionModal.prolong.title',
  });
  const prolongDescription = i18n.formatMessage({
    id: 'plp.retentionModal.prolong.description',
  });
  const swapTitle = i18n.formatMessage({
    id: 'plp.retentionModal.swap.title',
  });
  const swapDescription = i18n.formatMessage({
    id: 'plp.retentionModal.swap.description',
  });

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(isOpen) => !isOpen && onClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <ModalSubtitle>{description}</ModalSubtitle>
        </ModalHeader>
        <div className="flex flex-col gap-4">
          {prolongationData.isLoading ? (
            <Skeleton className="bg-pearl !mb-4 h-[60px] w-full !rounded-sm" />
          ) : (
            !!prolongationData.data?.prolongation_options?.length && (
              <ActionButton
                Icon={AddTime}
                title={prolongTitle}
                description={prolongDescription}
                onClick={openProlongationModal}
              />
            )
          )}
          <ActionButton
            Icon={SwapHoriz}
            title={swapTitle}
            description={swapDescription}
            onClick={() => openSwappablePLPPage(selectedSubscription)}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
