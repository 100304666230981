import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { cn } from '@finn/ui-utils';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { setForBusinessCookie } from '../../../helpers';
import {
  FilterKey,
  FilterValuesObject,
  useFilterValues,
  useReplaceFilterValues,
  useResultsCount,
} from '../../filters-management';
import { trackFiltersCleared } from '../../tracking';
import { FilterTagList } from './FilterTagList';
import { SortBy } from './individual-filters/SortBy';

const removeProductGroupFilter = (
  filters: FilterValuesObject
): FilterValuesObject => {
  return Object.fromEntries(
    Object.entries(filters).filter(([key]) => key !== FilterKey.PRODUCT_GROUP)
  );
};

export const CombinedFiltersTags = () => {
  const i18n = useIntl();
  const isMobile = useMediaQuery('md');
  const resultsCount = useResultsCount();
  const filterValues = useFilterValues();
  const replaceFilterValues = useReplaceFilterValues();
  const isFilterButtonExp = useIsABVariant(Features.ExpPLPFilterButton);

  const filteredFilterValues = removeProductGroupFilter(filterValues);
  const appliedFiltersCount = Object.entries(filteredFilterValues).length ?? 0;

  const handleReset = useCallback(() => {
    replaceFilterValues({});
    setForBusinessCookie(false);
    trackFiltersCleared();
  }, [replaceFilterValues]);

  return (
    <div
      className={cn(
        'z-[7] flex items-center bg-white md:top-0',
        isFilterButtonExp ? 'mb-6' : 'sticky top-[68px] mb-[14px] min-h-12',
        appliedFiltersCount === 0 && { 'h-4 min-h-0 overflow-hidden': isMobile }
      )}
    >
      <div className="flex items-center">
        <div
          className={cn('pr-2', {
            'hidden md:inline-block': !isFilterButtonExp,
          })}
        >
          <span className="body-12-light">{`${resultsCount} ${i18n.formatMessage({ id: 'plp.cars' })}`}</span>
        </div>
        {appliedFiltersCount > 0 && (
          <Button
            variant="action"
            size="sm"
            className="hidden md:block"
            data-testid="reset-filters"
            onClick={handleReset}
          >
            {i18n.formatMessage({ id: 'plp.clearFilter' })}
          </Button>
        )}
      </div>

      <>
        <div className="relative ml-4 mr-2 min-h-[29px] grow">
          <div
            className={cn(
              'absolute bottom-0 left-0 right-0 top-0 h-full w-full',
              { 'hidden md:block': isFilterButtonExp }
            )}
          >
            <FilterTagList />
          </div>
        </div>
      </>

      {!isMobile && <SortBy />}
    </div>
  );
};
