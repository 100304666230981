import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  FilterKey,
  FiltersResponse,
  FiltersState,
} from '../../../filters-management';
import { FilterTestID } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { FilterAccordion } from '../atoms/FilterAccordion';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';
import { PriceSelector } from './PriceSelector';

type Props = {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onClick: FilterOptionClickFunction;
};

export const PriceFilter = ({
  selectedFilters,
  availableFilters,
  onClick,
}: Props) => {
  const i18n = useIntl();
  const trackFilterOpen = useTrackFilterCategoryClicked('price');
  const selectedRange: [number, number] = useMemo(
    () => [selectedFilters.min_price, selectedFilters.max_price],
    [selectedFilters.min_price, selectedFilters.max_price]
  );

  const isPriceSelected = useMemo(() => {
    return selectedRange.some((price) => price !== undefined);
  }, [selectedRange]);

  return (
    <FilterAccordion
      title={i18n.formatMessage({ id: 'plp.price' })}
      count={isPriceSelected && 1}
      onOpen={trackFilterOpen}
      testId={FilterTestID.PriceFilter}
    >
      <PriceSelector
        selectedRange={selectedRange}
        availableRange={[
          availableFilters.min_price,
          availableFilters.max_price,
        ]}
        isPriceSelected={isPriceSelected}
        onPriceSelection={onClick}
        minPriceKey={FilterKey.MIN_PRICE}
        maxPriceKey={FilterKey.MAX_PRICE}
      />
    </FilterAccordion>
  );
};
