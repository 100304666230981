import axios from 'axios';
import useSWR from 'swr';

const currentHandoverDateFetcher = async (path: string): Promise<string> => {
  let handoverDate = '';
  try {
    const response = await axios.get<{ handoverDateString: string }>(
      `${window.origin}/${path}`
    );
    handoverDate = response.data.handoverDateString as string;
  } catch {
    console.error('Error fetching handover date');
  }

  return handoverDate;
};

export const useCurrentHandoverDateCKO = (dealId: string) => {
  const { data } = useSWR<string>(
    dealId ? `api/checkout/deal/${dealId}/handoverDate` : null,
    currentHandoverDateFetcher
  );

  return data;
};
