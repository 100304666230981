import { Button } from '@finn/design-system/atoms/button';
import { Add } from '@finn/design-system/icons/add';
import { Remove } from '@finn/design-system/icons/remove';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { FilterData, FilterToggleData } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { MultiSelectFilter } from '../atoms/MultiSelectFilter';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';

const MODEL_LIMIT = 3;

type Props = {
  data: FilterData;
  brand: string;
  onOptionClick: FilterOptionClickFunction;
};

export const ModelSubFilter = ({ brand, data, onOptionClick }: Props) => {
  const i18n = useIntl();
  const [showAll, setShowAll] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const trackMoreClicked = useTrackFilterCategoryClicked('models_more');

  const updatedData = useMemo(
    () => ({
      ...data,
      availableFilterValues: showAll
        ? data.availableFilterValues
        : data.availableFilterValues.slice(0, MODEL_LIMIT),
    }),
    [data, showAll]
  );

  const warning = useMemo(() => {
    const isBrandModelSelected = (
      data.availableFilterValues as FilterToggleData[]
    ).find((value) => value.selected);

    const selectedModels = data.selectedValues;

    return selectedModels.length > 0 && !isBrandModelSelected
      ? i18n.formatMessage({ id: 'plp.pleaseSelectModel' })
      : undefined;
  }, [data.availableFilterValues, data.selectedValues]);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const toggleShowAll = useCallback(() => {
    trackMoreClicked(!showAll, { brand });
    setShowAll(!showAll);
  }, [brand, showAll, trackMoreClicked]);

  return (
    <MultiSelectFilter
      title={brand}
      data={updatedData}
      warning={warning}
      reverse={true}
      expanded={isOpen}
      onOpen={toggleOpen}
      onOptionClick={onOptionClick}
    >
      {data.availableFilterValues.length > MODEL_LIMIT && (
        <Button
          variant="ghost"
          size="sm"
          className="-ml-4 h-10 w-full justify-start pl-2.5"
          onClick={toggleShowAll}
        >
          {showAll ? (
            <Remove className="mr-2.5 min-h-5 min-w-5" />
          ) : (
            <Add className="mr-2.5 min-h-5 min-w-5" />
          )}
          {i18n.formatMessage({ id: showAll ? 'plp.less' : 'plp.more' })}
        </Button>
      )}
    </MultiSelectFilter>
  );
};
