import { VEHICLE_VAT } from '@finn/ua-constants';
import { useMemo } from 'react';

import { getForBusinessCookie } from '../../../helpers';
import { GenericVehicleDetails } from '../../product-cards';
import { getDefaultTerm } from '../helpers/getDefaultTerm';

export const getKmPackagePriceWithoutVat = (
  price: number,
  isForBusiness: Boolean
) => (isForBusiness ? Math.round(Number(price) / VEHICLE_VAT) : Number(price));

type Params = {
  term?: number;
  isDownPayment?: boolean;
  kilometerPackage?: number;
  downPaymentAmount?: number;
  vehicle: GenericVehicleDetails;
};

type PriceListParams = {
  isDownPayment: boolean;
  downPaymentAmount: number;
  vehicle: GenericVehicleDetails;
};

type BasePriceParams = {
  term: number;
  isForBusiness: boolean;
  priceList: number | { [key: string]: number };
};

type KmPackagePriceParams = {
  isForBusiness: boolean;
  kilometerPackage: number;
  vehicle: GenericVehicleDetails;
};

const getPriceList = ({ vehicle, isDownPayment }: PriceListParams) => {
  if (isDownPayment) {
    return vehicle?.downpayment_prices?.available_price_list;
  }

  return vehicle?.price?.available_price_list;
};

const getBasePrice = ({
  term,
  priceList,
  isForBusiness,
}: BasePriceParams): number => {
  const priceKey = `${isForBusiness ? 'b2b' : 'b2c'}_${term}`;

  return typeof priceList === 'object' ? (priceList?.[priceKey] ?? 0) : 0;
};

const getKilometerPackagePrice = ({
  vehicle,
  isForBusiness,
  kilometerPackage,
}: KmPackagePriceParams): number => {
  const packagePrice = vehicle?.price?.[`extra_${kilometerPackage}`] ?? 0;

  return getKmPackagePriceWithoutVat(packagePrice, isForBusiness);
};

export const calculateTotalPrice = (params: Params): number => {
  const {
    term,
    vehicle,
    kilometerPackage = 0,
    downPaymentAmount = 0,
    isDownPayment = false,
  } = params;

  if (!vehicle) {
    return 0;
  }

  const isForBusiness = getForBusinessCookie();
  const adjustedTerm = term || getDefaultTerm(vehicle);

  const priceList = getPriceList({
    vehicle,
    isDownPayment,
    downPaymentAmount,
  });

  const basePrice = getBasePrice({
    priceList,
    isForBusiness,
    term: adjustedTerm,
  });

  const kmPackagePrice = getKilometerPackagePrice({
    vehicle,
    isForBusiness,
    kilometerPackage,
  });

  return basePrice + kmPackagePrice;
};

export const useCalculatePrice = (params: Params) => {
  return useMemo(() => calculateTotalPrice(params), [params]);
};
