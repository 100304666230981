import { useReadyValue } from '@finn/platform-modules';
import {
  CookieKeys,
  getClientBooleanCookie,
  setClientBooleanCookie,
} from '@finn/ui-utils';
import dayjs from 'dayjs';

import { FILTER_DATE_FORMAT } from './modules/filters/helpers';

const EXPIRATION_DAYS = 30;

const today = dayjs();
export const DATE_IN_14_DAYS = today.add(14, 'day').format(FILTER_DATE_FORMAT);
export const DATE_IN_30_DAYS = today.add(30, 'day').format(FILTER_DATE_FORMAT);

export const setForBusinessCookie = (value: boolean) =>
  setClientBooleanCookie(CookieKeys.IS_FOR_BUSINESS, value, EXPIRATION_DAYS);

export const getForBusinessCookie = () =>
  getClientBooleanCookie(CookieKeys.IS_FOR_BUSINESS);

export const useForBusinessCookie = () => {
  return useReadyValue(getForBusinessCookie(), false);
};
