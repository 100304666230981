import { createContext, ReactNode, useContext } from 'react';

import { FilterValuesObject } from '../helpers/filter-parser';

const ImplicitFilterContext = createContext<FilterValuesObject>({});

type Props = {
  filters: FilterValuesObject;
  children: ReactNode;
};

export const ImplicitFilterProvider = ({ filters, children }: Props) => (
  <ImplicitFilterContext.Provider value={filters}>
    {children}
  </ImplicitFilterContext.Provider>
);

export const useImplicitFilters = (): FilterValuesObject =>
  useContext(ImplicitFilterContext);
