import { cn } from '@finn/ui-utils';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cva, type VariantProps } from 'class-variance-authority';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

const thumbVariants = cva(
  'block cursor-pointer rounded-full border-2 transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary:
          'border-trustedBlue bg-trustedBlue focus-visible:ring-trustedBlue focus-visible:ring-2  focus-visible:ring-offset-2',
        secondary: 'bg-white shadow-pricing border-white focus-visible:ring-0',
      },
      size: {
        sm: 'h-4 w-4',
        md: 'h-7 w-7',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

const trackVariants = cva('absolute h-full', {
  variants: {
    variant: {
      primary: ' bg-trustedBlue',
      secondary: 'bg-iron',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export type SliderProps = ComponentPropsWithoutRef<
  typeof SliderPrimitive.Root
> &
  VariantProps<typeof thumbVariants> & { size?: 'sm' | 'md' };

const Slider = forwardRef<ElementRef<typeof SliderPrimitive.Root>, SliderProps>(
  ({ className, variant, size, ...props }, ref) => {
    const values = props.value || props.defaultValue;

    return (
      <SliderPrimitive.Root
        ref={ref}
        className={cn(
          'relative flex w-full touch-none select-none items-center',
          className
        )}
        {...props}
      >
        {/** we do weard small size when variant secondary for back compability
         * we should fix it after figma aligned TODO
         */}
        <SliderPrimitive.Track
          className={cn(
            'bg-pearl relative h-0.5 w-full grow overflow-hidden rounded-full',
            size === 'sm' || variant === 'secondary' ? 'h-0.5' : 'h-1'
          )}
        >
          <SliderPrimitive.Range className={trackVariants({ variant })} />
        </SliderPrimitive.Track>
        {values?.map?.((_, index) => (
          <SliderPrimitive.Thumb
            key={index}
            className={thumbVariants({ variant, size })}
          />
        ))}
      </SliderPrimitive.Root>
    );
  }
);

Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
