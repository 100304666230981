import { CookieKeys, getClientCookie } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import CheckoutContext from '~/contexts/Checkout';
import { useVoucherStore } from '~/modules/checkout/stores/useVoucherStore';
import { CartInfo } from '~/types/checkout';
import {
  ReferralVoucherData,
  VoucherClass,
  VoucherType,
} from '~/types/voucher';

import { useClosedUserGroup } from '../contexts/ClosedUserGroup';

const getVoucherData = (): ReferralVoucherData => {
  const referralVoucherCookieData = getClientCookie(
    CookieKeys.REFERRAL_VOUCHER_CODE
  );

  return JSON.parse(referralVoucherCookieData ?? '{}') as ReferralVoucherData;
};

export const isValidRelativePartnerVoucher = (
  voucherData: ReferralVoucherData
) => {
  return (
    voucherData?.class === VoucherClass?.closed_user_group &&
    voucherData?.is_valid &&
    voucherData?.value_rel
  );
};

export const isValidPartnerLP = (routerPath: string) => {
  if (routerPath.includes('/partnerships/')) {
    return true;
  }
  if (routerPath.includes('/club')) {
    return true;
  }

  return false;
};

const isValidPath = (routerPath: string) => {
  if (isValidPartnerLP(routerPath)) {
    return true;
  }

  if (routerPath.includes('/checkout/')) {
    return true;
  }
  const regex = /\/pdp.*\//;
  if (regex.test(routerPath)) {
    return true;
  }

  return false;
};

const getPartnerDiscountFromDeal = (cartInfo: Partial<CartInfo>): number => {
  if (cartInfo?.voucherClass !== VoucherClass.closed_user_group) {
    return 0;
  }
  if (cartInfo?.voucherType !== VoucherType.RELATIVE) {
    return 0;
  }
  if (!cartInfo?.voucherAmount || !cartInfo?.originalAmount) {
    return 0;
  }

  return cartInfo.voucherAmount / cartInfo.originalAmount;
};

export const usePartnerDiscount = () => {
  const [partnerDiscount, setPartnerDiscount] = useState(0);
  const { cartInfo } = useContext(CheckoutContext);
  const { closedUserGroupId } = useClosedUserGroup();
  const partnerDiscountFromDeal = getPartnerDiscountFromDeal(cartInfo);
  const hasVoucher = useVoucherStore((state) => state.hasVoucher);
  const router = useRouter();
  const isRouterReady = router.isReady;
  const routerPath = router.asPath;
  useEffect(() => {
    if (!isRouterReady || !isValidPath(routerPath) || closedUserGroupId) {
      return;
    }
    const voucherData = getVoucherData();
    if (isValidRelativePartnerVoucher(voucherData)) {
      setPartnerDiscount(voucherData.value_rel);
    }
  }, [hasVoucher, isRouterReady, routerPath]);

  return partnerDiscountFromDeal || partnerDiscount;
};
