import { Button } from '@finn/design-system/atoms/button';
import { FilterListDefault } from '@finn/design-system/icons/filter-list-default';
import { Tune } from '@finn/design-system/icons/tune';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  FiltersState,
  useAvailableFiltersForState,
  useFilterState,
  useFilterValues,
  useInitialFilterValues,
  useMergeFilterValues,
} from '../../../filters-management';
import { FiltersDrawer } from '../FiltersDrawer';
import { SortBy } from '../individual-filters/SortBy';
import { FiltersGroup } from './FiltersGroup';

export const CombinedFilters = memo(() => {
  const i18n = useIntl();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const mergeFilterValues = useMergeFilterValues();
  const filterValues = useFilterValues();

  const initialValues = useInitialFilterValues(filterValues);

  const toggleDrawer = useCallback(
    (isOpen: boolean) => {
      setIsDrawerOpen(isOpen);
    },
    [setIsDrawerOpen]
  );

  const availableFilters = useAvailableFiltersForState(filterValues);

  const [filterState, setFilterState] = useFilterState(
    availableFilters,
    initialValues
  );
  const availableFiltersFromState = useAvailableFiltersForState(filterState);

  const handleFiltersDesktop = useCallback(
    (selectedFilters: FiltersState) => {
      const updatedFilters = setFilterState(selectedFilters);
      mergeFilterValues(updatedFilters);
    },
    [mergeFilterValues]
  );

  const isFilterButtonExp = useIsABVariant(Features.ExpPLPFilterButton);
  const amountOfFilters = Object.values(filterState).filter((val) =>
    Array.isArray(val) ? val.length > 0 : !!val
  ).length;

  return (
    <>
      {!isFilterButtonExp && (
        <div className="sticky top-0 z-[999] flex gap-4 bg-white pt-4 md:hidden">
          <Button
            className="hover:bg-snow min-h-[52px] w-full border-black px-4 py-2 hover:text-black"
            variant="outline"
            size={'lg'}
            onClick={() => toggleDrawer(true)}
          >
            <FilterListDefault className="max-h-4 max-w-4" />
            {i18n.formatMessage({ id: 'plp.filterButton' })}
          </Button>
          <SortBy />
        </div>
      )}
      {isFilterButtonExp && (
        <div className="fixed bottom-8 left-4 right-4 z-[999] flex gap-4 pt-4 sm:bottom-4 md:hidden">
          <Button
            className="w-full rounded-full border"
            variant="secondaryWhite"
            size="lg"
            onClick={() => toggleDrawer(true)}
          >
            <Tune className="mr-2 max-h-5 max-w-5" />
            Filtern und sortieren{' '}
            {amountOfFilters > 0 && <>({amountOfFilters})</>}
          </Button>
        </div>
      )}
      <FiltersDrawer
        isOpen={isDrawerOpen}
        availableFilters={availableFiltersFromState}
        onToggleDrawer={toggleDrawer}
      />

      <div className="hidden md:block">
        <FiltersGroup
          filtersData={filterState}
          availableFilters={availableFilters}
          onClick={handleFiltersDesktop}
        />
      </div>
    </>
  );
});

CombinedFilters.displayName = 'CombinedFilters';
