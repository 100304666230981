import {
  CampaignCardCosmicMetadata,
  useIsCosmicHelperEnabled,
} from '@finn/ui-cosmic';
import { useMemo } from 'react';

export type CampaignCardProps = {
  slug: string;
  title: string;
  metadata: CampaignCardCosmicMetadata;
};

export const useCampaignCards = (campaignData?: CampaignCardProps[]) => {
  const isCosmicHelperEnabled = useIsCosmicHelperEnabled();

  const activeCampaigns = useMemo(
    () =>
      campaignData?.filter((campaign) => campaign.metadata.enabled === true),
    [campaignData]
  );

  const campaignsToShow = isCosmicHelperEnabled
    ? campaignData
    : activeCampaigns;

  return campaignsToShow;
};
