import {
  config,
  generateLocalizationHeaders,
  traceabilityHeadersBrowser,
} from '@finn/ui-utils';
import axios from 'axios';

export const instance = axios.create({
  baseURL: config.VOUCHER_API_URL,
  validateStatus: (responseStatusCode) => 200 === responseStatusCode,
});

export const applyVoucher = async (
  voucherCode: string,
  dealId: number,
  locale: string,
  traceabilityHeaders?: Record<string, string>
) => {
  const params = {
    voucher_code: voucherCode,
    deal_id: dealId,
  };
  if (!traceabilityHeaders) {
    traceabilityHeaders = traceabilityHeadersBrowser();
  }
  const { data, status } = await instance.post('/apply', params, {
    headers: {
      ...generateLocalizationHeaders(locale),
      ...traceabilityHeaders,
    },
  });

  return { ...data, status };
};

export const removeVoucher = async (
  voucherCode: string,
  dealId: number,
  locale: string,
  traceabilityHeaders?: Record<string, string>
) => {
  const params = {
    voucher_code: voucherCode,
    deal_id: dealId,
  };
  if (!traceabilityHeaders) {
    traceabilityHeaders = traceabilityHeadersBrowser();
  }
  const { data, status } = await instance.post('/unapply', params, {
    headers: {
      ...generateLocalizationHeaders(locale),
      ...traceabilityHeaders,
    },
  });

  return { ...data, status };
};

export const getVoucherByHash = async (
  hashedVoucherCode: string,
  locale: string,
  traceabilityHeaders?: Record<string, string>
) => {
  const params = {
    hashed_voucher_code: hashedVoucherCode,
  };
  if (!traceabilityHeaders) {
    traceabilityHeaders = traceabilityHeadersBrowser();
  }
  try {
    const { data, status } = await instance.post('/validate_by_hash', params, {
      headers: {
        ...generateLocalizationHeaders(locale),
        ...traceabilityHeaders,
      },
    });

    return { ...data, status };
  } catch (error) {
    const { data, status } = error?.response ?? {};

    return { data, status };
  }
};
