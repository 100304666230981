import { config, CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

export const useSetTestCookieForDev = () => {
  useEffect(() => {
    if (['development', 'staging', 'preview'].includes(config.APP_STAGE)) {
      const testCookie = Cookies.get(CookieKeys.E2E_TEST_IN_PROGRESS);
      if (!testCookie) {
        Cookies.set(CookieKeys.E2E_TEST_IN_PROGRESS, 'yes');
      }
    }
  }, []);
};
