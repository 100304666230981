import { Button } from '@finn/design-system/atoms/button';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from '@finn/design-system/atoms/drawer';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  defaultFilterValues,
  FiltersResponse,
  useAvailableFiltersForState,
  useFilterState,
  useFilterValues,
  useInitialFilterValues,
  useMergeFilterValues,
  useResultsCount,
} from '../../filters-management';
import {
  trackFilterDrawerClosed,
  trackFilterDrawerOpened,
} from '../../tracking';
import { FiltersGroup } from './grouped-filters/FiltersGroup';

type Props = {
  isOpen: boolean;
  availableFilters: FiltersResponse;
  onToggleDrawer: (isOpen: boolean) => void;
};

export const FiltersDrawer = ({
  isOpen,
  availableFilters,
  onToggleDrawer,
}: Props) => {
  const i18n = useIntl();
  const mergeFilterValues = useMergeFilterValues();

  const filterValues = useFilterValues();
  const initialValues = useInitialFilterValues(filterValues);
  const [filterState, setFilterState] = useFilterState(
    availableFilters,
    initialValues
  );
  const availableFiltersFromState = useAvailableFiltersForState(filterState);
  const resultsCount = useResultsCount(filterState);

  useEffect(() => {
    if (isOpen) {
      trackFilterDrawerOpened();
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    trackFilterDrawerClosed();
    setFilterState(initialValues);
    onToggleDrawer(false);
  }, [initialValues]);

  const handleReset = useCallback(() => {
    setFilterState(defaultFilterValues);
  }, [initialValues]);

  const handleSubmit = useCallback(() => {
    mergeFilterValues(filterState);
    onToggleDrawer(false);
  }, [filterState]);

  return (
    <Drawer open={isOpen} onClose={handleClose} direction="bottom">
      <DrawerContent
        className="!-p-4"
        contentClassName="py-0"
        title={i18n.formatMessage({ id: 'plp.filters' })}
      >
        <div className="z-10 -mx-4 h-full overflow-auto">
          <FiltersGroup
            filtersData={filterState}
            availableFilters={availableFiltersFromState}
            onClick={setFilterState}
          />
        </div>

        <DrawerFooter className="h-min flex-row py-4">
          <Button
            className="h-14 w-full"
            variant="secondaryWhite"
            size="sm"
            onClick={handleReset}
          >
            {i18n.formatMessage({ id: 'general.reset' })}
          </Button>
          <Button
            className="h-14 w-full"
            variant="primary"
            size="sm"
            onClick={handleSubmit}
          >
            {i18n.formatMessage(
              { id: 'plp.showResultsWithCount' },
              { count: resultsCount }
            )}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
