import { GenericVehicleDetails } from '../../product-cards';

export const getDefaultTerm = (
  vehicle: Pick<
    GenericVehicleDetails,
    'default_downpayment_term' | 'available_terms'
  >
) => {
  return (
    vehicle.default_downpayment_term ??
    Math.max(...(vehicle.available_terms ?? []))
  );
};
