import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FilterKey, FiltersState } from '../../../filters-management';
import { FilterData, FilterToggleData, TestProps } from '../../helpers';
import { useSortByOptions } from '../../hooks/useSortByOptions';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { SingleSelectFilter } from '../atoms/SingleSelectFilter';

type Props = TestProps & {
  selectedFilters: FiltersState;
  onOptionClick: (filterObject: FiltersState) => void;
};

export const SortFilter = ({ selectedFilters, onOptionClick }: Props) => {
  const i18n = useIntl();
  const trackFilterOpen = useTrackFilterCategoryClicked(FilterKey.SORT);
  const options = useSortByOptions();

  const selectedValues = selectedFilters[FilterKey.SORT];

  const availableFilterValues: FilterToggleData[] = options.map(
    (configItem) => ({
      value: configItem.value,
      label: configItem.label,
      selected: selectedValues?.includes(configItem.value),
    })
  );

  const data = useMemo<FilterData>(
    () => ({
      filterKey: FilterKey.SORT,
      selectedValues,
      availableFilterValues,
    }),
    [options, selectedValues]
  );

  const handleSortClick = useCallback(
    (value) => {
      onOptionClick(value);
    },
    [onOptionClick]
  );

  return (
    <SingleSelectFilter
      title={i18n.formatMessage({ id: 'plp.sort' })}
      selectedValue={selectedValues}
      defaultValue="default"
      data={data}
      onClick={handleSortClick}
      onOpen={trackFilterOpen}
      shouldReturnArray={false}
    />
  );
};
