import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { Image } from '@finn/ui-components';
import { CampaignCardCosmicMetadata, CosmicImageData } from '@finn/ui-cosmic';
import {
  getCloudinaryImgUrl,
  getCloudinarySrcSet,
  useIntersectionObserver,
} from '@finn/ui-utils';
import Link from 'next/link';
import { useCallback, useEffect, useRef } from 'react';

import { CampaignCardProps } from './hooks/useCampaignCards';

const ASPECT_RATIO_WIDTH = 296;
const ASPECT_RATIO_HEIGHT = 176;

const initialData: CampaignCardCosmicMetadata = {
  title: '',
  link: '#',
  enabled: true,
  default_position: 0,
  background_color: '#0000',
  icon: { url: '', cloudinary: false },
  image: { url: '', cloudinary: false },
};

const getCloudinaryOptions = () => ({
  w: ASPECT_RATIO_WIDTH,
  h: ASPECT_RATIO_HEIGHT,
  dpr: 2,
  c: 'fill',
  fl: 'progressive',
});

type ImageContainerProps = {
  title: string;
  cloudinaryURL: string;
  cloudinarySrcSet: string;
  image?: CosmicImageData;
};

const ImageContainer = ({
  title,
  cloudinaryURL,
  cloudinarySrcSet,
}: ImageContainerProps) => (
  <div className="overflow-hidden">
    <Image
      alt={title}
      src={cloudinaryURL}
      srcSet={cloudinarySrcSet}
      className="h-full w-full object-cover align-middle transition-transform duration-300 ease-in-out hover:scale-105"
    />
  </div>
);

export const CampaignCard = ({
  campaignData,
}: {
  campaignData?: CampaignCardProps;
}) => {
  const { slug, metadata } = campaignData || {};
  const { image, title, link, background_color } = metadata || initialData;

  const containerRef = useRef<HTMLAnchorElement | null>(null);
  const isInView = useIntersectionObserver(containerRef, 1, '0px', true);

  const cloudinaryOptions = getCloudinaryOptions();
  const cloudinaryURL = getCloudinaryImgUrl(image?.url, cloudinaryOptions);
  const cloudinarySrcSet = getCloudinarySrcSet(image?.url, [
    cloudinaryOptions.h,
  ]);

  const handleClick = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.CAMPAIGN_CARD_CLICKED, {
      campaign_id: slug,
    });
  }, [slug]);

  useEffect(() => {
    if (isInView) {
      interactionTrackingEvent(TrackingEventName.CAMPAIGN_CARD_VIEWED, {
        campaign_id: slug,
      });
    }
  }, [isInView, slug]);

  return (
    <Link
      href={link}
      passHref
      ref={containerRef}
      style={{ backgroundColor: background_color || '#000000' }}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      className="flex min-h-full flex-col overflow-hidden rounded-md"
    >
      <>
        {image && (
          <ImageContainer
            image={image}
            title={title}
            cloudinaryURL={cloudinaryURL}
            cloudinarySrcSet={cloudinarySrcSet}
          />
        )}
        <div className="p-4 md:p-6">
          <p className="body-14-semibold md:global-t5-semibold m-0 text-white">
            {title}
          </p>
        </div>
      </>
    </Link>
  );
};
