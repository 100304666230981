import { showToast } from '@finn/design-system/atoms/toast';
import { CountryCode, useCurrentLocale } from '@finn/ui-utils';
import { useIntl } from 'react-intl';
import useSWRInfinite from 'swr/infinite';

import {
  FiltersResponse,
  FilterValuesObject,
  VehicleViewKey,
} from '../../filters-management';
import { GetVehiclesParams, GetVehiclesResponse } from '../../fleet';
import { GenericVehicleDetails } from '../../product-cards';
import { fetchProductListItems } from '../helpers/fetchers';

export const DEFAULT_LIMIT = 9;
export const IN_STOCK_LIMIT = 24;
export const OUT_OF_STOCK_LIMIT = 6;
export const DEDUPING_INTERVAL_MS = 1000 * 60 * 2; // 2 minutes

export type GetProductListItemsParams = GetVehiclesParams;

export type GetProductListItemsResponse = GetVehiclesResponse;

export type ProductListItem = GenericVehicleDetails;

const getInfiniteLoadKey =
  (params: GetProductListItemsParams) =>
  (pageIndex: number, prevData: GetProductListItemsResponse | null) => {
    if (!prevData) {
      return params;
    }

    if (!params.offset && !prevData.offset) {
      return null;
    }

    return {
      ...params,
      offset: params.offset ?? prevData.offset,
    };
  };

export type UseProductListItemsParams = {
  filters?: FilterValuesObject;
  offset?: number;
  limit?: number;
  zipCode?: string;
};

export type UseProductListItemsConfig = {
  infinite?: boolean;
  fallbackData?: GetProductListItemsResponse;
};

type UseProductListResult = {
  products: ProductListItem[];
  offset: number;
  limit: number;
  total: number;
  isLoading: boolean;
  isValidating: boolean;
  loadNextPage(): void;
};

export type FiltersType = {
  [key: string]: FiltersResponse;
};

export const useProductListItems = (
  params: UseProductListItemsParams = {},
  config?: UseProductListItemsConfig
): UseProductListResult => {
  const { locale, region } = useCurrentLocale();
  const i18n = useIntl();

  if (!params.filters) {
    params.filters = {};
  }
  if (!params.filters.view) {
    params.filters.view =
      region === CountryCode.US
        ? VehicleViewKey.AVAILABLE_AND_COMING_SOON
        : VehicleViewKey.AVAILABLE;
  }
  if (!params.limit) {
    params.limit = DEFAULT_LIMIT;
  }

  const { data, isLoading, isValidating, setSize } =
    useSWRInfinite<GetProductListItemsResponse>(
      getInfiniteLoadKey({
        ...params,
        zipCode: region === CountryCode.US ? params?.zipCode : undefined,
        locale,
      }),
      fetchProductListItems,
      {
        fallbackData: config?.fallbackData ? [config.fallbackData] : undefined,
        keepPreviousData: true,
        dedupingInterval: DEDUPING_INTERVAL_MS,
        revalidateFirstPage: false,
        revalidateOnFocus: false,
        onError: () => {
          if (config?.fallbackData) {
            showToast({
              status: 'warning',
              title: i18n.formatMessage({ id: 'plp.slowInternet.title' }),
              description: i18n.formatMessage({
                id: 'plp.slowInternet.description',
              }),
            });
          }
        },
      }
    );

  const lastResponse = data?.[data.length - 1] ?? null;

  const products = config?.infinite
    ? (data?.reduce((acc, cur) => {
        acc.push(...cur.results);

        return acc;
      }, [] as ProductListItem[]) ?? [])
    : (lastResponse?.results ?? []);

  return {
    products,
    offset: lastResponse?.offset ?? 0,
    limit: params?.limit ?? DEFAULT_LIMIT,
    total: lastResponse?.total_results ?? 0,
    isLoading,
    isValidating,
    loadNextPage: () => {
      if (
        !isLoading &&
        !isValidating &&
        typeof lastResponse?.offset === 'number' &&
        lastResponse?.offset > 0
      ) {
        setSize((size) => size + 1);
      }
    },
  };
};
