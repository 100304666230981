import { BRAND, PLP_SEO_HEADER_TEMPLATES } from '@finn/ua-constants';
import {
  getCurrentMonthInShort,
  getCurrentYear,
  replaceStrKeys,
} from '@finn/ui-utils';
import get from 'lodash/get';

import { type FilterValuesObject } from '../../filters-management';
import { FilterKey } from '../../filters-management/hooks/useGetFilters';
import { isElectricVehicle } from '../../product-cards';
import { getInitialFilterKeysMap } from './seo-header';

type FilterArrays = Pick<
  FilterValuesObject,
  | typeof FilterKey.BRANDS
  | typeof FilterKey.MODELS
  | typeof FilterKey.TERMS
  | typeof FilterKey.CAR_TYPES
  | typeof FilterKey.FUELS
>;

export const isSingleSelectItem = (
  filters: FilterArrays,
  filterKey: FilterKey
) => filters[filterKey]?.length === 1;

export const getMetaDataKey = (filters: FilterValuesObject): string => {
  const considerBrands = isSingleSelectItem(filters, FilterKey.BRANDS);
  const considerCarTypes = isSingleSelectItem(filters, FilterKey.CAR_TYPES);
  const considerModels = isSingleSelectItem(filters, FilterKey.MODELS);
  const considerElectricFuelType =
    isSingleSelectItem(filters, FilterKey.FUELS) &&
    isElectricVehicle(filters[FilterKey.FUELS][0]);
  const considerFuelType = isSingleSelectItem(filters, FilterKey.FUELS);

  //TODO: clean up combinations, names, and also SEO HEADERS in Cosmic on PLP object
  switch (true) {
    case considerBrands && considerCarTypes:
      return PLP_SEO_HEADER_TEMPLATES.BRAND_CARTYPES;
    case considerBrands && considerModels:
      return PLP_SEO_HEADER_TEMPLATES.BRAND_MODELS;
    case considerBrands && considerFuelType:
      return PLP_SEO_HEADER_TEMPLATES.BRAND_FUELTYPE;
    case considerBrands:
      return PLP_SEO_HEADER_TEMPLATES.BRANDS;
    case considerCarTypes:
      return PLP_SEO_HEADER_TEMPLATES.CARTYPES;
    case considerElectricFuelType:
      return PLP_SEO_HEADER_TEMPLATES.ELECTRIC;
    case !!filters.has_deals:
      return PLP_SEO_HEADER_TEMPLATES.DEALS;
    case !!filters.is_young_driver:
      return PLP_SEO_HEADER_TEMPLATES.YOUNG_DRIVER;
    case !!filters.has_hitch:
      return PLP_SEO_HEADER_TEMPLATES.HITCH;
    default:
      return PLP_SEO_HEADER_TEMPLATES.FALLBACK;
  }
};

type SeoInfos<T> = {
  vehiclesCount: number;
  minPrice: string;
  seoHeader: T;
  finnBrand?: string;
  filters: FilterValuesObject;
  initialFilterKeysMap: ReturnType<typeof getInitialFilterKeysMap>;
};

export const getSingleSelectItem = (
  filters: FilterArrays,
  filterKey: FilterKey
) => (filters[filterKey]?.length === 1 ? filters[filterKey][0] : '');

// Replace seo keys data with real values
export const getSeoHeader = <T>({
  vehiclesCount,
  minPrice,
  seoHeader,
  finnBrand = BRAND,
  filters,
}: SeoInfos<T>) => {
  const brand = getSingleSelectItem(filters, FilterKey.BRANDS);
  const model = getSingleSelectItem(filters, FilterKey.MODELS);
  const fuel = getSingleSelectItem(filters, FilterKey.FUELS);
  const cartype = getSingleSelectItem(filters, FilterKey.CAR_TYPES);

  const title = get(seoHeader, 'metadata.title', '');
  const description = get(seoHeader, 'metadata.description', '');
  const seoData = {
    month: getCurrentMonthInShort(),
    year: getCurrentYear(),
    price: minPrice,
    model,
    brand,
    fuel,
    cartype,
    count: vehiclesCount,
    finn: finnBrand,
  };
  const actualTitle = replaceStrKeys(title, seoData);
  const actualDescription = replaceStrKeys(description, seoData);

  return {
    ...seoHeader,
    metadata: {
      ...(seoHeader as any)?.metadata,
      title: actualTitle,
      description: actualDescription,
      og_description: actualDescription,
      og_title: actualTitle,
    },
  };
};
