import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { useIntl } from 'react-intl';

export const useProductLabel = (
  { availability, product_label }: GenericVehicleDetails,
  partnerDiscount?: number
) => {
  const { formatMessage } = useIntl();

  const label =
    (!availability && formatMessage({ id: 'plp.outOfStock' })) ||
    (!partnerDiscount && product_label?.label);

  return label;
};
