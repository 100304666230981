import { stringify } from 'query-string';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  useFilterValues,
  useReplaceFilterValues,
} from '../../filters-management';

const isFilterEmpty = (values: any) => {
  // return true if string empty
  // return false if string not empty
  let newValues = values;
  if (typeof values === 'number') {
    newValues = values.toString(); // can't stringify number
  }

  return !stringify(newValues, {
    arrayFormat: 'none',
    skipNull: true,
  });
};

export const ZeroProduct = () => {
  const i18n = useIntl();
  const filterValues = useFilterValues();
  const replaceFilterValues = useReplaceFilterValues();

  const isEmpty = isFilterEmpty(filterValues);

  return (
    <div className="grid min-h-[340px] place-items-center">
      <div className="flex flex-col items-center justify-center gap-4 px-1 py-0">
        <span className="mobile-t4-semibold md:web-t4-semibold text-center">
          {i18n.formatMessage({ id: 'plp.noCarsFound' })}
        </span>
        <span className="body-16-regular text-center">
          {i18n.formatMessage({ id: 'plp.adjustFilters' })}
        </span>
        {isEmpty ? null : (
          <div
            onClick={() => replaceFilterValues({})}
            role="button"
            tabIndex={0}
          >
            <div className="flex items-center">
              <p className="body-16-semibold cursor-pointer hover:underline">
                {i18n.formatMessage({ id: 'plp.clearFilter' })}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
