export * from '@finn/ua-tracking';
import { TrackingEventName } from '@finn/ua-tracking';
import {
  GACustomDimensions,
  GenericVehicleDetails,
  SelectedProductInfo,
} from '@finn/ua-vehicle';

import { CheckoutStepOrder } from './checkout';

// General interaction event
export type TrackingEventProperties = GACustomDimensions & {
  category: string;
  action: string;
  label: string;
  value?: string | number;
};

// https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-step-viewed
export type EcommerceCheckoutStep = GACustomDimensions & {
  eventName: TrackingEventName;
  checkout_id: number | string; // Deal ID
  step?: CheckoutStepOrder;
  subStep?: string; // step is numeric, 1 denotes contact, 2 denotes payment so introducing a subStep to distinguish between contact and address
  shipping_method?: string;
  payment_method?: string;
};

export type ReferralModalEvent = {
  eventName: TrackingEventName;
};

export type ReferralOptionEvent = {
  eventName: TrackingEventName;
  channel: string;
};

// https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-started
export type EcommerceCheckout = GACustomDimensions & {
  order_id: string | number; // dealId
  affiliation?: string; // Store or affiliation from which this transaction occurred
  value: number; // Revenue with discounts and coupons added in
  shipping: number; // Delivery cost
  tax: number; // tax for the whole term  DE: Price / (1 + TAX_RATE), US: Tax from tax api * term
  monthlyTax?: number;
  coupon?: string; // Voucher code
  discount?: number; // For example: spacial offers
  currency?: number; // EURO | USD
  productInfo?: SelectedProductInfo;
  vehicles: Array<GenericVehicleDetails | undefined>;
  insurance?: string;
  isProlongation?: boolean;
};
