import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Ref, useCallback } from 'react';

import { FilterKey } from '../../../filters-management/hooks/useGetFilters';
import { trackFilterOptionClicked } from '../../tracking';
import { FilterCheckbox } from '../atoms/FilterCheckbox';
import { handleMultiSelect } from '../atoms/FilterOptions';
import { FilterOptionClickFunction } from './FiltersGroup';

export type PopularFilterToggleData = {
  label: string;
  selected?: boolean;
  value: string | number | boolean;
  disabled?: boolean;
};

export type PopularFilterData = {
  key: FilterKey;
  data: PopularFilterToggleData;
  selectedValues?: any;
};

type Props = {
  filters: PopularFilterData[];
  onClick: FilterOptionClickFunction;
};

export const PopularFiltersOptions = ({ filters, onClick }: Props) => {
  const handleClick = useCallback(
    ({ key, data, selectedValues }: PopularFilterData) => {
      const { value, selected } = data;
      const selectProps = {
        value,
        selected,
        onClick,
        filterKey: key,
        selectedValues,
      };

      if (value) {
        trackFilterOptionClicked(FilterKey.POPULAR, `${key}_${value}`);
      }

      if (typeof value === 'boolean') {
        onClick({ [key]: !selected });
      } else {
        handleMultiSelect(selectProps);
      }
    },
    [onClick, trackFilterOptionClicked, handleMultiSelect]
  );

  const animationRef = useAutoAnimate(null);

  return (
    <div className="w-full" ref={animationRef as Ref<HTMLDivElement>}>
      {filters.map((filter) => (
        <FilterCheckbox
          key={`${filter.key}-${filter.data.value}`}
          disabled={filter.data.disabled}
          label={filter.data.label}
          selected={filter.data.selected}
          onClick={() => handleClick(filter)}
        />
      ))}
    </div>
  );
};
