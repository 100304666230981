import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  FilterKey,
  FiltersResponse,
  FiltersState,
} from '../../../filters-management';
import { FilterTestID } from '../../helpers';
import { useTrackFilterCategoryClicked } from '../../tracking';
import { MiscFilter } from '../atoms/MiscFilter';
import { BusinessBlurb } from '../BusinessBlurb';
import { FilterOptionClickFunction } from '../grouped-filters/FiltersGroup';
import { PriceSelector } from './PriceSelector';

type OptionsFilterProps = {
  selectedFilters: FiltersState;
  availableFilters: FiltersResponse;
  onOptionClick: FilterOptionClickFunction;
};

type FilterData = {
  filterKey: FilterKey[];
  availableFilterValues: string[];
};

export const BusinessFilter = ({
  selectedFilters,
  onOptionClick,
  availableFilters,
}: OptionsFilterProps) => {
  const i18n = useIntl();
  const trackFilterOpen = useTrackFilterCategoryClicked('B2B');

  const filterData: FilterData = useMemo(() => {
    const allKeysAndValues = [
      {
        key: FilterKey.IS_FOR_BUSINESS,
        value: i18n.formatMessage({ id: 'plp.forBusiness' }),
      },
    ];

    return {
      filterKey: allKeysAndValues.map((item) => item.key),
      availableFilterValues: allKeysAndValues.map((item) => item.value),
    };
  }, [i18n]);

  const selectedRange: [number, number] = useMemo(
    () => [selectedFilters.min_price_msrp, selectedFilters.max_price_msrp],
    [selectedFilters.min_price_msrp, selectedFilters.max_price_msrp]
  );

  const isPriceSelected = useMemo(() => {
    return selectedRange.some((price) => price !== undefined);
  }, [selectedRange]);

  return (
    <>
      <MiscFilter
        data={filterData}
        onOpen={trackFilterOpen}
        onOptionClick={onOptionClick}
        selectedValues={[Boolean(selectedFilters.is_for_business)]}
        title={i18n.formatMessage({
          id: 'plp.businessFilter.title',
        })}
        testId={FilterTestID.BusinessFilter}
      >
        <span className="body-12-semibold mt-4">
          {i18n.formatMessage({ id: 'plp.businessFilter.msrpTitle' })}
        </span>

        <PriceSelector
          selectedRange={selectedRange}
          availableRange={[
            availableFilters.min_price_msrp,
            availableFilters.max_price_msrp,
          ]}
          isPriceSelected={isPriceSelected}
          onPriceSelection={onOptionClick}
          minPriceKey={FilterKey.MIN_PRICE_MSRP}
          maxPriceKey={FilterKey.MAX_PRICE_MSRP}
        />

        <BusinessBlurb />
      </MiscFilter>
    </>
  );
};
