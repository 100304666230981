import { cn, getFormattedPrice } from '@finn/ui-utils';
import { useIntl } from 'react-intl';

import { useForBusinessCookie } from '../../helpers';
import { ProductImage } from './components/ProductImage';
import { ModelCardWithType } from './types';

type Props = {
  modelInfo: ModelCardWithType;
  onModelCardSelected: () => void;
};

export const ModelCard = ({ modelInfo, onModelCardSelected }: Props) => {
  const i18n = useIntl();
  const forBusinessCookie = useForBusinessCookie();

  const { brand, model, imageURL, configsCount, displayPrice } = modelInfo;
  const brandModelLabel = `${brand} ${model}`;
  const priceFormatted = getFormattedPrice(i18n, displayPrice, 0);
  const perMonthLabel = i18n.formatMessage({
    id: !forBusinessCookie ? 'general.perMonth' : 'general.perMonthWithoutVat',
  });

  return (
    <div
      onClick={onModelCardSelected}
      className="relative flex h-full w-full flex-col overflow-hidden hover:cursor-pointer"
    >
      <div className="bg-snow relative rounded">
        <ProductImage vehiclePictureURL={imageURL} altText={brandModelLabel} />
      </div>

      <div className="mt-3 grid gap-3">
        <h5
          className={cn(
            'body-14-semibold sm:body-16-semibold overflow-hidden text-ellipsis py-1 sm:text-nowrap'
          )}
        >
          {brandModelLabel}
        </h5>

        {/* Configs amount section */}
        <div className="relative flex items-center overflow-hidden text-ellipsis whitespace-nowrap">
          <span className="py-0.5">
            <p className="body-12-light sm:body-14-light">
              {configsCount} {i18n.formatMessage({ id: 'plp.cars' })}
            </p>
          </span>
        </div>

        {/* Price section */}
        <div className="body-14-semibold sm:body-16-semibold">
          <div className="row-y-2 mb-1 flex flex-wrap items-center pb-2 md:mb-1.5">
            {i18n.formatMessage({ id: 'plp.priceFrom' })}&nbsp;
            {priceFormatted}&nbsp;
            <div className="body-12-light sm:body-14-light">
              {perMonthLabel}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
