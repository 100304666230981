import { useDeals } from '@finn/platform-modules';
import dayjs from 'dayjs';
import { useMemo } from 'react';

const MAX_MONTHS_BEFORE_RETURN = 4;

export const useReadyForRetentionDeals = () => {
  const { activeDeals, dealsStatus } = useDeals();

  // as we work with state: ACTIVE deals, it means that any deal in activeDeals
  // already has handover_appointment_date which is in the past
  const activeSubscriptions = useMemo(
    () =>
      activeDeals?.filter((deal) => {
        // if deal already prolonged we can not prolong it again
        const isDealProlonged = [
          'SWAP_SAME_DAY',
          'SWAP_DIFFERENT_DAY',
          'PROLONGATION',
        ].includes(deal.subscribed_to);

        if (isDealProlonged) {
          return false;
        }

        // return_date is preffered return date if set, or end_date if not
        const endDate = dayjs(deal.return_date || deal.end_date);

        // we check that return not yet done and that return date is within 4 months from now
        return (
          endDate.isAfter(dayjs()) &&
          endDate.isBefore(dayjs().add(MAX_MONTHS_BEFORE_RETURN, 'month'))
        );
      }),
    [activeDeals]
  );

  return { activeSubscriptions, dealsStatus };
};
