import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { Locale } from '@finn/ui-utils';

import {
  FilterKey,
  FilterValuesObject,
  processFilters,
  VehicleViewKey,
} from '../../filters-management';
import { getIsOutOfStock } from '../../product-listing';
import { getVehicles } from '../data/api';
import {
  FiltersType,
  GetProductListItemsParams,
  GetProductListItemsResponse,
  IN_STOCK_LIMIT,
  OUT_OF_STOCK_LIMIT,
} from '../hooks/useProductListItems';
import { deserializeFiltersFromPath } from './parser';

export const fetchProductListItems = async (
  params: GetProductListItemsParams
): Promise<GetProductListItemsResponse> => {
  const vehiclesResponse = await getVehicles(params);

  return {
    offset: vehiclesResponse.offset ?? 0,
    total_results: vehiclesResponse.total_results,
    results: vehiclesResponse.results,
  };
};

export const fetchAdjustedProductListItemsResponse = async (
  filterValues: FilterValuesObject,
  locale: string
): Promise<GetProductListItemsResponse> => {
  return await fetchProductListItems({
    filters: {
      ...filterValues,
      [FilterKey.VIEW]: VehicleViewKey.DISPLAYED_CARS,
    },
    limit: OUT_OF_STOCK_LIMIT,
    locale,
  });
};

export const fetchProductItems = async ({
  locale,
  slugArray,
  availableFilters,
  displayedFilters,
}: {
  locale?: string;
  slugArray?: string[];
  availableFilters?: FiltersType;
  displayedFilters: FiltersType;
}) => {
  const filterLocale = locale === Locale.ENGLISH_USA ? 'en-US' : 'de-DE';
  const filtersResponse = processFilters(
    availableFilters[filterLocale],
    displayedFilters[filterLocale]
  );

  const filterValues = slugArray
    ? deserializeFiltersFromPath(
        [...slugArray].join('/'),
        BASE_SUBSCRIPTION_PATH,
        locale,
        filtersResponse
      )
    : {};

  const isOutOfStock = getIsOutOfStock(filterValues, filtersResponse);
  //IS OUT OF STOCK RETURNS FALSE WHILE IT SHOULD BE TRUE

  const productListItemsResponse = await fetchProductListItems({
    filters: {
      ...filterValues,
      [FilterKey.VIEW]: isOutOfStock
        ? VehicleViewKey.DISPLAYED_CARS
        : locale === Locale.ENGLISH_USA
          ? VehicleViewKey.AVAILABLE_AND_COMING_SOON
          : VehicleViewKey.AVAILABLE,
    },
    limit: isOutOfStock ? OUT_OF_STOCK_LIMIT : IN_STOCK_LIMIT,
    locale,
  });

  return {
    filterValues,
    productListItemsResponse,
    isOutOfStock,
    filtersResponse,
  };
};
