import { convertToEnglish, slugify } from '@finn/ui-utils';
import pick from 'lodash/pick';
import trim from 'lodash/trim';

import { DATE_IN_14_DAYS, DATE_IN_30_DAYS } from '../../../helpers';
import {
  SHORT_14_DAYS_FILTER,
  SHORT_30_DAYS_FILTER,
  SHORT_DEALS_FILTER,
  SHORT_HITCH_FILTER,
  SHORT_YOUNG_DRIVER_FILTER,
} from '../../filters';
import { FiltersResponse, FilterValuesObject } from '../../filters-management';
import { FilterKey } from '../../filters-management/hooks/useGetFilters';
import { FilterMapper } from './filter-mapper';

const FILTER_VALUE_REGEX = /[a-zA-Z\d-()\säöëüÄÖÜßË]+/g;

const FIRST_LEVEL_KEYS = [FilterKey.BRANDS, FilterKey.MODELS];

const SECOND_LEVEL_KEYS = [
  FilterKey.CAR_TYPES,
  FilterKey.GEARSHIFTS,
  FilterKey.FUELS,
  FilterKey.TERMS,
  FilterKey.RETENTION,
  FilterKey.HAS_DEALS,
  FilterKey.IS_YOUNG_DRIVER,
  FilterKey.HAS_HITCH,
];

const THIRD_LEVEL_KEYS = [
  FilterKey.AVAILABLE_FROM,
  FilterKey.AVAILABLE_TO,
  FilterKey.MIN_PRICE,
  FilterKey.MAX_PRICE,
  FilterKey.MIN_PRICE_MSRP,
  FilterKey.MAX_PRICE_MSRP,
  FilterKey.SORT,
  FilterKey.IS_FOR_BUSINESS,
  FilterKey.VIEW,
  FilterKey.FEATURES,
];

/**
 * FilterValueToKeyMap maps possible values of string-valued filters
 * to filter keys. Only supported filter keys are listed keys.
 */
type FilterValueToKeyMap = Map<
  string,
  | {
      key:
        | FilterKey.BRANDS
        | FilterKey.HAS_HITCH
        | FilterKey.IS_YOUNG_DRIVER
        | FilterKey.HAS_DEALS
        | FilterKey.MODELS
        | FilterKey.CAR_TYPES
        | FilterKey.FUELS
        | FilterKey.AVAILABLE_TO
        | FilterKey.AVAILABLE_FROM
        | FilterKey.GEARSHIFTS
        | FilterKey.TERMS
        | FilterKey.RETENTION;

      value: string | number | boolean;
    }
  | undefined
>;

const FILTER_VALUE_SEPARATOR = '.';
const FILTER_GROUP_SEPARATOR = '_';

/**
 * PathFilterMapper allows to serialize and deserialize an instance of FilterMap
 * by parsing a URL of the following format.
 *
 * https://<origin>/<basePathname>/<firstLevelFilters>/<secondLevelFilters>?<thirdLevelFilters>
 *
 * where <firstLevelFilters>, <secondLevelFilters> and <thirdLevelFilters>
 * are optional.
 *
 * The <basePathname> is accepted in the constructor to find the exact place
 * where encoded filter values begin.
 *
 * Both <firstLevelFilters> and <secondLevelFilters> represented as
 * "filter groups". In a filter group, values of filters are separated
 * by FILTER_VALUE_SEPARATOR. Filter groups are separated by
 * FILTER_GROUP_SEPARATOR. A filter group never includes the key of a used
 * filter, but only values. E.g. in the pathname `/subscribe/automatic_gas.mild-hybrid`,
 * where <basePathname> is `/subscribe`, `automatic` represents one of
 * the possible values of the `gearshifts` filter, and `gas.mild-hybrid`
 * represent two possible values of the `fuels` filter.
 *
 * In order to detect filter keys in such a URL, PathFilterMapper builds
 * a map of all filter values to the corresponding filter keys.
 */
export class PathFilterMapper extends FilterMapper {
  private readonly valueToKeyMap: FilterValueToKeyMap;
  private readonly locale: string;

  public constructor(
    basePathname: string,
    locale: string,
    filtersResponse: FiltersResponse
  ) {
    super(basePathname);

    this.valueToKeyMap = PathFilterMapper.makeValueToKeyMap(
      locale,
      filtersResponse
    );

    this.locale = locale;
  }

  public serialize(filterMap: FilterValuesObject): string {
    const firstLevelFilterGroups: string[] = [];
    const secondLevelFilterGroups: string[] = [];

    if (filterMap.brands && filterMap.brands.length > 0) {
      firstLevelFilterGroups.push(
        filterMap.brands
          .map((value) => this.stringifyFilterValue(FilterKey.BRANDS, value))
          .join(FILTER_VALUE_SEPARATOR)
      );

      if (filterMap.models && filterMap.models.length > 0) {
        firstLevelFilterGroups.push(
          filterMap.models
            .map((value) => this.stringifyFilterValue(FilterKey.BRANDS, value))
            .join(FILTER_GROUP_SEPARATOR)
        );
      }
    }

    for (const filterKey of SECOND_LEVEL_KEYS) {
      const filterValue = filterMap[filterKey];
      if (!filterValue) {
        continue;
      }

      secondLevelFilterGroups.push(
        Array.isArray(filterValue)
          ? filterValue
              .map((value) => this.stringifyFilterValue(filterKey, value))
              .join(FILTER_VALUE_SEPARATOR)
          : this.stringifyFilterValue(filterKey, filterValue)
      );
    }

    if (filterMap[FilterKey.AVAILABLE_TO]) {
      const availableTo = filterMap[FilterKey.AVAILABLE_TO];

      if (availableTo === DATE_IN_14_DAYS) {
        secondLevelFilterGroups.push(SHORT_14_DAYS_FILTER);
        delete filterMap.available_to;
      }
      if (availableTo === DATE_IN_30_DAYS) {
        secondLevelFilterGroups.push(SHORT_30_DAYS_FILTER);
        delete filterMap.available_to;
      }
    }

    const filtersPath = [firstLevelFilterGroups, secondLevelFilterGroups]
      .map((group) => group.filter(Boolean).join(FILTER_GROUP_SEPARATOR))
      .filter(Boolean)
      .join('/');

    const fullPathname = `/${trim(this.basePathname, '/')}/${trim(
      filtersPath
    )}`;

    const queryParams = PathFilterMapper.stringifyQueryParams(filterMap);

    return `${fullPathname}${queryParams}`;
  }

  public deserialize(url: string): FilterValuesObject {
    return {
      ...this.parseFilterGroups(url),
      ...PathFilterMapper.parseQueryParams(url),
    };
  }

  private static makeValueToKeyMap(
    locale: string,
    filtersResponse: FiltersResponse
  ): FilterValueToKeyMap {
    const { brands, models, cartypes, fuels, gearshifts, terms } =
      filtersResponse;

    const valueToKeyMap: FilterValueToKeyMap = new Map();

    for (const brand of brands) {
      valueToKeyMap.set(slugify(brand.id), {
        key: FilterKey.BRANDS,
        value: brand.id,
      });
    }

    for (const model of models) {
      valueToKeyMap.set(slugify(model), {
        key: FilterKey.MODELS,
        value: model,
      });
    }

    for (const carType of cartypes) {
      valueToKeyMap.set(slugify(carType), {
        key: FilterKey.CAR_TYPES,
        value: carType,
      });
    }

    for (const fuel of fuels) {
      valueToKeyMap.set(slugify(fuel), { key: FilterKey.FUELS, value: fuel });
    }

    for (const gearshift of gearshifts) {
      valueToKeyMap.set(slugify(gearshift), {
        key: FilterKey.GEARSHIFTS,
        value: gearshift,
      });
    }

    for (const term of terms) {
      valueToKeyMap.set(PathFilterMapper.stringifyTerm(term, locale), {
        key: FilterKey.TERMS,
        value: term,
      });
    }

    valueToKeyMap.set(SHORT_YOUNG_DRIVER_FILTER, {
      key: FilterKey.IS_YOUNG_DRIVER,
      value: 'true',
    });

    valueToKeyMap.set(SHORT_HITCH_FILTER, {
      key: FilterKey.HAS_HITCH,
      value: 'true',
    });

    valueToKeyMap.set(SHORT_14_DAYS_FILTER, {
      key: FilterKey.AVAILABLE_TO,
      value: DATE_IN_14_DAYS,
    });

    valueToKeyMap.set(SHORT_30_DAYS_FILTER, {
      key: FilterKey.AVAILABLE_TO,
      value: DATE_IN_30_DAYS,
    });

    valueToKeyMap.set(SHORT_DEALS_FILTER, {
      key: FilterKey.HAS_DEALS,
      value: 'true',
    });

    valueToKeyMap.set(FilterKey.RETENTION, {
      key: FilterKey.RETENTION,
      value: FilterKey.RETENTION,
    });

    return valueToKeyMap;
  }

  private getFiltersPath(url: string): string {
    const filterPathIndex = url.indexOf(this.basePathname);
    const filterPath = url.substring(
      filterPathIndex + this.basePathname.length
    );

    const queryParamsStart = filterPath.indexOf('?');

    const path = filterPath.substring(
      0,
      queryParamsStart >= 0 ? queryParamsStart : undefined
    );

    return convertToEnglish(decodeURIComponent(path));
  }

  private parseFilterGroups(url: string): FilterValuesObject {
    const filterGroups = trim(this.getFiltersPath(url), '/')
      .split('/')
      .filter(Boolean);

    const filterMapRaw: Partial<Record<FilterKey, unknown>> = {};

    filterGroups.forEach((group, index) => {
      const filterValues = group.match(FILTER_VALUE_REGEX);
      if (filterValues === null) {
        return;
      }

      for (const value of filterValues) {
        const filter = this.valueToKeyMap.get(value);
        if (!filter) {
          continue;
        }

        if (index === 1 && FIRST_LEVEL_KEYS.includes(filter.key)) {
          continue;
        }

        const filterMapRawValueForKey = filterMapRaw[filter.key];

        if (Array.isArray(filterMapRawValueForKey)) {
          filterMapRawValueForKey.push(filter.value);
        } else if (filterMapRawValueForKey) {
          filterMapRaw[filter.key] = [filterMapRawValueForKey, filter.value];
        } else {
          filterMapRaw[filter.key] = filter.value;
        }
      }
    });

    return FilterMapper.parseFilterMap(filterMapRaw);
  }

  private stringifyFilterValue(filterKey: FilterKey, filterValue: unknown) {
    if (filterKey === FilterKey.IS_YOUNG_DRIVER) {
      return SHORT_YOUNG_DRIVER_FILTER;
    }

    if (filterKey === FilterKey.HAS_HITCH) {
      return SHORT_HITCH_FILTER;
    }

    if (filterKey === FilterKey.HAS_DEALS) {
      return SHORT_DEALS_FILTER;
    }

    if (filterKey === FilterKey.TERMS) {
      return PathFilterMapper.stringifyTerm(
        filterValue as number,
        this.locale
      ).toLowerCase();
    }

    return slugify(String(filterValue)).toLowerCase();
  }

  private static stringifyTerm(term: number, locale: string): string {
    let termStr: string;

    switch (locale) {
      case 'de-DE':
        termStr = `${term}-${term > 1 ? 'monate' : 'monat'}`;
        break;

      case 'en-US':
        termStr = `${term}-${term > 1 ? 'months' : 'month'}`;
        break;

      default:
        termStr = String(term);
        break;
    }

    return termStr;
  }

  private static parseQueryParams(url: string): FilterValuesObject {
    const queryParamsStart = url.indexOf('?');
    if (queryParamsStart < 0) {
      return {};
    }

    return FilterMapper.parseFilterMap(
      pick(
        FilterMapper.parseQueryString(url.substring(queryParamsStart)),
        THIRD_LEVEL_KEYS
      )
    );
  }

  private static stringifyQueryParams(filterMap: FilterValuesObject) {
    return FilterMapper.stringifyQueryString(pick(filterMap, THIRD_LEVEL_KEYS));
  }
}
