import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { SortKey } from '../../filters-management';

export type SortByOption = {
  label: string;
  value: SortKey | undefined;
};

export const useSortByOptions = () => {
  const i18n = useIntl();

  return useMemo<SortByOption[]>(
    () => [
      {
        label: i18n.formatMessage({ id: 'plp.defaultSort' }),
        value: SortKey.DEFAULT,
      },
      {
        label: i18n.formatMessage({ id: 'plp.sortLastAdded' }),
        value: SortKey.LAST_ADDED,
      },
      {
        label: i18n.formatMessage({ id: 'plp.sortAsc' }),
        value: SortKey.ASC,
      },
      {
        label: i18n.formatMessage({ id: 'plp.sortDesc' }),
        value: SortKey.DESC,
      },
      {
        label: i18n.formatMessage({ id: 'plp.sortEarliestAvailabilityDate' }),
        value: SortKey.AVAILABILITY,
      },
    ],
    [i18n]
  );
};
