import { Input, InputProps } from '@finn/design-system/atoms/input';
import { useCurrentLocale } from '@finn/ui-utils';
import { RefObject, useCallback } from 'react';
import NumberFormat, { SourceInfo } from 'react-number-format';

import { Range, TestProps } from '../../helpers';

type Props = TestProps & {
  label: string;
  min: number;
  max: number;
  value?: number;
  defaultValue: number;
  currentRange?: Range;
  onChange(nextValue: number | undefined): void;
};

const NumberFormatInput = (
  props: InputProps & {
    ref: RefObject<HTMLInputElement | null>;
  }
) => {
  // Destructuring them to prevent them from passing inside NumberFormat
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const { inputRef, onChange, ref, defaultValue, ...other } = props;

  const { currency } = useCurrentLocale();

  const handleValueChange = useCallback(
    ({ floatValue }, { event }: SourceInfo) => {
      if (!event) {
        return;
      }

      if (!onChange) {
        return;
      }

      onChange({
        ...event,
        target: {
          ...event.target,
          value: String(floatValue),
        } as unknown as EventTarget & HTMLInputElement,
      });
    },
    [onChange]
  );

  return (
    <NumberFormat
      {...other}
      type="text"
      isNumericString
      defaultValue={defaultValue as unknown as string | number | undefined}
      onValueChange={handleValueChange}
      getInputRef={inputRef}
      thousandSeparator="."
      decimalSeparator=","
      suffix={` ${currency}`}
      value={props.value as unknown as string | number | undefined}
    />
  );
};

export const PriceInput = ({
  label,
  min,
  max,
  value,
  defaultValue,
  currentRange,
  onChange,
  testId,
}: Props) => {
  const handleChange = useCallback(
    (event) => {
      onChange(
        Number.isFinite(Number(event.target.value))
          ? Number(event.target.value)
          : undefined
      );
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    if (value === undefined) {
      onChange(defaultValue);

      return;
    }

    if (
      value >= min &&
      value <= max &&
      currentRange?.[0] &&
      currentRange?.[1] &&
      currentRange[0] < currentRange[1]
    ) {
      return;
    }

    if (
      value > max ||
      value < min ||
      (currentRange?.[0] &&
        currentRange?.[1] &&
        currentRange[0] > currentRange[1])
    ) {
      onChange(defaultValue);
    } else {
      onChange(Number.isFinite(Number(value)) ? Number(value) : defaultValue);
    }
  }, [defaultValue, max, min, onChange, value]);

  return (
    <Input
      data-testid={testId}
      className="[&_input]:body-14-regular"
      value={value}
      label={label}
      ExternalInputComponent={NumberFormatInput}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
