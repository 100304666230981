export const BRAND: string = 'finn.com';

export const PLP_SEO_HEADER_TEMPLATES = {
  BRAND_CARTYPES: 'brand_cartype_plp',
  BRAND_MODELS: 'brands_models_plp',
  BRAND_FUELTYPE: 'brand_fueltype_plp',
  BRANDS: 'brands_plp',
  CARTYPES: 'cartypes_plp',
  FUELTYPE: 'fueltype_plp',
  FALLBACK: 'fallback_template_plp',
  REFERRAL: 'referral_plp',
  ELECTRIC: 'electric_plp',
  DEALS: 'deals_plp',
  HITCH: 'hitch_plp',
  YOUNG_DRIVER: 'young_driver_plp',
};

export const DEFAULT_VEHICLES_LIMIT = 9;

export const TAX_RATE = 0.19;

export const VEHICLE_VAT = 1 + TAX_RATE;

export const FALLBACK_ADDITIONAL_MILEAGE_FEE = 0.25;
export const FALLBACK_TK_DEDUCTIBLE = 500;
export const FALLBACK_VK_DEDUCTIBLE = 1000;

export const DELIVERY_PLAN = 0;

export const errorMessages = {
  CAR_NOT_FOUND: 'Car not found',
  CAR_NOT_FOUND_PROLONGATION: 'Prolongation vehicle could not be fetched',
  CAR_NOT_AVAILABLE: 'Car not available',
  AVAILABILITY_CHANGED: 'Availability date has been changed',
  EMAIL_EXISTS: 'Email already exists',
  TERM_NOT_AVAILABLE: 'Term is not available',
  KM_PACKAGE_NOT_AVAILABLE: 'KM Package is not available',
  DEAL_CLOSED_FOR_MISC_REASON: 'Deal closed for misc reason',
  PRICE_NOT_AVAILABLE: 'Price is not available',
  TERM_CHANGED: 'Term is changed',
  PRICE_CHANGED: 'Price is changed',
  EXPIRED_VOUCHER: 'Voucher is not valid anymore',
  REFERRAL_VOUCHER_FAILED: 'Referral voucher could not be applied',
  INVALID_VOUCHER: 'Invalid Voucher',
  SERVER_ERROR: 'Server Error',
  INCORRECT_IBAN: 'Incorrect IBAN',
  STRIPE_NOT_LOADED: 'Stripe Not Loaded',
  STRIPE_ERROR_ON_CHECKOUT_API: 'Stripe Error On Checkout API',
  STRIPE_BANK_TRANSFER_FAILED: 'Stripe Bank Transfer Failed',
  CONFIRM_STRIPE_INTENT_FAILED: 'Confirm Stripe Intent Failed',
  RECONFIRMATION_SUBMISSION_FAILED:
    'Re-Confirmation submission to integromat webhook failed',
  DEAL_CREATION_FAILED: 'Deal Creation Failed',
  CONTACT_UPDATE_FAILED: 'Contact Update Failed',
  ADDRESS_SUBMISSION_FAILED: 'Address Submission Failed',
  CREDIT_CHECK_FAILED: 'Credit Check Failed',
  PAYMENT_FAILED: 'Payment Failed',
  STRIPE_PAYMENT_FAILED: 'Stripe Payment Failed',
  STRIPE_CREATE_PAYMENT_METHOD_FAILED: 'Stripe Create Payment Method Failed',
  CONFIRMATION_FAILED: 'Confirmation Failed',
  SANTANDER_B2B_FINANCIAL_FAILED: 'Santander B2B Financial Failed',
  SANTANDER_B2C_FINANCIAL_FAILED: 'Santander B2C Financial Failed',
  PAGE_LOAD_FAILED: 'Page Load Failed',
  CART_NOT_AVAILABLE: 'Cart Not Available',
  RESTORE_ERROR: 'Restore Error',
};

export const DEFAULT_RETURN_PRICE = 199;
export const INSURANCE_DISCOUNT = 160;

export const SUBSCRIPTION_STATUS_LEVELS = {
  GRANULAR: 'granular',
  DEFAULT: 'overview',
};

export enum AuthErrors {
  USER_DOES_NOT_EXIST = 'user_does_not_exist',
  USER_ALREADY_EXISTS = 'user_already_exists',
  WRONG_PASSWORD = 'wrong_password',
  WRONG_CODE = 'wrong_code',
}

export const MarketingRoutes = { MY_LOYALTY_PROGRAM: '/loyalty' };

export enum ModalActionTypes {
  AFFIRMATIVE = 'affirmative',
  NEGATIVE = 'negative',
}

export enum DRIVER_TYPES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum DRIVER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export const ageBoundaries = {
  MAX_NORMAL_EXP: 100,
  MAX_NORMAL: 75,
  MAX_NORMAL_US: 80,
  MIN_NORMAL: 23,
  MAX_EXCEPTIONAL: 80,
  MIN_EXCEPTIONAL: 18,
  MIN_EXCEPTIONAL_US: 25,
};

export const REFERRAL_VOUCHER_COOKIE_EXPIRATION = 30; // 30d
export const CLOSED_USER_GROUP_COOKIE_EXPIRATION = 30; // 30d

export const LIMIT_VEHICLE_POWER_FOR_YOUNG_AGE = 150;

export const TEST_EMAIL_SUFFIX = '+test@finn.auto';

export const BUSINESSUNIT_ID = '5d1b57e91501f10001573745';

export const TIRES_TYPES = ['summer', 'winter', 'summerWinter', 'allSeason'];
export const TOP_NAV_ICON_SIZE = 110;
export const PRELOVED_LABEL = 'Pre-loved';
export const SWR_CACHE_TIME = 60 * 1000; // 1min
export const SWR_CACHE_TIME_LONG = 10 * 60 * 1000; // 10min
export const BASE_SUBSCRIPTION_PATH = 'subscribe';
export const FLEET_VIEW_DE = 'available-and-coming-soon';
// export const FLEET_VIEW_DE = 'available_cars';

export const WINTER = 'Winter';
export const IS_DIRECT_CHECKOUT_ENABLED = true;
export const NJ_EV_TAXT_EXEMPTION_EMPTY_FORM_FILE_URL =
  'https://finn-website-assets.s3.eu-central-1.amazonaws.com/st4.pdf';

export const TYPEFORM_SURVEY = {
  DE: 'mIMqhwqp',
  US: 'FNxo1qTP',
};

export const COMPARISON_SURVEY_WEBHOOK_URL =
  'https://hook.finn.integromat.cloud/mo5offy4yg57t4vukb6njjh4agcgoq1p';

export const ENABLE_FAKE_DOOR_LOGIN = false;

export const SECTION_HEADING_IDS = {
  CONTACT: 'contact-section-heading',
  ADDRESS: 'address-section-heading',
  DELIVERY: 'delivery-section-heading',
  PAYMENT: 'payment-section-heading',
  PAYMENT_ELEMENT: 'stripe-payment-element',
  CONFIRMATION: 'confirmation-section-heading',
  CONFIRMATION_COMBINED: 'confirmation-submission-button',
};

export const SESSION_STORAGE_LAST_INVALID_EMAIL = 'lastInvalidEmail';

export const RECAPTCHA_SITE_KEY = '6Lc88ywgAAAAAGd_RmVFnggUBIxTrGmjVOewFRrp';

// Set to true to enable Loyalty menu item. false value enables soft launch of Loyalty program where page is available but menu is hidden.
export const SHOW_LOYALTY_MENU_ITEM = true;

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_DELIVERY_FEE = 249;

export const DEFAULT_DOWN_PAYMENT = 1200;
export const DEFAULT_DISCOUNT_PERCENTAGE = 3;
export const EXCLUDED_PATH_FOR_COOKIE_BANNER = ['/privacy', '/imprint'];
export const UPGRADABLE_INSURANCE_VALUE = 1000;
export const COSMIC_SLUGS_UNDER_ACTIVE_DEVELOPMENT = [
  'retention-home-dev',
  'static-thank-you-page-v2',
];

export const SelfServiceRoutes = {
  MYACCOUNT: '/myaccount',
  CONTACT_DETAILS: '/myaccount/contactdetails',
  CHANGE_PASSWORD: '/myaccount/changepassword',
  DRIVER_MGMT: '/myaccount/drivers',
  ADD_DRIVER: '/myaccount/drivers/add',
  COMPANY_DETAILS: '/myaccount/companydetails',
  SERVICES: '/services',
  LOYALTY: '/loyalty',
  INVOICES: '/myaccount/invoices',
  MY_CARS: '/mycars',
};

// we want to enable FullStory session only after certain seconds to avoid recording very small sessions
export const FULL_STORY_INITIALIZATION_DELAY = 10000; // 10 seconds
